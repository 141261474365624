import React from "react";
import "./AfricanVillage.css";

function AfricanVillage() {
  return (
    <div className="african-village">
      {/* Hero Section */}
      <header className="hero" style={{ backgroundImage: `url(${require("../images/AV1.jpg")})` }}>
        <div className="hero-content solid-bg">
          <h1 className="hero-title">Mention GoOurHub To Get 10% Off</h1>
          <p className="hero-subtitle">Experience the rich and diverse taste of African cuisine.</p>
          <button className="order-button">Visit Today</button>
        </div>
      </header>

      {/* About Section */}
      <section className="about">
        <h2>Welcome to African Village</h2>
        <p>Classic Ethiopian/Eritrean dishes like kitfo, tibs & injera, plus veggie options, served with a warm and inviting atmosphere.</p>
        <p>Phone : 210-467-5102</p>
        
        {/* Call to Order */}
        <p className="call-to-order">
          Call: 210-467-5102 to place your order.
          <br />
          <br />
          <span className="discount-note">Mention GoOurHub and get 10% off!</span>
        </p>

        {/* Online Menu Link */}
        <a href="https://places.singleplatform.com/african-village/menu#menu_3052178" target="_blank" rel="noopener noreferrer" className="menu-link">
          View Our Menu
        </a>
      </section>

      {/* Address Section */}
      <section className="address">
        <h3>Find Us Here</h3>
        <p className="highlighted-text">Wurzbach Plaza | 10918 Wurzbach Rd #131, San Antonio, TX 78230</p>
      </section>

      {/* Features Section */}
      <section className="features">
        <div className="feature-box slim-box">
          <h4>Features</h4>
          <ul>
            <li>Serves Happy Hour Food | Serves Vegan Dishes | High Chairs Available</li>
          </ul>
        </div>
      </section>

      {/* Gallery Section */}
      <section className="gallery">
        {[1, 2, 3, 4, 5, 6, 7].map(num => (
          <div key={num} className="gallery-item">
            <img src={require(`../images/AV${num}.jpg`)} alt={`Dish ${num}`} className="gallery-img" />
          </div>
        ))}
      </section>
    </div>
  );
}

export default AfricanVillage;
