import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./ZelleConfirmation.css"; // Ensure your CSS includes styling for the new classes
import zelleQR from "../images/zelle.jpg"; // Adjust path as needed

function ZelleConfirmation() {
  const [confirmationNumber, setConfirmationNumber] = useState("");
  const [note, setNote] = useState("");
  const [pendingData, setPendingData] = useState(null);
  const [promoOfferMessage, setPromoOfferMessage] = useState("");

  useEffect(() => {
    // Retrieve the pending signup data stored from the PartnerSignUp page
    const data = localStorage.getItem("pendingVendorSignup");
    if (data) {
      const parsedData = JSON.parse(data);
      setPendingData(parsedData);

      // Compute promotional offer message based on coupon code
      const coupon = parsedData.couponCode ? parsedData.couponCode.toUpperCase() : "";
      if (coupon === "GOOURHUB50") {
        setPromoOfferMessage("$50.00");
      } else if (coupon === "GOOURHUB100") {
        setPromoOfferMessage("$100.00");
      } else if (coupon === "GOOURHUB@2503") {
        setPromoOfferMessage("$0.00");
      }
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!confirmationNumber.trim()) {
      alert("Please enter the confirmation number.");
      return;
    }
    // Generate a vendor ID (for example, using the current timestamp)
    const vendorId = "V" + Date.now();

    // Combine the pending signup data with the confirmation info and vendor ID
    const vendorData = {
      ...pendingData,
      confirmationNumber,
      note,
      vendorId,
    };

    // Save the complete vendor data (to be used in the Thank You page)
    localStorage.setItem("vendorSignupComplete", JSON.stringify(vendorData));
    // Optionally, remove the pending data now that the process is complete
    localStorage.removeItem("pendingVendorSignup");

    // Redirect to the thank you page
    window.location.href = "/zelle-thank-you";
  };

  return (
    <div className="zelle-confirmation-page">
      {/* QR Code container in the upper right */}
      <div className="qr-code-container">
        <img
        src={zelleQR} 

          alt="Zelle QR Code"
          className="qr-code"
        />
        <p className="qr-text">
          Zelle at goourhub@gmail.com <br /> or scan the QR
        </p>
      </div>

      <div className="confirmation-container">
      <h2 className="zelle-confirmation-heading">Zelle Payment Confirmation</h2>

{/* Updated tagline with an em-dash (—) and new CSS class */}
<p className="confirmation-tagline">
 {/*Connecting you to the world in a snap!*/}
</p>

{/* Promotional Offer in a separate line with new CSS class */}
{promoOfferMessage && (
  <p className="promo-offer">
    Promotional Offer: {promoOfferMessage}
  </p>
)}

        <p className="confirmation-instructions">
          Please enter your Zelle transaction confirmation number along with any additional note (optional).
        </p>
        <form onSubmit={handleSubmit}>
          <label htmlFor="confirmationNumber">Confirmation Number</label>
          <input
            type="text"
            id="confirmationNumber"
            name="confirmationNumber"
            placeholder="Enter confirmation number"
            value={confirmationNumber}
            onChange={(e) => setConfirmationNumber(e.target.value)}
            required
          />

          <label htmlFor="note">Note (optional)</label>
          <textarea
            id="note"
            name="note"
            placeholder="Enter any additional note (optional)"
            value={note}
            onChange={(e) => setNote(e.target.value)}
          ></textarea>

          <button type="submit" className="submit-button">
            Submit Confirmation
          </button>
        </form>
        <p>
          If you need assistance, please visit our{" "}
          <Link to="/faq">FAQs</Link>.
        </p>
      </div>
    </div>
  );
}

export default ZelleConfirmation;
