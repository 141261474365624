import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../logo.png";
import "./ThankYou.css";

const UserThankYou = () => {
  const [currentDate, setCurrentDate] = useState("");
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const today = new Date();
    setCurrentDate(today.toLocaleDateString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    }));

    // Retrieve user data from localStorage
    const data = localStorage.getItem("pendingUserSignup");
    if (data) {
      setUserData(JSON.parse(data));
      localStorage.removeItem("pendingUserSignup"); // cleanup after use
    }
  }, []);

  if (!userData) {
    return (
      <div className="thank-you-page">
        <h2>No Signup Data Found</h2>
        <p>Please contact us for assistance.</p>
        <Link to="/" className="neon-home-button">Back to Home</Link>
      </div>
    );
  }

  const { first_name, user_id, email } = userData;

  return (
    <div className="thank-you-page">
      <div className="thank-you-content">
        <div className="thank-you-logo">
          <img src={logo} alt="GoOurHub Logo" />
          <p className="thank-you-date">{currentDate}</p>
        </div>

        <h1 className="thank-you-title">
          Thank You, {first_name.charAt(0).toUpperCase() + first_name.slice(1)}!
        </h1>

        <p><strong>User ID:</strong> {user_id}</p>
        <p><strong>Email:</strong> {email}</p>

        <p>Your account has been created successfully. We're excited to have you join the community.</p>

        <p>
          Any questions? Feel free to{" "}
          <Link to="/contact" className="thank-you-contact">contact us</Link>.
        </p>

        <Link to="/" className="neon-home-button">Back to Home</Link>
      </div>
    </div>
  );
};

export default UserThankYou;
