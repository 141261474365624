import React, { useState, useEffect } from "react";
import logo from "../images/twobunniesLogo.jpg";
import adGoOurHub from "../images/ad_goourhub.jpg"; // <-- import your ad image
import "./VaduAplu.css";

const images = [
  require("../images/twobunnies1.jpg"),
  require("../images/twobunnies2.jpg"),
  require("../images/twobunnies3.jpg"),
  require("../images/twobunnies4.jpg"),
  require("../images/twobunnies5.jpg"),
  require("../images/twobunnies6.jpg"),
];


function TwoBunnies() {
  const [dealIndex, setDealIndex] = useState(0);
  const [fade, setFade] = useState(true);

  const deals = [
   
    "Mention GoOurHub to get better price/discounts",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setDealIndex((prev) => (prev + 1) % deals.length);
        setFade(true);
      }, 500);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="vaduaplu-container">
      {/* Header */}
      <header className="vaduaplu-header">
        {/* Left: Logo + Name */}
        <div className="vaduaplu-header-left">
          <img src={logo} alt="Logo" className="vaduaplu-logo" />
          <div className="vaduaplu-header-text">
            <h1 className="vaduaplu-company-name"> Two Bunnies
              
            </h1>
            
          </div>
        </div>

        {/* Right: Phone + Address */}
        <div className="vaduaplu-header-right">
          <span className="vaduaplu-phone"> Twobunnies2@icloud.com</span>

<span className="vaduaplu-address">
Pamela Mendoza
  <br/>
  <p className="vaduaplu-text">
    📲 TikTok: <a className = "a-links-vaduaplu" href="https://www.tiktok.com/@2bunnies11" target="_blank" rel="noopener noreferrer">@2bunnies11</a><br />
    📘 Facebook: <a className = "a-links-vaduaplu" href="https://facebook.com/BunniesBunnies" target="_blank" rel="noopener noreferrer">Bunnies Bunnies</a><br />
    📧 Email: <a className = "a-links-vaduaplu" href="mailto:Twobunnies2@icloud.com">Twobunnies2@icloud.com</a><br />
    📞 Text/Call: <a className = "a-links-vaduaplu" href="tel:+18303462593">(830) 346-2593</a>
  </p>
   
  
</span>

        </div>
      </header>

      <div className="vaduaplu-contact-info">
  <p className="vaduaplu-text">
    🐰 <strong>Two Bunnies</strong> – Custom, hand-crafted pens that bring personality, playfulness, and ✨pop✨ to your everyday writing tools.
  </p>

  <p className="vaduaplu-text">
    🖊️ We specialize in vibrant, collectible pens with interchangeable toppers — including character designs, logos, crystals, and more.
  </p>

  <p className="vaduaplu-text">
    🌎 Based in Castroville, TX and serving good vibes across the U.S. — find us online or in person at markets & live events.
  </p>

  

  <p className="vaduaplu-text">
    ✨ Peace. Love. Pens. ✨
  </p>
</div>

      {/* Single Top Box combining Quick Menu & Deals */}
      <div className="vaduaplu-top-box">
        {/* Deals Section */}
        <div className="vaduaplu-top-section">
          <h2 className="vaduaplu-heading">Get Deals</h2>
          {/* Flashing Text */}
          <p className={`vaduaplu-deals-text ${fade ? "fade-in" : "fade-out"}`}>
            {deals[dealIndex]}
          </p>

          {/* Static Image below the deals text */}
          <img
            src={adGoOurHub}
            alt="GoOurHub Ad"
            className="vaduaplu-ad-image"
          />
        </div>

        {/* Quick Menu Section */}
        <div className="vaduaplu-top-section">
          <h2 className="vaduaplu-heading">Our Highlights</h2>
          <div className="vaduaplu-accordion">
            <div className="vaduaplu-accordion-item">
              <input type="checkbox" id="service1" className="hidden-checkbox"defaultChecked />
              <label htmlFor="service1" className="vaduaplu-label" align = "center">
              Our Highlights
              </label>
              <div className="vaduaplu-accordion-content">
              <ul className="vaduaplu-list">
  <li className="vaduaplu-list-item">
    🐰 <strong>Two Bunnies</strong> – Where pens get personality! Handcrafted with care and a whole lot of flair.
  </li>

  <li className="vaduaplu-list-item">
    🖊️ From character toppers to custom styles, our pens are one-of-a-kind creations that are fun to use and even more fun to collect.
  </li>

  <li className="vaduaplu-list-item">
    💖 Each pen is made with love in Castroville, TX by Pamela Mendoza — bringing you Peace. Love. Pens.
  </li>

</ul>




              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content / Gallery */}
      <main className="vaduaplu-main-content">
        <h2 className="vaduaplu-heading">Gallery</h2>
        <div className="vaduaplu-gallery">
          {[...Array(6)].map((_, index) => (
            <img
              key={index}
              src={images[index % images.length]}
              alt={`Work Sample ${index + 1}`}
              className="vaduaplu-image"
            />
          ))}
        </div>
      </main>

      {/* Extra Text Section */}
      <div className="vaduaplu-extra-text">
        <p className="vaduaplu-extra-text-p">
        Two Bunnies | Peace * Love * Pens     </p>
      </div>
    </div>
  );
}

export default TwoBunnies;
