import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import validator from "validator";
import axios from "axios";
import "./PartnerSignUp.css";
import logo from "../../logo.png";

function PartnerSignUpPage() {
  const features = [
    { name: "Branded Webpage Included", icon: "🎁" },
    { name: "No Ongoing Platform Fees", icon: "💰" },
    { name: "Complimentary Business Promotion", icon: "📢" },
  ];

  const STRIPE_LINK = "https://buy.stripe.com/8wM15Oe8AayYbEQ7sw";

  const communityOptions = [
   "Afghan",
"African",
"Bangladeshi",
"Brazilian",
"British",
"Chinese",
"Cuban",
"Dominican",
"Eritrean",
"Ethiopian",
"Filipino",
"French",
"German",
"Greek",
"Haitian",
"Hawaiian",
"Indian",
"Italian",
"Jamaican",
"Japanese",
"Korean",
"Lebanese",
"Mexican",
"Middle Eastern",
"Nepalese",
"Nigerian",
"Polish",
"Russian",
"Spanish",
"Vietnamese",
"Others"
  ].map(c => ({ value: c, label: c }));

  const languageOptions = [
    "Amharic", "Arabic", "Bengali", "Chinese", "Ethiopian", "Filipino (Tagalog)",
    "French", "Gujarati", "German", "Hebrew", "Hindi", "Igbo", "Italian",
    "Japanese", "Korean", "Mandarin", "Oromo", "Portuguese", "Spanish",
    "Swahili", "Tigrinya", "Others"
  ].map(l => ({ value: l.toLowerCase(), label: l }));


  const [formData, setFormData] = useState({
    user_id: "",
    password: "",
    email: "",
    first_name: "",
    last_name: "",
    bus_name: "",
    city: "",
    state: "",
    user_type: "",
    ph_no: "",
    isAccepted: false,
    business_type: "",
    other_business_type: "",
    communities: [],
    other_community: "",
    languages: [],
    other_language: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const phoneRef = useRef(null);
  const emailRef = useRef(null);

  const validateEmail = (email) => validator.isEmail(email);
  const validatePhone = (phone) => {
    const digitsOnly = phone.replace(/\D/g, "");
    return digitsOnly.length === 10;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
// For react-select multi-select fields
const handleMultiChange = (selected, name) => {
  setFormData((prev) => ({
    ...prev,
    [name]: selected || [],
  }));
};

// For text inputs within multi-select groups (Others textboxes)
const handleTextChange = (e) => {
  const { name, value } = e.target;
  setFormData((prev) => ({
    ...prev,
    [name]: value
  }));
};

  const MyErrorModal = ({ message, onClose }) => (
    <div className="myErrorModal">
      <div className="myErrorModal-content">
        <span className="myErrorModal-close" onClick={onClose}>
          &times;
        </span>
        <p style={{ color: "red" }}>{message}</p>
      </div>
    </div>
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");
    setIsSubmitting(true);

    const {
      user_id,
      password,
      email,
      first_name,
      last_name,
      city,
      state,
      user_type,
      ph_no,
      isAccepted,
      business_type,
      other_business_type, communities, other_community, languages, other_language,
    } = formData;

    if (
      !user_id ||
      !password ||
      !email ||
      !first_name ||
      !last_name ||
      !city ||
      !state ||
      !user_type ||
      !ph_no
    ) {
      setErrorMessage("Please fill in all required fields.");
      setIsSubmitting(false);
      return;
    }

    if (!validateEmail(email)) {
      setErrorMessage("Please enter a valid email address.");
      setIsSubmitting(false);
      if (emailRef.current) {
        emailRef.current.scrollIntoView({ behavior: "smooth" });
      }
      return;
    }

    if (!validatePhone(ph_no)) {
      setErrorMessage("Please enter a valid 10-digit phone number.");
      setIsSubmitting(false);
      if (phoneRef.current) {
        phoneRef.current.scrollIntoView({ behavior: "smooth" });
      }
      return;
    }

    if (user_type === "vendor") {
      if (!formData.bus_name) {
        setErrorMessage("Business name is required for vendors.");
        setIsSubmitting(false);
        return;
      }
      if (!business_type) {
        setErrorMessage("Please select your business type.");
        setIsSubmitting(false);
        return;
      }
      if (
        (business_type === "others" || business_type === "multiple") &&
        !other_business_type
      ) {
        setErrorMessage("Please specify your business type.");
        setIsSubmitting(false);
        return;
      }
      if (communities?.length > 0 && communities.some(c => c.value === "Others") && !other_community) {
        setErrorMessage("Please specify the community in which your business focuses.");
        setIsSubmitting(false);
        return;
      }
      if (languages?.length > 0 && languages.some(l => l.value === "others") && !other_language) {
        setErrorMessage("Please specify the additional language(s) you offer.");
        setIsSubmitting(false);
        return;
      }
    }

    if (!isAccepted) {
      setErrorMessage("Please accept the terms before proceeding.");
      setIsSubmitting(false);
      return;
    }

    try {
      const serverUserType = user_type === "vendor" ? "B" : "U";
    
      const response = await axios.post(
        "https://mznser7yt4.execute-api.us-east-2.amazonaws.com/test/gouourhub/registration",
        {
          ...formData,
          user_type: serverUserType,
        }
      );
    
      if (response.status === 200) {
        const vendorId = response.data.body?.vendorId; // Get vendorId from API response
        const signUpData = {
          user_id,
          user_type,
          first_name,
          email,
          ...(vendorId && { vendorId }), // Include vendorId only if available
        };
    
        if (user_type === "vendor") {
          // Store signup data before Stripe redirect
          localStorage.setItem("pendingVendorSignup", JSON.stringify(signUpData));
    
          // Redirect to Stripe payment with vendor data as URL params
          window.location.href = `${STRIPE_LINK}?vendorId=${vendorId}&firstName=${encodeURIComponent(first_name)}`;
        } else {
          // Direct redirect to user-thankyou page for regular user
          localStorage.setItem("pendingUserSignup", JSON.stringify(signUpData));
          window.location.href = `/user-thankyou?firstName=${encodeURIComponent(first_name)}`;
        }
      } else {
        setErrorMessage("Unexpected server response. Please try again.");
      }
    } catch (error) {
      let serverMsg = "An error occurred. Please try again.";
      if (error.response && error.response.data) {
        if (error.response.data.body?.message) {
          serverMsg = error.response.data.body.message;
        } else if (typeof error.response.data === "string") {
          serverMsg = error.response.data;
        } else if (error.response.data.message) {
          serverMsg = error.response.data.message;
        } else {
          serverMsg = `Request failed with status code ${error.response.status}`;
        }
        if (serverMsg.toLowerCase().includes("user already exists")) {
          serverMsg = "User already exists. Please try a different ID or email.";
        }
      }
      setErrorMessage(serverMsg);
    } finally {
      setIsSubmitting(false);
    }
    
    
  };

  return (
    <div className="partner-signup-page">
      <header className="top-header"></header>

      <div className="signup-container">
        <div className="left-column_partner">
          <div className="faq-box">
            <p className="faq-text">
              Need Help? <Link to="/faq" className="faq-link">Read Our FAQs</Link>
            </p>
          </div>
          <div className="features-section">
            {features.map((feature, index) => (
              <div className="feature-item" key={index}>
                <span className="feature-icon">{feature.icon}</span>
                <p>
                  <span className="highlight-text">{feature.name.split(" ")[0]}</span>{" "}
                  {feature.name.split(" ").slice(1).join(" ")}
                </p>
              </div>
            ))}
          </div>
        </div>

        <div className="form-section">
          <h2>Sign Up Now</h2>

          {errorMessage && <MyErrorModal message={errorMessage} onClose={() => setErrorMessage("")} />}
          {successMessage && <p style={{ color: "green" }}>{successMessage}</p>}

          <form id="joinForm" onSubmit={handleSubmit}>
            <label>User Type</label>
            <select name="user_type" value={formData.user_type} onChange={handleChange} required>
              <option value="">Select One</option>
              <option value="vendor">Vendor</option>
              <option value="user">User</option>
            </select>

            <label>User ID</label>
            <input type="text" name="user_id" value={formData.user_id} onChange={handleChange} required />

            <label>Password</label>
            <input type="password" name="password" value={formData.password} onChange={handleChange} required />

            <label>Email</label>
            <input type="email" name="email" value={formData.email} onChange={handleChange} ref={emailRef} required />

            <label>First Name</label>
            <input type="text" name="first_name" value={formData.first_name} onChange={handleChange} required />

            <label>Last Name</label>
            <input type="text" name="last_name" value={formData.last_name} onChange={handleChange} required />

            {formData.user_type === "vendor" && (
              <>
                <label>Business Name</label>
                <input type="text" name="bus_name" value={formData.bus_name} onChange={handleChange} required />

                <label>Business Type</label>
                <select name="business_type" value={formData.business_type} onChange={handleChange} required>
                <option value="">Select Business Type</option>
              <option value="accommodation">🏠 Accommodation</option>
              <option value="beauty">💄 Beauty</option>
              <option value="boutique">👜 Boutique</option>
              <option value="catering">🍲 Catering</option>
              <option value="child care">👶 Child Care</option>
              <option value="construction">🔧 Construction</option>
              <option value="consulting">🤝 Consulting</option>
              <option value="design">🎨 Design</option>
              <option value="education">📚 Education</option>
              <option value="electrical">💡 Electrical</option>
              <option value="elderly care">🧓 Elderly Care</option>
              <option value="employment">💼 Employment</option>
              <option value="entertainment">🎭 Entertainment</option>
              <option value="event management">🎉 Event Management</option>
              <option value="financial">💰 Financial</option>
              <option value="fitness training">🏋️ Fitness Training</option>
              <option value="gardening">🌱 Gardening</option>
              <option value="health">⚕️ Health</option>
              <option value="home help">🏡 Home Help</option>
              <option value="it services">💻 IT Services</option>
              <option value="jewelry">💍 Jewelry</option>
              <option value="kosher">🕍 Kosher</option>
              <option value="legal">⚖️ Legal</option>
              <option value="legal advice">⚖️ Legal Advice</option>
              <option value="marketing">📈 Marketing</option>
              <option value="personal shopping">🛍️ Personal Shopping</option>
              <option value="pet care">🐾 Pet Care</option>
              <option value="photography">📷 Photography</option>
              <option value="plumbing">🚰 Plumbing</option>
              <option value="realtors">🏘️ Realtors</option>
              <option value="repair services">🔧 Repair Services</option>
              <option value="restaurants">🍴 Restaurants</option>
              <option value="sports">🏅 Sports</option>
              <option value="storage">📦 Storage</option>
              <option value="student services">🎓 Student Services</option>
              <option value="tailor">👗 Tailor</option>
              <option value="translation">🌐 Translation</option>
              <option value="transportation">🚗 Transportation</option>
              <option value="tutoring">📖 Tutoring</option>
              <option value="vegan">🥗 Vegan</option>
              <option value="venues">🏢 Venues</option>
              <option value="worship places">⛪ Worship Places</option>
              <option value="others">❓ Others</option>
              <option value="multiple">💼 Multiple</option>

                </select>

                {(formData.business_type === "others" || formData.business_type === "multiple") && (
                  <input
                    type="text"
                    name="other_business_type"
                    placeholder="Please specify your business type"
                    value={formData.other_business_type}
                    onChange={handleChange}
                    required
                  />
                )}
              

<label>(Optional) If your business focuses on a particular communities’s goods, services, or culture, please select it here.</label>
              <Select className="community-select-ps"

                isMulti
                options={communityOptions}
                onChange={(selected) => handleMultiChange(selected, "communities")}
                value={formData.communities}
              />
              {formData.communities.some(c => c.value === "Others") && (
                <input
                  type="text"
                  name="other_community"
                  placeholder="Please specify other community"
                  value={formData.other_community}
                  onChange={handleTextChange}
                />
              )}
<label>(Optional) What languages (besides English) do you speak or offer services in? (Select all that apply.)</label>
              <Select className="language-select-ps"
                isMulti
                options={languageOptions}
                onChange={(selected) => handleMultiChange(selected, "languages")}
                value={formData.languages}
              />
              {formData.languages.some(l => l.value === "others") && (
                <input
                  type="text"
                  name="other_language"
                  placeholder="Please specify other language(s)"
                  value={formData.other_language}
                  onChange={handleTextChange}
                />
              )}

</>
            )}
            <label>City</label>
            <select name="city" value={formData.city} onChange={handleChange} required>
              <option value="">Select City</option>
              <option value="San Antonio">San Antonio</option>
              <option value="Houston">Houston</option>
              <option value="Dallas">Dallas</option>
              <option value="Austin">Austin</option>
            </select>

            <label>State</label>
            <select name="state" value={formData.state} onChange={handleChange} required>
              <option value="">Select State</option>
              <option value="Texas">Texas</option>
              <option value="California">California</option>
              <option value="New York">New York</option>
              <option value="Florida">Florida</option>
            </select>

            <label>Phone Number</label>
            <input type="text" name="ph_no" value={formData.ph_no} onChange={handleChange} ref={phoneRef} required />

            <p className="partnerpage-message">
              ** Vendor ID will generate once you complete the sign up process
            </p>

            <label className="checkbox-label">
              <input type="checkbox" name="isAccepted" checked={formData.isAccepted} onChange={handleChange} />
              <span>
                I acknowledge that I have read <Link to="/terms" className="terms-link">terms</Link> and agree to move forward.
              </span>
            </label>

            <button type="submit" className="signup-button" disabled={!formData.isAccepted || isSubmitting}>
              {isSubmitting ? "Processing..." : "Sign Up"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default PartnerSignUpPage;
