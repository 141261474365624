import React from 'react';
import { Link } from 'react-router-dom';
import './VisitResturants.css'; // Import the CSS file for styling

// Define your three categories with names, icons, and URLs
const categories = [
  { name: 'Un-Gwan-Gway', icon: '🦁', url: '/un-Gwan-Gway' },

    {
      name: "¡Viva Vegeria!",
      icon: "🥬",
      specialties: ["Vegan", "Tex-Mex", "Gluten-Free"],
      address: "1422 Nogalitos St, San Antonio, TX 78204",
      phone: "",
      url: "http://www.myvegeria.com"
    },
    {
      name: "Plantaqueria",
      icon: "🥬",
      specialties: ["Vegan", "Tacos"],
      address: "124 Broadway St, San Antonio, TX 78205",
      phone: "",
      url: "http://plantaqueria.com"
    },
    {
      name: "Vegan Avenue",
      icon: "🥬",
      specialties: ["Vegan", "Gluten-Free"],
      address: "2512 N Main Ave, San Antonio, TX 78237",
      phone: "",
      url: ""
    },
    {
      name: "Blissful Burgers",
      icon: "🥬",
      specialties: ["Vegan", "Burgers"],
      address: "3950 S Zarzamora St, San Antonio, TX 78225",
      phone: "",
      url: "http://blissfulburgers.net"
    },
    {
      name: "Earth Burger",
      icon: "🥬",
      specialties: ["Vegan", "Fast Food"],
      address: "818 NW Loop 410 (Blanco), San Antonio, TX 78216",
      phone: "",
      url: "http://eatatearthburger.com"
    },
    {
      name: "Miss Chickpeas Cafétal",
      icon: "🥬",
      specialties: ["Vegan", "Bakery", "Coffee"],
      address: "8002 Callaghan Rd Ste 106, San Antonio, TX 78230",
      phone: "",
      url: "https://miss-chickpeas-bakeshop.business.site"
    },
    
      {
          "name": "Viva Vegeria",
          "location": "San Antonio, TX",
          "description": "A casual restaurant serving vegan versions of Tex-Mex food, including mushroom chicharron tacos, pozole, and taquitos. Dog friendly.",
          "icon": "🥬",
          "cite": "turn0search0"
      },
      {
          "name": "Plantology",
          "location": "San Antonio, TX",
          "description": "Gluten-free restaurant serving all-day breakfast, lunch, and dinner with items like pancakes, tacos, burgers, and matcha soft serve.",
          "icon": "🥬",
          "cite": "turn0search0"
      },
      {
          "name": "Go Vegan San Antonio",
          "location": "San Antonio, TX",
          "description": "A woman-owned, 100% vegan food truck offering plant-based comfort food, drinks, and smoothies. Located at The Block SA.",
          "icon": "🥬",
          "cite": "turn0search5"
      },
      
      {
          "name": "Green Vegetarian Cuisine",
          "location": "San Antonio, TX",
          "description": "A plant-based diner known for vegan southern comfort food and their 3.5lb cinnamon roll. Located in the Alamo Quarry Market.",
          "icon": "🥬",
          "cite": "turn0search12"
      },
      {
          "name": "Blissful Burgers",
          "location": "San Antonio, TX",
          "description": "A vegan burger shop offering salads, faux chicken fingers, and more. Relocated to 5714 Evers Rd, Leon Valley.",
          "icon": "🥬",
          "cite": "turn0search0"
      },
      {
          "name": "HASH - Vegan Eats SA",
          "location": "San Antonio, TX",
          "description": "Vegan eatery featuring breakfast-themed meals, craft non-alcoholic beer, and mocktails with CBD options.",
          "icon": "🥬",
          "cite": "turn0search4"
      },
      {
          "name": "Plantaqueria",
          "location": "San Antonio, TX",
          "description": "A 100% vegan restaurant offering innovative plant-based cuisine with creative and flavorful dishes.",
          "icon": "🥬",
          "cite": "turn0search18"
      },
      {
          "name": "Cake Thieves Bakery & Eatery",
          "location": "San Antonio, TX",
          "description": "Vegan bakery offering donuts, chopped BBQ sandwiches, baked mac and cheese, chicken and waffles, and more.",
          "icon": "🥬",
          "cite": "turn0search14"
      },
      {
          "name": "Miss Chickpeas Bakeshop",
          "location": "San Antonio, TX",
          "description": "Vegan bakeshop offering a variety of plant-based baked goods and treats.",
          "icon": "🥬",
          "cite": "turn0search14"
      },
      {
        name: "Vegan Avenue",
        icon: "🥬",
        specialties: ["Vegan", "Gluten-Free", "Brunch"],
        address: "2512 N Main Ave, San Antonio, TX 78237",
        phone: "",
        url: ""
      },
      {
        name: "Hash Vegan Eats",
        icon: "🥬",
        specialties: ["Vegan", "Breakfast", "Mocktails"],
        address: "5007 S Flores St, San Antonio, TX 78214",
        phone: "",
        url: "https://www.hashveganeats.com"
      },
      {
        name: "Blissful Burgers",
        icon: "🥬",
        specialties: ["Vegan", "Burgers", "Faux Chicken"],
        address: "5714 Evers Rd, San Antonio, TX 78238",
        phone: "",
        url: "http://blissfulburgers.net"
      },
      
      {
        name: "Go Vegan San Antonio",
        icon: "🥬",
        specialties: ["Vegan", "Comfort Food", "Food Truck"],
        address: "11911 Crosswinds Way, San Antonio, TX 78233",
        phone: "",
        url: "https://govegansatx.com"
      },
      {
        name: "Plantaqueria",
        icon: "🥬",
        specialties: ["Vegan", "Mexican", "Tacos"],
        address: "124 Broadway St, San Antonio, TX 78205",
        phone: "",
        url: "http://plantaqueria.com"
      },
      {
        name: "HapPea Vegans",
        icon: "🥬",
        specialties: ["Vegan", "Artisan", "Food Truck"],
        address: "9111 Broadway St, San Antonio, TX 78217",
        phone: "",
        url: "https://www.happeavegans.com"
      },
      {
        name: "Revolución Coffee + Juice",
        icon: "🥬",
        specialties: ["Vegan Options", "Juice Bar", "Smoothies"],
        address: "7959 Broadway St #500, San Antonio, TX 78209",
        phone: "(210) 701-0725",
        url: "https://revoluciontx.com/"
      },
      {
        name: "Pharm Table",
        icon: "🥬",
        specialties: ["Vegan", "Ayurvedic", "Local"],
        address: "611 S Presa St Suite 106, San Antonio, TX 78205",
        phone: "(210) 802-1860",
        url: "https://pharmtable.com/"
      },
      {
        name: "Plantyful Sweets",
        icon: "🥬",
        specialties: ["Vegan", "Bakery", "Desserts"],
        address: "Food Truck – check Instagram for current location",
        phone: "",
        url: "https://www.instagram.com/plantyfulsweets/"
      },
      {
        name: "Sprout Café",
        icon: "🥬",
        specialties: ["Vegan", "Organic", "Healthy Bowls"],
        address: "6222 De Zavala Rd Ste 103, San Antonio, TX 78249",
        phone: "(210) 994-9832",
        url: "https://www.sproutcafesa.com/"
      },
      {
        name: "Señor Veggie",
        icon: "🥬",
        specialties: ["Vegan", "Latin-Inspired", "Gluten-Free"],
        address: "620 S Presa St, San Antonio, TX 78210",
        phone: "(210) 228-0073",
        url: "https://senorveggie.com/"
      }
  
  
  
  
  
  
];

function VisitResturants() {
 return (
     <div className="categories-container">
     <div className="new-tile-container-new">
     
         {categories.map((category, index) => (
          <div key={index} className="new-tile">
             <Link to={category.url} className="category-link">
             <span className="icon-for-new-tile">{category.icon}</span>
             <span>{category.name}</span>
             </Link>
           </div>
         ))}
       </div>
 
       {/* Slim long tile that navigates back to homepage */}
       <div className="back-home-tile">
               <Link to="/" className="back-home-link">
                 Go Back to Homepage
               </Link>
             </div>
     
     </div>
 
     
   );
}

export default VisitResturants;
