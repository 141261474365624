import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './VisitResturants.css';
import PromoSection from './PromoSection';
// Define your three categories with names, icons, and URLs
const categories = [
 
  { name: 'Pita Plus', icon: '🥙', url: '/pitaplus' },

  // 🇲🇽 Mexican
  {
    name: "Tacos La Salsita",
    icon: "🚚",
    cuisine: "Mexican",
    address: "San Antonio, TX",
    url: "https://www.expressnews.com/projects/food-trucks-52-weeks/",
    phone: ""
  },
  {
    name: "Lady Picoza",
    icon: "🚚",
    cuisine: "Mexican",
    address: "San Antonio, TX",
    url: "https://www.bestfoodtrucks.com/top-food-trucks/san-antonio",
    phone: ""
  },

  // 🇺🇸 American Comfort
  {
    name: "Eddie's Hijo-e-Su Mobile Cafe",
    icon: "🚚",
    cuisine: "American Comfort",
    address: "San Antonio, TX",
    url: "https://www.bestfoodtrucks.com/top-food-trucks/san-antonio",
    phone: ""
  },
  {
    name: "Wally's Deli",
    icon: "🚚",
    cuisine: "American Comfort",
    address: "San Antonio, TX",
    url: "https://www.bestfoodtrucks.com/top-food-trucks/san-antonio",
    phone: ""
  },

  // 🇵🇭 Filipino
  {
    name: "Not JUST Lechon",
    icon: "🚚",
    cuisine: "Filipino",
    address: "San Antonio, TX",
    url: "https://foodtrucksofsa.com/",
    phone: ""
  },
  {
    name: "Peg Leg Jack Bobaecue",
    icon: "🚚",
    cuisine: "Filipino-Cajun",
    address: "San Antonio, TX",
    url: "https://foodtrucksofsa.com/",
    phone: ""
  },

  // 🇩🇪 German
  {
    name: "Hüftgold",
    icon: "🚚",
    cuisine: "German",
    address: "San Antonio, TX",
    url: "https://foodtrucksofsa.com/",
    phone: ""
  },

  // 🇵🇹 Portuguese
  {
    name: "Avô's Eats",
    icon: "🚚",
    cuisine: "Portuguese",
    address: "San Antonio, TX",
    url: "https://foodtrucksofsa.com/",
    phone: ""
  },

  // 🇺🇸 BBQ
  {
    name: "KHILL BBQ COMPANY",
    icon: "🚚",
    cuisine: "Barbecue",
    address: "San Antonio, TX",
    url: "https://www.bestfoodtrucks.com/top-food-trucks/san-antonio",
    phone: ""
  },
  {
    name: "Mighty Oak BBQ",
    icon: "🚚",
    cuisine: "Barbecue",
    address: "San Antonio, TX",
    url: "https://www.expressnews.com/projects/food-trucks-52-weeks/",
    phone: ""
  },

  // 🇺🇸 Cajun
  {
    name: "Sauce’s Southern Kitchen",
    icon: "🚚",
    cuisine: "Cajun",
    address: "San Antonio, TX",
    url: "https://www.expressnews.com/projects/food-trucks-52-weeks/",
    phone: ""
  },
  {
    name: "Jewell’s Cajun & Southern Cuisine",
    icon: "🚚",
    cuisine: "Cajun",
    address: "San Antonio, TX",
    url: "https://foodtrucksofsa.com/",
    phone: ""
  },

  // 🍨 Desserts
  {
    name: "Swirlz Soft Serve",
    icon: "🚚",
    cuisine: "Desserts",
    address: "San Antonio, TX",
    url: "https://foodtrucksofsa.com/",
    phone: ""
  },
  {
    name: "Atomic Ice",
    icon: "🚚",
    cuisine: "Desserts",
    address: "San Antonio, TX",
    url: "https://foodtrucksofsa.com/",
    phone: ""
  },


  
    // 🇲🇽 Mexican Cuisine
   // Mexican Cuisine
  {
    name: "Tacos El Regio",
    icon: "🚚",
    cuisine: "Mexican",
    address: "2726 N Saint Mary's St, San Antonio, TX 78212",
    phone: "(210) 439-9299",
    url: "https://www.yelp.com/biz/tacos-el-regio-san-antonio"
  },
  {
    name: "Tacos El Guero Loko",
    icon: "🚚",
    cuisine: "Mexican",
    address: "San Antonio, TX",
    phone: "",
    url: ""
  },
  {
    name: "Carnitas Don Raúl-USA",
    icon: "🚚",
    cuisine: "Mexican",
    address: "San Antonio, TX",
    phone: "",
    url: ""
  },

  // American Comfort Food
  {
    name: "Slider Provider",
    icon: "🚚",
    cuisine: "American Comfort",
    address: "San Antonio, TX",
    phone: "",
    url: ""
  },
  {
    name: "Maniacs Chef's Truck",
    icon: "🚚",
    cuisine: "American Comfort",
    address: "San Antonio, TX",
    phone: "",
    url: ""
  },

  // Italian Cuisine
  {
    name: "Vero Wood Fired Pizza",
    icon: "🚚",
    cuisine: "Italian",
    address: "San Antonio, TX",
    phone: "",
    url: ""
  },

  // Greek Cuisine
  {
    name: "Lada Ladies",
    icon: "🚚",
    cuisine: "Greek",
    address: "San Antonio, TX",
    phone: "",
    url: ""
  },

  // Desserts
  {
    name: "Paciugo Gelato",
    icon: "🚚",
    cuisine: "Desserts",
    address: "San Antonio, TX",
    phone: "",
    url: ""
  },

  // Asian Fusion
  {
    name: "The Smokin' Wok",
    icon: "🚚",
    cuisine: "Asian Fusion",
    address: "San Antonio, TX",
    phone: "",
    url: ""
  },

  // Ethiopian Cuisine
  {
    name: "Berbere Ethiopian Cuisine",
    icon: "🚚",
    cuisine: "Ethiopian",
    address: "San Antonio, TX",
    phone: "",
    url: ""
  },

  // Caribbean Cuisine
  {
    name: "The Jerk Shack",
    icon: "🚚",
    cuisine: "Caribbean",
    address: "117 Matyear St, San Antonio, TX 78210",
    phone: "(210) 776-7780",
    url: "https://www.thejerkshack.com/"
  },

  // Donuts
  {
    name: "DonutNV Central San Antonio, TX",
    icon: "🚚",
    cuisine: "Desserts",
    address: "San Antonio, TX",
    phone: "",
    url: ""
  },

  // Shaved Ice
  {
    name: "Kona Ice of San Antonio",
    icon: "🚚",
    cuisine: "Desserts",
    address: "San Antonio, TX",
    phone: "",
    url: ""
  },

  // Chicken
  {
    name: "Chi Chi Birds Hot Chicken",
    icon: "🚚",
    cuisine: "American Comfort",
    address: "San Antonio, TX",
    phone: "",
    url: ""
  },

  // Sandwiches
  {
    name: "Curbside Eats",
    icon: "🚚",
    cuisine: "American",
    address: "San Antonio, TX",
    phone: "",
    url: ""
  },

  // Lobster Rolls
  {
    name: "Masshole Lobster Truck",
    icon: "🚚",
    cuisine: "Seafood",
    address: "San Antonio, TX",
    phone: "(210) 209-0495",
    url: "https://massholelobstertruck.com/"
  },

  // Cajun & Southern Cuisine
  {
    name: "Jewell’s Cajun & Southern Cuisine",
    icon: "🚚",
    cuisine: "Cajun & Southern",
    address: "San Antonio, TX",
    phone: "",
    url: ""
  },

  // German Cuisine
  {
    name: "Hüftgold",
    icon: "🚚",
    cuisine: "German",
    address: "San Antonio, TX",
    phone: "",
    url: ""
  },

  // Portuguese Cuisine
  {
    name: "Avô's Eats",
    icon: "🚚",
    cuisine: "Portuguese",
    address: "San Antonio, TX",
    phone: "",
    url: ""
  },

  {
    name: "Jerusalem Grill Food Truck",
    icon: "🚚",
    address: "1023 Rittiman Rd, San Antonio, TX 78218",
    phone: "(210) 651-6666",
    url: "https://jerusalemgrillsa.com"
  },
  {
    name: "Pita Island Food Truck",
    icon: "🚚",
    address: "1635 SW Military Dr, San Antonio, TX 78221",
    phone: "(210) 465-7099",
    url: "https://www.facebook.com/pitaislandtx/"
  },
  {
    name: "Shisha Shack Food Truck",
    icon: "🚚",
    address: "9807 Fredericksburg Rd, San Antonio, TX 78240",
    phone: "(210) 690-9070",
    url: "https://shishashacksa.com"
  },
  {
    name: "Zaatar Lebanese Grill (Truck)",
    icon: "🚚",
    address: "5250 McCullough Ave, San Antonio, TX 78212",
    phone: "(210) 994-9832",
    url: "https://www.zaatargrill.com"
  },
  {
    name: "The Kebab Shop (Truck)",
    icon: "🚚",
    address: "6395 De Zavala Rd, San Antonio, TX 78249",
    phone: "(726) 444-0027",
    url: "https://thekebabshop.com"
  },
  {
    name: "King Of Shawarma & Kabab Food Truck",
    icon: "🚚",
    address: "8719 Wurzbach Rd, San Antonio, TX 78240",
    phone: "12107191335",
    url: "None"
  },
  {
    name: "Shawarma Alzaeem Food Truck",
    icon: "🚚",
    address: "Mobile station next sonic restaurant, 7880 Culebra Rd, San Antonio, TX 78251",
    phone: "12109012026",
    url: "https://www.shawarmaalzaeem.com"
  },
  {
    name: "Golden Meals",
    icon: "🚚",
    address: "Chevron San Antonio, 10711 Huebner Rd, San Antonio, TX 78240",
    phone: "12103504717",
    url: "None"
  },
  {
    name: "Baba Shawarma Food Truck",
    icon: "🚚",
    address: "5643 Fredericksburg Rd, San Antonio, TX 78229",
    phone: "12103180499",
    url: "None"
  },
  {
    name: "Shawarmatee",
    icon: "🚚",
    address: "Chevron San Antonio, 10711 Huebner Rd, San Antonio, TX 78240",
    phone: "12108916100",
    url: "https://www.shawarmateetx.com"
  },
  
  {
    name: "Mediterranean Street Eats",
    icon: "🚚",
    address: "4115 Fredericksburg Rd, San Antonio, TX 78229",
    phone: "12101234567",
    url: "https://mediterraneanstreateats.com"
  },
  {
    name: "Halal Bros SA",
    icon: "🚚",
    address: "12345 Blanco Rd, San Antonio, TX 78216",
    phone: "12107654321",
    url: "https://halalbrossa.com"
  },
  {
    name: "Yalla Mandi Truck",
    icon: "🚚",
    address: "Culebra & 410, San Antonio, TX 78238",
    phone: "12101112222",
    url: "https://yallamanditx.com"
  },
  {
    name: "Falafel Guys SA",
    icon: "🚚",
    address: "7340 Bandera Rd, San Antonio, TX 78238",
    phone: "12102223333",
    url: "https://falafelguystx.com"
  },
  {
    name: "Shawarma Stop",
    icon: "🚚",
    address: "UTSA Blvd near Babcock, San Antonio, TX 78249",
    phone: "12103334444",
    url: "https://shawarmastop.com"
  },
  
];

function VisitFoodTrucks() {
  const [selected, setSelected] = useState(null);
  const navigate = useNavigate();

  const handleTileClick = (category) => {
    if (category.url?.startsWith('/')) {
      navigate(category.url); // Internal route
    } else {
      setSelected(category); // Show popup for external or missing URL
    }
  };
  

  const closePopup = () => setSelected(null);

  return (
    <div className="categories-container">
      <PromoSection />
      <div className="new-tile-container-new">
        {categories.map((category, index) => (
          <div
          key={index}
          className={`new-tile ${category.url ? 'tile-clickable' : 'tile-informational'}`}
          onClick={() => handleTileClick(category)}
        >
          <span className="icon-for-new-tile">{category.icon}</span>
          
          <span className="tile-name">
            {category.name}
            {(!category.url || category.url.startsWith('http')) && (
              <span className="info-badge" title="Unverified">ⓘ</span>
            )}
          </span>
        </div>
        
        ))}
      </div>

      <div className="back-home-tile">
        <Link to="/" className="back-home-link">Go Back to Homepage</Link>
      </div>

      {/* Popup Box */}
      {selected && (
  <div className="popup-overlay" onClick={closePopup}>
    <div className="popup-box" onClick={(e) => e.stopPropagation()}>
      <h3>{selected.name}</h3>
      <p><strong>Address:</strong> {selected.address}</p>
      <p><strong>Phone:</strong> {selected.phone}</p>

      {selected.url && selected.url.startsWith('http') && (
  <p>
    <a
      href={selected.url}
      target="_blank"
      rel="noopener noreferrer"
      className="business-website-link"
    >
      {selected.url
        .replace(/^https?:\/\//, '') 
        .replace(/\/$/, '')}         
    </a>
  </p>
)}


      <button onClick={closePopup}>Close</button>

      {selected.name  && (
        <div className="extra-links">
          <Link to="/request-form" className="popup-link">Claim your business</Link> <span> / </span>
          <Link to="/request-form" className="popup-link">Opt out</Link> <span> / </span>
          <Link to="/request-form" className="popup-link">Suggest update</Link>
        </div>
      )}
      <hr className="popup-divider" />

<p className="disclaimer-text">
Disclaimer: This listing has not been claimed or verified by the business owner.
Information provided is based on publicly available sources.
</p>

    </div>
  </div>
)}

    </div>
  );
}


export default VisitFoodTrucks;
