import React, { useState, useEffect } from "react";
import logo from "../images/IcToyxLogo.jpg";
import adGoOurHub from "../images/ad_goourhub.jpg"; // <-- import your ad image
import "./VaduAplu.css";

const images = [
  require("../images/IcToyx1.jpg"),
  require("../images/IcToyx2.jpg"),
  require("../images/IcToyx3.jpg"),
  require("../images/IcToyx4.jpg"),
  require("../images/IcToyx5.jpg"),
  require("../images/IcToyx6.jpg"),
];


function IcTroyz() {
  const [dealIndex, setDealIndex] = useState(0);
  const [fade, setFade] = useState(true);

  const deals = [
    "Get 10% on services when you mention GOOURHUB",
    "Mention GoOurHub to get better price/discounts",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setDealIndex((prev) => (prev + 1) % deals.length);
        setFade(true);
      }, 500);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="vaduaplu-container">
      {/* Header */}
      <header className="vaduaplu-header">
        {/* Left: Logo + Name */}
        <div className="vaduaplu-header-left">
          <img src={logo} alt="Logo" className="vaduaplu-logo" />
          <div className="vaduaplu-header-text">
            <h1 className="vaduaplu-company-name"> IcToyz
              
            </h1>
            
          </div>
        </div>

        {/* Right: Phone + Address */}
        <div className="vaduaplu-header-right">
          <span className="vaduaplu-phone">IcToyz.com</span>

<span className="vaduaplu-address">
 
    Traders Village in San Antonio
  
</span>

        </div>
      </header>

      <div className="vaduaplu-contact-info">
  <p className="vaduaplu-text">
    🚗 <strong>Welcome to IcToyz</strong> – your destination for high-quality die-cast collectibles and car culture merch.
  </p>

  <p className="vaduaplu-text">
    🏎️ From Hot Wheels and GreenLight to Maisto and more — we handpick collectible models for every kind of enthusiast and collector.
  </p>

  <p className="vaduaplu-text">
    🏬 Shop in-person at <strong>Traders Village, San Antonio</strong> and discover rare finds and new drops.
  </p>

  <p className="vaduaplu-text">
    ✉️ Have questions? Hit us up on Instagram or drop by our online store anytime.
  </p>

  <p className="vaduaplu-text">
    📸 Follow us on IG: <a className = "a-links-vaduaplu" href="https://www.instagram.com/ictoyz/" target="_blank" rel="noopener noreferrer">@ictoyz</a>
    <br />
    🌐 Shop online: <a className = "a-links-vaduaplu" href="https://ictoyz.com/" target="_blank" rel="noopener noreferrer">ictoyz.com</a>
  </p>
</div>



      {/* Single Top Box combining Quick Menu & Deals */}
      <div className="vaduaplu-top-box">
        {/* Deals Section */}
        <div className="vaduaplu-top-section">
          <h2 className="vaduaplu-heading">Get Deals</h2>
          {/* Flashing Text */}
          <p className={`vaduaplu-deals-text ${fade ? "fade-in" : "fade-out"}`}>
            {deals[dealIndex]}
          </p>

          {/* Static Image below the deals text */}
          <img
            src={adGoOurHub}
            alt="GoOurHub Ad"
            className="vaduaplu-ad-image"
          />
        </div>

        {/* Quick Menu Section */}
        <div className="vaduaplu-top-section">
          <h2 className="vaduaplu-heading">Our Highlights</h2>
          <div className="vaduaplu-accordion">
            <div className="vaduaplu-accordion-item">
              <input type="checkbox" id="service1" className="hidden-checkbox"defaultChecked />
              <label htmlFor="service1" className="vaduaplu-label" align = "center">
              Our Highlights
              </label>
              <div className="vaduaplu-accordion-content">
              <ul className="vaduaplu-list">
          
  <li className="vaduaplu-list-item">
  ✨ <strong>Welcome to The IcToyz</strong>  🚗 <strong>Welcome to IcToyz</strong> – your premier destination for high-quality die-cast collectible models.
  
  </li>
  <br></br>
  <li className="vaduaplu-list-item">
  🏎️ We feature a curated selection from top brands such as Hot Wheels, GreenLight, and Maisto, ensuring every collector finds their perfect piece.
  </li>
  <br></br>
  <li className="vaduaplu-list-item">
  📸 Follow our latest updates and new arrivals on Instagram: <a href="https://www.instagram.com/ictoyz/" target="_blank" rel="noopener noreferrer">@ictoyz</a>
  </li>
  <br></br>
 
</ul>



              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content / Gallery */}
      <main className="vaduaplu-main-content">
        <h2 className="vaduaplu-heading">Gallery</h2>
        <div className="vaduaplu-gallery">
          {[...Array(6)].map((_, index) => (
            <img
              key={index}
              src={images[index % images.length]}
              alt={`Work Sample ${index + 1}`}
              className="vaduaplu-image"
            />
          ))}
        </div>
      </main>

      {/* Extra Text Section */}
      <div className="vaduaplu-extra-text">
        <p className="vaduaplu-extra-text-p">
        IcToyz     </p>
      </div>
    </div>
  );
}

export default IcTroyz;
