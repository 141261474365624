import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation

import ProductCategories from './ProductCategories';

function Ethiopian() {
  const africanVillageImage = require('../images/AV7.jpg');
    const selamFoodMartImage = require('../images/selam3.jpeg');
    
  
    const categories = [
      { name: 'African Village', icon: '🏘️', url: '/africanvillage', image: africanVillageImage },
  
  
   
      { name: 'Selam Food Mart', icon: '🍎', url: '/selamfoodmart', image: selamFoodMartImage },
     
    ];
  
    const filteredCategories = [
      { name: 'Boutique', icon: '👜' },
      { name: 'Salon', icon: '💇‍♀️' },
      { name: 'Restaurants', icon: '🍴', url: '/AfricanVillage' },
      { name: 'Health', icon: '⚕️' },
      { name: 'Student Services', icon: '🎓' },
      { name: 'Beauty', icon: '💄' },
      { name: 'Tailor', icon: '👗' },
      { name: 'Venues', icon: '🏢' },
      { name: 'Photography', icon: '📷' },
      { name: 'Event Management', icon: '🎉' },
      { name: 'Jewelry', icon: '💍' },
      { name: 'Food Truck', icon: '🚚' },
    ];
  
    return (
          <div className="categories-container">
          <div className="new-tile-container-new">
          
              {categories.map((category, index) => (
               <div key={index} className="new-tile">
                  <Link to={category.url} className="category-link">
                  <span className="icon-for-new-tile">{category.icon}</span>
                  <span>{category.name}</span>
                  </Link>
                </div>
              ))}
            </div>
      
            {/* Slim long tile that navigates back to homepage */}
            <div className="back-home-tile">
                    <Link to="/" className="back-home-link">
                      Go Back to Homepage
                    </Link>
                  </div>
          
          </div>
      
          
        );
  }
export default Ethiopian;
