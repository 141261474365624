import React from 'react';
import { Link } from 'react-router-dom';
import './VisitResturants.css'; // Import the CSS file for styling

// Define your three categories with names, icons, and URLs
const categories = [
  { name: 'Rigos Plants', icon: '🪴 ', url: '/rigoplant' },
  { name: 'Trinket Trunk', icon: '✨', url: '/TrinketTrunk' },
  { name: 'Ic Toyz', icon: ' 🚗', url: '/IcToyz' },
  { name: 'Two Bunnies', icon: '🐰', url: '/TwoBunnies' },
  { name: 'G3 Productions', icon: '🎬 ', url: '/G3Production' },
  { name: 'JamesWreaths', icon: '🎀', url: '/JamesWreaths' },
  { name: 'Alex Sword', icon: '🗡️', url: '/AlexSword' },
  { name: 'Murals by S.Knight', icon: '🗡️', url: '/StarrMurals' },
  { name: 'Nena Mexican Clothing and Footwear', icon: '👗', url: '/NenaClothing' },
  
 

  
];

function FleaMarketPlace() {
  return (
    <div className="categories-container">
    <div className="new-tile-container-new">
    
        {categories.map((category, index) => (
         <div key={index} className="new-tile">
            <Link to={category.url} className="category-link">
            <span className="icon-for-new-tile">{category.icon}</span>
            <span>{category.name}</span>
            </Link>
          </div>
        ))}
      </div>

      {/* Slim long tile that navigates back to homepage */}
      <div className="back-home-tile">
              <Link to="/" className="back-home-link">
                Go Back to Homepage
              </Link>
            </div>
    
    </div>

    
  );
}

export default FleaMarketPlace;
