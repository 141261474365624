import React from "react";
import "./VendorTemplate3.css";

function VendorTemplate2() {
  return (
    <div className="vendor-template2-container">
      {/* Gradient Background */}
      <div className="gradient-background">
        {/* Logo Section */}
        <div className="logo-section">
          <img
            src={require("../images/adinkralogo.png")} // Replace with your logo image
            alt="Vendor Logo"
            className="vendor-logo"
          />
          <h2 className="vendor-name">Adinkra Creations</h2>
        </div>

        {/* About Section */}
        <div className="about-section">
          <h2>Hi, I'm Natalie Stokes Peters</h2>
          <p>Your place for <strong>Better Than Basic</strong> greeting cards and gifts</p>
          <button className="email-button">Email Us</button>
        </div>

        {/* Content Section */}
        <div className="content-section">
          <div className="info-section">
            <h3>About</h3>
            <p>Our Info:</p>
            <p>P.O. Box 201, Celina, TX 78308</p>
            <p>
              <a href="https://adinkracreations.com" target="_blank" rel="noreferrer">
                adinkracreations.com
              </a>
            </p>
          </div>

          <div className="specialties-section">
            <h3>Our Specialties</h3>
            <ul>
              <li>Custom Gallery</li>
              <li>Handcrafted Greeting Cards</li>
              <li>Handcrafted Tote Bags</li>
              <li>Handcrafted Wine Bags</li>
              <li>Journals</li>
              <li>Printed Cards</li>
            </ul>
          </div>
        </div>

        {/* Product Gallery */}
        <div className="product-gallery">
          <img src={require("../images/adinkra1.jpg")} alt="Product 1" />
          <img src={require("../images/adinkra2.jpg")} alt="Product 2" />
          <img src={require("../images/adinkra3.jpg")} alt="Product 3" />
          <img src={require("../images/adinkra4.jpg")} alt="Product 4" />
        </div>
      </div>
    </div>
  );
}

export default VendorTemplate2;
