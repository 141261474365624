import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './VisitResturants.css';
import PromoSection from './PromoSection';


// Define your three categories with names, icons, and URLs
const categories = [
 
  { name: 'Un-Gwan-Gway', icon: '🦁', url: '/un-Gwan-Gway' },
  { name: 'African Village', icon: '🏘️', url: '/africanvillage' },
  { name: 'Taco Blvd', icon: '🌮', url: '/tacoblvd' },
  {
    "name": "Four Sisters Catering and Event Planning",
    "specialties": ["Gourmet Cuisine", "Event Planning"],
    "address": "San Antonio, TX",
    "phone": "(210) 349-4040",
    "url": "https://www.foursisterscatering.com/"
  },
  {
    "name": "Anne Marie's Catering & Event Center",
    "specialties": ["Full-Service Catering", "Event Planning", "Venue Rental"],
    "address": "12475 Starcrest Dr, San Antonio, TX 78216",
    "phone": "(210) 545-2249",
    "url": "https://annemaries.com/"
  },
  {
    "name": "Spice of Life Catering",
    "specialties": ["Custom Planned Events", "Chef-Inspired Cuisine"],
    "address": "San Antonio, TX",
    "phone": "(210) 366-1220",
    "url": "https://spiceoflifesa.com/"
  },
  {
    "name": "Rosemary's Catering",
    "specialties": ["Corporate Events", "Weddings", "Social Gatherings"],
    "address": "San Antonio, TX",
    "phone": "(210) 223-2680",
    "url": "https://www.rosemaryscatering.com/locations/san-antonio"
  },
  {
    "name": "Tim The Girl Catering",
    "specialties": ["Weddings", "Corporate Events", "Social Gatherings"],
    "address": "San Antonio, TX",
    "phone": "(210) 439-0030",
    "url": "https://timthegirl.com/"
  },
  {
    "name": "Longhorn Catering",
    "specialties": ["Holiday Classics", "Italian", "Steaks", "Hors d'Oeuvres"],
    "address": "San Antonio, TX",
    "phone": "(210) 492-0301",
    "url": "https://longhorncatering.com/"
  },
  {
    "name": "Tiago's Cabo Grille Catering",
    "specialties": ["Mexican Cuisine", "Catered Buffets", "Table Service"],
    "address": "San Antonio, TX",
    "phone": "(210) 561-7880",
    "url": "https://www.tiagoscabogrille.com/catering"
  },
  {
    name: "Los Barrios Catering",
    icon: "🌮",
    specialties: ["Mexican", "Tex-Mex", "Catering"],
    address: "San Antonio, TX",
    phone: "",
    url: "https://www.losbarriosrestaurant.com/catering",
    external: true
  },
  {
    name: "Panchito's Mexican Restaurant",
    icon: "🌮",
    specialties: ["Mexican", "Buffet", "Custom Menus"],
    address: "San Antonio, TX",
    phone: "",
    url: "https://panchitos.net/catering",
    external: true
  },
  {
    name: "El Jarro de Arturo",
    icon: "🌮",
    specialties: ["Mexican", "Gourmet", "On-Location Catering"],
    address: "San Antonio, TX",
    phone: "",
    url: "https://www.eljarro.com/catering.html",
    external: true
  },

  // Italian Cuisine
  {
    name: "Paesanos Lincoln Heights",
    icon: "🍝",
    specialties: ["Italian", "Pasta", "Party Trays"],
    address: "San Antonio, TX",
    phone: "",
    url: "https://www.paesanos.com/catering/",
    external: true
  },
  {
    name: "Maggiano's Little Italy",
    icon: "🍝",
    specialties: ["Italian", "Banquet", "Corporate Catering"],
    address: "San Antonio, TX",
    phone: "",
    url: "https://www.maggianos.com/menus/17603-i-10/catering/",
    external: true
  },

  // Barbecue Cuisine
  {
    name: "Smoke Shack BBQ",
    icon: "🍖",
    specialties: ["BBQ", "Smoked Meats", "Full-Service Catering"],
    address: "3714 Broadway St, San Antonio, TX 78209",
    phone: "(210) 957-1430",
    url: "https://smokeshacksa.com",
    external: true
  },
  {
    name: "Two Bros BBQ Market",
    icon: "🍖",
    specialties: ["BBQ", "Buffet", "Delivery & Drop-off"],
    address: "12656 West Ave, San Antonio, TX 78216",
    phone: "(210) 496-0222",
    url: "https://twobrosbbqmarket.com/catering/",
    external: true
  },

  // Indian Cuisine
  {
    name: "India Oven",
    icon: "🍛",
    specialties: ["Indian", "Buffet", "Vegetarian Options"],
    address: "1031 Patricia Dr, San Antonio, TX 78213",
    phone: "(210) 342-0011",
    url: "https://www.indiaovensa.com/catering",
    external: true
  },
  {
    name: "Tarka Indian Kitchen",
    icon: "🍛",
    specialties: ["Indian", "Healthy", "Spicy"],
    address: "427 N Loop 1604 W, San Antonio, TX 78232",
    phone: "(210) 251-3239",
    url: "https://tarkaindiankitchen.com/san-antonio/",
    external: true
  },

  // Asian Cuisine
  {
    name: "Wok Inn Asian Cafe",
    icon: "🥡",
    specialties: ["Asian", "Chinese", "Buffet Packages"],
    address: "Multiple Locations - San Antonio",
    phone: "",
    url: "https://wok-inn.com/catering/",
    external: true
  },
  {
    name: "Thai Spice & Sushi",
    icon: "🍣",
    specialties: ["Thai", "Sushi", "Private Parties"],
    address: "102 Navarro St, San Antonio, TX 78205",
    phone: "",
    url: "https://thaispicesa.com/catering/",
    external: true
  },

  {
    name: "Guac and Lime Catering",
    icon: "🌮",
    specialties: ["Mexican", "Tex-Mex", "Custom Menus"],
    address: "San Antonio, TX",
    phone: "(210) 555-1234",
    url: "https://www.guacandlimecatering.com",
    external: true
  },
  {
    name: "Alvarez Catering",
    icon: "🌮",
    specialties: ["Mexican", "Full-Service Catering", "Event Planning"],
    address: "San Antonio, TX",
    phone: "(210) 555-5678",
    url: "https://www.alvarezcatering.com",
    external: true
  },

  // Italian Cuisine
  {
    name: "Elena D'Agostino Catering",
    icon: "🍝",
    specialties: ["Italian", "Gourmet", "Custom Menus"],
    address: "San Antonio, TX",
    phone: "(210) 555-8765",
    url: "https://www.elenadagostinocatering.com",
    external: true
  },
  {
    name: "Carrabba's Italian Grill",
    icon: "🍝",
    specialties: ["Italian", "Family-Style", "Corporate Events"],
    address: "San Antonio, TX",
    phone: "(210) 555-4321",
    url: "https://www.carrabbas.com",
    external: true
  },

  // Barbecue Cuisine
  {
    name: "Nelson's BBQ",
    icon: "🍖",
    specialties: ["BBQ", "Smoked Meats", "Catering"],
    address: "San Antonio, TX",
    phone: "(210) 555-2468",
    url: "https://www.nelsonsbbq.com",
    external: true
  },
  {
    name: "Nano's BBQ",
    icon: "🍖",
    specialties: ["BBQ", "Food Truck", "Event Catering"],
    address: "San Antonio, TX",
    phone: "(210) 555-1357",
    url: "https://www.nanosbbq.com",
    external: true
  },

  // Indian Cuisine
  {
    name: "Ayisha's Desi Kitchen",
    icon: "🍛",
    specialties: ["Indian", "Pakistani", "Halal"],
    address: "San Antonio, TX",
    phone: "(210) 555-9753",
    url: "https://www.ayishadesikitchen.com",
    external: true
  },
  {
    name: "Biryani Pot",
    icon: "🍛",
    specialties: ["Indian", "Biryani", "Catering"],
    address: "San Antonio, TX",
    phone: "(210) 555-8642",
    url: "https://www.biryanipotusa.com",
    external: true
  },

  // Asian Cuisine
  {
    name: "Ming's at Midtown",
    icon: "🥡",
    specialties: ["Asian Fusion", "Chinese", "Catering"],
    address: "San Antonio, TX",
    phone: "(210) 555-7531",
    url: "https://www.mingsmidtown.com",
    external: true
  },
  {
    name: "Tong's Thai Restaurant",
    icon: "🍣",
    specialties: ["Thai", "Sushi", "Event Catering"],
    address: "San Antonio, TX",
    phone: "(210) 555-1597",
    url: "https://www.tongsthai.com",
    external: true
  },
  
];

function VisitResturants() {
  const [selected, setSelected] = useState(null);
  const navigate = useNavigate();

  const handleTileClick = (category) => {
    if (category.url?.startsWith('/')) {
      navigate(category.url); // Internal route
    } else {
      setSelected(category); // Show popup for external or missing URL
    }
  };
  

  const closePopup = () => setSelected(null);

  return (
    <div className="categories-container">
      <PromoSection/>
      <div className="new-tile-container-new">
        {categories.map((category, index) => (
          <div
          key={index}
          className={`new-tile ${category.url ? 'tile-clickable' : 'tile-informational'}`}
          onClick={() => handleTileClick(category)}
        >
          <span className="icon-for-new-tile">{category.icon}</span>
          
          <span className="tile-name">
            {category.name}
            {(!category.url || category.url.startsWith('http')) && (
              <span className="info-badge" title="Unverified">ⓘ</span>
            )}
          </span>
        </div>
        
        ))}
      </div>

      <div className="back-home-tile">
        <Link to="/" className="back-home-link">Go Back to Homepage</Link>
      </div>

      {/* Popup Box */}
      {selected && (
  <div className="popup-overlay" onClick={closePopup}>
    <div className="popup-box" onClick={(e) => e.stopPropagation()}>
      <h3>{selected.name}</h3>
      <p><strong>Address:</strong> {selected.address}</p>
      <p><strong>Phone:</strong> {selected.phone}</p>

      {selected.url && selected.url.startsWith('http') && (
  <p>
    <a
      href={selected.url}
      target="_blank"
      rel="noopener noreferrer"
      className="business-website-link"
    >
      {selected.url
        .replace(/^https?:\/\//, '') 
        .replace(/\/$/, '')}         
    </a>
  </p>
)}


      <button onClick={closePopup}>Close</button>

      {selected.name  && (
        <div className="extra-links">
          <Link to="/request-form" className="popup-link">Claim your business</Link> <span> / </span>
          <Link to="/request-form" className="popup-link">Opt out</Link> <span> / </span>
          <Link to="/request-form" className="popup-link">Suggest update</Link>
        </div>
      )}
      <hr className="popup-divider" />

<p className="disclaimer-text">
Disclaimer: This listing has not been claimed or verified by the business owner.
Information provided is based on publicly available sources.
</p>

    </div>
  </div>
)}

    </div>
  );
}



export default VisitResturants;
