import React, { useState, useEffect } from "react";
import logo from "../images/unGwanlogo.jpg";
import adGoOurHub from "../images/ad_goourhub.jpg"; // <-- import your ad image
import "./VaduAplu.css";

const images = [
  require("../images/unGwan1.jpg"),
  require("../images/unGwan2.jpg"),
  require("../images/unGwan3.jpg"),
  require("../images/unGwan4.jpg"),
  require("../images/unGwan5.jpg"),
  require("../images/unGwan6.jpg"),
];


function UnGwan() {
  const [dealIndex, setDealIndex] = useState(0);
  const [fade, setFade] = useState(true);

  const deals = [
   
    "Mention GoOurHub to get buy 2 and get 1 free",
    "Get 10% on services when you mention GoOurHub",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setDealIndex((prev) => (prev + 1) % deals.length);
        setFade(true);
      }, 500);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="vaduaplu-container">
      {/* Header */}
      <header className="vaduaplu-header">
        {/* Left: Logo + Name */}
        <div className="vaduaplu-header-left">
          <img src={logo} alt="Logo" className="vaduaplu-logo" />
          <div className="vaduaplu-header-text">
            <h1 className="vaduaplu-company-name">Un Gwan Gway
              
            </h1>
            
          </div>
        </div>

        {/* Right: Phone + Address */}
        <div className="vaduaplu-header-right">
          <span className="vaduaplu-phone">At the @pearlmarkets</span>
Sat 9am-1pm
Sun 10am-2pm |
A Nigerian food Experience
<span className="vaduaplu-address">
  <a className= "a-vaduaplu" href="https://www.fruitfromtheearth.com/order" target="_blank" rel="noopener noreferrer">
    Fruit From The Earth
  </a>
</span>

        </div>
      </header>

      {/* Contact Info */}
      <div className="vaduaplu-contact-info">
  <p className="vaduaplu-text">
    <span>🦁</span> Welcome to <strong>Un-Gwan-Gway</strong> (pronounced: <em>UN-GA-WAYNE-GA-WAY</em>)<br />
    <br />
    <br />

    <span>🌍</span> “Fruit from the Earth” is a bold Nigerian stew concept crafted by Chef King Christopher Okoli.<br />
    <br/>
    <span>🍲</span> Made with tomatoes, onions, bell pepper, garlic, and habanero—then slow-cooked with either chicken, beef, or vegan broth until perfection. Finished with a secret blend of herbs and spices.
    <br />
    
  </p>
  <br />
  <p className="vaduaplu-text">
    <span>👑</span> Chef King brings over a decade of food and hospitality experience into every batch.
    <br />
    <br/>
    <span>📍</span> Catch Un-Gwan-Gway every weekend at the Pearl Farmers Market in San Antonio, TX.
    <br />
    <br/>
    Saturdays: 9:00 AM – 1:00 PM | Sundays: 10:00 AM – 2:00 PM
    <br />
    <br />
    <span>📲</span> Follow us on Instagram: <a className="a-links-vaduaplu" href="https://instagram.com/ungwangway">@Ungwangway</a> & <a className="a-links-vaduaplu" href="https://instagram.com/kingokoli3">@Kingokoli3</a>
  </p>
</div>


      {/* Single Top Box combining Quick Menu & Deals */}
      <div className="vaduaplu-top-box">
        {/* Deals Section */}
        <div className="vaduaplu-top-section">
          <h2 className="vaduaplu-heading">Get Deals</h2>
          {/* Flashing Text */}
          <p className={`vaduaplu-deals-text ${fade ? "fade-in" : "fade-out"}`}>
            {deals[dealIndex]}
          </p>

          {/* Static Image below the deals text */}
          <img
            src={adGoOurHub}
            alt="GoOurHub Ad"
            className="vaduaplu-ad-image"
          />
        </div>

        {/* Quick Menu Section */}
        <div className="vaduaplu-top-section">
          <h2 className="vaduaplu-heading">Our Highlights</h2>
          <div className="vaduaplu-accordion">
            <div className="vaduaplu-accordion-item">
              <input type="checkbox" id="service1" className="hidden-checkbox"defaultChecked />
              <label htmlFor="service1" className="vaduaplu-label" align = "center">
              Our Highlights
              </label>
              <div className="vaduaplu-accordion-content">
              <ul className="vaduaplu-list">
  <li className="vaduaplu-list-item">🍛 The O.G. Special – Served with white rice & chicken or beef </li>
  <br/>
  <li className="vaduaplu-list-item">🍳 The King’s Special – Served over fries, egg, and your choice of meat</li>
  <br/>
  <li className="vaduaplu-list-item">🥬 Vegan Special – Plant-based Un-Gwan-Gway with white rice and plantains</li>
  <br/>
  <li className="vaduaplu-list-item">🍌 Fried Plantains  | 🍗 Chicken | 🥚 Egg | 🔥 Extra Spicy | 🥩 Beef</li>
  <br/>
</ul>

              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content / Gallery */}
      <main className="vaduaplu-main-content">
        <h2 className="vaduaplu-heading">Gallery</h2>
        <div className="vaduaplu-gallery">
          {[...Array(6)].map((_, index) => (
            <img
              key={index}
              src={images[index % images.length]}
              alt={`Work Sample ${index + 1}`}
              className="vaduaplu-image"
            />
          ))}
        </div>
      </main>

      {/* Extra Text Section */}
      <div className="vaduaplu-extra-text">
        <p className="vaduaplu-extra-text-p">
        Fruit From The Earth – A Nigerian Food Experience
        </p>
      </div>
    </div>
  );
}

export default UnGwan;
