import React from 'react';

import { Link } from 'react-router-dom'; // Import Link for navigation


// Dynamically import all images from the folder
const images = require.context('../images', false, /\.(png|jpe?g|svg)$/);

function American() {
  // Map all images into an object for easy access
   const categories = [
    { name: 'Trinket Trunk', icon: '✨', url: '/TrinketTrunk' },
    { name: 'JamesWreaths', icon: '🎀', url: '/JamesWreaths' },
    { name: 'Alex Sword', icon: '🗡️', url: '/AlexSword' },
    { name: 'Murals by S.Knight', icon: '🗡️', url: '/StarrMurals' },
     
      
     
      
      ];
    
      const filteredCategories = [
        { name: 'Boutique', icon: '👜' },
        { name: 'Salon', icon: '💇‍♀️' },
        { name: 'Restaurants', icon: '🍴', url: '/AfricanVillage' },
        { name: 'Health', icon: '⚕️' },
        { name: 'Student Services', icon: '🎓' },
        { name: 'Beauty', icon: '💄' },
        { name: 'Tailor', icon: '👗' },
        { name: 'Venues', icon: '🏢' },
        { name: 'Photography', icon: '📷' },
        { name: 'Event Management', icon: '🎉' },
        { name: 'Jewelry', icon: '💍' },
        { name: 'Food Truck', icon: '🚚' },
      ];
    
      return (
            <div className="categories-container">
            <div className="new-tile-container-new">
            
                {categories.map((category, index) => (
                 <div key={index} className="new-tile">
                    <Link to={category.url} className="category-link">
                    <span className="icon-for-new-tile">{category.icon}</span>
                    <span>{category.name}</span>
                    </Link>
                  </div>
                ))}
              </div>
        
              {/* Slim long tile that navigates back to homepage */}
              <div className="back-home-tile">
                      <Link to="/" className="back-home-link">
                        Go Back to Homepage
                      </Link>
                    </div>
            
            </div>
        
            
          );
    }

export default American;
