import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './VisitResturants.css';
import CouponBanner from './CouponBanner';

// Define your three categories with names, icons, and URLs
const categories = [
 
  { name: 'Feven Tx-Realtor', icon: '🥙', url: '/FevenRealtor' },
  
    {
      "name": "Neal & Neal Team",
      "agency": "Keller Williams Realty",
      "experience": "14 years",
      "recent_sales": 1561,
      "average_price_point": "$199.6K",
      "total_sales_last_year": "$279M",
      "profile": "https://www.fastexpert.com/agents/neal-neal-team-13001/"
    },
    {
      "name": "Keller Williams Heritage",
      "logo": "https://www.kwsanantonio.com/assets/images/logo.png",
      "description": "A leading real estate agency offering comprehensive services with a deep understanding of the San Antonio market.",
      "website": "https://www.kwsanantonio.com/"
    },
    {
      "name": "Phyllis Browning Company",
      "logo": "https://www.phyllisbrowning.com/assets/images/logo.png",
      "description": "Providing professional real estate services in South Texas for over 30 years, specializing in luxury properties.",
      "website": "https://www.phyllisbrowning.com/"
    },
    {
      "name": "JBGoodwin REALTORS®",
      "logo": "https://www.jbgoodwin.com/assets/images/logo.png",
      "description": "Serving Austin and San Antonio with a 'Help People' philosophy, offering a wide range of real estate services.",
      "website": "https://www.jbgoodwin.com/"
    },
    {
      "name": "RE/MAX North-San Antonio",
      "logo": "https://www.remax.com/assets/images/logo.png",
      "description": "Part of the global RE/MAX network, providing extensive resources for buying and selling homes in the San Antonio area.",
      "website": "https://www.remax.com/real-estate-agents/san-antonio-tx"
    },
    {
      "name": "Century 21 Scott Myers Realtors",
      "logo": "https://www.century21.com/assets/images/logo.png",
      "description": "Offering a team of experienced agents dedicated to assisting clients with buying and selling properties in San Antonio.",
      "website": "https://www.century21.com/real-estate-office/profile/century-21-scott-myers-realtors-10018245"
    },

    {
      "name": "Kristen Schramme",
      "agency": "Keller Williams Legacy",
      "experience": "18 years",
      "recent_sales": 707,
      "average_price_point": "$8.2K",
      "total_sales_last_year": "$181M",
      "profile": "https://www.fastexpert.com/agents/kristen-schramme-13002/"
    },
    {
      "name": "Hector Mendes",
      "agency": "Century 21 Northside",
      "experience": "23 years",
      "recent_sales": 15,
      "average_price_point": "$243.3K",
      "total_sales_last_year": "$0",
      "profile": "https://www.fastexpert.com/agents/hector-mendes-13003/"
    },
    {
      "name": "Sandra Rangel",
      "agency": "Real Broker LLC",
      "experience": "18 years",
      "recent_sales": 244,
      "average_price_point": "$9.4K",
      "total_sales_last_year": "$0",
      "profile": "https://www.fastexpert.com/agents/sandra-rangel-13004/"
    },
    {
      "name": "Missy Stagers",
      "agency": "M. Stagers Realty Partners",
      "experience": "31 years",
      "recent_sales": 359,
      "average_price_point": "$880",
      "total_sales_last_year": "$53.9M",
      "profile": "https://www.fastexpert.com/agents/missy-stagers-13005/"
    },
    {
      "name": "Robert Saenz",
      "agency": "Xsellence Realty",
      "experience": "19 years",
      "recent_sales": 199,
      "average_price_point": "$2.4K",
      "total_sales_last_year": "$23M",
      "profile": "https://www.fastexpert.com/agents/robert-saenz-13006/"
    },
    {
      "name": "Scott Myers",
      "agency": "Century 21 Scott Myers, Realtors",
      "experience": "51 years",
      "recent_sales": 269,
      "average_price_point": "$340.6K",
      "total_sales_last_year": "$120.2M",
      "profile": "https://www.fastexpert.com/agents/scott-myers-13007/"
    },
    {
      "name": "Lisa Sinn",
      "agency": "Keller Williams Legacy",
      "experience": "19 years",
      "recent_sales": 175,
      "average_price_point": "-",
      "total_sales_last_year": "$55M",
      "profile": "https://www.fastexpert.com/agents/lisa-sinn-13008/"
    },
    {
      "name": "Richard Rodriguez",
      "agency": "Miramont Group",
      "experience": "7 years",
      "recent_sales": 163,
      "average_price_point": "$1.4K",
      "total_sales_last_year": "$22.2M",
      "profile": "https://www.fastexpert.com/agents/richard-rodriguez-13009/"
    },
    {
      "name": "Shana Kounse",
      "agency": "Keller Williams Heritage",
      "experience": "11 years",
      "recent_sales": 129,
      "average_price_point": "$121.2K",
      "total_sales_last_year": "$23.4M",
      "profile": "https://www.fastexpert.com/agents/shana-kounse-13010/"
    },

    {
      "name": "The Neal & Neal Team",
      "url": "https://www.nealteam.com",
      "description": "A top-performing real estate team in San Antonio with extensive experience in buying and selling homes.",
      "image": "https://www.nealteam.com/logo.png"
    },
    {
      "name": "The Levi Rodgers Group",
      "url": "https://www.levirodgersgroup.com",
      "description": "Specializes in residential real estate, offering personalized services to clients in the San Antonio area.",
      "image": "https://www.levirodgersgroup.com/logo.png"
    },
    {
      "name": "Team Kristen Schramme",
      "url": "https://www.sanantoniotoprealtor.com",
      "description": "Known for exceptional client service and a deep understanding of the San Antonio real estate market.",
      "image": "https://www.sanantoniotoprealtor.com/logo.png"
    },
    {
      "name": "Exquisite Properties",
      "url": "https://www.exquisiteproperties.com",
      "description": "Offers luxury real estate services, focusing on high-end properties in San Antonio.",
      "image": "https://www.exquisiteproperties.com/logo.png"
    },
    

  
  
  
];

function Realtor() {
  const [selected, setSelected] = useState(null);
  const navigate = useNavigate();

  const handleTileClick = (category) => {
    if (category.url?.startsWith('/')) {
      navigate(category.url); // Internal route
    } else {
      setSelected(category); // Show popup for external or missing URL
    }
  };
  

  const closePopup = () => setSelected(null);

  return (
    <div className="categories-container">
      <CouponBanner />
      <div className="new-tile-container-new">
        {categories.map((category, index) => (
          <div
          key={index}
          className={`new-tile ${category.url ? 'tile-clickable' : 'tile-informational'}`}
          onClick={() => handleTileClick(category)}
        >
          <span className="icon-for-new-tile">{category.icon}</span>
          
          <span className="tile-name">
            {category.name}
            {(!category.url || category.url.startsWith('http')) && (
              <span className="info-badge" title="Unverified">ⓘ</span>
            )}
          </span>
        </div>
        
        ))}
      </div>

      <div className="back-home-tile">
        <Link to="/" className="back-home-link">Go Back to Homepage</Link>
      </div>

      {/* Popup Box */}
      {selected && (
  <div className="popup-overlay" onClick={closePopup}>
    <div className="popup-box" onClick={(e) => e.stopPropagation()}>
      <h3>{selected.name}</h3>
      <p><strong>Address:</strong> {selected.address}</p>
      <p><strong>Phone:</strong> {selected.phone}</p>

      {selected.url && selected.url.startsWith('http') && (
  <p>
    <a
      href={selected.url}
      target="_blank"
      rel="noopener noreferrer"
      className="business-website-link"
    >
      {selected.url
        .replace(/^https?:\/\//, '') 
        .replace(/\/$/, '')}         
    </a>
  </p>
)}


      <button onClick={closePopup}>Close</button>

      {selected.name  && (
        <div className="extra-links">
          <Link to="/request-form" className="popup-link">Claim your business</Link> <span> / </span>
          <Link to="/request-form" className="popup-link">Opt out</Link> <span> / </span>
          <Link to="/request-form" className="popup-link">Suggest update</Link>
        </div>
      )}
      <hr className="popup-divider" />

<p className="disclaimer-text">
Disclaimer: This listing has not been claimed or verified by the business owner.
Information provided is based on publicly available sources.
</p>

    </div>
  </div>
)}

    </div>
  );
}

export default Realtor;
