import React from "react";
import { 
  FaFacebookF, 
  FaInstagram, 
  FaTiktok, 
  FaTwitter, 
  FaLinkedinIn, 
  FaYoutube 
} from "react-icons/fa";
import { Link } from "react-router-dom";
import "./SocialLandingPage.css";

const SocialLandingPage = () => {
  const socials = [
    { icon: <FaFacebookF />, url: "https://www.facebook.com/profile.php?id=61560982405400" },
    { icon: <FaInstagram />, url: "https://www.instagram.com/goourhub/" },
    { icon: <FaTiktok />, url: "#" },
    // Additional socials can be added:
    { icon: <FaTwitter />, url: "https://twitter.com/yourprofile" },
    { icon: <FaLinkedinIn />, url: "https://linkedin.com/in/yourprofile" },
    { icon: <FaYoutube />, url: "https://youtube.com/yourchannel" }
  ];

  return (
    <div className="social-landing-page">
      <h1 className="social-heading">Connect With Us</h1>
      <p className="social-subheading">Follow our journey on social media</p>
      <div className="social-icons-grid">
        {socials.map((social, index) => (
          <a
            key={index}
            href={social.url}
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon-link"
          >
            {social.icon}
          </a>
        ))}
      </div>
      <div className="join-us-container">
        <Link to="/partner-signup" className="join-us-button">
          Join Us
        </Link>
      </div>
    </div>
  );
};

export default SocialLandingPage;
