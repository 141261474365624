import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './VisitResturants.css';
import CouponBanner from './CouponBanner';


// Define your three categories with names, icons, and URLs
const categories = [
  { name: 'Peters Elite Cleaning', icon: '🏘️', url: '/PetersEliteCleaning' },
  {
    "name": "Lupe's Home & Office Cleaning, LLC",
    "specialties": ["General Cleaning", "Deep Cleaning", "Move-In/Out Cleaning"],
    "address": "San Antonio, TX",
    "phone": "(210) 942-7242",
    "url": "https://mylupeshousecleaning.co/"
  },
  {
    "name": "Molly Maid of San Antonio-Northwest",
    "specialties": ["Home Cleaning", "Move Out Cleaning"],
    "address": "San Antonio, TX",
    "phone": "(210) 774-6720",
    "url": "https://www.mollymaid.com/san-antonio-northwest/"
  },
  {
    "name": "Merry Maids of San Antonio",
    "specialties": ["House Cleaning", "Special Event Cleaning"],
    "address": "San Antonio, TX",
    "phone": "(210) 774-6720",
    "url": "https://www.merrymaids.com/san-antonio/"
  },
  {
    "name": "Texas OCD Cleaners",
    "specialties": ["Residential Cleaning", "Commercial Cleaning"],
    "address": "San Antonio, TX",
    "phone": "Contact via website",
    "url": "https://texasocdcleaners.com/"
  },
  {
    "name": "Domestic Divas",
    "specialties": ["Move In/Move Out Cleaning", "Janitorial Services"],
    "address": "San Antonio, TX",
    "phone": "(210) 988-0288",
    "url": "https://domesticdivas.net/"
  },
  {
    "name": "The Cleaning Authority",
    "specialties": ["House Cleaning", "Eco-Friendly Cleaning"],
    "address": "San Antonio, TX",
    "phone": "(210) 775-2884",
    "url": "https://www.thecleaningauthority.com/sanantonio/"
  },
  {
    "name": "Maids On A Mission",
    "specialties": ["House Cleaning", "Move-In/Out Cleaning"],
    "address": "10864 Gulfdale St., San Antonio, TX 78216",
    "phone": "(210) 988-0288",
    "url": "https://www.maidsonamissionus.com/"
  },
  {
    "name": "Imagine Maids",
    "specialties": ["House Cleaning", "Maid Service"],
    "address": "San Antonio, TX",
    "phone": "(833) 435-3503",
    "url": "https://imaginemaids.com/san-antonio/"
  },
  {
    "name": "The White Glove Maid Service",
    "specialties": ["Basic Cleaning", "Spring Cleaning", "Move-In/Out Cleaning"],
    "address": "San Antonio, TX",
    "phone": "(210) 342-3878",
    "url": "https://thewhiteglove.biz/"
  },
  {
    "name": "Two Maids & A Mop",
    "specialties": ["House Cleaning", "Move-In/Out Cleaning"],
    "address": "San Antonio, TX",
    "phone": "Contact via website",
    "url": "https://twomaidscleaning.com/san-antonio/"
  },

  {
    "name": "Maid Affordable",
    "specialties": ["Residential Cleaning", "Recurring Cleaning", "Move-In/Out Cleaning"],
    "address": "San Antonio, TX",
    "phone": "(210) 372-9970",
    "url": "https://maidaffordable.com/"
  },
  {
    "name": "Alpha Elite Cleaning Service",
    "specialties": ["Residential Cleaning", "Commercial Cleaning", "Post-Construction Cleaning"],
    "address": "San Antonio, TX",
    "phone": "(210) 880-6788",
    "url": "https://alphaeliteservice.com/"
  },
  {
    "name": "Patriot Maids",
    "specialties": ["House Cleaning", "Maid Service"],
    "address": "San Antonio, TX",
    "phone": "(210) 939-7280",
    "url": "https://patriotmaids.com/location/san-antonio-tx/"
  },
  {
    "name": "Imagine Maids",
    "specialties": ["House Cleaning", "Maid Service"],
    "address": "San Antonio, TX",
    "phone": "(833) 450-1848",
    "url": "https://imaginemaids.com/san-antonio/"
  },
  {
    "name": "The Magic Cleaner",
    "specialties": ["Residential Cleaning", "Commercial Cleaning", "Carpet Cleaning"],
    "address": "20770 U.S. Hwy 281 N SUITE 108, San Antonio, TX 78258",
    "phone": "(210) 427-7668",
    "url": "https://themagiccleaner.com/"
  },
  {
    "name": "Trustworthy Cleaning Service",
    "specialties": ["Deep Cleaning", "Recurring House Cleaning", "Move-In/Move-Out Cleaning", "Post-Construction Cleaning", "Office Cleaning", "Carpet Cleaning", "Window Cleaning", "Pressure Washing"],
    "address": "San Antonio, TX",
    "phone": "(254) 249-6894",
    "url": "https://www.trustworthycleaningserv.com/san-antonio/"
  },
  {
    "name": "You've Got Maids",
    "specialties": ["Residential Cleaning", "Move-In/Move-Out Cleaning", "Eco-Friendly Cleaning"],
    "address": "San Antonio, TX",
    "phone": "(210) 590-6243",
    "url": "https://www.youvegotmaids.com/house-cleaning/tx/san-antonio/"
  },
  {
    "name": "3rd Generation Services",
    "specialties": ["Commercial Cleaning", "Janitorial Services"],
    "address": "6800 Park Ten Blvd., Suite 136-E, San Antonio, TX 78213",
    "phone": "(210) 224-9588",
    "url": "https://3rdgenerationservices.com/contact.html"
  },
  
];

function CleaningServices() {
  const [selected, setSelected] = useState(null);
  const navigate = useNavigate();

  const handleTileClick = (category) => {
    if (category.url?.startsWith('/')) {
      navigate(category.url); // Internal route
    } else {
      setSelected(category); // Show popup for external or missing URL
    }
  };
  

  const closePopup = () => setSelected(null);

  return (
    <div className="categories-container">
      <CouponBanner />
      <div className="new-tile-container-new">
        {categories.map((category, index) => (
          <div
          key={index}
          className={`new-tile ${category.url ? 'tile-clickable' : 'tile-informational'}`}
          onClick={() => handleTileClick(category)}
        >
          <span className="icon-for-new-tile">{category.icon}</span>
          
          <span className="tile-name">
            {category.name}
            {(!category.url || category.url.startsWith('http')) && (
              <span className="info-badge" title="Unverified">ⓘ</span>
            )}
          </span>
        </div>
        
        ))}
      </div>

      <div className="back-home-tile">
        <Link to="/" className="back-home-link">Go Back to Homepage</Link>
      </div>

      {/* Popup Box */}
      {selected && (
  <div className="popup-overlay" onClick={closePopup}>
    <div className="popup-box" onClick={(e) => e.stopPropagation()}>
      <h3>{selected.name}</h3>
      <p><strong>Address:</strong> {selected.address}</p>
      <p><strong>Phone:</strong> {selected.phone}</p>

      {selected.url && selected.url.startsWith('http') && (
  <p>
    <a
      href={selected.url}
      target="_blank"
      rel="noopener noreferrer"
      className="business-website-link"
    >
      {selected.url
        .replace(/^https?:\/\//, '') 
        .replace(/\/$/, '')}         
    </a>
  </p>
)}


      <button onClick={closePopup}>Close</button>

      {selected.name  && (
        <div className="extra-links">
          <Link to="/request-form" className="popup-link">Claim your business</Link> <span> / </span>
          <Link to="/request-form" className="popup-link">Opt out</Link> <span> / </span>
          <Link to="/request-form" className="popup-link">Suggest update</Link>
        </div>
      )}
      <hr className="popup-divider" />

<p className="disclaimer-text">
Disclaimer: This listing has not been claimed or verified by the business owner.
Information provided is based on publicly available sources.
</p>

    </div>
  </div>
)}

    </div>
  );
}


export default CleaningServices;
