import React, { useState } from 'react';
import './RequestForm.css'; // optional if you want to style it externally

function RequestForm() {
  const [formData, setFormData] = useState({
    type: '',
    businessName: '',
    contactName: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const form = new FormData();
    for (const key in formData) {
      form.append(key, formData[key]);
    }
  
    try {
      const response = await fetch("https://goourhub.com/send.php", {
        method: "POST",
        body: form
      });
  
      if (response.ok) {
        alert("✅ Your request was submitted! We'll be in touch.");
        setFormData({
          type: '',
          businessName: '',
          contactName: '',
          email: '',
          message: ''
        });
      } else {
        alert("❌ Submission failed. Try again.");
      }const handleSubmit = async (e) => {
        e.preventDefault();
      
        const form = new FormData();
        Object.entries(formData).forEach(([key, value]) => form.append(key, value));
      
        try {
          const res = await fetch("https://goourhub.com/send.php", {
            method: "POST",
            body: form
          });
      
          const text = await res.text(); // or use res.json() if you return JSON
      
          if (res.ok || text.includes("success")) {
            alert("✅ Email sent! We’ll be in touch.");
            setFormData({
              type: "",
              businessName: "",
              contactName: "",
              email: "",
              message: ""
            });
          } else {
            console.error("Server said:", text);
            alert("❌ Server error. Please try later.");
          }
        } catch (err) {
          console.error("Error:", err);
          alert("❌ Something went wrong.");
        }
      };
      
    } catch (err) {
      console.error("Form error:", err);
      alert("❌ Server error. Please try later.");
    }
  };
  

  return (
    <div className="request-form-container">
      <h2 className="form-title">Business Request Form</h2>
      <form onSubmit={handleSubmit} className="request-form">
        <label>Request Type</label>
        <select name="type" value={formData.type} onChange={handleChange} required>
          <option value="">Select One</option>
          <option value="claim">Claim Business</option>
          <option value="optout">Opt Out</option>
          <option value="update">Suggest Update</option>
        </select>

        <label>Business Name</label>
        <input
          type="text"
          name="businessName"
          value={formData.businessName}
          onChange={handleChange}
          placeholder="Business name as listed"
          required
        />

        <label>Contact Name</label>
        <input
          type="text"
          name="contactName"
          value={formData.contactName}
          onChange={handleChange}
          required
        />

        <label>Email</label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />

        <label>Message</label>
        <textarea
          name="message"
          rows="4"
          placeholder="Provide details about your request..."
          value={formData.message}
          onChange={handleChange}
          required
        ></textarea>

        <button type="submit">Submit</button>

        <p className="disclaimer-text">
          By submitting, you agree to GoOurHub’s <a className='request_term' href="/terms">Terms & Conditions</a>. Claiming your business does not automatically register or sign you up — our team will review and verify ownership first.
        </p>
      </form>
    </div>
  );
}

export default RequestForm;
