import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../logo.png";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import emailjs from "@emailjs/browser";
import "./ThankYouPartner.css";

const ZelleThankYou = () => {
  const contentRef = useRef(null);

  // Local state for user data
  const [firstName, setFirstName] = useState("");
  const [zelleConfirmation, setZelleConfirmation] = useState("");
  const [currentDate, setCurrentDate] = useState("");
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);

  useEffect(() => {
    // Set today's formatted date
    const today = new Date();
    const formattedDate = today.toLocaleDateString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    setCurrentDate(formattedDate);

    // Retrieve pending signup data from localStorage
    // For Zelle flow, assume data is stored as "vendorSignupComplete"
    const dataString = localStorage.getItem("vendorSignupComplete");
    if (!dataString) {
      setNoData(true);
      setLoading(false);
      return;
    }

    const data = JSON.parse(dataString);
    setFirstName(data.firstName || "Partner");
    setZelleConfirmation(data.confirmationNumber || "Not Provided");

    // If payment method is Zelle, send the admin email with extra fields
    if (data.paymentMethod === "zelle") {
      const emailParams = {
        firstName: data.firstName,
        lastName: data.lastName,
        businessName: data.businessName,
        businessType:
          (data.businessType === "others" || data.businessType === "multiple")
            ? data.otherBusinessType
            : data.businessType,
        email: data.email,
        phone: data.phone,
        phoneType: data.phoneType,
        preferredContact: data.preferredContact ? data.preferredContact.join(", ") : "",
        startDate: data.startDate,
        couponCode: data.couponCode,
        promoPrice: data.promoOffer
          ? data.promoOffer === "FREE"
            ? "Free"
            : `$${data.promoOffer}`
          : "",
        userID: "Not Assigned",
        // Extra fields from Zelle confirmation page:
        confirmationNumber: data.confirmationNumber || "Not Provided",
        note: data.note || "",
      };

      emailjs
        .send("service_wcb9p0d", "template_1716vkm", emailParams, "4L6c5XHTR-ND4i4-d")
        .then(() => console.log("Admin email sent successfully for Zelle payment."))
        .catch((error) => console.error("Error sending admin email:", error))
        .finally(() => {
          // Clear pending data and stop loading
          localStorage.removeItem("vendorSignupComplete");
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, []);

  // PDF generation function (unchanged)
  const generatePDF = () => {
    const input = contentRef.current;
    const buttons = document.querySelectorAll(".pdf-hide");
    buttons.forEach((btn) => (btn.style.display = "none"));

    html2canvas(input, {
      scale: 3,
      windowWidth: document.documentElement.offsetWidth,
      windowHeight: document.documentElement.scrollHeight,
      backgroundColor: null,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save(`Thank_You_${firstName}.pdf`);
      buttons.forEach((btn) => (btn.style.display = "block"));
    });
  };

  // Loading and no data states
  if (loading) {
    return (
      <div className="thank-you-page">
        <p>Processing your payment details, please wait...</p>
      </div>
    );
  }

  if (noData) {
    return (
      <div className="thank-you-page">
        <h2>No Signup Data Found</h2>
        <p>
          If you just completed a payment and reached this page, please contact us for assistance.
        </p>
        <Link to="/" className="neon-home-button pdf-hide">
          Back to Home
        </Link>
      </div>
    );
  }

  // Main content rendered when data is available
  return (
    <div className="thank-you-page">
      <button className="neon-button pdf-hide" onClick={generatePDF}>
        Download as PDF
      </button>

      <div ref={contentRef} className="thank-you-content">
        <div className="thank-you-logo">
          <img src={logo} alt="GoOurHub Logo" />
          <p className="thank-you-date">{currentDate}</p>
        </div>

        <h1 className="thank-you-title">
          Thank You, {firstName.charAt(0).toUpperCase() + firstName.slice(1)}!
        </h1>

        <p>Thank you for your Zelle payment.</p>
        <h2 className="vendor-id">
          Your Vendor ID: Not Assigned<br />
          Zelle Confirmation Number: {zelleConfirmation}
        </h2>
        <p>
          Once we verify your payment, we will send you a confirmation email with your Vendor ID.
        </p>
        <p>
          We appreciate your business. Together we will create <strong>amazing opportunities</strong>.
        </p>
        <p>
          If you have any questions, feel free to{" "}
          <Link to="/contact" className="thank-you-contact">
            contact us
          </Link>.
        </p>

        <Link to="/" className="neon-home-button pdf-hide">
          Back to Home
        </Link>
      </div>
    </div>
  );
};

export default ZelleThankYou;
