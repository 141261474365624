import React from "react";
import { Link } from "react-router-dom";

function TermsPage() {
  return (
    <div
      className="terms-page"
      style={{
        maxWidth: "800px",
        margin: "0 auto",
        padding: "1rem",
        textAlign: "left",
        color: "black",
        fontSize: "0.9rem", // slightly smaller font size
        lineHeight: "1.6",
      }}
    >
      <h4>Terms & Conditions</h4>
      <p style={{ marginBottom: "1rem" }}>
        Please see our{" "}
        <Link to="/faq" className="faq-link" style={{ color: "#673AB7" }}>
          FAQs
        </Link>{" "}
        page for additional details.
      </p>

      <h5>Benefits for You</h5>
      <p style={{ marginBottom: "1rem" }}>
        Get more visibility online across various communities in one place.
        Have your own page on GoOurHub. Reach new customers through multiple
        platforms (Instagram, Facebook, TikTok, etc.).
      </p>

      <h5>Your Responsibilities</h5>
      <ul style={{ marginBottom: "1rem" }}>
        <li>You are responsible for the accuracy of your business content in a timely manner.</li>
        <li>You must be 18 or older.</li>
        <li>You have the authority to add your business with GoOurHub.</li>
      </ul>

      <h5>Our Responsibilities</h5>
      <p style={{ marginBottom: "1rem" }}>
        We provide comprehensive marketing services. We provide contact and account
        management support. We do not own your business.
      </p>

      <h5>Liability Waiver</h5>
      <p style={{ marginBottom: "1rem" }}>
        GoOurHub is not liable for any mistakes, errors, or technical problems. You must
        be 18 or older and have the authority to add your business. By signing up, you
        agree to these terms.
      </p>

      <h3>Business Listings Disclaimer</h3>
<p>
  GoOurHub displays business listings compiled from publicly available sources and directories.
  If your business is listed and you'd like to claim, update, or remove it, please use the
  <Link to="/request-form" className="legal-link"> <strong>claim/opt-out form/Suggest edits</strong></Link>.
</p>
<p>
  While we strive for accuracy, GoOurHub does not guarantee the completeness or verification
  of business information unless officially claimed.
</p>
<p>
  By using this platform, you acknowledge and agree that listings may include unverified content
  sourced from public records or community submissions.
</p>
Support : contact@goourhub.com

    </div>
  );
}

export default TermsPage;
