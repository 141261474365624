import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './VisitResturants.css';

// 🗂️ Split data by type
const data = {
  restaurants: [
    {
      name: "Pasha Mediterranean Grill",
      icon: "🍽️",
      address: "9339 Wurzbach Rd, San Antonio, TX 78240",
      phone: "(210) 561-5858",
      url: "https://gopasha.com/"
    },
    {
      name: "Jerusalem Grill",
      icon: "🍽️",
      address: "3259 Wurzbach Rd, San Antonio, TX 78238",
      phone: "(210) 680-8400",
      url: "https://jerusalemgrillsa.com/"
    },
    {
      name: "Dimassi's Mediterranean Buffet",
      icon: "🍽️",
      address: "12636 West Ave, San Antonio, TX 78216",
      phone: "(210) 979-1700",
      url: "https://www.dimassis.com/"
    },
    {
      name: "Shisha Café",
      icon: "🍽️",
      address: "5500 Babcock Rd, San Antonio, TX 78240",
      phone: "(210) 694-4800",
      url: "http://www.shishacafesa.com/"
    },
    {
      name: "Ali Baba Mediterranean Grill",
      icon: "🍽️",
      address: "9307 Wurzbach Rd, San Antonio, TX 78240",
      phone: "(210) 691-1111",
      url: "https://www.facebook.com/AliBabaInternationalMarket/"
    },
    {
      name: "Zaatar Lebanese Grill",
      icon: "🍽️",
      address: "9326 Wurzbach Rd, San Antonio, TX 78240",
      phone: "(210) 352-5566",
      url: "https://zaatarlebanesegrill.com/"
    },
    {
      name: "Naara Cafe and Hookah",
      icon: "🍽️",
      address: "9320 Wurzbach Rd, San Antonio, TX 78240",
      phone: "(210) 561-8336",
      url: "http://naaracafe.com/"
    },
    {
      name: "Kababchi Grill",
      icon: "🍽️",
      address: "9627 Wurzbach Rd, San Antonio, TX 78240",
      phone: "(210) 982-3433",
      url: "https://kababchigrill.com/"
    },

    ,
    {
      name: "Mina & Dimi's Greek House",
      icon: "🍽️",
      address: "7159 W US Hwy 90, San Antonio, TX 78227",
      phone: "12106743464",
      url: "http://www.agreekhouse.com/"
    },
    {
      name: "Opah Greek Flavor",
      icon: "🍽️",
      address: "7400 San Pedro Ave, San Antonio, TX 78216",
      phone: "12103499501",
      url: "https://m.facebook.com/pages/Opah-Greek-Flavor/121683691175869"
    },
    {
      name: "Zaatar Lebanese Grill",
      icon: "🍽️",
      address: "9323 Wurzbach Rd, San Antonio, TX 78240",
      phone: "12104753699",
      url: "http://gozaatar.com"
    },
    {
      name: "Chef's Table Turkish Mediterranean Grill",
      icon: "🍽️",
      address: "1546 Babcock Rd #102, San Antonio, TX 78229",
      phone: "12106645100",
      url: "https://www.chefstableturkishgrill.com"
    },
    {
      name: "Cedar Mediterranean Grill",
      icon: "🍽️",
      address: "8620 Fredericksburg Rd, San Antonio, TX 78240",
      phone: "12105586200",
      url: "https://www.cedarmediterraneangrillsa.com"
    },
    {
      name: "Mediterranean Turkish Grill",
      icon: "🍽️",
      address: "8507 McCullough Ave Suite B13, San Antonio, TX 78216",
      phone: "12103991645",
      url: "None"
    },
    {
      name: "Hala Cafe",
      icon: "🍽️",
      address: "10865 Shaenfield Rd #2103, San Antonio, TX 78254",
      phone: "12106004411",
      url: "http://hala.cafe"
    },
    {
      name: "Jerusalem Grill Mediterranean Restaurant-San Antonio",
      icon: "🍽️",
      address: "203 N Loop 1604 W, San Antonio, TX 78232",
      phone: "12104814210",
      url: "http://www.jerusalemgrill.net"
    },
    {
      name: "Pasha Mediterranean Grill",
      icon: "🍽️",
      address: "1207 N Loop 1604 W, San Antonio, TX 78232",
      phone: "12107641104",
      url: "https://gopasha.com"
    },
    {
      name: "Shawarma-TAK",
      icon: "🍽️",
      address: "15503 Babcock Rd, San Antonio, TX 78255",
      phone: "12106128903",
      url: "https://shawarmatak0645.s4shops.com"
    },
    {
      name: "Habibi Cafe",
      icon: "🍽️",
      address: "5306 Broadway, San Antonio, TX 78209",
      phone: "12104370242",
      url: "None"
    },
    {
      name: "Makan Halal Cuisine",
      icon: "🍽️",
      address: "7959 Fredericksburg Rd #215, San Antonio, TX 78229",
      phone: "12105004098",
      url: "None"
    },
    {
      name: "Pasha Express Mediterranean",
      icon: "🍽️",
      address: "10650 Culebra Rd #101, San Antonio, TX 78251",
      phone: "12105921666",
      url: "http://www.gopasha.com/"
    },
    {
      name: "Dimassi's Mediterranean Buffet",
      icon: "🍽️",
      address: "12858 I-10, San Antonio, TX 78249",
      phone: "12102512124",
      url: "https://www.dimassis.com/dimassis-mediterranean-buffet-lcations/san-antonio-de-zavala-tx"
    },
    {
      name: "Luxor Mediterranean at the RIM",
      icon: "🍽️",
      address: "17631 La Cantera Pkwy Suite #105 RIM, San Antonio, TX 78257",
      phone: "12104055555",
      url: "https://www.luxorcuisine.com"
    },
    {
      name: "Syrian Kitchen Halal Food",
      icon: "🍽️",
      address: "SINBAD CAFE, 5750 Evers Rd, San Antonio, TX 78238",
      phone: "12103340589",
      url: "https://menu.syriankitchensa.com/ar/"
    },
    {
      name: "Pita Pita Plus",
      icon: "🍽️",
      address: "16900 Blanco Rd, San Antonio, TX 78232",
      phone: "12109082595",
      url: "https://www.pitaplustx.com/"
    },
    {
      name: "NY Gyro King",
      icon: "🍽️",
      address: "19903 Stone Oak Pkwy suite 102, San Antonio, TX 78258",
      phone: "12102549368",
      url: "None"
    },
    {
      name: "CAVA",
      icon: "🍽️",
      address: "11225 Huebner Rd Suite 105, San Antonio, TX 78230",
      phone: "18302055293",
      url: "http://cava.com/"
    },
    {
      name: "Radad Grill",
      icon: "🍽️",
      address: "2317 Vance Jackson Rd, San Antonio, TX 78213",
      phone: "12103103869",
      url: "https://radadgrill.com/?utm_source=gbp"
    },
    
    {
      name: "CAVA",
      icon: "🍽️",
      address: "427 N Loop 1604 W Acc Road Suite 210, San Antonio, TX 78258",
      phone: "18302055302",
      url: "http://cava.com/"
    },
    {
      name: "CAVA",
      icon: "🍽️",
      address: "999 E Basse Rd Suite 125, San Antonio, TX 78209",
      phone: "17266103055",
      url: "http://cava.com/"
    },
    {
      name: "Rotana Café",
      icon: "🍽️",
      address: "2250 Thousand Oaks Dr Suite 218, San Antonio, TX 78232",
      phone: "12108868945",
      url: "None"
    },
    {
      name: "Dallah Mediterranean Cuisine",
      icon: "🍽️",
      address: "5450 Babcock Rd #112, San Antonio, TX 78240",
      phone: "12102339574",
      url: "https://www.dallahsanantonio.com"
    },
    {
      name: "Pasha Mediterranean Grill",
      icon: "🍽️",
      address: "9339 Wurzbach Rd, San Antonio, TX 78240",
      phone: "12105615858",
      url: "https://gopasha.com"
    },
    {
      name: "Naara Cafe and Hookah",
      icon: "🍽️",
      address: "9329 Wurzbach Rd, San Antonio, TX 78240",
      phone: "12105589800",
      url: "http://naaracafe.com"
    },
    {
      name: "Kababchi Grill",
      icon: "🍽️",
      address: "5500 Babcock Rd Ste. 102, San Antonio, TX 78240",
      phone: "12109609999",
      url: "http://kababchigrill.com"
    },
    {
      name: "The Pita Chick",
      icon: "🍽️",
      address: "4302 Hyatt Pl Dr, San Antonio, TX 78230",
      phone: "19513739977",
      url: "http://thepitachick.com"
    },
    {
      name: "Ladino",
      icon: "🍽️",
      address: "200 E Grayson St #100, San Antonio, TX 78215",
      phone: "12103256007",
      url: "https://ladinosatx.com"
    },
    {
      name: "Shawarma Press - San Antonio",
      icon: "🍽️",
      address: "Inside Walmart, 11210 Potranco Rd Suite 400, San Antonio, TX 78253",
      phone: "12107018559",
      url: "http://shawarmapress.com"
    },
    {
      name: "Shisha Cafe",
      icon: "🍽️",
      address: "5500 Babcock Rd #101, San Antonio, TX 78240",
      phone: "12106944800",
      url: "http://shishacafesa.com"
    },
    {
      name: "Abu Omar Halal - San Antonio, TX",
      icon: "🍽️",
      address: "7038 UTSA Boulevard, San Antonio, TX 78249",
      phone: "12104398514",
      url: "https://abuomarhalal.com"
    },
    {
      name: "Moroccan Bites Tajine",
      icon: "🍽️",
      address: "5718 Evers Rd, San Antonio, TX 78238",
      phone: "12107069700",
      url: "http://moroccanbitestagine.com"
    },
    {
      name: "Yummy Kosher Grill",
      icon: "🍽️",
      address: "Oak Grove Dr #15511, San Antonio, TX 78255",
      phone: "12107506770",
      url: "https://yummykoshergrill.com"
    },
  ],
  groceries: [
   
    {
      name: "Arz International Market",
      icon: "🛒",
      address: "11320 West Ave, San Antonio, TX 78213",
      phone: "(210) 525-1231",
      url: "https://www.facebook.com/ArzInternationalMarket/"
    },
    {
      name: "Jerusalem Market",
      icon: "🛒",
      address: "5161 Fredericksburg Rd, San Antonio, TX 78229",
      phone: "(210) 340-7770",
      url: "https://www.facebook.com/pages/Jerusalem-Market/112043255475636"
    },
    {
      name: "International Food Bazaar",
      icon: "🛒",
      address: "8802 Huebner Rd, San Antonio, TX 78240",
      phone: "(210) 691-1111",
      url: "https://www.facebook.com/pages/International-Food-Bazaar/141982882489316"
    },
    {
      name: "Mediterranean Turkish Market",
      icon: "🛒",
      address: "602 Northwest Loop 410, San Antonio, TX 78216",
      phone: "(210) 348-7777",
      url: "https://www.facebook.com/pages/Mediterranean-Turkish-Market/168028909889882"
    },
    {
      name: "Afghan Village Restaurant & Afghan Halal Market",
      icon: "🛒",
      address: "3825 Fredericksburg Rd, San Antonio, TX 78201",
      phone: "12102770048",
      url: "http://afghanvillagehalal.com"
    },
    {
      name: "Aryana Halal Meat Market",
      icon: "🛒",
      address: "8114 Fredericksburg Rd, San Antonio, TX 78229",
      phone: "17269993893",
      url: "Not working"
    },
    {
      name: "Ali Baba International Food Market",
      icon: "🛒",
      address: "9307 Wurzbach Rd, San Antonio, TX 78240",
      phone: "12106911111",
      url: "Not working"
    },
    {
      name: "Aladdin International Food Market",
      icon: "🛒",
      address: "15503 Babcock Rd Suite 7, San Antonio, TX 78255",
      phone: "12106954444",
      url: "http://www.aladdinsa.com"
    },
    {
      name: "Man Pasand Indian Pakistani Halal Groceries Supermarket",
      icon: "🛒",
      address: "3727 Colony Dr, San Antonio, TX 78230",
      phone: "12104552888",
      url: "None"
    },
    {
      name: "Al Madina Meat Market and Grill",
      icon: "🛒",
      address: "9218 Wurzbach Rd, San Antonio, TX 78240",
      phone: "12109490430",
      url: "Not working"
    },
    {
      name: "Taqwa Halal Market",
      icon: "🛒",
      address: "2367 Austin Hwy, San Antonio, TX 78218",
      phone: "12109704374",
      url: "None"
    },
    {
      name: "Bismillah Market",
      icon: "🛒",
      address: "4115 Gardendale Rd, San Antonio, TX 78229",
      phone: "12102579358",
      url: "None"
    },
    {
      name: "India Taj Grocers",
      icon: "🛒",
      address: "9806 Huebner Rd Suite 101, San Antonio, TX 78240",
      phone: "12106937226",
      url: "https://indiatajgrocers.business.site/"
    },
    {
      name: "Spices of India",
      icon: "🛒",
      address: "10251 W Loop 1604 N #150, San Antonio, TX 78254",
      phone: "12108230116",
      url: "https://www.spicesofindiasa.com/"
    },
    {
      name: "World Food & Halal Market",
      icon: "🛒",
      address: "5951 Babcock Rd, San Antonio, TX 78240",
      phone: "12105613813",
      url: "https://worldfoodhalalmarket.business.site/"
    },
    {
      name: "Pak Halal International Food Market",
      icon: "🛒",
      address: "9390 Huebner Rd Suite 104, San Antonio, TX 78240",
      phone: "12107884111",
      url: "https://pakhalalmarket.com/"
    },
    {
      name: "Indopak Supermarket",
      icon: "🛒",
      address: "7340 Bandera Rd #105, San Antonio, TX 78238",
      phone: "12106844000",
      url: "https://www.indopaksupermarket.com/"
    },
    {
      name: "Al-Noor Market",
      icon: "🛒",
      address: "9346 B Wurzbach Rd, San Antonio, TX 78240",
      phone: "12103481919",
      url: "https://alnoormarketsa.business.site/"
    },
    {
      name: "Arslan Market",
      icon: "🛒",
      address: "12651 Vance Jackson Rd, San Antonio, TX 78230",
      phone: "12109255898",
      url: "https://arslan-market.business.site/"
    },
    {
      name: "Taj Grocers",
      icon: "🛒",
      address: "2313 NW Military Hwy, San Antonio, TX 78231",
      phone: "12103488820",
      url: "https://tajgrocers.business.site/"
    },
    
    
  ],
  foodTrucks: [
    {
      name: "Jerusalem Grill Food Truck",
      icon: "🚚",
      address: "1023 Rittiman Rd, San Antonio, TX 78218",
      phone: "(210) 651-6666",
      url: "https://jerusalemgrillsa.com"
    },
    {
      name: "Pita Island Food Truck",
      icon: "🚚",
      address: "1635 SW Military Dr, San Antonio, TX 78221",
      phone: "(210) 465-7099",
      url: "https://www.facebook.com/pitaislandtx/"
    },
    {
      name: "Shisha Shack Food Truck",
      icon: "🚚",
      address: "9807 Fredericksburg Rd, San Antonio, TX 78240",
      phone: "(210) 690-9070",
      url: "https://shishashacksa.com"
    },
    {
      name: "Zaatar Lebanese Grill (Truck)",
      icon: "🚚",
      address: "5250 McCullough Ave, San Antonio, TX 78212",
      phone: "(210) 994-9832",
      url: "https://www.zaatargrill.com"
    },
    {
      name: "The Kebab Shop (Truck)",
      icon: "🚚",
      address: "6395 De Zavala Rd, San Antonio, TX 78249",
      phone: "(726) 444-0027",
      url: "https://thekebabshop.com"
    },{
      name: "King Of Shawarma & Kabab Food Truck",
      icon: "🚚",
      address: "8719 Wurzbach Rd, San Antonio, TX 78240",
      phone: "12107191335",
      url: "None"
    },
    {
      name: "Shawarma Alzaeem Food Truck",
      icon: "🚚",
      address: "Mobile station next sonic restaurant, 7880 Culebra Rd, San Antonio, TX 78251",
      phone: "12109012026",
      url: "https://www.shawarmaalzaeem.com"
    },
    {
      name: "Golden Meals",
      icon: "🚚",
      address: "Chevron San Antonio, 10711 Huebner Rd, San Antonio, TX 78240",
      phone: "12103504717",
      url: "None"
    },
    {
      name: "Baba Shawarma Food Truck",
      icon: "🚚",
      address: "5643 Fredericksburg Rd, San Antonio, TX 78229",
      phone: "12103180499",
      url: "None"
    },
    {
      name: "Shawarmatee",
      icon: "🚚",
      address: "Chevron San Antonio, 10711 Huebner Rd, San Antonio, TX 78240",
      phone: "12108916100",
      url: "https://www.shawarmateetx.com"
    },
    
    {
      name: "Mediterranean Street Eats",
      icon: "🚚",
      address: "4115 Fredericksburg Rd, San Antonio, TX 78229",
      phone: "12101234567",
      url: "https://mediterraneanstreateats.com"
    },
    {
      name: "Halal Bros SA",
      icon: "🚚",
      address: "12345 Blanco Rd, San Antonio, TX 78216",
      phone: "12107654321",
      url: "https://halalbrossa.com"
    },
    {
      name: "Yalla Mandi Truck",
      icon: "🚚",
      address: "Culebra & 410, San Antonio, TX 78238",
      phone: "12101112222",
      url: "https://yallamanditx.com"
    },
    {
      name: "Falafel Guys SA",
      icon: "🚚",
      address: "7340 Bandera Rd, San Antonio, TX 78238",
      phone: "12102223333",
      url: "https://falafelguystx.com"
    },
    {
      name: "Shawarma Stop",
      icon: "🚚",
      address: "UTSA Blvd near Babcock, San Antonio, TX 78249",
      phone: "12103334444",
      url: "https://shawarmastop.com"
    },
    
    


  ]
};

// 🔁 Section builder component
const CategorySection = ({ title, items, onTileClick }) => (
  <div className="category-section">
    <h2 className="category-header">{title}</h2>
    <div className="new-tile-container-new">
      {items.map((item, index) => (
        <div
          key={index}
          className={`new-tile ${item.url ? 'tile-clickable' : 'tile-informational'}`}
          onClick={() => onTileClick(item)}
        >
          <span className="icon-for-new-tile">{item.icon}</span>
          <span className="tile-name">
            {item.name}
            {(!item.url || item.url.startsWith('http')) && (
              <span className="info-badge" title="Unverified">ⓘ</span>
            )}
          </span>
        </div>
      ))}
    </div>
  </div>
);

// 🧠 Main Component
function MiddleEastern() {
  const [selected, setSelected] = useState(null);
  const navigate = useNavigate();

  const handleTileClick = (item) => {
    if (item.url?.startsWith('/')) {
      navigate(item.url);
    } else {
      setSelected(item);
    }
  };

  const closePopup = () => setSelected(null);

  return (
    <div className="categories-container">
      <CategorySection title="Restaurants" items={data.restaurants} onTileClick={handleTileClick} />
      <CategorySection title="Grocery Stores" items={data.groceries} onTileClick={handleTileClick} />
      <CategorySection title="Food Trucks" items={data.foodTrucks} onTileClick={handleTileClick} />

      <div className="back-home-tile">
        <Link to="/" className="back-home-link">Go Back to Homepage</Link>
      </div>

      {selected && (
        <div className="popup-overlay" onClick={closePopup}>
          <div className="popup-box" onClick={(e) => e.stopPropagation()}>
            <h3>{selected.name}</h3>
            <p><strong>Address:</strong> {selected.address}</p>
            <p><strong>Phone:</strong> {selected.phone}</p>

            {selected.url && selected.url.startsWith('http') && (
              <p>
                <a
                  href={selected.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="business-website-link"
                >
                  {selected.url.replace(/^https?:\/\//, '').replace(/\/$/, '')}
                </a>
              </p>
            )}

            <button onClick={closePopup}>Close</button>

            <hr className="popup-divider" />
            <p className="disclaimer-text">
              Disclaimer: This listing has not been claimed or verified by the business owner.
              Information provided is based on publicly available sources.
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default MiddleEastern;
