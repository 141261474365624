import React, { useState } from 'react';
import './ImprtantLinksContainerOverridde.css';
import { Link } from "react-router-dom";
import { 
  FaAmazon, FaGoogle, FaFacebook, FaTwitter, FaInstagram, 
  FaYoutube, FaLinkedin, FaGithub, FaPinterest, FaTiktok,
  FaReddit, FaSnapchat, FaSpotify, FaSlack, FaWhatsapp
} from 'react-icons/fa';

function ImportantLinks() {
  const [showSubLinks, setShowSubLinks] = useState(false);

  const links = [
    { name: 'Community Events', url: 'https://www.sacurrent.com/sanantonio/eventsearch?v=d' },
    { name: 'Community Connect', url: '#' },
  ];

  const subLinks = [
    { name: 'American Indians in Texas', url: 'https://aitscm.org/' },
    { name: 'Anuja SA (Marathi Community)', url: 'https://www.anujasa.com/' },
    { name: 'Bengali Community', url: 'https://sanantoniobcc.org/' },
    { name: 'Gujarati Samaj of San Antonio', url: 'https://www.gujaratisamajofsa.org/' },
    { name: 'India Association of San Antonio', url: 'https://www.indiasa.org/' },
    { name: 'Kannada Sangha San Antonio', url: 'https://www.kks-sa.org/html/NewSite/about.html' },
    { name: 'Kerala Association of San Antonio (Malayalam Community)', url: 'https://www.keralasa.org/' },
    { name: 'Mexican American Unity Council', url: 'https://stmupublichistory.org/lcd/index.php/mexican-american-unity-council/' },
    { name: 'San Antonio African American Community Archive and Museum', url: 'https://www.sanantoniomag.com/local-organizations-to-support-to-further-racial-equality/' },
    { name: 'San Antonio Ethnic Art Society', url: 'https://saethnicartsociety.org/' },
    { name: 'Tamil Community', url: 'https://satamilsangam.org/' },
    { name: 'Urban Indian SA', url: 'https://urbanindiansa.net/' },
  ];

  const popularSites = [
    { name: 'Amazon', url: 'https://www.amazon.com', icon: FaAmazon },
    { name: 'Google', url: 'https://www.google.com', icon: FaGoogle },
    { name: 'Facebook', url: 'https://www.facebook.com', icon: FaFacebook },
    { name: 'Twitter', url: 'https://www.twitter.com', icon: FaTwitter },
    { name: 'Instagram', url: 'https://www.instagram.com', icon: FaInstagram },
    { name: 'YouTube', url: 'https://www.youtube.com', icon: FaYoutube },
    { name: 'LinkedIn', url: 'https://www.linkedin.com', icon: FaLinkedin },
    { name: 'GitHub', url: 'https://www.github.com', icon: FaGithub },
    { name: 'Pinterest', url: 'https://www.pinterest.com', icon: FaPinterest },
    { name: 'TikTok', url: 'https://www.tiktok.com', icon: FaTiktok },
    { name: 'Reddit', url: 'https://www.reddit.com', icon: FaReddit },
    { name: 'Snapchat', url: 'https://www.snapchat.com', icon: FaSnapchat },
    { name: 'Spotify', url: 'https://www.spotify.com', icon: FaSpotify },
    { name: 'Slack', url: 'https://www.slack.com', icon: FaSlack },
    { name: 'WhatsApp', url: 'https://www.whatsapp.com', icon: FaWhatsapp },
  ];
  return (
    <div className="important-links-container-wrapper">
      <div className="important-links-container important-links-container-custom">
              {/* Popular Sites Section */}
              <div className="popular-sites-list">
  <h5><b>Popular Sites</b></h5>
  <div className="popular-sites-grid">
    {popularSites.map((site, index) => {
      const Icon = site.icon;
      return (
        <a 
          key={index}
          href={site.url}
          target="_blank"
          rel="noopener noreferrer"
          className="popular-site-box"
          title={site.name}
        >
          <Icon className={`icon-${site.name.toLowerCase()}`} size={28} />
          <span>{site.name}</span>
        </a>
      );
    })}
  </div>
</div>
        {/* Important Links Section */}
        <div className="important-links-list">
          <h5><b>Important Links Hub</b></h5>
          <ul className="important-links-container">
            {links.map((link, index) => (
              <li key={index}>
                <a
                  href={link.url}
                  className="important-link-button"
                  target={link.name === 'Community Events' ? '_blank' : '_self'}
                  rel={link.name === 'Community Events' ? 'noopener noreferrer' : undefined}
                  onClick={(e) => {
                    if (link.name === 'Community Connect') {
                      e.preventDefault();
                      setShowSubLinks(!showSubLinks);
                    }
                  }}
                >
                  {link.name}
                </a>
                {link.name === 'Community Connect' && showSubLinks && (
                  <ul className="sub-links-container">
                    {subLinks.map((subLink, subIndex) => (
                      <li key={subIndex} className="sub-link-item">
                        <a
                          href={subLink.url}
                          className="sub-link-button"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {subLink.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>

  

      </div>
    </div>
  );
}

export default ImportantLinks;
