import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './VisitResturants.css';
import CouponBanner from './CouponBanner';


// Define your three categories with names, icons, and URLs
const categories = [
  
  { name: 'Selam Food Mart', icon: '🍎', url: '/selamfoodmart' },

  
    // African & Caribbean Markets
    {
      name: "First Choice International Supermarket",
      icon: "🛒",
      specialties: ["African", "Caribbean", "Indian"],
      address: "5712 Wurzbach Rd, San Antonio, TX 78238",
      phone: "210-465-9692",
      url: "https://fcisupermarket.com/"
    },
    {
      name: "Blessing African Food Store",
      icon: "🛒",
      specialties: ["African"],
      address: "San Antonio, TX",
      phone: "",
      url: ""
    },
    {
      name: "Boricua Food Market",
      icon: "🛒",
      specialties: ["Puerto Rican", "Jamaican"],
      address: "1015 Rittiman Rd, San Antonio, TX 78218",
      phone: "",
      url: ""
    },
    // European Markets
    {
      name: "Sasha's European Market",
      icon: "🛒",
      specialties: ["Russian", "Eastern European"],
      address: "8023 Callaghan Rd #106, San Antonio, TX 78230",
      phone: "",
      url: "https://russiansanantonio.com/"
    },
    // Middle Eastern & Mediterranean Markets
    
    {
      name: "Ali Baba International Food Market",
      icon: "🛒",
      specialties: ["Middle Eastern", "Halal", "Mediterranean"],
      address: "9301 Wurzbach Rd, San Antonio, TX 78240",
      phone: "210-691-1111",
      url: "https://www.alibabainternationalmarket.com/"
    },
    {
      name: "Jerusalem Market & Bakery",
      icon: "🧆",
      specialties: ["Palestinian", "Jordanian", "Lebanese", "Bakery"],
      address: "5161 Fredericksburg Rd, San Antonio, TX 78229",
      phone: "210-737-0404",
      url: "https://www.jerusalemgrill.net/market/"
    },
    {
      name: "Zara International Food",
      icon: "🛒",
      specialties: ["Halal", "Turkish", "Mediterranean"],
      address: "3145 Thousand Oaks Dr, San Antonio, TX 78247",
      phone: "210-495-6200",
      url: "https://zarainternationalfood.business.site/"
    },
    {
      name: "Asia Market",
      icon: "🥬",
      specialties: ["Iranian", "Pakistani", "Afghan", "Middle Eastern"],
      address: "7460 Callaghan Rd #205, San Antonio, TX 78229",
      phone: "210-733-1411",
      url: "https://www.facebook.com/AsiaMarketSA/"
    },
    {
      name: "Al Madina International Market",
      icon: "🕌",
      specialties: ["Halal", "Middle Eastern", "Desi"],
      address: "9214 Wurzbach Rd, San Antonio, TX 78240",
      phone: "210-614-6000",
      url: "https://www.almadinasa.com/"
    },
    


    // Asian Markets
    {
      name: "Tim's Oriental & Seafood Market",
      icon: "🛒",
      specialties: ["Asian"],
      address: "7015 Bandera Rd, San Antonio, TX 78238",
      phone: "",
      url: ""
    },
    {
      name: "Tokyo Mart",
      icon: "🛒",
      specialties: ["Japanese"],
      address: "825 W Hildebrand Ave, San Antonio, TX 78212",
      phone: "",
      url: ""
    },
    {
      name: "Minnano Japanese Grocery",
      icon: "🛒",
      specialties: ["Japanese"],
      address: "7460 Callaghan Rd #410, San Antonio, TX 78229",
      phone: "",
      url: ""
    },
    {
      name: "Seoul Asian Market and Cafe",
      icon: "🛒",
      specialties: ["Korean"],
      address: "1005 Rittiman Rd, San Antonio, TX 78218",
      phone: "",
      url: ""
    },
    {
      name: "Himalayan Bazar",
      icon: "🛒",
      specialties: ["Nepalese", "South Asian"],
      address: "8466 Fredericksburg Rd, San Antonio, TX 78229",
      phone: "",
      url: ""
    },
    {
      name: "Thai Hut Market",
      icon: "🛒",
      specialties: ["Thai", "Asian"],
      address: "9820 Potranco Rd, San Antonio, TX 78251",
      phone: "",
      url: ""
    },
    // Indian & Pakistani Markets
    {
      name: "Man Pasand International Food Market",
      icon: "🛒",
      specialties: ["Indian", "Pakistani"],
      address: "3727 Colony Dr, San Antonio, TX 78230",
      phone: "210-462-7111",
      url: ""
    },
    {
      name: "Shahi Grocery",
      icon: "🛒",
      specialties: ["Indian", "Pakistani", "Halal"],
      address: "20323 Huebner Rd, San Antonio, TX 78258",
      phone: "",
      url: ""
    },
    {
      name: "India Store",
      icon: "🛒",
      specialties: ["Indian"],
      address: "5700 Wurzbach Rd, San Antonio, TX 78238",
      phone: "",
      url: ""
    },
    // Latin American Markets
    {
      name: "Las Americas Latin Market",
      icon: "🛒",
      specialties: ["Latin American"],
      address: "San Antonio, TX",
      phone: "",
      url: ""
    },
    // Filipino Markets
    {
      name: "Pearl of the Orient Philippine Market",
      icon: "🛒",
      specialties: ["Filipino"],
      address: "San Antonio, TX",
      phone: "",
      url: ""
    },
    {
      name: "Tabares Philippine Market",
      icon: "🛒",
      specialties: ["Filipino"],
      address: "San Antonio, TX",
      phone: "",
      url: ""
    },
    // Vietnamese Markets
    {
      name: "Vietnam Market",
      icon: "🛒",
      specialties: ["Vietnamese"],
      address: "San Antonio, TX",
      phone: "",
      url: ""
    },
    // Chinese Markets
    {
      name: "Hung Phong Oriental Market",
      icon: "🛒",
      specialties: ["Chinese", "Asian"],
      address: "San Antonio, TX",
      phone: "",
      url: ""
    },
    // Mexican Markets
    {
      name: "La Garita Grocery & Cafe",
      icon: "🛒",
      specialties: ["Mexican"],
      address: "San Antonio, TX",
      phone: "",
      url: ""
    },
    {
      name: "Culebra Meat Market",
      icon: "🛒",
      specialties: ["Mexican"],
      address: "San Antonio, TX",
      phone: "",
      url: ""
    },
    
      
      {
        name: "H-E-B – Converse",
        icon: "🛒",
        specialties: ["General Groceries"],
        address: "10137 Interstate 10 E., Converse, TX 78109",
        phone: "",
        url: "https://www.heb.com/store-locations"
      },
      {
        name: "Des Pardes International Market",
        icon: "🌍",
        specialties: ["Indian", "Pakistani", "Afghan", "Middle Eastern"],
        address: "4081 Medical Drive, San Antonio, TX 78229",
        phone: "210-254-9073",
        url: "https://despardestx.com/"
      },
   
      {
        name: "Borderless European Market",
        icon: "🛒",
        specialties: ["European"],
        address: "7065 San Pedro Ave., San Antonio, TX 78216",
        phone: "210-265-1300",
        url: "https://www.bemsatx.com/"
      },
      {
        name: "Poco Loco Supermercado",
        icon: "🛒",
        specialties: ["Local and Organic Produce", "Bakery"],
        address: "4603 West Ave., San Antonio, TX 78213",
        phone: "",
        url: ""
      },
      
        {
          "name": "Central Market – Broadway",
          "icon": "🛒",
          "specialties": ["Gourmet", "Organic", "International"],
          "address": "4821 Broadway St., San Antonio, TX 78209",
          "phone": "(210) 368-8600",
          "url": "https://www.centralmarket.com/locations/san-antonio"
        },
        {
          "name": "Sari-Sari Filipino Restaurant Market and Bakery",
          "icon": "🥖",
          "specialties": ["Filipino"],
          "address": "5700 Wurzbach Road, San Antonio, TX 78238",
          "phone": "(210) 647-7274",
          "url": "https://www.sari-sari-satx.com/"
        },
        {
          "name": "Sprouts Farmers Market",
          "icon": "🥦",
          "specialties": ["Natural", "Organic"],
          "address": "22135 Bulverde Rd, San Antonio, TX 78259",
          "phone": "(210) 350-3985",
          "url": "https://www.sprouts.com/store/tx/san-antonio/bulverde-rd/"
        },
       
        {
          "name": "Korean Market",
          "icon": "🥬",
          "specialties": ["Korean"],
          "address": "6210 Fairdale Dr, San Antonio, TX 78218",
          "phone": "(210) 657-5000",
          "url": ""
        },
        {
          "name": "Larder at Hotel Emma",
          "icon": "🍷",
          "specialties": ["Gourmet", "Specialty Foods"],
          "address": "136 E Grayson St, San Antonio, TX 78215",
          "phone": "(210) 448-8355",
          "url": "https://www.thehotelemma.com/food-drink/larder/"
        },
        
          {
            "name": "Royal Blue Grocery",
            "icon": "🛒",
            "specialties": ["Gourmet", "Convenience"],
            "address": "122 E Houston St, San Antonio, TX 78205",
            "phone": "(210) 957-0096",
            "url": "https://royalbluegrocery.com/pages/san-antonio"
          },
          {
            "name": "La Fiesta Supermarkets",
            "icon": "🛒",
            "specialties": ["Mexican", "Latin American"],
            "address": "1414 Guadalupe St, San Antonio, TX 78207",
            "phone": "(210) 226-6100",
            "url": "http://www.lafiestasupermarkets.com/"
          },
          {
            "name": "Natural Grocers",
            "icon": "🥦",
            "specialties": ["Organic", "Natural"],
            "address": "13310 NW Military Hwy, San Antonio, TX 78231",
            "phone": "(210) 541-4000",
            "url": "https://www.naturalgrocers.com/store/san-antonio-nw-military-highway"
          },
          {
            "name": "Tim's Oriental & Seafood Market",
            "icon": "🐟",
            "specialties": ["Asian", "Seafood"],
            "address": "7015 Bandera Rd, San Antonio, TX 78238",
            "phone": "(210) 523-1688",
            "url": ""
          },
          {
            "name": "Minnano Japanese Grocery",
            "icon": "🍣",
            "specialties": ["Japanese"],
            "address": "7460 Callaghan Rd #410, San Antonio, TX 78229",
            "phone": "(210) 239-5631",
            "url": ""
          },
          {
            "name": "Seoul Asian Market and Cafe",
            "icon": "🥬",
            "specialties": ["Korean"],
            "address": "1005 Rittiman Rd, San Antonio, TX 78218",
            "phone": "(210) 822-3373",
            "url": ""
          },
          {
            "name": "Himalayan Bazar",
            "icon": "🛒",
            "specialties": ["Nepalese", "South Asian"],
            "address": "8466 Fredericksburg Rd, San Antonio, TX 78229",
            "phone": "(210) 616-1988",
            "url": ""
          },
          {
            "name": "Thai Hut Market",
            "icon": "🛒",
            "specialties": ["Thai", "Asian"],
            "address": "9820 Potranco Rd, San Antonio, TX 78251",
            "phone": "(210) 684-0630",
            "url": ""
          },
          {
            "name": "Boricua Food Market",
            "icon": "🛒",
            "specialties": ["Puerto Rican", "Jamaican"],
            "address": "1015 Rittiman Rd, San Antonio, TX 78218",
            "phone": "(210) 822-3663",
            "url": ""
          },
          {
            "name": "Sasha's European Market",
            "icon": "🛒",
            "specialties": ["Russian", "Eastern European"],
            "address": "8023 Callaghan Rd #106, San Antonio, TX 78230",
            "phone": "(210) 614-1212",
            "url": "https://russiansanantonio.com/"
          },
          
            {
              name: "Trader Joe's – Huebner Oaks Center",
              icon: "🛒",
              specialties: ["Unique Private-Label Products"],
              address: "11745 Interstate 10 W., San Antonio, TX 78230",
              phone: "",
              url: "https://locations.traderjoes.com/tx/san-antonio/451/"
            },
            {
              name: "H-E-B – Converse",
              icon: "🛒",
              specialties: ["General Groceries"],
              address: "10137 Interstate 10 E., Converse, TX 78109",
              phone: "",
              url: "https://www.heb.com/store-locations"
            },
            {
              name: "Des Pardes International Market",
              icon: "🌍",
              specialties: ["Indian", "Pakistani", "Afghan", "Middle Eastern"],
              address: "4081 Medical Drive, San Antonio, TX 78229",
              phone: "210-254-9073",
              url: "https://despardestx.com/"
            },
            {
              name: "Pullman Market",
              icon: "🍽️",
              specialties: ["Specialty Grocer", "Eateries"],
              address: "221 Newell Ave., San Antonio, TX 78215",
              phone: "210-759-0086",
              url: "https://www.pullmanmarket.com/"
            },
            {
              name: "Borderless European Market",
              icon: "🛒",
              specialties: ["European"],
              address: "7065 San Pedro Ave., San Antonio, TX 78216",
              phone: "210-265-1300",
              url: "https://www.bemsatx.com/"
            },
            {
              name: "Poco Loco Supermercado",
              icon: "🛒",
              specialties: ["Local and Organic Produce", "Bakery"],
              address: "4603 West Ave., San Antonio, TX 78213",
              phone: "",
              url: ""
            },
            {
              name: "Central Market – Broadway",
              icon: "🛒",
              specialties: ["Gourmet", "Organic", "International"],
              address: "4821 Broadway St., San Antonio, TX 78209",
              phone: "(210) 368-8600",
              url: "https://www.centralmarket.com/locations/san-antonio"
            },
            {
              name: "Sari-Sari Filipino Restaurant Market and Bakery",
              icon: "🥖",
              specialties: ["Filipino"],
              address: "5700 Wurzbach Road, San Antonio, TX 78238",
              phone: "(210) 647-7274",
              url: "https://www.sari-sari-satx.com/"
            },
            {
              name: "Sprouts Farmers Market",
              icon: "🥦",
              specialties: ["Natural", "Organic"],
              address: "22135 Bulverde Rd, San Antonio, TX 78259",
              phone: "(210) 350-3985",
              url: "https://www.sprouts.com/store/tx/san-antonio/bulverde-rd/"
            },
           
            {
              name: "Korean Market",
              icon: "🥬",
              specialties: ["Korean"],
              address: "6210 Fairdale Dr, San Antonio, TX 78218",
              phone: "(210) 657-5000",
              url: ""
            },
            {
              name: "Larder at Hotel Emma",
              icon: "🍷",
              specialties: ["Gourmet", "Specialty Foods"],
              address: "136 E Grayson St, San Antonio, TX 78215",
              phone: "(210) 448-8355",
              url: "https://www.thehotelemma.com/food-drink/larder/"
            },
            
              {
                name: "Royal Blue Grocery",
                icon: "🛒",
                specialties: ["Gourmet", "Convenience"],
                address: "122 E Houston St, San Antonio, TX 78205",
                phone: "(210) 957-0096",
                url: "https://royalbluegrocery.com/pages/san-antonio"
              },
              {
                name: "La Fiesta Supermarkets",
                icon: "🛒",
                specialties: ["Mexican", "Latin American"],
                address: "1414 Guadalupe St, San Antonio, TX 78207",
                phone: "(210) 226-6100",
                url: "http://www.lafiestasupermarkets.com/"
              },
             
              {
                name: "Tim's Oriental & Seafood Market",
                icon: "🐟",
                specialties: ["Asian", "Seafood"],
                address: "7015 Bandera Rd, San Antonio, TX 78238",
                phone: "(210) 523-1688",
                url: ""
              },
              {
                name: "Minnano Japanese Grocery",
                icon: "🍣",
                specialties: ["Japanese"],
                address: "7460 Callaghan Rd #410, San Antonio, TX 78229",
                phone: "(210) 239-5631",
                url: ""
              },
              {
                name: "Seoul Asian Market and Cafe",
                icon: "🥬",
                specialties: ["Korean"],
                address: "1005 Rittiman Rd, San Antonio, TX 78218",
                phone: "(210) 822-3373",
                url: ""
              },
              {
                name: "Himalayan Bazar",
                icon: "🛒",
                specialties: ["Nepalese", "South Asian"],
                address: "8466 Fredericksburg Rd, San Antonio, TX 78229",
                phone: "(210) 616-1988",
                url: ""
              },
              {
                name: "Thai Hut Market",
                icon: "🛒",
                specialties: ["Thai", "Asian"],
                address: "9820 Potranco Rd, San Antonio, TX 78251",
                phone: "(210) 684-0630",
                url: ""
              },
              {
                name: "Boricua Food Market",
                icon: "🛒",
                specialties: ["Puerto Rican", "Jamaican"],
                address: "1015 Rittiman Rd, San Antonio, TX 78218",
                phone: "(210) 822-3663",
                url: ""
              },
              {
                name: "Sasha's European Market",
                icon: "🛒",
                specialties: ["Russian", "Eastern European"],
                address: "8023 Callaghan Rd #106, San Antonio, TX 78230",
                phone: "(210) 614-1212",
                url: "https://russiansanantonio.com/"
              },
              
                {
                  "name": "Ali Baba International Food Market",
                  "icon": "🛒",
                  "specialties": ["Mediterranean", "Middle Eastern", "South Asian"],
                  "address": "9307 Wurzbach Rd, San Antonio, TX 78240",
                  "phone": "(210) 691-1111",
                  "url": "https://www.alibabainternationalmarket.com/"
                },
                {
                  "name": "El Eqatorial Market",
                  "icon": "🛒",
                  "specialties": ["African", "Caribbean"],
                  "address": "6829 Bandera Rd, San Antonio, TX 78238",
                  "phone": "(210) 530-4612",
                  "url": ""
                },
                {
                  "name": "Man Pasand International Food Market",
                  "icon": "🛒",
                  "specialties": ["Indian", "Pakistani"],
                  "address": "3727 Colony Dr, San Antonio, TX 78230",
                  "phone": "(210) 462-7111",
                  "url": ""
                },
                {
                  "name": "Shahi Grocery",
                  "icon": "🛒",
                  "specialties": ["Indian", "Pakistani", "Halal"],
                  "address": "20323 Huebner Rd, San Antonio, TX 78258",
                  "phone": "",
                  "url": ""
                },
                
                {
                  "name": "Al Madina International Market",
                  "icon": "🛒",
                  "specialties": ["Halal", "Middle Eastern", "Desi"],
                  "address": "9214 Wurzbach Rd, San Antonio, TX 78240",
                  "phone": "210-614-6000",
                  "url": "https://www.almadinasa.com/"
                },
                {
                  "name": "First Choice International Supermarket",
                  "icon": "🛒",
                  "specialties": ["African", "Caribbean", "Indian"],
                  "address": "5712 Wurzbach Rd, San Antonio, TX 78238",
                  "phone": "210-465-9692",
                  "url": "https://fcisupermarket.com/"
                },
                {
                  "name": "Blessing African Food Store",
                  "icon": "🛒",
                  "specialties": ["African"],
                  "address": "San Antonio, TX",
                  "phone": "",
                  "url": ""
                },
                {
                  "name": "Boricua Food Market",
                  "icon": "🛒",
                  "specialties": ["Puerto Rican", "Jamaican"],
                  "address": "1015 Rittiman Rd, San Antonio, TX 78218",
                  "phone": "",
                  "url": ""
                },
                {
                  "name": "Sasha's European Market",
                  "icon": "🛒",
                  "specialties": ["Russian", "Eastern European"],
                  "address": "8023 Callaghan Rd #106, San Antonio, TX 78230",
                  "phone": "",
                  "url": "https://russiansanantonio.com/"
                },
                {
                  "name": "Natural Grocers",
                  "icon": "🛒",
                  "specialties": ["Organic", "Natural"],
                  "address": "13310 NW Military Hwy, San Antonio, TX 78231",
                  "phone": "(210) 541-4000",
                  "url": "https://www.naturalgrocers.com/store/san-antonio-nw-military-highway"
                },
                {
                  "name": "Sprouts Farmers Market – Hwy 151",
                  "icon": "🛒",
                  "specialties": ["Natural", "Organic"],
                  "address": "9702 State Highway 151, San Antonio, TX 78251",
                  "phone": "726-227-8068",
                  "url": "https://www.sprouts.com/store/tx/san-antonio/161-san-antonio-hwy-151/"
                },
                {
                  "name": "Central Market – Broadway",
                  "icon": "🛒",
                  "specialties": ["Gourmet", "Organic", "International"],
                  "address": "4821 Broadway St, San Antonio, TX 78209",
                  "phone": "(210) 368-8600",
                  "url": "https://www.centralmarket.com/locations/san-antonio"
                },
                {
                  "name": "Whole Foods Market – Vineyard",
                  "icon": "🛒",
                  "specialties": ["Organic", "Natural"],
                  "address": "18403 Blanco Rd, San Antonio, TX 78258",
                  "phone": "(210) 408-3110",
                  "url": "https://www.wholefoodsmarket.com/stores/vineyard"
                },
                {
                  "name": "H-E-B – Alon Market",
                  "icon": "🛒",
                  "specialties": ["General Groceries"],
                  "address": "8503 NW Military Hwy, San Antonio, TX 78231",
                  "phone": "",
                  "url": "https://www.heb.com/store-locations"
                },
                {
                  "name": "H-E-B – Nacogdoches Rd",
                  "icon": "🛒",
                  "specialties": ["General Groceries"],
                  "address": "1955 Nacogdoches Rd, San Antonio, TX 78209",
                  "phone": "",
                  "url": "https://www.heb.com/store-locations"
                },
                {
                  "name": "H-E-B plus! – Zarzamora",
                  "icon": "🛒",
                  "specialties": ["General Groceries"],
                  "address": "6818 S Zarzamora St, San Antonio, TX 78224",
                  "phone": "",
                  "url": "https://www.heb.com/store-locations"
                },
                {
                  "name": "H-E-B plus! – Lytle",
                  "icon": "🛒",
                  "specialties": ["General Groceries"],
                  "address": "19337 Mcdonald St, Lytle, TX 78052",
                  "phone": "",
                  "url": "https://www.heb.com/store-locations"
                },
                {
                  "name": "Mustafa Inc",
                  "icon": "🛒",
                  "specialties": ["Middle Eastern"],
                  "address": "4081 Medical Dr, San Antonio, TX 78229",
                  "phone": "(210) 615-7861",
                  "url": ""
                },

                
                  {
                    "name": "La Michoacana Meat Market",
                    "icon": "🛒",
                    "specialties": ["Mexican", "Meat Market"],
                    "address": "5271 Walzem Rd, San Antonio, TX 78218",
                    "phone": "(210) 653-8883",
                    "url": "https://www.lamichoacanameatmarket.com/"
                  },
                  {
                    "name": "El Rancho Supermercado",
                    "icon": "🛒",
                    "specialties": ["Mexican", "Latin American"],
                    "address": "8600 Fourwinds Dr, Windcrest, TX 78239",
                    "phone": "(210) 637-7100",
                    "url": "https://elranchoinc.com/"
                  },
                  {
                    "name": "Las Americas Latin Market",
                    "icon": "🛒",
                    "specialties": ["Latin American"],
                    "address": "6623 San Pedro Ave, San Antonio, TX 78216",
                    "phone": "(210) 320-1207",
                    "url": ""
                  },
                  {
                    "name": "Hung Phong Oriental Market",
                    "icon": "🛒",
                    "specialties": ["Vietnamese", "Chinese", "Asian"],
                    "address": "243 Remount Dr, San Antonio, TX 78218",
                    "phone": "(210) 821-7953",
                    "url": ""
                  },
                  {
                    "name": "Vietnam Market",
                    "icon": "🛒",
                    "specialties": ["Vietnamese"],
                    "address": "5360 Walzem Rd, San Antonio, TX 78218",
                    "phone": "(210) 653-3166",
                    "url": ""
                  },
                  {
                    "name": "Pearl of the Orient Philippine Market",
                    "icon": "🛒",
                    "specialties": ["Filipino"],
                    "address": "9107 Marbach Rd, San Antonio, TX 78245",
                    "phone": "(210) 674-2205",
                    "url": ""
                  },
                  {
                    "name": "Tabares Philippine Market",
                    "icon": "🛒",
                    "specialties": ["Filipino"],
                    "address": "9830 Marbach Rd, San Antonio, TX 78245",
                    "phone": "(210) 520-6600",
                    "url": ""
                  },
                  {
                    "name": "India Store",
                    "icon": "🛒",
                    "specialties": ["Indian"],
                    "address": "5700 Wurzbach Rd, San Antonio, TX 78238",
                    "phone": "(210) 520-6390",
                    "url": ""
                  },
                  {
                    "name": "Culebra Meat Market",
                    "icon": "🛒",
                    "specialties": ["Mexican"],
                    "address": "2800 Culebra Rd, San Antonio, TX 78228",
                    "phone": "(210) 434-0139",
                    "url": ""
                  },
                  {
                    "name": "La Garita Grocery & Cafe",
                    "icon": "🛒",
                    "specialties": ["Mexican"],
                    "address": "615 S Presa St, San Antonio, TX 78210",
                    "phone": "(210) 227-7600",
                    "url": ""
                  }
                
                
              
            
            
          
          
        
      
      
    
    
  
];

function VisitGroceries() {
  const [selected, setSelected] = useState(null);
  const navigate = useNavigate();

  const handleTileClick = (category) => {
    if (category.url?.startsWith('/')) {
      navigate(category.url); // Internal route
    } else {
      setSelected(category); // Show popup for external or missing URL
    }
  };
  

  const closePopup = () => setSelected(null);

  return (
    <div className="categories-container">
      <CouponBanner />
      <div className="new-tile-container-new">
        {categories.map((category, index) => (
          <div
          key={index}
          className={`new-tile ${category.url ? 'tile-clickable' : 'tile-informational'}`}
          onClick={() => handleTileClick(category)}
        >
          <span className="icon-for-new-tile">{category.icon}</span>
          
          <span className="tile-name">
            {category.name}
            {(!category.url || category.url.startsWith('http')) && (
              <span className="info-badge" title="Unverified">ⓘ</span>
            )}
          </span>
        </div>
        
        ))}
      </div>

      <div className="back-home-tile">
        <Link to="/" className="back-home-link">Go Back to Homepage</Link>
      </div>

      {/* Popup Box */}
      {selected && (
  <div className="popup-overlay" onClick={closePopup}>
    <div className="popup-box" onClick={(e) => e.stopPropagation()}>
      <h3>{selected.name}</h3>
      <p><strong>Address:</strong> {selected.address}</p>
      <p><strong>Phone:</strong> {selected.phone}</p>

      {selected.url && selected.url.startsWith('http') && (
  <p>
    <a
      href={selected.url}
      target="_blank"
      rel="noopener noreferrer"
      className="business-website-link"
    >
      {selected.url
        .replace(/^https?:\/\//, '') 
        .replace(/\/$/, '')}         
    </a>
  </p>
)}


      <button onClick={closePopup}>Close</button>

      {selected.name  && (
        <div className="extra-links">
          <Link to="/request-form" className="popup-link">Claim your business</Link> <span> / </span>
          <Link to="/request-form" className="popup-link">Opt out</Link> <span> / </span>
          <Link to="/request-form" className="popup-link">Suggest update</Link>
        </div>
      )}
      <hr className="popup-divider" />

<p className="disclaimer-text">
Disclaimer: This listing has not been claimed or verified by the business owner.
Information provided is based on publicly available sources.
</p>

    </div>
  </div>
)}

    </div>
  );
}


export default VisitGroceries;
