import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './VisitResturants.css';
// Define your three categories with names, icons, and URLs

import CouponBanner from './CouponBanner';
const categories = [
  { name: 'G3 Productions', icon: '🎬 ', url: '/G3Production' },
  {
    name: "Infinity Video & Photo",
    icon: "📸",
    specialties: ["Wedding", "Photography", "Videography"],
    address: "San Antonio, TX",
    phone: "",
    url: "https://infinityweddings.com/",
    external: true
  },
  {
    name: "Media Bar Productions",
    icon: "🎥",
    specialties: ["Corporate Video", "Real Estate", "Events"],
    address: "San Antonio, TX",
    phone: "",
    url: "https://www.mediabarproductions.com/",
    external: true
  },
  {
    "name": "Eleven Hundred Collective",
    "icon": "📸",
    "specialties": ["Studio", "Creative Space"],
    "address": "1100 Broadway St #305/310/311, San Antonio, TX 78215",
    "phone": "(210) 504-8984",
    "url": "https://www.collectivestudiostx.com/",
    "external": true
  },
  {
    "name": "Melissa Raelynn Photography",
    "icon": "📸",
    "specialties": ["Portrait", "Personal Branding", "Boudoir"],
    "address": "816 Camaron St. Studio 218, San Antonio, TX 78212",
    "phone": "",
    "url": "https://melissaraelynnphotography.com/",
    "external": true
  },
  {
    "name": "Dos Kiwis Studio",
    "icon": "📸",
    "specialties": ["Wedding", "Fine Art", "Lifestyle"],
    "address": "1112 West Ave, San Antonio, TX 78201",
    "phone": "(210) 735-5555",
    "url": "https://www.doskiwis.com/",
    "external": true
  },
  {
    "name": "Jessica Idalia Photography",
    "icon": "📸",
    "specialties": ["Family", "Children", "Lifestyle"],
    "address": "San Antonio, TX",
    "phone": "830-428-4763",
    "url": "https://www.jessicaidalia.com/",
    "external": true
  },
  {
    "name": "Annakyi Photography",
    "icon": "📸",
    "specialties": ["Boudoir", "Branding", "Portrait"],
    "address": "San Antonio, TX",
    "phone": "",
    "url": "https://annakyi.com/",
    "external": true
  },
  {
    "name": "Meet the Bryants",
    "icon": "📸",
    "specialties": ["Wedding", "Engagement", "Couples"],
    "address": "San Antonio, TX",
    "phone": "(210) 560-3682",
    "url": "https://www.meetthebryants.com/",
    "external": true
  },
  {
    "name": "Christy Anna Photography",
    "icon": "📸",
    "specialties": ["Newborn", "Maternity", "Family"],
    "address": "San Antonio, TX",
    "phone": "",
    "url": "https://christyannaphotography.com/",
    "external": true
  },
  {
    "name": "Studio 125",
    "icon": "📸",
    "specialties": ["Studio Rental", "Portrait", "Commercial"],
    "address": "125 Lamar, 113, San Antonio, TX 78202",
    "phone": "(210) 274-9948",
    "url": "https://www.theonetwofive.com/",
    "external": true
  },
  {
    "name": "Scott Photography",
    "icon": "📸",
    "specialties": ["Senior Portraits", "Family", "Commercial"],
    "address": "318 East Nakoma Dr, Suite 208, San Antonio, TX 78216",
    "phone": "(210) 281-1085",
    "url": "https://www.scottphotographycompany.com/",
    "external": true
  },
  {
    "name": "Bluefire Photography",
    "icon": "📸",
    "specialties": ["Wedding", "Portrait", "Creative"],
    "address": "San Antonio, TX",
    "phone": "(210) 807-9337",
    "url": "https://www.bluefirephoto.com/",
    "external": true
  },
  {
    "name": "Lois M Photography",
    "icon": "📸",
    "specialties": ["Portrait", "Wedding", "Maternity"],
    "address": "San Antonio, TX",
    "phone": "",
    "url": "https://loismphotography.com/",
    "external": true
  },
  {
    "name": "Matt Roberts Photography",
    "icon": "📸",
    "specialties": ["Commercial", "Branding", "Events"],
    "address": "816 Camaron St Suite 209, San Antonio, TX 78212",
    "phone": "(726) 227-1027",
    "url": "https://www.mattrobertsphoto.com/",
    "external": true
  },
  {
    "name": "Abby Kennan Photography",
    "icon": "📸",
    "specialties": ["Family", "Children", "Newborn"],
    "address": "San Antonio, TX",
    "phone": "",
    "url": "https://www.abbykennan.com/",
    "external": true
  },
  {
    "name": "The Lumen Room",
    "icon": "📸",
    "specialties": ["Studio Rental", "Creative Shoots", "Events"],
    "address": "816 Camaron St. #2.47, San Antonio, TX 78212",
    "phone": "469-544-6960",
    "url": "https://www.thelumenroom.com/",
    "external": true
  },
  {
    "name": "Layla Rylee Photography",
    "icon": "📸",
    "specialties": ["Wedding", "Lifestyle", "Couples"],
    "address": "San Antonio, TX 78253",
    "phone": "(210) 504-7293",
    "url": "https://www.laylaryleephotography.com/",
    "external": true
  },
  {
    "name": "Front Page Photography",
    "icon": "📸",
    "specialties": ["Portrait", "Senior", "Family"],
    "address": "San Antonio, TX",
    "phone": "(210) 802-1222",
    "url": "https://frontpagephotographysa.com/",
    "external": true
  },
  {
    "name": "Studio Benton Photography",
    "icon": "📸",
    "specialties": ["Family Portraits", "Senior Portraits", "Events"],
    "address": "10828 Gulfdale St, San Antonio, TX 78216",
    "phone": "(210) 804-1188",
    "url": "https://www.studiobenton.com/",
    "external": true
  },
  {
    "name": "Hayward Gaude Photography",
    "icon": "📸",
    "specialties": ["Family Portraits", "Children's Portraits", "High School Seniors"],
    "address": "555 W. Bitters Rd, San Antonio, TX",
    "phone": "(210) 265-1101",
    "url": "https://www.haywardgaudephotography.com/",
    "external": true
  },
  {
    "name": "Expose The Heart",
    "icon": "📸",
    "specialties": ["Wedding", "Engagement", "Family"],
    "address": "San Antonio, TX",
    "phone": "(210) 549-7427",
    "url": "https://exposetheheart.com/contact",
    "external": true
  },
  {
    "name": "Artistic Images",
    "icon": "📸",
    "specialties": ["Family Portraits", "Custom Wall Art"],
    "address": "17222 Classen Rd, San Antonio, TX 78247",
    "phone": "(210) 497-3809",
    "url": "https://www.portraitsbyelizabeth.com/",
    "external": true
  },
  {
    "name": "Bend The Light Photography",
    "icon": "📸",
    "specialties": ["Family Photos", "Branding Photography", "Business Headshots"],
    "address": "San Antonio, TX",
    "phone": "(210) 373-4447",
    "url": "https://bendthelightphotography.com/contact/",
    "external": true
  },
  
];

function Photography() {
  const [selected, setSelected] = useState(null);
  const navigate = useNavigate();

  const handleTileClick = (category) => {
    if (category.url?.startsWith('/')) {
      navigate(category.url); // Internal route
    } else {
      setSelected(category); // Show popup for external or missing URL
    }
  };
  

  const closePopup = () => setSelected(null);

  return (
    <div className="categories-container">
      <CouponBanner />
      <div className="new-tile-container-new">
        {categories.map((category, index) => (
          <div
          key={index}
          className={`new-tile ${category.url ? 'tile-clickable' : 'tile-informational'}`}
          onClick={() => handleTileClick(category)}
        >
          <span className="icon-for-new-tile">{category.icon}</span>
          
          <span className="tile-name">
            {category.name}
            {(!category.url || category.url.startsWith('http')) && (
              <span className="info-badge" title="Unverified">ⓘ</span>
            )}
          </span>
        </div>
        
        ))}
      </div>

      <div className="back-home-tile">
        <Link to="/" className="back-home-link">Go Back to Homepage</Link>
      </div>

      {/* Popup Box */}
      {selected && (
  <div className="popup-overlay" onClick={closePopup}>
    <div className="popup-box" onClick={(e) => e.stopPropagation()}>
      <h3>{selected.name}</h3>
      <p><strong>Address:</strong> {selected.address}</p>
      <p><strong>Phone:</strong> {selected.phone}</p>

      {selected.url && selected.url.startsWith('http') && (
  <p>
    <a
      href={selected.url}
      target="_blank"
      rel="noopener noreferrer"
      className="business-website-link"
    >
      {selected.url
        .replace(/^https?:\/\//, '') 
        .replace(/\/$/, '')}         
    </a>
  </p>
)}


      <button onClick={closePopup}>Close</button>

      {selected.name  && (
        <div className="extra-links">
          <Link to="/request-form" className="popup-link">Claim your business</Link> <span> / </span>
          <Link to="/request-form" className="popup-link">Opt out</Link> <span> / </span>
          <Link to="/request-form" className="popup-link">Suggest update</Link>
        </div>
      )}
      <hr className="popup-divider" />

<p className="disclaimer-text">
Disclaimer: This listing has not been claimed or verified by the business owner.
Information provided is based on publicly available sources.
</p>

    </div>
  </div>
)}

    </div>
  );
}

export default Photography;
