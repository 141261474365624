import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

function highlightText(text, query) {
  if (!query) return text;
  const regex = new RegExp(`(${query})`, 'gi');
  const parts = text.split(regex);
  return (
    <>
      {parts.map((part, i) =>
        part.toLowerCase() === query.toLowerCase() ? (
          <mark key={i}>{part}</mark>
        ) : (
          part
        )
      )}
    </>
  );
}

function ProductCategories({ searchQuery = "", defaultCategories: propDefaultCategories }) {
  // Define a fallback list in case the prop isn't passed
  const fallbackCategories = [
    { name: 'Grocery Stores', icon: '🛒', url: '/visitgroceries' },
    { name: 'Restaurants', icon: '🍴', url: '/visitresturants' },
    { name: 'Food Truck', icon: '🚚', url: '/visitfoodtrucks' },
    { name: 'Wreaths', icon: '🎀', url: '/Wreaths' },
    { name: "Flea Market Place", icon: '🛍️', url: '/fleamarketplace' },
    { name: 'Vegan', icon: '🥗', url: '/visitveganplaces' },
    { name: 'Realtors', icon: '🏘️', url: '/realtors' },
    { name: 'Photography', icon: '📷',url:'/G3Production'  },
    { name: 'Gardening', icon: '🌱' ,url:'/Gardening'},
    { name: 'Cleaning services', icon: '🧹', url: '/cleaning-services' },
    { name: 'IT Services', icon: '💻', url: '/itservices' },
    { name: 'Clothing & Footwear', icon: '👗', url: '/Clothing' },
    { name: 'Kosher', icon: '🕍', url: '/visitkosherplaces' },
    { name: 'Catering', icon: '🍲', url: '/catering' },
    { name: 'Murals', icon: '🎨', url: '/murals' },
  ];

  // Use the passed defaultCategories prop if provided, otherwise fallback to our defined list.
  const categoriesDefault = propDefaultCategories || fallbackCategories;
  const [categoriesToRender, setCategoriesToRender] = useState(categoriesDefault);

  useEffect(() => {
    const query = searchQuery ? searchQuery.toLowerCase().trim() : "";
    if (query === "") {
      setCategoriesToRender(categoriesDefault);
    } else {
      const filteredCategories = categoriesDefault.filter(category =>
        category.name.toLowerCase().includes(query)
      );
      setCategoriesToRender(filteredCategories.length > 0 ? filteredCategories : []);
    }
  }, [searchQuery, categoriesDefault]);

  return (
    <div className="new-tile-container-new">
      {categoriesToRender.length > 0 ? (
        categoriesToRender.map((category, index) => (
          <div key={index} className="new-tile">
            {category.url ? (
              <Link
                to={category.url}
                 className="tile-link"
              >
                <span className="icon-for-new-tile">{category.icon}</span>
                {/* Highlight the part of the name that matches the search */}
                <span>{highlightText(category.name, searchQuery)}</span>
              </Link>
            ) : (
              <>
                <span className="icon-for-new-tile">{category.icon}</span>
                <span>{highlightText(category.name, searchQuery)}</span>
              </>
            )}
          </div>
        ))
      ) : (
        <p>No results found.</p>
      )}
    </div>
  );
}

export default ProductCategories;