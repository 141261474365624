import React, { useState, useEffect } from "react";
import logo from "../images/TrinketTrunkLogo.jpg";
import adGoOurHub from "../images/ad_goourhub.jpg"; // <-- import your ad image
import "./VaduAplu.css";

const images = [
  require("../images/TrinketTrunk1.jpg"),
  require("../images/TrinketTrunk2.jpg"),
  require("../images/TrinketTrunk3.jpg"),
  require("../images/TrinketTrunk4.jpg"),
  require("../images/TrinketTrunk5.jpg"),
  require("../images/TrinketTrunk6.jpg"),
];


function TrinketTrunk() {
  const [dealIndex, setDealIndex] = useState(0);
  const [fade, setFade] = useState(true);

  const deals = [
   
    "Mention GoOurHub to get better price/discounts",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setDealIndex((prev) => (prev + 1) % deals.length);
        setFade(true);
      }, 500);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="vaduaplu-container">
      {/* Header */}
      <header className="vaduaplu-header">
        {/* Left: Logo + Name */}
        <div className="vaduaplu-header-left">
          <img src={logo} alt="Logo" className="vaduaplu-logo" />
          <div className="vaduaplu-header-text">
            <h1 className="vaduaplu-company-name">Trinket Trunk
              
            </h1>
            
          </div>
        </div>

        {/* Right: Phone + Address */}
        <div className="vaduaplu-header-right">
          <span className="vaduaplu-phone">trinketsbysof@gmail.com</span>

<span className="vaduaplu-address">
 
    Ph : 929-461-2146
  
</span>

        </div>
      </header>

      {/* Contact Info */}
      <div className="vaduaplu-contact-info">
  <p className="vaduaplu-text">
  🌟 <strong>The Trinket Trunk</strong> <em></em><br />
    <br />
    <br />

    ✋ Handmade, all-natural, high-quality & affordable healing crystal jewelry and trinkets ✨😉.<br />
    <br/>
    
    <br />
    
  </p>
  <br />
  <p className="vaduaplu-text">
    <span>🌿</span> 📬 DM us to order via Instagram  <br />
    📸 Follow us on IG: <a className = "a-links-vaduaplu" href="https://www.instagram.com/trinketsbysof/" target="_blank" rel="noopener noreferrer">@trinketsbysof</a>
  </p>
</div>


      {/* Single Top Box combining Quick Menu & Deals */}
      <div className="vaduaplu-top-box">
        {/* Deals Section */}
        <div className="vaduaplu-top-section">
          <h2 className="vaduaplu-heading">Get Deals</h2>
          {/* Flashing Text */}
          <p className={`vaduaplu-deals-text ${fade ? "fade-in" : "fade-out"}`}>
            {deals[dealIndex]}
          </p>

          {/* Static Image below the deals text */}
          <img
            src={adGoOurHub}
            alt="GoOurHub Ad"
            className="vaduaplu-ad-image"
          />
        </div>

        {/* Quick Menu Section */}
        <div className="vaduaplu-top-section">
          <h2 className="vaduaplu-heading">Our Highlights</h2>
          <div className="vaduaplu-accordion">
            <div className="vaduaplu-accordion-item">
              <input type="checkbox" id="service1" className="hidden-checkbox"defaultChecked />
              <label htmlFor="service1" className="vaduaplu-label" align = "center">
              Our Highlights
              </label>
              <div className="vaduaplu-accordion-content">
              <ul className="vaduaplu-list">
          
  <li className="vaduaplu-list-item">
  ✨ <strong>Welcome to The Trinket Trunk</strong> — handmade, all-natural crystal creations made with intention and love.
  </li>
  <br></br>
  <li className="vaduaplu-list-item">
  🕯️ Our best-selling <strong>"Unwind"</strong> crystal-infused candles feature calming blends like lavender, rose quartz, and amethyst — perfect for setting intentions or just chilling out.
  </li>
  <br></br>
  <li className="vaduaplu-list-item">
  📿 Explore our hand-beaded crystal bracelets with adorable bear charms — available in calming tones like moss agate, aquamarine, and rose quartz.
  </li>
  <br></br>
  <li className="vaduaplu-list-item">
  🔑 Don’t miss our super cute handmade keychains — fun, colorful, and infused with personality!
    <br />
    | candles | bracelets| keychains  
  </li>
</ul>



              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content / Gallery */}
      <main className="vaduaplu-main-content">
        <h2 className="vaduaplu-heading">Gallery</h2>
        <div className="vaduaplu-gallery">
          {[...Array(6)].map((_, index) => (
            <img
              key={index}
              src={images[index % images.length]}
              alt={`Work Sample ${index + 1}`}
              className="vaduaplu-image"
            />
          ))}
        </div>
      </main>

      {/* Extra Text Section */}
      <div className="vaduaplu-extra-text">
        <p className="vaduaplu-extra-text-p">
        Trinket Trunk - specializes in handmade, all-natural, high-quality, and affordable healing crystal jewelry and trinkets.     </p>
      </div>
    </div>
  );
}

export default TrinketTrunk;
