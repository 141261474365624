import React, { useState, useEffect } from "react";


import "./VaduAplu.css";




function TwoLittleMonkeys() {
  const [dealIndex, setDealIndex] = useState(0);
  const [fade, setFade] = useState(true);

  const deals = [
    
    "Mention GoOurHub to get better price/discounts",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setDealIndex((prev) => (prev + 1) % deals.length);
        setFade(true);
      }, 500);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="vaduaplu-container">
      {/* Header */}
      <header className="vaduaplu-header">
        {/* Left: Logo + Name */}
        <div className="vaduaplu-header-left">
          
          <div className="vaduaplu-header-text">
            <h1 className="vaduaplu-company-name"> Two Little Monkeys <br></br>

            <h6>Resale & Novelties</h6>

              
            </h1>
            
          </div>
        </div>

        {/* Right: Phone + Address */}
        <div className="vaduaplu-header-right">
          <span className="vaduaplu-phone">Ph : 210779-3358</span> <br></br>

          Email : evetperales@gmail.com

<span className="vaduaplu-address">
 
    Traders Village in San Antonio
  
</span>

        </div>
      </header>

      


      {/* Single Top Box combining Quick Menu & Deals */}
      <div className="vaduaplu-top-box">
        {/* Deals Section */}
        <div className="vaduaplu-top-section">
          <h2 className="vaduaplu-heading">Get Deals</h2>
          {/* Flashing Text */}
          <p className={`vaduaplu-deals-text ${fade ? "fade-in" : "fade-out"}`}>
            {deals[dealIndex]}
          </p>

          {/* Static Image below the deals text */}
          
        </div>

        {/* Quick Menu Section */}
        <div className="vaduaplu-top-section">
          <h2 className="vaduaplu-heading">Our Highlights</h2>
          <div className="vaduaplu-accordion">
            <div className="vaduaplu-accordion-item">
              <input type="checkbox" id="service1" className="hidden-checkbox"defaultChecked />
              <label htmlFor="service1" className="vaduaplu-label" align = "center">
              Our Highlights
              </label>
              <div className="vaduaplu-accordion-content">
              <ul className="vaduaplu-list">
          
  <li className="vaduaplu-list-item">
  ✨ Why buy new when you can rock pre-loved pieces that are both eco-friendly and full of character? We’re all about giving old treasures a fresh spin.
  
  </li>
  <br></br>
  <li className="vaduaplu-list-item">
  We're not about cutting corners. Our products are built to last, offering style and quality at prices that won’t break the bank.
  </li>
  <br></br>
  
  <br></br>
 
</ul>



              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content / Gallery */}
      <main className="vaduaplu-main-content">
        <h2 className="vaduaplu-heading">Gallery</h2>
        
      </main>

      {/* Extra Text Section */}
      <div className="vaduaplu-extra-text">
        <p className="vaduaplu-extra-text-p">
        Two Little Monkeys    </p>
      </div>
    </div>
  );
}

export default  TwoLittleMonkeys;
