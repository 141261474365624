import React, { useState, useEffect } from "react";
import logo from "../images/heroselam.jpg";
import adGoOurHub from "../images/ad_goourhub.jpg"; // <-- import your ad image
import "./VaduAplu.css";

const images = [
  require("../images/selam4.jpeg"),
  require("../images/selam10.jpeg"),
  require("../images/selam12.jpeg"),
  require("../images/selam1.jpeg"),
  require("../images/selam13.jpeg"),
  require("../images/selam5.jpeg"),
];

function VaduAplu() {
  const [dealIndex, setDealIndex] = useState(0);
  const [fade, setFade] = useState(true);

  const deals = [
    "Get 10% on services when you mention GOOURHUB",
    "Mention GoOurHub to get better price/discounts",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setDealIndex((prev) => (prev + 1) % deals.length);
        setFade(true);
      }, 500);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="vaduaplu-container">
      {/* Header */}
      <header className="vaduaplu-header">
        {/* Left: Logo + Name */}
        <div className="vaduaplu-header-left">
          <img src={logo} alt="Logo" className="vaduaplu-logo" />
          <div className="vaduaplu-header-text">
            <h1 className="vaduaplu-company-name">Selam Food Mart
              
            </h1>
            
          </div>
        </div>

        {/* Right: Phone + Address */}
        <div className="vaduaplu-header-right">
          <span className="vaduaplu-phone">Phone: (210) 332-5220</span>
          <span className="vaduaplu-address">
          5757 Evers Rd, San Antonio, TX 78238
          </span>
        </div>
      </header>

      {/* Contact Info */}
      <div className="vaduaplu-contact-info">
  <p className="vaduaplu-text">
     100% Fresh &amp; Organic 
    <br />
    <span>🌿</span> Experience the best quality food items at Selam Food Mart.
  </p>
  <br />
  <span>🌍</span> Your go-to Ethiopian - Eritrean and International Grocery Store
  <br />
  <span>🫘</span> Mediterranean (Foul - Fava Beans), 
  <span>🥄</span> Indian (Ghee), 
  <span>🌶️</span> Mexican Tajin seasoning, herbs and spices.
</div>


      {/* Single Top Box combining Quick Menu & Deals */}
      <div className="vaduaplu-top-box">
        {/* Deals Section */}
        <div className="vaduaplu-top-section">
          <h2 className="vaduaplu-heading">Get Deals</h2>
          {/* Flashing Text */}
          <p className={`vaduaplu-deals-text ${fade ? "fade-in" : "fade-out"}`}>
            {deals[dealIndex]}
          </p>

          {/* Static Image below the deals text */}
          <img
            src={adGoOurHub}
            alt="GoOurHub Ad"
            className="vaduaplu-ad-image"
          />
        </div>

        {/* Quick Menu Section */}
        <div className="vaduaplu-top-section">
          <h2 className="vaduaplu-heading">Our Highlights</h2>
          <div className="vaduaplu-accordion">
            <div className="vaduaplu-accordion-item">
              <input type="checkbox" id="service1" className="hidden-checkbox"defaultChecked />
              <label htmlFor="service1" className="vaduaplu-label" align = "center">
              Our Highlights
              </label>
              <div className="vaduaplu-accordion-content">
              <ul className="vaduaplu-list">
                <li className="vaduaplu-list-item">✔️ Accepts SNAP/EBT</li>
                <li className="vaduaplu-list-item">⏰ Open until 12 AM</li>
                <li className="vaduaplu-list-item">🍏 Fresh and organic products</li>
                <li className="vaduaplu-list-item">Fresh Fruits & Vegetables</li>
                  <li className="vaduaplu-list-item">Locally sourced and organic options available.</li>
                  <li className="vaduaplu-list-item">Ethiopian Spices & Grains</li>
                  <li className="vaduaplu-list-item">Authentic flavors straight from Ethiopia.</li>
                 
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content / Gallery */}
      <main className="vaduaplu-main-content">
        <h2 className="vaduaplu-heading">Gallery</h2>
        <div className="vaduaplu-gallery">
          {[...Array(6)].map((_, index) => (
            <img
              key={index}
              src={images[index % images.length]}
              alt={`Work Sample ${index + 1}`}
              className="vaduaplu-image"
            />
          ))}
        </div>
      </main>

      {/* Extra Text Section */}
      <div className="vaduaplu-extra-text">
        <p className="vaduaplu-extra-text-p">
        Mediterranean (Foul - Fava Beans), Indian (Ghee), Mexican Tajin seasoning, herbs and spices.
        </p>
      </div>
    </div>
  );
}

export default VaduAplu;
