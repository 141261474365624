import React, { useState, useEffect } from "react";
import logo from "../images/alexswordLogo.jpg";
import adGoOurHub from "../images/ad_goourhub.jpg"; // <-- import your ad image
import "./VaduAplu.css";

const images = [
  require("../images/alexsword1.jpg"),
  require("../images/alexsword2.jpg"),
 
 
];


function AlexSword() {
  const [dealIndex, setDealIndex] = useState(0);
  const [fade, setFade] = useState(true);

  const deals = [
    
    "Mention GoOurHub to get better price/discounts",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setDealIndex((prev) => (prev + 1) % deals.length);
        setFade(true);
      }, 500);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="vaduaplu-container">
      {/* Header */}
      <header className="vaduaplu-header">
        {/* Left: Logo + Name */}
        <div className="vaduaplu-header-left">
          <img src={logo} alt="Logo" className="vaduaplu-logo" />
          <div className="vaduaplu-header-text">
            <h1 className="vaduaplu-company-name"> Alex's Swords and Knives
              
            </h1>
            
          </div>
        </div>

        {/* Right: Phone + Address */}
        <div className="vaduaplu-header-right">
          <span className="vaduaplu-phone"> 210-827-0196</span>

<span className="vaduaplu-address">
📍 Booth: Traders Village Booth 546<br />
    📍 Location: 9333 SW Loop 410, San Antonio, TX 78242<br />
  
  <br/>
  <br/>
  
   
  
</span>

        </div>
      </header>

    
      {/* Single Top Box combining Quick Menu & Deals */}
      <div className="vaduaplu-top-box">
        {/* Deals Section */}
        <div className="vaduaplu-top-section">
          <h2 className="vaduaplu-heading">Get Deals</h2>
          {/* Flashing Text */}
          <p className={`vaduaplu-deals-text ${fade ? "fade-in" : "fade-out"}`}>
            {deals[dealIndex]}
          </p>

          {/* Static Image below the deals text */}
          <img
            src={adGoOurHub}
            alt="GoOurHub Ad"
            className="vaduaplu-ad-image"
          />
        </div>

        {/* Quick Menu Section */}
        <div className="vaduaplu-top-section">
          <h2 className="vaduaplu-heading">Our Highlights</h2>
          <div className="vaduaplu-accordion">
            <div className="vaduaplu-accordion-item">
              <input type="checkbox" id="service1" className="hidden-checkbox"defaultChecked />
              <label htmlFor="service1" className="vaduaplu-label" align = "center">
              Our Highlights
              </label>
              <div className="vaduaplu-accordion-content">
              <ul className="vaduaplu-list">
  <li className="vaduaplu-list-item">
  <br/>
    ⚔️ <strong>Alex’s Swords and Knives</strong> – Specializing in decorative blades, collectible swords, and knives for display or personal collections.
  </li>
<br/>
  <li className="vaduaplu-list-item">
    🗡️ Whether you're into samurai swords, fantasy daggers, or martial arts replicas, we’ve got something unique for every enthusiast.
  </li>
  <br/>
  <li className="vaduaplu-list-item">
    🏬 Find us at Booth #546 inside <strong>Traders Village Flea Market</strong>, San Antonio, TX.
  </li>
  <br/>
  <li className="vaduaplu-list-item">
    📞 Call or text: <a className = "a-links-vaduaplu" href="tel:+12108270196">(210) 827-0196</a>
  </li>
</ul>








              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content / Gallery */}
      <main className="vaduaplu-main-content">
        <h2 className="vaduaplu-heading">Gallery</h2>
        <div className="vaduaplu-gallery">
          {[...Array(6)].map((_, index) => (
            <img
              key={index}
              src={images[index % images.length]}
              alt={`Work Sample ${index + 1}`}
              className="vaduaplu-image"
            />
          ))}
        </div>
      </main>

      {/* Extra Text Section */}
      <div className="vaduaplu-extra-text">
        <p className="vaduaplu-extra-text-p">
       Alex's Swords and Knives    </p>
      </div>
    </div>
  );
}

export default  AlexSword
  ;
