import React from "react";
import "./PitaPlus.css";
import logo from "../images/Pita-Plus-Logo-1024x768.jpeg"; // Adjust path as needed
import menu from "../images/Pita-Plus-Menu.jpg";
import background from "../images/pitabackground.jpg";

const PitaPlus = () => {
  return (
    <div className="pita-container">
      {/* Background Overlay */}
      <div className="background">
        <img src={background} alt="Background" />
      </div>

      {/* Logo Section */}
      <header className="pita-header">
        <img src={logo} alt="Pita Plus Logo" className="pita-logo" />
        <h2 className="tagline">Newest Kosher dining concept in San Antonio, TX</h2>
      </header>
{/* Contact Information */}
<div className="contact-info">
  <span className="phone">📞 (210) 575-6171</span>
  <span className="email">📧 <a href="mailto:lindawolfe@gmail.com">lindawolfe@gmail.com</a></span>
  <a href="https://www.pitaplusplus.com/" target="_blank" rel="noopener noreferrer" className="website-link">
    Visit Pita Plus++
  </a>
</div>


      {/* Address Section */}
      <section className="pita-address">
        <h3>Address:</h3>
        <p>Inside the Jewish Community Center of San Antonio</p>
        <p>12500 N.W. Military Highway, San Antonio, TX 78231</p>
      </section>

      {/* Menu Section */}
      <section className="pita-menu">
        <h2>Menu:</h2>
        <img src={menu} alt="Pita Plus Menu" className="menu-image" />
      </section>

      <footer className="pita-footer">
        <h3>Hours of Operation:</h3>
        <p className="closed-notice"></p>

        <div className="hours-table">
          <p>
            <strong>Monday - Thursday:</strong> <br />
            11:00 AM - 7:30 PM
          </p>
          <p>
            <strong>Friday - Saturday:</strong> <br />
            <span className="closed">CLOSED</span>
          </p>
          <p>
            <strong>Sunday:</strong> <br />
            11:00 AM - 6:00 PM
          </p>
        </div>
      </footer>
    </div>
  );
};

export default PitaPlus;
