import React, { useState, useEffect } from "react";
import logo from "../images/sMuralsLogo.jpg";
import adGoOurHub from "../images/ad_goourhub.jpg"; // <-- import your ad image
import "./VaduAplu.css";

const images = [
  require("../images/sMurals1.jpg"),
  require("../images/sMurals2.jpg"),
  require("../images/sMurals3.jpg"),
  require("../images/sMurals4.jpg"),
  require("../images/sMurals5.jpg"),
  require("../images/sMurals6.jpg"),
];


function StarrMurals() {
  const [dealIndex, setDealIndex] = useState(0);
  const [fade, setFade] = useState(true);

  const deals = [
   
    "Mention GoOurHub to get better price/discounts",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setDealIndex((prev) => (prev + 1) % deals.length);
        setFade(true);
      }, 500);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="vaduaplu-container">
      {/* Header */}
      <header className="vaduaplu-header">
        {/* Left: Logo + Name */}
        <div className="vaduaplu-header-left">
          <img src={logo} alt="Logo" className="vaduaplu-logo" />
          <div className="vaduaplu-header-text">
            <h1 className="vaduaplu-company-name">Murals by S.Knight
              
            </h1>
            
          </div>
        </div>

        {/* Right: Phone + Address */}
        <div className="vaduaplu-header-right">
          <span className="vaduaplu-phone">Ph : (210) 986-0553</span>
          <br />Email : starreknight@gmail.com
       

<span className="vaduaplu-address">
  
</span>

        </div>
      </header>

      {/* Contact Info */}
      <div className="vaduaplu-contact-info">
  <p className="vaduaplu-text">
    <span>🎨</span> Welcome to <strong>Murals by S.Knight</strong> <br />
    <br />
    <br />

    Original one of kind Hand painted' Over 35+ Years Experience I provide extremely colorful custom mural wall art designs at reasonable prices! Fast Friendly Service Free Estimates Murals Signs Banners Holiday window art Storefront Lettering Vehicle Lettering Face Painting Wall Lettering Custom Graphics Logos & Design pinstriping

    
    
  </p>
  <br />
  Established in 1977.

Over 35+ Years Experience and counting

<br />
<br />
 


      {/* Single Top Box combining Quick Menu & Deals */}
      <div className="vaduaplu-top-box">
        {/* Deals Section */}
        <div className="vaduaplu-top-section">
          <h2 className="vaduaplu-heading">Get Deals</h2>
          {/* Flashing Text */}
          <p className={`vaduaplu-deals-text ${fade ? "fade-in" : "fade-out"}`}>
            {deals[dealIndex]}
          </p>

          {/* Static Image below the deals text */}
          <img
            src={adGoOurHub}
            alt="GoOurHub Ad"
            className="vaduaplu-ad-image"
          />
        </div>

        {/* Quick Menu Section */}
        <div className="vaduaplu-top-section">
          <h2 className="vaduaplu-heading">Our Highlights</h2>
          <div className="vaduaplu-accordion">
            <div className="vaduaplu-accordion-item">
              <input type="checkbox" id="service1" className="hidden-checkbox"defaultChecked />
              <label htmlFor="service1" className="vaduaplu-label" align = "center">
              Our Highlights
              </label>
              <ul className="vaduaplu-list">
  <li className="vaduaplu-list-item">
  <br/>
  <br/>
  🖌️ Stunning custom mural work by a native San Antonio artist, bringing walls to life since the '90s.
  </li>
  <br/>

  <li className="vaduaplu-list-item">
    🖌️ From children’s rooms to business storefronts, Starr Knight turns blank spaces into bold expressions of art and color.
  </li>
  <br/>
  <li className="vaduaplu-list-item">
    📞 Contact Starr directly at <a href="tel:+12109860553">(210) 986-0553</a> for inquiries, commissions, or consultations.
  </li>
  <br/>
  <li className="vaduaplu-list-item">
    📘 Explore more of her work and ongoing projects on Facebook: 
    <a className="a-links-vaduaplu"  href="https://www.facebook.com/profile.php?id=100057167189389" target="_blank" rel="noopener noreferrer"> FB - Murals by S. Knight</a>
  </li>
  <br/>
</ul>

              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content / Gallery */}
      <main className="vaduaplu-main-content">
        <h2 className="vaduaplu-heading">Gallery</h2>
        <div className="vaduaplu-gallery">
          {[...Array(6)].map((_, index) => (
            <img
              key={index}
              src={images[index % images.length]}
              alt={`Work Sample ${index + 1}`}
              className="vaduaplu-image"
            />
          ))}
        </div>
      </main>

      {/* Extra Text Section */}
      <div className="vaduaplu-extra-text">
        <p className="vaduaplu-extra-text-p">
        "I am passionate about the work i do because, I can create something new and positive for others to appreciate. "- Starr Knight
        </p>
      </div>
    </div>
  );
}

export default StarrMurals;
