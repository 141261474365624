// Modernized Header.jsx
import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import {
  FaMicrophone, FaBars, FaTimes, FaThLarge, FaAmazon, FaGoogle, FaFacebook, FaTwitter, FaInstagram,
  FaYoutube, FaLinkedin, FaGithub, FaPinterest, FaTiktok, FaReddit, FaSnapchatGhost, FaSpotify,
  FaSlack, FaWhatsapp
} from 'react-icons/fa';
import logo from "../../logo.png";
import "./header.css";

function Header({ onSearch }) {
  const [isListening, setIsListening] = useState(false);
  const [showReferralModal, setShowReferralModal] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isLight, setIsLight] = useState(false);
  const menuRef = useRef(null);
  const modalRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [showImportant, setShowImportant] = useState(false);
  const [showSubLinks, setShowSubLinks] = useState(false);
  const [showPopular, setShowPopular] = useState(false);

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location.pathname]);

  useEffect(() => {
    document.body.classList.toggle("light-mode", isLight);
  }, [isLight]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
      if (showReferralModal && modalRef.current && !modalRef.current.contains(e.target)) {
        setShowReferralModal(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [showReferralModal, isMenuOpen]);

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchText(query);
    onSearch(query);
  };

  const clearSearch = () => {
    setSearchText("");
    onSearch("");
    navigate("/");
  };

  const handleMicClick = () => {
    if (!("webkitSpeechRecognition" in window)) return alert("Use Chrome for voice search");
    const recognition = new window.webkitSpeechRecognition();
    recognition.lang = "en-US";
    recognition.interimResults = true;
    recognition.onstart = () => setIsListening(true);
    recognition.onresult = (e) => {
      let transcript = "";
      for (let i = e.resultIndex; i < e.results.length; i++) {
        transcript += e.results[i][0].transcript;
      }
      setSearchText(transcript);
    };
    recognition.onend = () => {
      setIsListening(false);
      onSearch(searchText.trim());
    };
    recognition.start();
  };

  return (
    <header className="header">
      <div className="header-left">
        <Link to="/" className="logo-link">
          <img src={logo} alt="GoOurHub Logo" className="logo" />
          <div className="logo-text">
            <strong>GoOurHub</strong>
            <span className="header-tagline">Connecting you to the world in a snap!</span>
          </div>
        </Link>
      </div>

      <div className="theme-toggle-wrapper">
        <label className="theme-switch">
          <input type="checkbox" checked={isLight} onChange={() => setIsLight(!isLight)} />
          <span className="slider"></span>
        </label>
        <span>{isLight ? "Light" : "Dark"} Mode</span>
      </div>

      <div className="header-right">
        <div className="search-wrapper">
          <button className="mic" onClick={handleMicClick}>
            <FaMicrophone className={`mic-icon ${isListening ? "listening" : ""}`} />
          </button>
          <i className="fas fa-search search-icon"></i>
          <input
            type="text"
            className="header-search-input"
            placeholder="Search "
            value={searchText}
            onChange={handleSearchChange}
          />
          {searchText && (
            <button className="clear-btn" onClick={clearSearch}>
              <FaTimes size={14} />
            </button>
          )}
        </div>

        <div className="nav-buttons">
          <HashLink smooth to="/partner-signup#joinForm" className="join-btn">Join Us</HashLink>
          <button className="referral-btn" onClick={() => setShowReferralModal(true)}>Refer & Earn 60%</button>
          <Link to="/connect" className="social-icon"><FaThLarge /></Link>
          <button className="menu-toggle" onClick={() => setIsMenuOpen(!isMenuOpen)}><FaBars /></button>
        </div>
      </div>

      {isMenuOpen && (
        <div ref={menuRef} className="dropdown">
          <Link to="/">Home</Link>
          <Link to="/about-us">About</Link>
          <Link to="/contact">Contact</Link>
          <Link to="/forum">Community Forum</Link>
          <Link to="/review-system">Review & Ratings</Link>
          <Link to="/dashboard">My Dashboard</Link>

          <div className="mobile-only">
            <div className="accordion-tile" onClick={() => setShowImportant(!showImportant)}>
              📌 Community Hub
            </div>

            {showImportant && (
              <div className="imp-accordion-panel">
                <div className="imp-accordion-tile" onClick={() => setShowSubLinks(!showSubLinks)}>
                  🔗 Community Connect
                </div>
                {showSubLinks && (
                  <div className="accordion-panel">
                    {[{ name: 'American Indians in Texas', url: 'https://aitscm.org/' },
                      { name: 'Anuja SA (Marathi Community)', url: 'https://www.anujasa.com/' },
                      { name: 'Bengali Community', url: 'https://sanantoniobcc.org/' },
                      { name: 'Gujarati Samaj of San Antonio', url: 'https://www.gujaratisamajofsa.org/' },
                      { name: 'India Association of San Antonio', url: 'https://www.indiasa.org/' },
                      { name: 'Kannada Sangha San Antonio', url: 'https://www.kks-sa.org/html/NewSite/about.html' },
                      { name: 'Kerala Association of San Antonio (Malayalam Community)', url: 'https://www.keralasa.org/' },
                      { name: 'Mexican American Unity Council', url: 'https://stmupublichistory.org/lcd/index.php/mexican-american-unity-council/' },
                      { name: 'San Antonio African American Community Archive and Museum', url: 'https://www.sanantoniomag.com/local-organizations-to-support-to-further-racial-equality/' },
                      { name: 'San Antonio Ethnic Art Society', url: 'https://saethnicartsociety.org/' },
                      { name: 'Tamil Community', url: 'https://satamilsangam.org/' },
                      { name: 'Urban Indian SA', url: 'https://urbanindiansa.net/' }]
                      .map((item, i) => (
                        <a key={i} href={item.url} target="_blank" rel="noopener noreferrer" className="tile-link sub-tile">
                          {item.name}
                        </a>
                      ))}
                  </div>
                )}
              </div>
            )}

            <div className="accordion-tile" onClick={() => setShowPopular(!showPopular)}>
              🌍 Popular Sites
            </div>

            {showPopular && (
              <div className="accordion-panel popular-grid">
                {[{ icon: <FaAmazon />, name: 'Amazon', url: 'https://www.amazon.com' },
                  { icon: <FaGoogle />, name: 'Google', url: 'https://www.google.com' },
                  { icon: <FaFacebook />, name: 'Facebook', url: 'https://facebook.com' },
                  { icon: <FaTwitter />, name: 'Twitter', url: 'https://twitter.com' },
                  { icon: <FaInstagram />, name: 'Instagram', url: 'https://instagram.com' },
                  { icon: <FaYoutube />, name: 'YouTube', url: 'https://youtube.com' },
                  { icon: <FaLinkedin />, name: 'LinkedIn', url: 'https://linkedin.com' },
                  { icon: <FaGithub />, name: 'GitHub', url: 'https://github.com' },
                  { icon: <FaPinterest />, name: 'Pinterest', url: 'https://pinterest.com' },
                  { icon: <FaTiktok />, name: 'TikTok', url: 'https://tiktok.com' },
                  { icon: <FaReddit />, name: 'Reddit', url: 'https://reddit.com' },
                  { icon: <FaSnapchatGhost />, name: 'Snapchat', url: 'https://snapchat.com' },
                  { icon: <FaSpotify />, name: 'Spotify', url: 'https://spotify.com' },
                  { icon: <FaSlack />, name: 'Slack', url: 'https://slack.com' },
                  { icon: <FaWhatsapp />, name: 'WhatsApp', url: 'https://whatsapp.com' }]
                  .map((site, index) => (
                    <a key={index} href={site.url} target="_blank" rel="noopener noreferrer" className="popular-tile">
                      <div className="icon">{site.icon}</div>
                      <div className="label">{site.name}</div>
                    </a>
                  ))}
              </div>
            )}

          </div>
        </div>
      )}

      {showReferralModal && (
        <div className="modal">
          <div className="modal-box" ref={modalRef}>
            <span className="close" onClick={() => setShowReferralModal(false)}>&times;</span>
            <h2>Referral Program</h2>
            <p>Refer a friend to sign up and earn 60% of their sign-up amount. Let’s grow together!</p>
          </div>
        </div>
      )}
    </header>
  );
}

export default Header;
