import React from "react";
import "./PetersEliteCleaning.css";
import cleaningImage from "../images/cleaning-image.jpg"; // Placeholder for the actual image

const PetersEliteCleaning = () => {
  return (
    <div className="business-card">
      <div className="card-header">
        <h1>Peters Elite Cleaning</h1>
        <p>Over 25 Years in Cleaning</p>
      </div>
      <div className="card-body">
        <div className="card-info">
          <h2>Mark Peters</h2>
          <p className="owner">Owner</p>
          <p><strong>Cell:</strong>210-801-2884</p>
          <p><strong>Email:</strong>mapeters81@gmail.com</p>
        </div>
        <div className="card-image">
          <img src={cleaningImage} alt="Cleaning supplies" />
        </div>
      </div>
      <div className="card-footer">
        <p>Specialize in Residential and Commercial Cleaning</p>
        <p>General Cleaning and Deep Cleaning</p>
      </div>
    </div>
  );
};

export default PetersEliteCleaning;
