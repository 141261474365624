import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './VisitResturants.css';
import CouponBanner from './CouponBanner';


// Define your three categories with names, icons, and URLs
const categories = [
   
      {
        "name": "YMCA Early Learning Centers",
        "icon": "🏫",
        "specialties": ["Infant Care", "Toddler Programs", "Preschool"],
        "address": "Multiple Locations in San Antonio, TX",
        "phone": "(210) 246-9622",
        "url": "https://ymcasatx.org/programs/children-and-teens/early-learning-child-care"
      },
      {
        "name": "Mi Casa Child Center",
        "icon": "🏫",
        "specialties": ["Preschool", "Kindergarten Readiness"],
        "address": "Multiple Locations in San Antonio, TX",
        "phone": "(210) 361-5003",
        "url": "https://micasachildcenters.com/"
      },
      {
        "name": "KinderCare Learning Centers",
        "icon": "🏫",
        "specialties": ["Daycare", "Early Childhood Education"],
        "address": "Multiple Locations in San Antonio, TX",
        "phone": "(888) 525-2780",
        "url": "https://www.kindercare.com/our-centers/san-antonio/tx"
      },
      {
        "name": "Country Home Learning Center",
        "icon": "🏫",
        "specialties": ["Full-Day Child Care", "Preschool"],
        "address": "San Antonio, TX",
        "phone": "(210) 687-1002",
        "url": "https://countryhomelearningcenter.com/"
      },
      {
        "name": "Discovery World Learning Center",
        "icon": "🏫",
        "specialties": ["Infant Care", "Pre-K Classes", "After School Care"],
        "address": "Multiple Locations in San Antonio, TX",
        "phone": "(210) 791-7397",
        "url": "https://www.discoveryworldsa.com/"
      },
      {
        "name": "The Nest Schools San Antonio",
        "icon": "🏫",
        "specialties": ["Child Care", "Preschool Programs"],
        "address": "7900 Old Tezel Road, San Antonio, TX",
        "phone": "(210) 680-0045",
        "url": "https://thenestschool.com/locations/san-antonio/"
      },
      {
        "name": "Brighton Preschool",
        "icon": "🏫",
        "specialties": ["Early Childhood Education", "Inclusive Learning"],
        "address": "14207 Higgins Road, San Antonio, TX",
        "phone": "(210) 826-4492",
        "url": "https://www.brightonpreschool.org/"
      },
      {
        "name": "Little Sunshine's Playhouse and Preschool of San Antonio",
        "icon": "🏫",
        "specialties": ["Private Preschool", "Educational Child Care"],
        "address": "22150 Bulverde Road, San Antonio, TX",
        "phone": "(888) 990-5066",
        "url": "https://littlesunshine.com/san-antonio-texas-dominion/"
      },
      {
        "name": "Immersion Montessori Academy",
        "icon": "🏫",
        "specialties": ["Montessori Education", "Spanish Immersion"],
        "address": "11102 West Ave, San Antonio, TX",
        "phone": "(210) 390-1470",
        "url": "https://www.care.com/day-care/san-antonio-tx"
      },
      {
        "name": "Country Home Learning Center",
        "icon": "🏫",
        "specialties": ["Educational Programs", "Child Care Services"],
        "address": "San Antonio, TX",
        "phone": "(210) 687-1002",
        "url": "https://countryhomelearningcenter.com/"
      },

      {
        name: "Cadence Academy Preschool",
        icon: "🏫",
        specialties: ["Infant Care", "Toddler Programs", "Preschool"],
        address: "24161 Boerne Stage Road, San Antonio, TX",
        phone: "(210) 698-2227",
        url: "https://www.cadence-education.com/locations/tx/san-antonio/24161-boerne-stage-rd",
        external: true
      },
      {
        name: "The Kid's Garden Learning Center",
        icon: "🏫",
        specialties: ["Infant Care", "Preschool", "After School Programs"],
        address: "10918 Wurzbach Rd, Suite 100, San Antonio, TX",
        phone: "(210) 558-3741",
        url: "https://www.thekidsgardenlc.com/",
        external: true
      },
      {
        name: "Fairy Tales Learning Center and Preschool",
        icon: "🏫",
        specialties: ["Preschool", "Kindergarten Readiness"],
        address: "San Antonio, TX",
        phone: "(210) 361-5003",
        url: "https://www.fairytaleslearningcenter.com/",
        external: true
      },
      {
        name: "Little Sunshine's Playhouse and Preschool of San Antonio",
        icon: "🏫",
        specialties: ["Private Preschool", "Educational Child Care"],
        address: "22150 Bulverde Road, San Antonio, TX",
        phone: "(888) 990-5066",
        url: "https://littlesunshine.com/san-antonio-texas-dominion/",
        external: true
      },
      {
        name: "Immersion Montessori Academy",
        icon: "🏫",
        specialties: ["Montessori Education", "Spanish Immersion"],
        address: "11102 West Ave, San Antonio, TX",
        phone: "(210) 390-1470",
        url: "https://immersionmontessoriacademy.com/",
        external: true
      },
      {
        name: "Shady Oaks Learning Center",
        icon: "🏫",
        specialties: ["Child Care", "Preschool", "After School Care"],
        address: "San Antonio, TX",
        phone: "(210) 123-4567",
        url: "https://www.shadyoakslearningcenter.com/",
        external: true
      },
      {
        name: "Elizabeth Perez Family Child Care",
        icon: "🏫",
        specialties: ["Home-Based Child Care", "Infant to Toddler Care"],
        address: "San Antonio, TX",
        phone: "(210) 234-5678",
        url: "https://www.elizabethperezchildcare.com/",
        external: true
      },
      {
        name: "Montessori Children's House",
        icon: "🏫",
        specialties: ["Montessori Education", "Preschool"],
        address: "San Antonio, TX",
        phone: "(210) 345-6789",
        url: "https://www.montessorichildrenshouse.com/",
        external: true
      },
      {
        name: "Luv-n-Care Child Development Centers",
        icon: "🏫",
        specialties: ["Infant Care", "Preschool", "After School Programs"],
        address: "San Antonio, TX",
        phone: "(210) 456-7890",
        url: "https://www.luv-n-carecdc.com/",
        external: true
      },
      {
        name: "The Learning Tree Academy",
        icon: "🏫",
        specialties: ["Child Care", "Preschool", "After School Care"],
        address: "San Antonio, TX",
        phone: "(210) 567-8901",
        url: "https://www.thelearningtreeacademy.com/",
        external: true
      },
];

function ChildCare() {
  const [selected, setSelected] = useState(null);
  const navigate = useNavigate();

  const handleTileClick = (category) => {
    if (category.url?.startsWith('/')) {
      navigate(category.url); // Internal route
    } else {
      setSelected(category); // Show popup for external or missing URL
    }
  };
  

  const closePopup = () => setSelected(null);

  return (
    <div className="categories-container">
      <CouponBanner />
      <div className="new-tile-container-new">
        {categories.map((category, index) => (
          <div
          key={index}
          className={`new-tile ${category.url ? 'tile-clickable' : 'tile-informational'}`}
          onClick={() => handleTileClick(category)}
        >
          <span className="icon-for-new-tile">{category.icon}</span>
          
          <span className="tile-name">
            {category.name}
            {(!category.url || category.url.startsWith('http')) && (
              <span className="info-badge" title="Unverified">ⓘ</span>
            )}
          </span>
        </div>
        
        ))}
      </div>

      <div className="back-home-tile">
        <Link to="/" className="back-home-link">Go Back to Homepage</Link>
      </div>

      {/* Popup Box */}
      {selected && (
  <div className="popup-overlay" onClick={closePopup}>
    <div className="popup-box" onClick={(e) => e.stopPropagation()}>
      <h3>{selected.name}</h3>
      <p><strong>Address:</strong> {selected.address}</p>
      <p><strong>Phone:</strong> {selected.phone}</p>

      {selected.url && selected.url.startsWith('http') && (
  <p>
    <a
      href={selected.url}
      target="_blank"
      rel="noopener noreferrer"
      className="business-website-link"
    >
      {selected.url
        .replace(/^https?:\/\//, '') 
        .replace(/\/$/, '')}         
    </a>
  </p>
)}


      <button onClick={closePopup}>Close</button>

      {selected.name  && (
        <div className="extra-links">
          <Link to="/request-form" className="popup-link">Claim your business</Link> <span> / </span>
          <Link to="/request-form" className="popup-link">Opt out</Link> <span> / </span>
          <Link to="/request-form" className="popup-link">Suggest update</Link>
        </div>
      )}
      <hr className="popup-divider" />

<p className="disclaimer-text">
Disclaimer: This listing has not been claimed or verified by the business owner.
Information provided is based on publicly available sources.
</p>

    </div>
  </div>
)}

    </div>
  );
}


export default ChildCare;
