import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './VisitResturants.css';
import CouponBanner from './CouponBanner';


// Define your three categories with names, icons, and URLs
const categories = [
  {
    "name": "San Antonio Jewelry",
    icon: "💍",
    "specialties": ["Engagement Rings", "Wedding Bands", "Custom Jewelry"],
    "address": "1874 N Loop 1604 W, San Antonio, TX 78248",
    "phone": "(210) 493-7789",
    "url": "https://sajewelry.com/"
  },
  {
    "name": "Dennis Jewelry Store",
    icon: "💍",
    "specialties": ["Custom Designs", "Jewelry Repair", "Fine Jewelry"],
    "address": "14701 San Pedro Ave #110, San Antonio, TX 78232",
    "phone": "(210) 499-1212",
    "url": "https://dennisjewelry.com/"
  },
  {
    "name": "Shetler Fine Jewelers",
    icon: "💍",
    "specialties": ["Fine Jewelry", "Designer Watches", "Custom Jewelry"],
    "address": "7373 Broadway, Suite 106, San Antonio, TX 78209",
    "phone": "(210) 826-0660",
    "url": "https://www.shetlerfinejewelers.com/"
  },
  {
    "name": "Rialto Jewelry",
    icon: "💍",
    "specialties": ["Engagement Rings", "Custom Jewelry", "Jewelry Repair"],
    "address": "San Antonio, TX",
    "phone": "(210) 308-7426",
    "url": "https://www.rialtojewelry.com/"
  },
  {
    "name": "Alonso Jewelry Design",
    icon: "💍",
    "specialties": ["Custom Jewelry", "Engagement Rings", "Religious Jewelry"],
    "address": "San Antonio, TX",
    "phone": "(210) 930-3900",
    "url": "https://www.alonsojewelry.com/"
  },
  {
    "name": "Moses Jewelers",
    icon: "💍",
    "specialties": ["Custom Designed Jewelry", "Jewelry Repair", "Fine Jewelry"],
    "address": "San Antonio, TX",
    "phone": "(210) 490-2404",
    "url": "https://mosesjewelers.net/"
  },
  {
    "name": "Lee Michaels Fine Jewelry",
    icon: "💍",
    "specialties": ["Fine Jewelry", "Designer Watches", "Custom Jewelry"],
    "address": "7400 San Pedro Avenue, Suite 422, San Antonio, TX 78216",
    "phone": "(210) 541-9575",
    "url": "https://www.lmfj.com/location-san-antonio-at-north-star-mall"
  },
  {
    "name": "Diamonds Direct",
    icon: "💍",
    "specialties": ["Engagement Rings", "Loose Diamonds", "Fine Jewelry"],
    "address": "15900 La Cantera Parkway, San Antonio, TX 78256",
    "phone": "(210) 247-6767",
    "url": "https://diamondsdirect.com/san-antonio-tx/"
  },
  {
    "name": "Tiffany & Co.",
    icon: "💍",
    "specialties": ["Luxury Jewelry", "Engagement Rings", "Designer Jewelry"],
    "address": "15900 La Cantera Parkway, San Antonio, TX 78256",
    "phone": "(210) 877-9933",
    "url": "https://www.tiffany.com/jewelry-stores/san-antonio-the-shops-at-la-cantera/"
  },
  {
    "name": "Zales",
    icon: "💍",
    "specialties": ["Engagement Rings", "Wedding Bands", "Fashion Jewelry"],
    "address": "7400 San Pedro Ave., 1785, San Antonio, TX 78216-5399",
    "phone": "(210) 349-3191",
    "url": "https://stores.zales.com/tx/san-antonio/5646"
  },
  {
    "name": "Kay Jewelers",
    icon: "💍",
    "specialties": ["Engagement Rings", "Wedding Bands", "Custom Jewelry"],
    "address": "6301 Northwest Loop 410, Ste. S12, San Antonio, TX 78238-3852",
    "phone": "(210) 522-0622",
    "url": "https://stores.kay.com/tx/san-antonio/1784"
  },
  {
    "name": "Jared The Galleria of Jewelry",
    icon: "💍",
    "specialties": ["Engagement Rings", "Diamonds", "Watches"],
    "address": "San Antonio, TX",
    "phone": "(210) 340-1919",
    "url": "https://stores.jared.com/tx/san-antonio/2559"
  },

  
];

function Jewelry() {
  const [selected, setSelected] = useState(null);
  const navigate = useNavigate();

  const handleTileClick = (category) => {
    if (category.url?.startsWith('/')) {
      navigate(category.url); // Internal route
    } else {
      setSelected(category); // Show popup for external or missing URL
    }
  };
  

  const closePopup = () => setSelected(null);

  return (
    <div className="categories-container">
      <CouponBanner />
      <div className="new-tile-container-new">
        {categories.map((category, index) => (
          <div
          key={index}
          className={`new-tile ${category.url ? 'tile-clickable' : 'tile-informational'}`}
          onClick={() => handleTileClick(category)}
        >
          <span className="icon-for-new-tile">{category.icon}</span>
          
          <span className="tile-name">
            {category.name}
            {(!category.url || category.url.startsWith('http')) && (
              <span className="info-badge" title="Unverified">ⓘ</span>
            )}
          </span>
        </div>
        
        ))}
      </div>

      <div className="back-home-tile">
        <Link to="/" className="back-home-link">Go Back to Homepage</Link>
      </div>

      {/* Popup Box */}
      {selected && (
  <div className="popup-overlay" onClick={closePopup}>
    <div className="popup-box" onClick={(e) => e.stopPropagation()}>
      <h3>{selected.name}</h3>
      <p><strong>Address:</strong> {selected.address}</p>
      <p><strong>Phone:</strong> {selected.phone}</p>

      {selected.url && selected.url.startsWith('http') && (
  <p>
    <a
      href={selected.url}
      target="_blank"
      rel="noopener noreferrer"
      className="business-website-link"
    >
      {selected.url
        .replace(/^https?:\/\//, '') 
        .replace(/\/$/, '')}         
    </a>
  </p>
)}


      <button onClick={closePopup}>Close</button>

      {selected.name  && (
        <div className="extra-links">
          <Link to="/request-form" className="popup-link">Claim your business</Link> <span> / </span>
          <Link to="/request-form" className="popup-link">Opt out</Link> <span> / </span>
          <Link to="/request-form" className="popup-link">Suggest update</Link>
        </div>
      )}
      <hr className="popup-divider" />

<p className="disclaimer-text">
Disclaimer: This listing has not been claimed or verified by the business owner.
Information provided is based on publicly available sources.
</p>

    </div>
  </div>
)}

    </div>
  );
}


export default Jewelry;
