import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './VisitResturants.css';
import PromoSection from './PromoSection';

// Define your three categories with names, icons, and URLs
const categories = [
 
  { name: 'Pita Plus', icon: '🥙', url: '/pitaplus' },
  {
    name: "Yummy Kosher Grill",
    icon: "🍽️",
    specialties: ["Kosher", "Mediterranean", "Glatt Kosher"],
    address: "San Antonio, TX",
    phone: "",
    url: "https://www.google.com/maps/search/Yummy+Kosher+Grill%2C+San+Antonio%2C+TX",
    external: true
  },
  {
    name: "Nibblits and Nosh Food Truck",
    icon: "🚚",
    specialties: ["Kosher", "Food Truck", "BBQ"],
    address: "San Antonio, TX (various locations)",
    phone: "",
    url: "https://www.google.com/maps/search/Nibblits+and+Nosh+Food+Truck%2C+San+Antonio%2C+TX",
    external: true
  },
  {
    name: "HTeaO Shavano Park",
    icon: "🧋",
    specialties: ["Kosher-certified Teas", "Drinks"],
    address: "San Antonio, TX",
    phone: "",
    url: "https://www.google.com/maps/search/HTeaO+Shavano+Park%2C+San+Antonio%2C+TX",
    external: true
  },
  {
    name: "BreVity Coffee Shop",
    icon: "☕",
    specialties: ["Kosher-certified Beverages", "Coffee"],
    address: "San Antonio, TX",
    phone: "",
    url: "https://www.google.com/maps/search/BreVity+Coffee+Shop%2C+San+Antonio%2C+TX",
    external: true
  },
  {
    name: "Nothing Bundt Cakes",
    icon: "🍰",
    specialties: ["Kosher-certified Cakes", "Desserts"],
    address: "San Antonio, TX",
    phone: "",
    url: "https://www.google.com/maps/search/Nothing+Bundt+Cakes%2C+San+Antonio%2C+TX",
    external: true
  },
  {
    "name": "HEB Alon Market",
    "icon": "🛒",
    "specialties": ["Kosher Groceries", "Kosher Bakery"],
    "address": "8503 NW Military Hwy, San Antonio, TX 78230",
    "phone": "(210) 479-4300",
    "url": "https://www.heb.com/heb-store/US/tx/san-antonio/alon-market-682"
  },
  {
    "name": "Linda's Catering",
    "icon": "🍽️",
    "specialties": ["Kosher Catering"],
    "address": "San Antonio, TX",
    "phone": "(210) 573-0711",
    "url": "mailto:lindaswolfe@hotmail.com"
  },
  {
    "name": "Malkie's Challah",
    "icon": "🥖",
    "specialties": ["Kosher Baked Goods"],
    "address": "San Antonio, TX",
    "phone": "",
    "url": "mailto:malkiemarrus@gmail.com"
  },
  {
    "name": "Cafe Rodfei",
    "icon": "🍴",
    "specialties": ["Kosher Dining"],
    "address": "San Antonio, TX",
    "phone": "",
    "url": "https://www.rodfeisholom.com/caferodfei"
  },
];

function VisitResturants() {
  const [selected, setSelected] = useState(null);
  const navigate = useNavigate();

  const handleTileClick = (category) => {
    if (category.url?.startsWith('/')) {
      navigate(category.url); // Internal route
    } else {
      setSelected(category); // Show popup for external or missing URL
    }
  };
  

  const closePopup = () => setSelected(null);

  return (
    <div className="categories-container">
      <PromoSection/>
      <div className="new-tile-container-new">
        {categories.map((category, index) => (
          <div
          key={index}
          className={`new-tile ${category.url ? 'tile-clickable' : 'tile-informational'}`}
          onClick={() => handleTileClick(category)}
        >
          <span className="icon-for-new-tile">{category.icon}</span>
          
          <span className="tile-name">
            {category.name}
            {(!category.url || category.url.startsWith('http')) && (
              <span className="info-badge" title="Unverified">ⓘ</span>
            )}
          </span>
        </div>
        
        ))}
      </div>

      <div className="back-home-tile">
        <Link to="/" className="back-home-link">Go Back to Homepage</Link>
      </div>

      {/* Popup Box */}
      {selected && (
  <div className="popup-overlay" onClick={closePopup}>
    <div className="popup-box" onClick={(e) => e.stopPropagation()}>
      <h3>{selected.name}</h3>
      <p><strong>Address:</strong> {selected.address}</p>
      <p><strong>Phone:</strong> {selected.phone}</p>

      {selected.url && selected.url.startsWith('http') && (
  <p>
    <a
      href={selected.url}
      target="_blank"
      rel="noopener noreferrer"
      className="business-website-link"
    >
      {selected.url
        .replace(/^https?:\/\//, '') 
        .replace(/\/$/, '')}         
    </a>
  </p>
)}


      <button onClick={closePopup}>Close</button>

      {selected.name  && (
        <div className="extra-links">
          <Link to="/request-form" className="popup-link">Claim your business</Link> <span> / </span>
          <Link to="/request-form" className="popup-link">Opt out</Link> <span> / </span>
          <Link to="/request-form" className="popup-link">Suggest update</Link>
        </div>
      )}
      <hr className="popup-divider" />

<p className="disclaimer-text">
Disclaimer: This listing has not been claimed or verified by the business owner.
Information provided is based on publicly available sources.
</p>

    </div>
  </div>
)}

    </div>
  );
}


export default VisitResturants;
