import React, { useState, useEffect } from "react";
import logo from "../images/nenaLogo.jpg";
import adGoOurHub from "../images/ad_goourhub.jpg"; // <-- import your ad image
import "./VaduAplu.css";

const images = [
  require("../images/nena1.jpg"),
  require("../images/nena2.jpg"),
  require("../images/nena3.jpg"),
  require("../images/nena4.jpg"),
  require("../images/nena5.jpg"),
  require("../images/nena6.jpg"),
];


function NenaClothing() {
  const [dealIndex, setDealIndex] = useState(0);
  const [fade, setFade] = useState(true);

  const deals = [
   
    "Mention GoOurHub to get better price/discounts",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setDealIndex((prev) => (prev + 1) % deals.length);
        setFade(true);
      }, 500);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="vaduaplu-container">
      {/* Header */}
      <header className="vaduaplu-header">
        {/* Left: Logo + Name */}
        <div className="vaduaplu-header-left">
          <img src={logo} alt="Logo" className="vaduaplu-logo" />
          <div className="vaduaplu-header-text">
            <h1 className="vaduaplu-company-name">Nena's  </h1>  <h6>Mexican Clothing and Footwear</h6>
              
          
            
          </div>
        </div>

        {/* Right: Phone + Address */}
        <div className="vaduaplu-header-right">
          <span className="vaduaplu-phone">Trader's Village San Antonio</span>
          <br />9333 SW Loop 410, San Antonio, TX 78242
          <br />Space #267 #269 #368 #366
          <br />Open Weekends
          <br />10am - 6pm
       

<span className="vaduaplu-address">
  
</span>

        </div>
      </header>

      {/* Contact Info */}
      <div className="vaduaplu-contact-info">
  <p className="vaduaplu-text">
    <span>💃</span> Welcome to <strong>Nena's Mexican Clothing and Footwear </strong> <br />
    <br />
    <br />

    👗🌺🇲🇽 Nena’s Mexican Clothing and Footwear – Traditional fashion, bold colors, and handmade beauty.

Let me know if you want a specific “aesthetic” like clean-modern, playful, or traditional-folk. I can tailor the whole section around that vibe too!

    
    
  </p>
 

<br />
<br />
 


      {/* Single Top Box combining Quick Menu & Deals */}
      <div className="vaduaplu-top-box">
        {/* Deals Section */}
        <div className="vaduaplu-top-section">
          <h2 className="vaduaplu-heading">Get Deals</h2>
          {/* Flashing Text */}
          <p className={`vaduaplu-deals-text ${fade ? "fade-in" : "fade-out"}`}>
            {deals[dealIndex]}
          </p>

          {/* Static Image below the deals text */}
          <img
            src={adGoOurHub}
            alt="GoOurHub Ad"
            className="vaduaplu-ad-image"
          />
        </div>

        {/* Quick Menu Section */}
        <div className="vaduaplu-top-section">
          <h2 className="vaduaplu-heading">Our Highlights</h2>
          <div className="vaduaplu-accordion">
            <div className="vaduaplu-accordion-item">
              <input type="checkbox" id="service1" className="hidden-checkbox"defaultChecked />
              <label htmlFor="service1" className="vaduaplu-label" align = "center">
              Our Highlights
              </label>
              <ul className="vaduaplu-list">
  <li className="vaduaplu-list-item">
  <br />
  <br />
    👗 <strong>Nena’s Mexican Clothing and Footwear</strong> – Beautiful, traditional Mexican fashion straight from the heart of San Antonio.
  </li>
  <br />
  <br />
  <li className="vaduaplu-list-item">
    🌺 Explore a vibrant collection of handmade clothing, embroidered blouses, cultural dresses, footwear, and accessories that celebrate Mexican heritage and style.
  </li>
  <br />
  <br />
  <li className="vaduaplu-list-item">
    📍 Visit us at <strong>Trader’s Village San Antonio</strong><br />
    9333 SW Loop 410, San Antonio, TX 78242<br />
    Spaces: #267, #269, #368, #366<br />
    🕙 Open Weekends: 10AM – 6PM
  </li>
  <br />
  <br />
  <li className="vaduaplu-list-item">
    📞 Phone: <a className="a-links-vaduaplu" href="tel:+15629726502">(562) 972-6502</a><br />
    ✉️ Email: <a className="a-links-vaduaplu"href="mailto:griselda_moma@hotmail.com">griselda_moma@hotmail.com</a>
  </li>
  <br />
  <br />
  <li className="vaduaplu-list-item">
    📸 Follow us on Instagram: <a className="a-links-vaduaplu"href="https://instagram.com/nenas_satx" target="_blank" rel="noopener noreferrer">@nenas_satx</a>
  </li>
</ul>

              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content / Gallery */}
      <main className="vaduaplu-main-content">
        <h2 className="vaduaplu-heading">Gallery</h2>
        <div className="vaduaplu-gallery">
          {[...Array(6)].map((_, index) => (
            <img
              key={index}
              src={images[index % images.length]}
              alt={`Work Sample ${index + 1}`}
              className="vaduaplu-image"
            />
          ))}
        </div>
      </main>

      {/* Extra Text Section */}
      <div className="vaduaplu-extra-text">
        <p className="vaduaplu-extra-text-p">
      Nena's Mexican Clothing and Footwear
        </p>
      </div>
    </div>
  );
}

export default NenaClothing;
