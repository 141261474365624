import React from 'react';
import { Link } from 'react-router-dom';
import './VisitResturants.css'; // Import the CSS file for styling

// Define your three categories with names, icons, and URLs
const categories = [
 
  { name: 'Murals by S.Knight', icon: '🗡️', url: '/StarrMurals' },
  
];

function Murals() {
   return (
      <div className="categories-container">
      <div className="new-tile-container-new">
      
          {categories.map((category, index) => (
           <div key={index} className="new-tile">
              <Link to={category.url} className="category-link">
              <span className="icon-for-new-tile">{category.icon}</span>
              <span>{category.name}</span>
              </Link>
            </div>
          ))}
        </div>
  
        {/* Slim long tile that navigates back to homepage */}
        <div className="back-home-tile">
                <Link to="/" className="back-home-link">
                  Go Back to Homepage
                </Link>
              </div>
      
      </div>
  
      
    );
}

export default Murals;
