import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './VisitResturants.css';
import CouponBanner from './CouponBanner';


// Define your three categories with names, icons, and URLs
const categories = [
   
  {
    name: "Visiting Angels San Antonio",
    icon: "👼",
    specialties: ["In-Home Care", "Companion Care", "Personal Care"],
    address: "San Antonio, TX",
    phone: "(210) 826-9200",
    url: "https://www.visitingangels.com/sa/home",
    external: true
  },
  {
    name: "Amada Senior Care San Antonio",
    icon: "🏡",
    specialties: ["In-Home Senior Care", "Companion Care", "Personal Care"],
    address: "San Antonio, TX",
    phone: "(210) 960-4304",
    url: "https://www.amadaseniorcare.com/san-antonio-senior-care/",
    external: true
  },
  {
    name: "Comfort Keepers San Antonio",
    icon: "🤗",
    specialties: ["In-Home Care", "24-Hour Care", "Alzheimer's and Dementia Care"],
    address: "San Antonio, TX",
    phone: "(210) 399-0202",
    url: "https://www.comfortkeepers.com/offices/texas/san-antonio/",
    external: true
  },
  {
    name: "Home Instead San Antonio",
    icon: "🏠",
    specialties: ["Personal Care", "Alzheimer’s Care", "Hospice Support"],
    address: "San Antonio, TX",
    phone: "(210) 614-1132",
    url: "https://www.homeinstead.com/home-care/usa/tx/san-antonio/192/",
    external: true
  },
  {
    name: "Senior Buddies",
    icon: "👴",
    specialties: ["In-Home Care", "24/7 Care", "Dementia Support"],
    address: "San Antonio, TX",
    phone: "(210) 361-6168",
    url: "https://www.senior-buddies.com/",
    external: true
  },
  {
    name: "Right at Home Greater San Antonio",
    icon: "✔️",
    specialties: ["In-Home Care", "Dementia Care", "Companion Care"],
    address: "San Antonio, TX",
    phone: "(210) 829-1033",
    url: "https://www.rightathome.net/san-antonio",
    external: true
  },
  {
    name: "TheKey San Antonio",
    icon: "🔑",
    specialties: ["In-Home Care", "24/7 Care", "Specialized Care"],
    address: "San Antonio, TX",
    phone: "(210) 239-9444",
    url: "https://thekey.com/locations/texas/san-antonio",
    external: true
  },
  {
    name: "Always Best Care Senior Services",
    icon: "🌟",
    specialties: ["In-Home Care", "Senior Community Referral", "Special Care Services"],
    address: "San Antonio, TX",
    phone: "(210) 772-2277",
    url: "https://alwaysbestcare.com/san-antonio/",
    external: true
  },
  {
    name: "Senior Helpers Greater San Antonio",
    icon: "🧓",
    specialties: ["In-Home Senior Care", "Alzheimer’s & Dementia Care", "Companion Care"],
    address: "San Antonio, TX",
    phone: "(210) 810-3535",
    url: "https://www.seniorhelpers.com/tx/greater-san-antonio/",
    external: true
  },
  {
    name: "Family Tree Private Care",
    icon: "🌳",
    specialties: ["In-Home Caregiving", "Private Nursing Services"],
    address: "San Antonio, TX",
    phone: "(210) 764-8500",
    url: "https://familytreecares.com/locations/in-home-care-san-antonio-tx/",
    external: true
  },
  {
    name: "Sisters Care In-Home Care at The Village",
    icon: "🏡",
    specialties: ["In-Home Care", "Personal Assistance"],
    address: "San Antonio, TX",
    phone: "(210) 734-1000",
    url: "https://www.thevillageiw.org/in-home-care-services/",
    external: true
  },
  {
    name: "HomeWell Care Services",
    icon: "🤝",
    specialties: ["In-Home Care", "Personal Care", "Companion Care"],
    address: "San Antonio, TX",
    phone: "(210) 375-3180",
    url: "https://homewellcares.com/in-home-care-tx-san-antonio-tx195/",
    external: true
  },
  {
    name: "Cardinal Senior Care",
    icon: "❤️",
    specialties: ["In-Home Care", "Personal Care", "Alzheimer's Care"],
    address: "San Antonio, TX",
    phone: "(210) 361-6168",
    url: "https://cardinalseniorcare.com/",
    external: true
  },
  {
    name: "IJNA Nursing Services",
    icon: "🩺",
    specialties: ["In-Home Nursing", "Personal Care"],
    address: "San Antonio, TX",
    phone: "(210) 590-8499",
    url: "https://www.ijnanursing.com/",
    external: true
  },
  {
    name: "Premier Assisted Living",
    icon: "🏠",
    specialties: ["Assisted Living", "Personal Care"],
    address: "San Antonio, TX",
    phone: "(210) 684-0202",
    url: "https://www.premierassistedliving.com/",
    external: true
  },
  {
    name: "Safe Haven Provider Services",
    icon: "🛡️",
    specialties: ["In-Home Care", "Personal Assistance"],
    address: "San Antonio, TX",
    phone: "(210) 660-8000",
    url: "https://www.safehavenproviders.com/",
    external: true
  },
  {
    name: "Educational HUGSSS",
    icon: "🎓",
    specialties: ["Senior Education", "Support Services"],
    address: "San Antonio, TX",
    phone: "(210) 555-1234",
    url: "https://www.educationalhugsss.com/",
    external: true
  },
];

function ElderlyCare() {
  const [selected, setSelected] = useState(null);
  const navigate = useNavigate();

  const handleTileClick = (category) => {
    if (category.url?.startsWith('/')) {
      navigate(category.url); // Internal route
    } else {
      setSelected(category); // Show popup for external or missing URL
    }
  };
  

  const closePopup = () => setSelected(null);

  return (
    <div className="categories-container">
      <CouponBanner />
      <div className="new-tile-container-new">
        {categories.map((category, index) => (
          <div
          key={index}
          className={`new-tile ${category.url ? 'tile-clickable' : 'tile-informational'}`}
          onClick={() => handleTileClick(category)}
        >
          <span className="icon-for-new-tile">{category.icon}</span>
          
          <span className="tile-name">
            {category.name}
            {(!category.url || category.url.startsWith('http')) && (
              <span className="info-badge" title="Unverified">ⓘ</span>
            )}
          </span>
        </div>
        
        ))}
      </div>

      <div className="back-home-tile">
        <Link to="/" className="back-home-link">Go Back to Homepage</Link>
      </div>

      {/* Popup Box */}
      {selected && (
  <div className="popup-overlay" onClick={closePopup}>
    <div className="popup-box" onClick={(e) => e.stopPropagation()}>
      <h3>{selected.name}</h3>
      <p><strong>Address:</strong> {selected.address}</p>
      <p><strong>Phone:</strong> {selected.phone}</p>

      {selected.url && selected.url.startsWith('http') && (
  <p>
    <a
      href={selected.url}
      target="_blank"
      rel="noopener noreferrer"
      className="business-website-link"
    >
      {selected.url
        .replace(/^https?:\/\//, '') 
        .replace(/\/$/, '')}         
    </a>
  </p>
)}


      <button onClick={closePopup}>Close</button>

      {selected.name  && (
        <div className="extra-links">
          <Link to="/request-form" className="popup-link">Claim your business</Link> <span> / </span>
          <Link to="/request-form" className="popup-link">Opt out</Link> <span> / </span>
          <Link to="/request-form" className="popup-link">Suggest update</Link>
        </div>
      )}
      <hr className="popup-divider" />

<p className="disclaimer-text">
Disclaimer: This listing has not been claimed or verified by the business owner.
Information provided is based on publicly available sources.
</p>

    </div>
  </div>
)}

    </div>
  );
}


export default ElderlyCare;
