

export function filter(searchString, searchables) {
  // Convert searchString to lowercase to ensure case-insensitive filtering
  const normalizedSearchString = searchString.toLowerCase();

  // Filter the list based on whether the item starts with the searchString
  const filteredResults = searchables.filter(item => item.name.toLowerCase().startsWith(normalizedSearchString));
  console.log('console.log(filteredResults);');
  console.log(filteredResults);
  return filteredResults;
}

// data.js
export function getData(language) {
    const dataMap = {
      english: {
        frequentlyVisitedCommunities: [
          { people: 'American', flag: 'us', url: '/american' },
          { people: 'African', flag: 'af', url: '/african' },
          { people: 'Mexican', flag: 'mx', url: '/mexican' },
         
          { people: 'Middle Eastern', flag: 'ae', url: '/MiddleEastern' },
        ],
         communities : [
          { people: 'American', flag: 'us', url: '/american' },
          { people: 'Algerian', flag: 'dz' },
          { people: 'African', flag: 'af', url: '/african' },
          { people: 'Argentinian', flag: 'ar' },
          { people: 'Australian', flag: 'au' },
          { people: 'British', flag: 'gb' },
          { people: 'Cameroonian', flag: 'cm' },  // 🇨🇲 Cameroon
          { people: 'Chinese', flag: 'cn' },
          { people: 'Ethiopian', flag: 'et',url: '/ethiopian' },
          { people: 'Eritrean', flag: 'er', url: '/eritrean' },
          { people: 'European', flag: 'eu', url: '/european' },
          { people: 'Hawaiian', flag: 'us' },
          { people: 'Indian', flag: 'in' },
          { people: 'Mexican', flag: 'mx', url: '/mexican' },
          { people: 'Middle Eastern', flag: 'ae', url: '/MiddleEastern' },

          { people: 'Jamaican', flag: 'jm' },
          { people: 'Lebanese', flag: 'lb' },
          { people: 'Nepali', flag: 'np' },
          { people: 'Nigerian', flag: 'ng', url: '/Nigerian' },
          { people: 'Senegalese', flag: 'sn' }, 
          { people: 'Syrian', flag: 'sy', url: '/syrian' },  // 🇸🇳 Senegal
          { people: 'Thai', flag: 'th' },
          { people: 'Trinidadian', flag: 'tt' },
          { people: 'Turkish', flag: 'tr' },
          { people: 'Vietnamese', flag: 'vn' },
          
          

          

        ],
        categories: [
          { name: 'Grocery Stores', icon: '🛒', url: '/visitgroceries' },
          { name: 'Restaurants', icon: '🍴', url: '/visitresturants' },
          { name: 'Food Truck', icon: '🚚', url: '/visitfoodtrucks' },
          { name: 'Jewelry', icon: '💎', url: '/Jewelry' },
          { name: 'Electrical', icon: '💡', url: '/Electrical' },
          { name: 'ChildCare', icon: '👶', url: '/ChildCare' },
          {name: 'ElderlyCare', icon: '🧓', url: '/ElderlyCare' },
          { name: 'Wreaths', icon: '🎀', url: '/Wreaths' },
          { name: "Flea Market Place", icon: '🛍️', url: '/fleamarketplace' },
          { name: 'Vegan', icon: '🥗', url: '/visitveganplaces' },
          { name: 'Plumbing', icon: '🚰', url: '/Plumbing' },
          { name: 'Realtors', icon: '🏘️', url: '/realtor' },
          { name: 'Photography', icon: '📷',url:'/photography'  },
          { name: 'Gardening', icon: '🌱' ,url:'/Gardening'},
          { name: 'Cleaning services', icon: '🧹', url: '/cleaning-services' },
          { name: 'IT Services', icon: '💻', url: '/itservices' },
         { name: 'Clothing & Footwear', icon: '👗', url: '/Clothing' },
          { name: 'Kosher', icon: '🕍', url: '/visitkosherplaces' },
          { name: 'Catering', icon: '🍲', url: '/catering' },
          { name: 'Murals', icon: '🎨', url: '/murals' },
          { name: 'Toys', icon: '🌸', url: '/Toys' },
        ],

        service :[
          { name: 'Boutique', icon: '👜' },
             
             { name: 'Home Help', icon: '🏡' },
            
             { name: 'Transportation', icon: '🚗' },
             { name: 'Legal', icon: '⚖️' },
             { name: 'Education', icon: '📚' },
             { name: 'Health', icon: '⚕️' },
             { name: 'Student Services', icon: '🎓' },
             { name: 'Beauty', icon: '💄' },
             { name: 'Tailor', icon: '👗' },
             { name: 'Venues', icon: '🏢' },
           
             { name: 'Event Management', icon: '🎉' },
           
           
         
          { name: 'Pet Care', icon: '🐾' },
        

          
          
          { name: 'Marketing', icon: '📈' },
          { name: 'Design', icon: '🎨' },
          { name: 'Legal Advice', icon: '⚖️' },
          { name: 'Fitness Training', icon: '🏋️' },
          { name: 'Repair Services', icon: '🔧' },
          { name: 'Transportation', icon: '🚛' },
          { name: 'Tutoring', icon: '📖' },
          { name: 'Consulting', icon: '🤝' },
          { name: 'Personal Shopping', icon: '🛍️' },
          { name: 'Accommodation', icon: '🏠' },
          { name: 'Construction', icon: '🔧' },
          { name: 'Storage', icon: '📦' },
          { name: 'Translation', icon: '🌐' },
          { name: 'Worship Places', icon: '⛪' },
          { name: 'Sports', icon: '🏅' },
          { name: 'Entertainment', icon: '🎭' },
          { name: 'Employment', icon: '💼' },
          { name: 'Financial', icon: '💰' },
          
  
         
        
      
        ],
      },
      ethiopian: {
        frequentlyVisitedCommunities: [
          { people: 'ሜክሲካን', flag: 'mx', url: '/mexican' },
          { people: 'አሜሪካዊ', flag: 'us', url: '/american' },
          { people: 'አፍሪካዊ', flag: 'af', url: '/african' },
        ],
        communities: [
          { people: 'አሜሪካዊ', flag: 'us', url: '/american' },
          { people: 'አልጀሪኛ', flag: 'dz' },
          { people: 'አፍሪካዊ', flag: 'af', url: '/african' },
          { people: 'አርጀንቲኛ', flag: 'ar' },
          { people: 'አውስትራሊያኛ', flag: 'au' },
          { people: 'ብሪቲሽ', flag: 'gb' },
          { people: 'ቻይንኛ', flag: 'cn' },
          { people: 'ኢትዮጵያዊ', flag: 'et' },
          { people: 'ሃዋያን', flag: 'us' },
          { people: 'ህንዳዊ', flag: 'in' },
          { people: 'ሜክሲካን', flag: 'mx', url: '/mexican' },
          { people: 'ጃማይካዊ', flag: 'jm' },
          { people: 'ሊባንኛ', flag: 'lb' },
          { people: 'ኔፓሊዛዊ', flag: 'np' },
          { people: 'ናይጄሪያዊ', flag: 'ng', url: '/Nigerian' },
          { people: 'ታይ', flag: 'th' },

        ],
        categories :
        [
          
            { name: 'የምግብ ሱቆች', icon: '🛒', url: '/visitgroceries' },
            { name: 'ምግብ ቤቶች', icon: '🍴', url: '/visitresturants' },
            { name: 'የምግብ ትራክ', icon: '🚚', url: '/visitfoodtrucks' },
            { name: 'ኮሽር', icon: '🕍', url: '/visitkosherplaces' },
            { name: 'ቬጋን', icon: '🥗', url: '/visitveganplaces' },
            { name: 'ገበሬዎች ገበያ', icon: '🌽', url: '/farmersmarket' },
            { name: 'ፍሊ ማርኬት ቦታ', icon: '🛍️', url: '/fleamarketplace' },
            { name: 'የንጽህና አገልግሎት', icon: '🧹', url: '/cleaning-services' },
            { name: 'የኢቲ አገልግሎት', icon: '💻', url: '/itservices' },
            { name: 'ምግብ አቅርቦት', icon: '🍲', url: '/catering' },
  { name: 'ቅጥሮች', icon: '🎨', url: '/murals' },
  { name: 'የቤት ንግዶች', icon: '🏘️', url: '/realtors' },
  { name: 'ፎቶግራፍ', icon: '📷', url: '/photography' },
  { name: 'አትክልት እርሻ', icon: '🌱', url: '/gardening' },
  { name: 'አበባ ዘርግቦች', icon: '🌸', url: '/wreaths' },

          
          
        ],

        service : [
          { name: 'ቡቲክ', icon: '👜' },
          { name: 'የቤት እርዳታ', icon: '🏡' },
          { name: 'የንብረት ወኪል', icon: '🏘️' },
          { name: 'ትራንስፖርት', icon: '🚗' },
          { name: 'ሕጋዊ', icon: '⚖️' },
          { name: 'ትምህርት', icon: '📚' },
          { name: 'ጤና', icon: '⚕️' },
          { name: 'የተማሪ አገልግሎት', icon: '🎓' },
          { name: 'ውበት', icon: '💄' },
          { name: 'ታይልር', icon: '👗' },
          { name: 'ቦታዎች', icon: '🏢' },
          { name: 'ፎቶግራፊ', icon: '📷' },
          { name: 'የክብረት አስተዳደር', icon: '🎉' },
          { name: 'ጌጣጌጥ', icon: '💍' },
          { name: 'የምግብ መኪና', icon: '🚚' },
          { name: 'ምግብ ቤቶች', icon: '🍴' },
          { name: 'የአትክልት እንቅስቃሴ', icon: '🌱' },
          { name: 'ምግብ አቀራረብ', icon: '🍲' },
          { name: 'የእንስሳት እንክብካቤ', icon: '🐾' },
          { name: 'የሕፃናት እንክብካቤ', icon: '👶' },
          { name: 'የሽማግሌ እንክብካቤ', icon: '🧓' },
          { name: 'ገበያ ማስተዋወቂያ', icon: '📈' },
          { name: 'ዲዛይን', icon: '🎨' },
          { name: 'ሕጋዊ ምክር', icon: '⚖️' },
          { name: 'አካል ልማት ስልጠና', icon: '🏋️' },
          { name: 'አስተካክል አገልግሎት', icon: '🔧' },
          { name: 'ትራንስፖርት (ጭነት)', icon: '🚛' },
          { name: 'ትምህርት አስተማሪ', icon: '📖' },
          { name: 'ምክር አገልግሎት', icon: '🤝' },
          { name: 'የግል ግዢ', icon: '🛍️' },
          { name: 'መኖሪያ', icon: '🏠' },
          { name: 'ስራ ሥራ', icon: '🔧' },
          { name: 'መዝገብ', icon: '📦' },
          { name: 'ትርጉም', icon: '🌐' },
          { name: 'የእምነት ቦታዎች', icon: '⛪' },
          { name: 'ስፖርት', icon: '🏅' },
          { name: 'መዝናኛ', icon: '🎭' },
          { name: 'ሥራ እድል', icon: '💼' },
          { name: 'የገንዘብ አገልግሎት', icon: '💰' },
          { name: 'ፕላምቢንግ', icon: '🚰' },
          { name: 'ኤሌክትሪክ አገልግሎት', icon: '💡' },
        ]
,        
        
      },
        arabic: {
          frequentlyVisitedCommunities: [
            { people: 'مكسيكي', flag: 'mx', url: '/mexican' },
            { people: 'أمريكي', flag: 'us', url: '/american' },
            { people: 'أفريقي', flag: 'af', url: '/african' },
          ],
          communities: [
            { people: 'أمريكي', flag: 'us', url: '/american' },
            { people: 'جزائري', flag: 'dz' },
            { people: 'أفريقي', flag: 'af', url: '/african' },
            { people: 'أرجنتيني', flag: 'ar' },
            { people: 'أسترالي', flag: 'au' },
            { people: 'بريطاني', flag: 'gb' },
            { people: 'صيني', flag: 'cn' },
            { people: 'إثيوبي', flag: 'et', url: '/ethiopian' },
            { people: 'إريتري', flag: 'er', url: '/eritrean' },
            { people: 'هاوايي', flag: 'us' },
            { people: 'هندي', flag: 'in' },
            { people: 'مكسيكي', flag: 'mx', url: '/mexican' },
            { people: 'جامايكي', flag: 'jm' },
            { people: 'لبناني', flag: 'lb' },
            { people: 'نيبالي', flag: 'np' },
            { people: 'نيجيري', flag: 'ng', url: '/Nigerian' },
            { people: 'تايلاندي', flag: 'th' },
          ],

          categories:[
            { name: 'محلات البقالة', icon: '🛒', url: '/visitgroceries' },
            { name: 'مطاعم', icon: '🍴', url: '/visitresturants' },
            { name: 'شاحنة طعام', icon: '🚚', url: '/visitfoodtrucks' },
            { name: 'كوشر', icon: '🕍', url: '/visitkosherplaces' },
            { name: 'نباتي', icon: '🥗', url: '/visitveganplaces' },
            { name: 'سوق المزارعين', icon: '🌽', url: '/farmersmarket' },
            { name: 'سوق البرغش', icon: '🛍️', url: '/fleamarketplace' },
            { name: 'خدمات التنظيف', icon: '🧹', url: '/cleaning-services' },
            { name: 'خدمات تكنولوجيا المعلومات', icon: '💻', url: '/itservices' },
            { name: 'تقديم الطعام', icon: '🍲', url: '/catering' },
  { name: 'جداريات', icon: '🎨', url: '/murals' },
  { name: 'وكلاء العقارات', icon: '🏘️', url: '/realtors' },
  { name: 'تصوير فوتوغرافي', icon: '📷', url: '/photography' },
  { name: 'البستنة', icon: '🌱', url: '/gardening' },
  { name: 'أكاليل زهور', icon: '🌸', url: '/wreaths' },
          ],
          
          service :[
            { name: 'بوتيك', icon: '👜' },
            { name: 'مساعدة منزلية', icon: '🏡' },
            { name: 'وكلاء عقارات', icon: '🏘️' },
            { name: 'النقل', icon: '🚗' },
            { name: 'قانوني', icon: '⚖️' },
            { name: 'تعليم', icon: '📚' },
            { name: 'صحة', icon: '⚕️' },
            { name: 'خدمات الطلاب', icon: '🎓' },
            { name: 'تجميل', icon: '💄' },
            { name: 'خياط', icon: '👗' },
            { name: 'أماكن الفعاليات', icon: '🏢' },
            { name: 'تصوير', icon: '📷' },
            { name: 'إدارة الفعاليات', icon: '🎉' },
            { name: 'مجوهرات', icon: '💍' },
            { name: 'البستنة', icon: '🌱' },
            { name: 'تموين', icon: '🍲' },
            { name: 'رعاية الحيوانات الأليفة', icon: '🐾' },
            { name: 'رعاية الأطفال', icon: '👶' },
            { name: 'رعاية المسنين', icon: '🧓' },
            { name: 'تسويق', icon: '📈' },
            { name: 'تصميم', icon: '🎨' },
            { name: 'استشارات قانونية', icon: '⚖️' },
            { name: 'تدريب اللياقة', icon: '🏋️' },
            { name: 'خدمات الإصلاح', icon: '🔧' },
            { name: 'نقل الشحن', icon: '🚛' },
            { name: 'دروس خصوصية', icon: '📖' },
            { name: 'استشارات', icon: '🤝' },
            { name: 'تسوق شخصي', icon: '🛍️' },
            { name: 'إقامة', icon: '🏠' },
            { name: 'إنشاءات', icon: '🔧' },
            { name: 'تخزين', icon: '📦' },
            { name: 'ترجمة', icon: '🌐' },
            { name: 'أماكن العبادة', icon: '⛪' },
            { name: 'رياضة', icon: '🏅' },
            { name: 'ترفيه', icon: '🎭' },
            { name: 'توظيف', icon: '💼' },
            { name: 'مالي', icon: '💰' },
            { name: 'سباكة', icon: '🚰' },
            { name: 'كهربائي', icon: '💡' },
          ]
,          
          
        },
        chinese: {
          frequentlyVisitedCommunities: [
            { people: '墨西哥人', flag: 'mx', url: '/mexican' },
            { people: '美国人', flag: 'us', url: '/american' },
            { people: '非洲人', flag: 'af', url: '/african' },
          ],
          communities: [
            { people: '美国人', flag: 'us', url: '/american' },
            { people: '阿尔及利亚人', flag: 'dz' },
            { people: '非洲人', flag: 'af', url: '/african' },
            { people: '阿根廷人', flag: 'ar' },
            { people: '澳大利亚人', flag: 'au' },
            { people: '英国人', flag: 'gb' },
            { people: '中国人', flag: 'cn' },
            { people: '埃塞俄比亚人', flag: 'et', url: '/ethiopian' },
    { people: '厄立特里亚人', flag: 'er', url: '/eritrean' },
            { people: '夏威夷人', flag: 'us' },
            { people: '印度人', flag: 'in' },
            { people: '墨西哥人', flag: 'mx', url: '/mexican' },
            { people: '牙买加人', flag: 'jm' },
            { people: '黎巴嫩人', flag: 'lb' },
            { people: '尼泊尔人', flag: 'np' },
            { people: '尼日利亚人', flag: 'ng', url: '/Nigerian' },
            { people: '泰国人', flag: 'th' },
          ],

          categories:[
            { name: '杂货店', icon: '🛒', url: '/visitgroceries' },
            { name: '餐厅', icon: '🍴', url: '/visitresturants' },
            { name: '餐车', icon: '🚚', url: '/visitfoodtrucks' },
            { name: '科舍尔', icon: '🕍', url: '/visitkosherplaces' },
            { name: '纯素', icon: '🥗', url: '/visitveganplaces' },
            { name: '农贸市场', icon: '🌽', url: '/farmersmarket' },
            { name: '跳蚤市场', icon: '🛍️', url: '/fleamarketplace' },
            { name: '清洁服务', icon: '🧹', url: '/cleaning-services' },
            { name: 'IT服务', icon: '💻', url: '/itservices' },
            { name: '餐饮服务', icon: '🍲', url: '/catering' },
  { name: '壁画艺术', icon: '🎨', url: '/murals' },
  { name: '房地产经纪', icon: '🏘️', url: '/realtors' },
  { name: '摄影', icon: '📷', url: '/photography' },
  { name: '园艺', icon: '🌱', url: '/gardening' },
  { name: '花环', icon: '🌸', url: '/wreaths' },
          ]
          ,

          service : [
            { name: '精品店', icon: '👜' },
            { name: '家政服务', icon: '🏡' },
            { name: '房产经纪人', icon: '🏘️' },
            { name: '交通运输', icon: '🚗' },
            { name: '法律服务', icon: '⚖️' },
            { name: '教育', icon: '📚' },
            { name: '健康', icon: '⚕️' },
            { name: '学生服务', icon: '🎓' },
            { name: '美容', icon: '💄' },
            { name: '裁缝', icon: '👗' },
            { name: '场馆', icon: '🏢' },
            { name: '摄影', icon: '📷' },
            { name: '活动管理', icon: '🎉' },
            { name: '珠宝', icon: '💍' },
            { name: '货车运输', icon: '🚚' },
            { name: '餐厅', icon: '🍴' },
            { name: '园艺', icon: '🌱' },
            { name: '餐饮服务', icon: '🍲' },
            { name: '宠物护理', icon: '🐾' },
            { name: '儿童看护', icon: '👶' },
            { name: '老人护理', icon: '🧓' },
            { name: '营销', icon: '📈' },
            { name: '设计', icon: '🎨' },
            { name: '法律咨询', icon: '⚖️' },
            { name: '健身训练', icon: '🏋️' },
            { name: '维修服务', icon: '🔧' },
            { name: '货物运输', icon: '🚛' },
            { name: '辅导', icon: '📖' },
            { name: '咨询服务', icon: '🤝' },
            { name: '私人购物', icon: '🛍️' },
            { name: '住宿', icon: '🏠' },
            { name: '建筑', icon: '🔧' },
            { name: '仓储', icon: '📦' },
            { name: '翻译', icon: '🌐' },
            { name: '礼拜场所', icon: '⛪' },
            { name: '体育', icon: '🏅' },
            { name: '娱乐', icon: '🎭' },
            { name: '就业', icon: '💼' },
            { name: '金融', icon: '💰' },
            { name: '水暖工程', icon: '🚰' },
            { name: '电工服务', icon: '💡' },
          ]
,          
          
        },

        filipino: {
          frequentlyVisitedCommunities: [
            { people: 'Mexicano', flag: 'mx', url: '/mexican' },
            { people: 'Amerikano', flag: 'us', url: '/american' },
            { people: 'Afrikano', flag: 'af', url: '/african' },
          ],
          communities: [
            { people: 'Amerikano', flag: 'us', url: '/american' },
            { people: 'Algeriano', flag: 'dz' },
            { people: 'Afrikano', flag: 'af', url: '/african' },
            { people: 'Argentino', flag: 'ar' },
            { people: 'Australyano', flag: 'au' },
            { people: 'Britaniko', flag: 'gb' },
            { people: 'Tsino', flag: 'cn' },
            { people: 'Etiyopyano', flag: 'et', url: '/ethiopian' },
            { people: 'Eritreyano', flag: 'er', url: '/eritrean' },
            { people: 'Hawaiiano', flag: 'us' },
            { people: 'Indiano', flag: 'in' },
            { people: 'Mexicano', flag: 'mx' },
            { people: 'Jamaicano', flag: 'jm' },
            { people: 'Lebaniano', flag: 'lb' },
            { people: 'Nepalino', flag: 'np' },
            { people: 'Nigeriyano', flag: 'ng', url: '/Nigerian' },
            { people: 'Taylandes', flag: 'th' },
          ],
          categories:[
            { name: 'Mga Tindahan ng Groseri', icon: '🛒', url: '/visitgroceries' },
            { name: 'Restawran', icon: '🍴', url: '/visitresturants' },
            { name: 'Trak ng Pagkain', icon: '🚚', url: '/visitfoodtrucks' },
            { name: 'Kosher', icon: '🕍', url: '/visitkosherplaces' },
            { name: 'Vegano', icon: '🥗', url: '/visitveganplaces' },
            { name: 'Pamilihang Magsasaka', icon: '🌽', url: '/farmersmarket' },
            { name: 'Flea Market', icon: '🛍️', url: '/fleamarketplace' },
            { name: 'Serbisyo sa Paglilinis', icon: '🧹', url: '/cleaning-services' },
            { name: 'Serbisyo sa IT', icon: '💻', url: '/itservices' },
            { name: 'Catering', icon: '🍲', url: '/catering' },
  { name: 'Mga Mural', icon: '🎨', url: '/murals' },
  { name: 'Mga Realtor', icon: '🏘️', url: '/realtors' },
  { name: 'Potograpiya', icon: '📷', url: '/photography' },
  { name: 'Paghahalaman', icon: '🌱', url: '/gardening' },
  { name: 'Korona ng Bulaklak', icon: '🌸', url: '/wreaths' },
          ],

          service : [
            { name: 'Boutique', icon: '👜' },
            { name: 'Tulong sa Bahay', icon: '🏡' },
            { name: 'Ahente ng Real Estate', icon: '🏘️' },
            { name: 'Transportasyon', icon: '🚗' },
            { name: 'Legal', icon: '⚖️' },
            { name: 'Edukasyon', icon: '📚' },
            { name: 'Kalusugan', icon: '⚕️' },
            { name: 'Serbisyo para sa Estudyante', icon: '🎓' },
            { name: 'Ganda', icon: '💄' },
            { name: 'Mananahi', icon: '👗' },
            { name: 'Pook ng Kaganapan', icon: '🏢' },
            { name: 'Potograpiya', icon: '📷' },
            { name: 'Pamamahala ng Kaganapan', icon: '🎉' },
            { name: 'Alahas', icon: '💍' },
            { name: 'Transportasyon (Pangkarga)', icon: '🚚' },
            { name: 'Restawran', icon: '🍴' },
            { name: 'Paghahalaman', icon: '🌱' },
            { name: 'Catering', icon: '🍲' },
            { name: 'Pag-aalaga ng Alagang Hayop', icon: '🐾' },
            { name: 'Pag-aalaga sa Bata', icon: '👶' },
            { name: 'Pag-aalaga sa Matatanda', icon: '🧓' },
            { name: 'Pagmemerkado', icon: '📈' },
            { name: 'Disenyo', icon: '🎨' },
            { name: 'Legal na Payo', icon: '⚖️' },
            { name: 'Pagsasanay sa Pag-eehersisyo', icon: '🏋️' },
            { name: 'Serbisyong Pagkukumpuni', icon: '🔧' },
            { name: 'Transportasyong Pangkarga', icon: '🚛' },
            { name: 'Pagtuturo', icon: '📖' },
            { name: 'Konsultasyon', icon: '🤝' },
            { name: 'Personal na Pamimili', icon: '🛍️' },
            { name: 'Tirahan', icon: '🏠' },
            { name: 'Konstruksyon', icon: '🔧' },
            { name: 'Imbakan', icon: '📦' },
            { name: 'Pagsasalin', icon: '🌐' },
            { name: 'Lugar ng Pagsamba', icon: '⛪' },
            { name: 'Palakasan', icon: '🏅' },
            { name: 'Aliwan', icon: '🎭' },
            { name: 'Trabaho', icon: '💼' },
            { name: 'Pananalapi', icon: '💰' },
            { name: 'Tubero', icon: '🚰' },
            { name: 'Elektrikal', icon: '💡' },
          ]
,          
          
        },
        




        spanish: {
          frequentlyVisitedCommunities: [
            { people: 'Mexicano', flag: 'mx', url: '/mexican' },
            { people: 'Americano', flag: 'us', url: '/american' },
            { people: 'Africano', flag: 'af', url: '/african' },
          ],
          communities: [
            { people: 'Americano', flag: 'us', url: '/american' },
            { people: 'Argelino', flag: 'dz' },
            { people: 'Africano', flag: 'af', url: '/african' },
            { people: 'Argentino', flag: 'ar' },
            { people: 'Australiano', flag: 'au' },
            { people: 'Británico', flag: 'gb' },
            { people: 'Chino', flag: 'cn' },
            { people: 'Etíope', flag: 'et', url: '/ethiopian' },
    { people: 'Eritreo', flag: 'er', url: '/eritrean' },
            { people: 'Hawaiano', flag: 'us' },
            { people: 'Indio', flag: 'in' },
            { people: 'Mexicano', flag: 'mx', url: '/mexican' },
            { people: 'Jamaicano', flag: 'jm' },
            { people: 'Libanés', flag: 'lb' },
            { people: 'Nepalí', flag: 'np' },
            { people: 'Nigeriano', flag: 'ng' , url: '/Nigerian'},
            { people: 'Tailandés', flag: 'th' },
          ],

          categories:[
            { name: 'Tiendas de comestibles', icon: '🛒', url: '/visitgroceries' },
            { name: 'Restaurantes', icon: '🍴', url: '/visitresturants' },
            { name: 'Camión de comida', icon: '🚚', url: '/visitfoodtrucks' },
            { name: 'Kosher', icon: '🕍', url: '/visitkosherplaces' },
            { name: 'Vegano', icon: '🥗', url: '/visitveganplaces' },
            { name: 'Mercado de agricultores', icon: '🌽', url: '/farmersmarket' },
            { name: 'Mercado de pulgas', icon: '🛍️', url: '/fleamarketplace' },
            { name: 'Servicios de limpieza', icon: '🧹', url: '/cleaning-services' },
            { name: 'Servicios de TI', icon: '💻', url: '/itservices' },
            { name: 'Catering', icon: '🍲', url: '/catering' },
  { name: 'Murales', icon: '🎨', url: '/murals' },
  { name: 'Agentes inmobiliarios', icon: '🏘️', url: '/realtors' },
  { name: 'Fotografía', icon: '📷', url: '/photography' },
  { name: 'Jardinería', icon: '🌱', url: '/gardening' },
  { name: 'Coronas florales', icon: '🌸', url: '/wreaths' },
          ]
          ,

          service : [
            { name: 'Boutique', icon: '👜' },
            { name: 'Ayuda a Domicilio', icon: '🏡' },
            { name: 'Agentes Inmobiliarios', icon: '🏘️' },
            { name: 'Transporte', icon: '🚗' },
            { name: 'Legal', icon: '⚖️' },
            { name: 'Educación', icon: '📚' },
            { name: 'Salud', icon: '⚕️' },
            { name: 'Servicios para Estudiantes', icon: '🎓' },
            { name: 'Belleza', icon: '💄' },
            { name: 'Sastre', icon: '👗' },
            { name: 'Lugares para Eventos', icon: '🏢' },
            { name: 'Fotografía', icon: '📷' },
            { name: 'Gestión de Eventos', icon: '🎉' },
            { name: 'Joyería', icon: '💍' },
            { name: 'Jardinería', icon: '🌱' },
            { name: 'Catering', icon: '🍲' },
            { name: 'Cuidado de Mascotas', icon: '🐾' },
            { name: 'Cuidado Infantil', icon: '👶' },
            { name: 'Cuidado de Ancianos', icon: '🧓' },
            { name: 'Marketing', icon: '📈' },
            { name: 'Diseño', icon: '🎨' },
            { name: 'Asesoría Legal', icon: '⚖️' },
            { name: 'Entrenamiento Físico', icon: '🏋️' },
            { name: 'Servicios de Reparación', icon: '🔧' },
            { name: 'Transporte de Carga', icon: '🚛' },
            { name: 'Tutoría', icon: '📖' },
            { name: 'Consultoría', icon: '🤝' },
            { name: 'Compras Personales', icon: '🛍️' },
            { name: 'Alojamiento', icon: '🏠' },
            { name: 'Construcción', icon: '🔧' },
            { name: 'Almacenamiento', icon: '📦' },
            { name: 'Traducción', icon: '🌐' },
            { name: 'Lugares de Culto', icon: '⛪' },
            { name: 'Deportes', icon: '🏅' },
            { name: 'Entretenimiento', icon: '🎭' },
            { name: 'Empleo', icon: '💼' },
            { name: 'Servicios Financieros', icon: '💰' },
            { name: 'Plomería', icon: '🚰' },
            { name: 'Servicios Eléctricos', icon: '💡' },
          ]
,          
          
        },

        gujarati: {
          frequentlyVisitedCommunities: [
            { people: 'મેક્સિકન', flag: 'mx', url: '/mexican' },
            { people: 'અમેરિકન', flag: 'us', url: '/american' },
            { people: 'આફ્રિકન', flag: 'af', url: '/african' },
          ],
          communities: [
            { people: 'અમેરિકન', flag: 'us', url: '/american' },
            { people: 'અલ્જેરિયન', flag: 'dz' },
            { people: 'આફ્રિકન', flag: 'af', url: '/african' },
            { people: 'આર્જેન્ટિનિયન', flag: 'ar' },
            { people: 'ઔસ્ટ્રેલિયન', flag: 'au' },
            { people: 'બ્રિટિશ', flag: 'gb' },
            { people: 'ચીની', flag: 'cn' },
            { people: 'ઇથિયોપીયન', flag: 'et', url: '/ethiopian' },
            { people: 'એરિટ્રિયન', flag: 'er', url: '/eritrean' },
            { people: 'હવાઈઅન', flag: 'us' },
            { people: 'ભારતીય', flag: 'in' },
            { people: 'મેક્સિકન', flag: 'mx', url: '/mexican' },
            { people: 'જમૈકન', flag: 'jm' },
            { people: 'લેબેનિઝ', flag: 'lb' },
            { people: 'નેપાળી', flag: 'np' },
            { people: 'નાઈજરિયન', flag: 'ng' },
            { people: 'થાઈ', flag: 'th' },
          ],
          categories: [
            { name: 'કિરાણાની દુકાનો', icon: '🛒', url: '/visitgroceries' },
            { name: 'રેસ્ટોરન્ટ્સ', icon: '🍴', url: '/visitresturants' },
            { name: 'ખાદ્ય ટ્રક', icon: '🚚', url: '/visitfoodtrucks' },
            { name: 'કોશેર', icon: '🕍', url: '/visitkosherplaces' },
            { name: 'વેગન', icon: '🥗', url: '/visitveganplaces' },
            { name: 'ખેડૂત બજાર', icon: '🌽', url: '/farmersmarket' },
            { name: 'ફ્લિયા માર્કેટ પ્લેસ', icon: '🛍️', url: '/fleamarketplace' },
            { name: 'સફાઈ સેવાઓ', icon: '🧹', url: '/cleaning-services' },
            { name: 'આઈટી સેવાઓ', icon: '💻', url: '/itservices' },
            { name: 'કેટરિંગ', icon: '🍲', url: '/catering' },
  { name: 'મ્યુરલ્સ', icon: '🎨', url: '/murals' },
  { name: 'રિયલ્ટર્સ', icon: '🏘️', url: '/realtors' },
  { name: 'ફોટોગ્રાફી', icon: '📷', url: '/photography' },
  { name: 'બાગવાની', icon: '🌱', url: '/gardening' },
  { name: 'વ્રીથ્સ', icon: '🌸', url: '/wreaths' },

          ],
          service: [
            { name: 'બ્યુટિક', icon: '👜' },
            { name: 'ઘર મદદ', icon: '🏡' },
            { name: 'રિયલ્ટર', icon: '🏘️' },
            { name: 'પરિવહન', icon: '🚗' },
            { name: 'કાનૂની', icon: '⚖️' },
            { name: 'શિક્ષણ', icon: '📚' },
            { name: 'આરೋಗ್ಯ', icon: '⚕️' },
            { name: 'વિદ્યાર્થી સેવાઓ', icon: '🎓' },
            { name: 'સૌંદર્ય', icon: '💄' },
            { name: 'દર્જી', icon: '👗' },
            { name: 'સ્થાનો', icon: '🏢' },
            { name: 'ફોટોગ્રાફી', icon: '📷' },
            { name: 'ઇવેન્ટ મેનેજમેન્ટ', icon: '🎉' },
            { name: 'જ્વેલરી', icon: '💍' },
            { name: 'બાગબાની', icon: '🌱' },
            { name: 'કેટરિંગ', icon: '🍲' },
            { name: 'પાલતુ પશુ સંભાળ', icon: '🐾' },
            { name: 'બાળ સંભાળ', icon: '👶' },
            { name: 'વૃદ્ધ સંભાળ', icon: '🧓' },
            { name: 'માર્કેટિંગ', icon: '📈' },
            { name: 'ડિઝાઇન', icon: '🎨' },
            { name: 'કાનૂની સલાહ', icon: '⚖️' },
            { name: 'ફિટનેસ તાલીમ', icon: '🏋️' },
            { name: 'રિપેર સેવાઓ', icon: '🔧' },
            { name: 'પરિવહન', icon: '🚛' }, // Same word, different icon
            { name: 'ટ્યુશન', icon: '📖' },
            { name: 'કન્સલ્ટિંગ', icon: '🤝' },
            { name: 'વ્યક્તિગત ખરીદી', icon: '🛍️' },
            { name: 'રહેવાની વ્યવસ્થા', icon: '🏠' },
            { name: 'બાંધકામ', icon: '🔧' },
            { name: 'સ્ટોરેજ', icon: '📦' },
            { name: 'ભાષાંતર', icon: '🌐' },
            { name: 'ઉપાસના સ્થળ', icon: '⛪' },
            { name: 'ખેલકૂદ', icon: '🏅' },
            { name: 'મનોરંજન', icon: '🎭' },
            { name: 'રોજગાર', icon: '💼' },
            { name: 'નાણાકીય', icon: '💰' },
            { name: 'પ્લમ્બિંગ', icon: '🚰' },
            { name: 'ઇલેક્ટ્રિકલ', icon: '💡' },
          ],
        },
        german: {
          frequentlyVisitedCommunities: [
            { people: 'Mexikanisch', flag: 'mx', url: '/mexican' },
            { people: 'Amerikanisch', flag: 'us', url: '/american' },
            { people: 'Afrikanisch', flag: 'af', url: '/african' },
          ],
          communities: [
            { people: 'Amerikanisch', flag: 'us', url: '/american' },
            { people: 'Algerisch', flag: 'dz' },
            { people: 'Afrikanisch', flag: 'af', url: '/african' },
            { people: 'Argentinisch', flag: 'ar' },
            { people: 'Australisch', flag: 'au' },
            { people: 'Britisch', flag: 'gb' },
            { people: 'Chinesisch', flag: 'cn' },
            { people: 'Äthiopisch', flag: 'et', url: '/ethiopian' },
            { people: 'Eritreisch', flag: 'er', url: '/eritrean' },
            { people: 'Hawaiianisch', flag: 'us' },
            { people: 'Indisch', flag: 'in' },
            { people: 'Mexikanisch', flag: 'mx', url: '/mexican' },
            { people: 'Jamaikanisch', flag: 'jm' },
            { people: 'Libanesisch', flag: 'lb' },
            { people: 'Nepalesisch', flag: 'np' },
            { people: 'Nigerianisch', flag: 'ng' , url: '/Nigerian'},
            { people: 'Thailändisch', flag: 'th' },
          ],
          categories: [
            { name: 'Lebensmittelgeschäfte', icon: '🛒', url: '/visitgroceries' },
            { name: 'Restaurants', icon: '🍴', url: '/visitresturants' },
            { name: 'Food-Truck', icon: '🚚', url: '/visitfoodtrucks' },
            { name: 'Koscher', icon: '🕍', url: '/visitkosherplaces' },
            { name: 'Vegan', icon: '🥗', url: '/visitveganplaces' },
            { name: 'Bauernmarkt', icon: '🌽', url: '/farmersmarket' },
            { name: 'Flohmarkt', icon: '🛍️', url: '/fleamarketplace' },
            { name: 'Reinigungsdienste', icon: '🧹', url: '/cleaning-services' },
            { name: 'IT-Dienstleistungen', icon: '💻', url: '/itservices' },
            { name: 'Catering', icon: '🍲', url: '/catering' },
  { name: 'Wandmalereien', icon: '🎨', url: '/murals' },
  { name: 'Immobilienmakler', icon: '🏘️', url: '/realtors' },
  { name: 'Fotografie', icon: '📷', url: '/photography' },
  { name: 'Gartenarbeit', icon: '🌱', url: '/gardening' },
  { name: 'Blumenkränze', icon: '🌸', url: '/wreaths' },
            

          ],
          service: [
            { name: 'Boutique', icon: '👜' },
            { name: 'Haushaltshilfe', icon: '🏡' },
            { name: 'Immobilienmakler', icon: '🏘️' },
            { name: 'Transport', icon: '🚗' },
            { name: 'Rechtlich', icon: '⚖️' },
            { name: 'Bildung', icon: '📚' },
            { name: 'Gesundheit', icon: '⚕️' },
            { name: 'Studentenservice', icon: '🎓' },
            { name: 'Schönheit', icon: '💄' },
            { name: 'Schneider', icon: '👗' },
            { name: 'Veranstaltungsorte', icon: '🏢' },
            { name: 'Fotografie', icon: '📷' },
            { name: 'Eventmanagement', icon: '🎉' },
            { name: 'Schmuck', icon: '💍' },
            { name: 'Gartenarbeit', icon: '🌱' },
            { name: 'Catering', icon: '🍲' },
            { name: 'Haustierbetreuung', icon: '🐾' },
            { name: 'Kinderbetreuung', icon: '👶' },
            { name: 'Altenpflege', icon: '🧓' },
            { name: 'Marketing', icon: '📈' },
            { name: 'Design', icon: '🎨' },
            { name: 'Rechtsberatung', icon: '⚖️' },
            { name: 'Fitnesstraining', icon: '🏋️' },
            { name: 'Reparaturdienste', icon: '🔧' },
            { name: 'Gütertransport', icon: '🚛' }, // Differentiating from normal Transport
            { name: 'Nachhilfe', icon: '📖' },
            { name: 'Beratung', icon: '🤝' },
            { name: 'Persönlicher Einkauf', icon: '🛍️' },
            { name: 'Unterkunft', icon: '🏠' },
            { name: 'Bau', icon: '🔧' },
            { name: 'Lagerung', icon: '📦' },
            { name: 'Übersetzung', icon: '🌐' },
            { name: 'Gotteshäuser', icon: '⛪' },
            { name: 'Sport', icon: '🏅' },
            { name: 'Unterhaltung', icon: '🎭' },
            { name: 'Beschäftigung', icon: '💼' },
            { name: 'Finanziell', icon: '💰' },
            { name: 'Klempnerarbeiten', icon: '🚰' },
            { name: 'Elektrisch', icon: '💡' },
          ],
        },

        korean: {
          frequentlyVisitedCommunities: [
            { people: '멕시코인', flag: 'mx', url: '/mexican' },
            { people: '미국인', flag: 'us', url: '/american' },
            { people: '아프리카인', flag: 'af', url: '/african' },
          ],
          communities: [
            { people: '미국인', flag: 'us', url: '/american' },
            { people: '알제리인', flag: 'dz' },
            { people: '아프리카인', flag: 'af', url: '/african' },
            { people: '아르헨티나인', flag: 'ar' },
            { people: '호주인', flag: 'au' },
            { people: '영국인', flag: 'gb' },
            { people: '중국인', flag: 'cn' },
            { people: '에티오피아인', flag: 'et', url: '/ethiopian' },
            { people: '에리트레아인', flag: 'er', url: '/eritrean' },
            { people: '하와이인', flag: 'us' },
            { people: '인도인', flag: 'in' },
            { people: '멕시코인', flag: 'mx', url: '/mexican' },
            { people: '자메이카인', flag: 'jm' },
            { people: '레바논인', flag: 'lb' },
            { people: '네팔인', flag: 'np' },
            { people: '나이지리아인', flag: 'ng', url: '/Nigerian' },
            { people: '태국인', flag: 'th' },
          ],
          categories: [
            { name: '식료품점', icon: '🛒', url: '/visitgroceries' },
            { name: '레스토랑', icon: '🍴', url: '/visitresturants' },
            { name: '푸드 트럭', icon: '🚚', url: '/visitfoodtrucks' },
            { name: '코셔', icon: '🕍', url: '/visitkosherplaces' },
            { name: '비건', icon: '🥗', url: '/visitveganplaces' },
            { name: '농산물 시장', icon: '🌽', url: '/farmersmarket' },
            { name: '플리 마켓', icon: '🛍️', url: '/fleamarketplace' },
            { name: '청소 서비스', icon: '🧹', url: '/cleaning-services' },
            { name: 'IT 서비스', icon: '💻', url: '/itservices' },
            { name: '케이터링', icon: '🍲', url: '/catering' },
            { name: '벽화', icon: '🎨', url: '/murals' },
            { name: '부동산 중개인', icon: '🏘️', url: '/realtors' },
            { name: '사진 촬영', icon: '📷', url: '/photography' },
            { name: '원예', icon: '🌱', url: '/gardening' },
            { name: '꽃 화환', icon: '🌸', url: '/wreaths' },
          ],
          service: [
            { name: '부티크', icon: '👜' },
            { name: '가정 도우미', icon: '🏡' },
            { name: '부동산 중개인', icon: '🏘️' },
            { name: '운송', icon: '🚗' },
            { name: '법률', icon: '⚖️' },
            { name: '교육', icon: '📚' },
            { name: '건강', icon: '⚕️' },
            { name: '학생 서비스', icon: '🎓' },
            { name: '미용', icon: '💄' },
            { name: '재단사', icon: '👗' },
            { name: '장소', icon: '🏢' },
            { name: '사진 촬영', icon: '📷' },
            { name: '이벤트 관리', icon: '🎉' },
            { name: '보석류', icon: '💍' },
            { name: '원예', icon: '🌱' },
            { name: '케이터링', icon: '🍲' },
            { name: '반려동물 관리', icon: '🐾' },
            { name: '유아 돌봄', icon: '👶' },
            { name: '노인 돌봄', icon: '🧓' },
            { name: '마케팅', icon: '📈' },
            { name: '디자인', icon: '🎨' },
            { name: '법률 상담', icon: '⚖️' },
            { name: '피트니스 훈련', icon: '🏋️' },
            { name: '수리 서비스', icon: '🔧' },
            { name: '운송', icon: '🚛' },
            { name: '과외', icon: '📖' },
            { name: '컨설팅', icon: '🤝' },
            { name: '개인 쇼핑', icon: '🛍️' },
            { name: '숙박', icon: '🏠' },
            { name: '건설', icon: '🔧' },
            { name: '보관', icon: '📦' },
            { name: '번역', icon: '🌐' },
            { name: '예배 장소', icon: '⛪' },
            { name: '스포츠', icon: '🏅' },
            { name: '엔터테인먼트', icon: '🎭' },
            { name: '취업', icon: '💼' },
            { name: '재정', icon: '💰' },
            { name: '배관', icon: '🚰' },
            { name: '전기', icon: '💡' },
          ],
        },
        italian: {
          frequentlyVisitedCommunities: [
            { people: 'Messicano', flag: 'mx', url: '/mexican' },
            { people: 'Americano', flag: 'us', url: '/american' },
            { people: 'Africano', flag: 'af', url: '/african' },
          ],
          communities: [
            { people: 'Americano', flag: 'us', url: '/american' },
            { people: 'Algerino', flag: 'dz' },
            { people: 'Africano', flag: 'af', url: '/african' },
            { people: 'Argentino', flag: 'ar' },
            { people: 'Australiano', flag: 'au' },
            { people: 'Britannico', flag: 'gb' },
            { people: 'Cinese', flag: 'cn' },
            { people: 'Etiopico', flag: 'et', url: '/ethiopian' },
            { people: 'Eritreo', flag: 'er', url: '/eritrean' },
            { people: 'Hawaiano', flag: 'us' },
            { people: 'Indiano', flag: 'in' },
            { people: 'Messicano', flag: 'mx', url: '/mexican' },
            { people: 'Giamaicano', flag: 'jm' },
            { people: 'Libanese', flag: 'lb' },
            { people: 'Nepalese', flag: 'np' },
            { people: 'Nigeriano', flag: 'ng' , url: '/Nigerian'},
            { people: 'Tailandese', flag: 'th' },
          ],
          categories: [
            { name: 'Negozi di alimentari', icon: '🛒', url: '/visitgroceries' },
            { name: 'Ristoranti', icon: '🍴', url: '/visitresturants' },
            { name: 'Food Truck', icon: '🚚', url: '/visitfoodtrucks' },
            { name: 'Kosher', icon: '🕍', url: '/visitkosherplaces' },
            { name: 'Vegano', icon: '🥗', url: '/visitveganplaces' },
            { name: 'Mercato contadino', icon: '🌽', url: '/farmersmarket' },
            { name: 'Mercato delle pulci', icon: '🛍️', url: '/fleamarketplace' },
            { name: 'Servizi di pulizia', icon: '🧹', url: '/cleaning-services' },
            { name: 'Servizi IT', icon: '💻', url: '/itservices' },
            { name: 'Catering', icon: '🍲', url: '/catering' },
  { name: 'Murales', icon: '🎨', url: '/murals' },
  { name: 'Agenti immobiliari', icon: '🏘️', url: '/realtors' },
  { name: 'Fotografia', icon: '📷', url: '/photography' },
  { name: 'Giardinaggio', icon: '🌱', url: '/gardening' },
  { name: 'Ghirlande floreali', icon: '🌸', url: '/wreaths' },
          ],
          service: [
            { name: 'Boutique', icon: '👜' },
            { name: 'Assistenza domestica', icon: '🏡' },
            { name: 'Agenti immobiliari', icon: '🏘️' },
            { name: 'Trasporto', icon: '🚗' },
            { name: 'Legale', icon: '⚖️' },
            { name: 'Istruzione', icon: '📚' },
            { name: 'Salute', icon: '⚕️' },
            { name: 'Servizi per studenti', icon: '🎓' },
            { name: 'Bellezza', icon: '💄' },
            { name: 'Sarto', icon: '👗' },
            { name: 'Locali', icon: '🏢' },
            { name: 'Fotografia', icon: '📷' },
            { name: 'Organizzazione eventi', icon: '🎉' },
            { name: 'Gioielleria', icon: '💍' },
            { name: 'Giardinaggio', icon: '🌱' },
            { name: 'Catering', icon: '🍲' },
            { name: 'Cura degli animali', icon: '🐾' },
            { name: 'Assistenza all’infanzia', icon: '👶' },
            { name: 'Assistenza agli anziani', icon: '🧓' },
            { name: 'Marketing', icon: '📈' },
            { name: 'Design', icon: '🎨' },
            { name: 'Consulenza legale', icon: '⚖️' },
            { name: 'Allenamento fitness', icon: '🏋️' },
            { name: 'Servizi di riparazione', icon: '🔧' },
            { name: 'Trasporto merci', icon: '🚛' },
            { name: 'Lezioni private', icon: '📖' },
            { name: 'Consulenza', icon: '🤝' },
            { name: 'Acquisti personalizzati', icon: '🛍️' },
            { name: 'Alloggio', icon: '🏠' },
            { name: 'Costruzione', icon: '🔧' },
            { name: 'Stoccaggio', icon: '📦' },
            { name: 'Traduzione', icon: '🌐' },
            { name: 'Luoghi di culto', icon: '⛪' },
            { name: 'Sport', icon: '🏅' },
            { name: 'Intrattenimento', icon: '🎭' },
            { name: 'Occupazione', icon: '💼' },
            { name: 'Finanziario', icon: '💰' },
            { name: 'Idraulica', icon: '🚰' },
            { name: 'Elettrico', icon: '💡' },
          ],
        },
                
        japanese: {
          frequentlyVisitedCommunities: [
            { people: 'メキシコ人', flag: 'mx', url: '/mexican' },
            { people: 'アメリカ人', flag: 'us', url: '/american' },
            { people: 'アフリカ人', flag: 'af', url: '/african' },
          ],
          communities: [
            { people: 'アメリカ人', flag: 'us', url: '/american' },
            { people: 'アルジェリア人', flag: 'dz' },
            { people: 'アフリカ人', flag: 'af', url: '/african' },
            { people: 'アルゼンチン人', flag: 'ar' },
            { people: 'オーストラリア人', flag: 'au' },
            { people: 'イギリス人', flag: 'gb' },
            { people: '中国人', flag: 'cn' },
            { people: 'エチオピア人', flag: 'et', url: '/ethiopian' },
            { people: 'エリトリア人', flag: 'er', url: '/eritrean' },
            { people: 'ハワイ人', flag: 'us' },
            { people: 'インド人', flag: 'in' },
            { people: 'メキシコ人', flag: 'mx', url: '/mexican' },
            { people: 'ジャマイカ人', flag: 'jm' },
            { people: 'レバノン人', flag: 'lb' },
            { people: 'ネパール人', flag: 'np' },
            { people: 'ナイジェリア人', flag: 'ng', url: '/Nigerian' },
            { people: 'タイ人', flag: 'th' },
          ],
          categories: [
            { name: '食料品店', icon: '🛒', url: '/visitgroceries' },
            { name: 'レストラン', icon: '🍴', url: '/visitresturants' },
            { name: 'フードトラック', icon: '🚚', url: '/visitfoodtrucks' },
            { name: 'コーシャー', icon: '🕍', url: '/visitkosherplaces' },
            { name: 'ヴィーガン', icon: '🥗', url: '/visitveganplaces' },
            { name: '農家市場', icon: '🌽', url: '/farmersmarket' },
            { name: 'フリーマーケット', icon: '🛍️', url: '/fleamarketplace' },
            { name: '清掃サービス', icon: '🧹', url: '/cleaning-services' },
            { name: 'ITサービス', icon: '💻', url: '/itservices' },
            { name: 'ケータリング', icon: '🍲', url: '/catering' },
  { name: '壁画', icon: '🎨', url: '/murals' },
  { name: '不動産業者', icon: '🏘️', url: '/realtors' },
  { name: '写真撮影', icon: '📷', url: '/photography' },
  { name: 'ガーデニング', icon: '🌱', url: '/gardening' },
  { name: '花輪', icon: '🌸', url: '/wreaths' },
          ],
          service: [
            { name: 'ブティック', icon: '👜' },
            { name: 'ホームヘルプ', icon: '🏡' },
            { name: '不動産仲介', icon: '🏘️' },
            { name: '輸送', icon: '🚗' },
            { name: '法律', icon: '⚖️' },
            { name: '教育', icon: '📚' },
            { name: '健康', icon: '⚕️' },
            { name: '学生サービス', icon: '🎓' },
            { name: '美容', icon: '💄' },
            { name: '仕立て屋', icon: '👗' },
            { name: '会場', icon: '🏢' },
            { name: '写真撮影', icon: '📷' },
            { name: 'イベント運営', icon: '🎉' },
            { name: 'ジュエリー', icon: '💍' },
            { name: 'ガーデニング', icon: '🌱' },
            { name: 'ケータリング', icon: '🍲' },
            { name: 'ペットケア', icon: '🐾' },
            { name: '保育', icon: '👶' },
            { name: '高齢者ケア', icon: '🧓' },
            { name: 'マーケティング', icon: '📈' },
            { name: 'デザイン', icon: '🎨' },
            { name: '法律相談', icon: '⚖️' },
            { name: 'フィットネストレーニング', icon: '🏋️' },
            { name: '修理サービス', icon: '🔧' },
            { name: '輸送', icon: '🚛' },
            { name: '個別指導', icon: '📖' },
            { name: 'コンサルティング', icon: '🤝' },
            { name: 'パーソナルショッピング', icon: '🛍️' },
            { name: '宿泊施設', icon: '🏠' },
            { name: '建設', icon: '🔧' },
            { name: '保管', icon: '📦' },
            { name: '翻訳', icon: '🌐' },
            { name: '礼拝所', icon: '⛪' },
            { name: 'スポーツ', icon: '🏅' },
            { name: 'エンターテインメント', icon: '🎭' },
            { name: '就職', icon: '💼' },
            { name: 'ファイナンス', icon: '💰' },
            { name: '配管', icon: '🚰' },
            { name: '電気工事', icon: '💡' },
          ],
        },
        
        hindi: {
          frequentlyVisitedCommunities: [
            { people: 'मेक्सिकन', flag: 'mx', url: '/mexican' },
            { people: 'अमेरिकन', flag: 'us', url: '/american' },
            { people: 'अफ्रीकी', flag: 'af', url: '/african' },
          ],
          communities: [
            { people: 'अमेरिकन', flag: 'us', url: '/american' },
            { people: 'अल्जीरियन', flag: 'dz' },
            { people: 'अफ्रीकी', flag: 'af', url: '/african' },
            { people: 'अर्जेंटीनी', flag: 'ar' },
            { people: 'ऑस्ट्रेलियाई', flag: 'au' },
            { people: 'ब्रिटिश', flag: 'gb' },
            { people: 'चीनी', flag: 'cn' },
            { people: 'इथियोपियाई', flag: 'et', url: '/ethiopian' },
            { people: 'इरिट्रियन', flag: 'er', url: '/eritrean' },
            { people: 'हवाईयन', flag: 'us' },
            { people: 'भारतीय', flag: 'in' },
            { people: 'मेक्सिकन', flag: 'mx', url: '/mexican' },
            { people: 'जमैका', flag: 'jm' },
            { people: 'लेबनानी', flag: 'lb' },
            { people: 'नेपाली', flag: 'np' },
            { people: 'नाइजीरियाई', flag: 'ng' , url: '/Nigerian'},
            { people: 'थाई', flag: 'th' },
          ],

          categories:[
            { name: 'किराना दुकानें', icon: '🛒', url: '/visitgroceries' },
            { name: 'रेस्टोरेंट', icon: '🍴', url: '/visitresturants' },
            { name: 'फूड ट्रक', icon: '🚚', url: '/visitfoodtrucks' },
            { name: 'कोशेर', icon: '🕍', url: '/visitkosherplaces' },
            { name: 'विगन', icon: '🥗', url: '/visitveganplaces' },
            { name: 'किसान बाजार', icon: '🌽', url: '/farmersmarket' },
            { name: 'फ्ली मार्केट', icon: '🛍️', url: '/fleamarketplace' },
            { name: 'सफाई सेवाएं', icon: '🧹', url: '/cleaning-services' },
            { name: 'आईटी सेवाएं', icon: '💻', url: '/itservices' },
            { name: 'कैटरिंग', icon: '🍲', url: '/catering' },
  { name: 'भित्ति चित्र', icon: '🎨', url: '/murals' },
  { name: 'रियल्टर्स', icon: '🏘️', url: '/realtors' },
  { name: 'फोटोग्राफी', icon: '📷', url: '/photography' },
  { name: 'बागवानी', icon: '🌱', url: '/gardening' },
  { name: 'फूलों की मालाएँ', icon: '🌸', url: '/wreaths' },
          ],
          service :[
            { name: 'बुटीक', icon: '👜' },
            { name: 'गृह सहायता', icon: '🏡' },
            { name: 'रियल्टर', icon: '🏘️' },
            { name: 'परिवहन', icon: '🚗' },
            { name: 'कानूनी', icon: '⚖️' },
            { name: 'शिक्षा', icon: '📚' },
            { name: 'स्वास्थ्य', icon: '⚕️' },
            { name: 'छात्र सेवाएँ', icon: '🎓' },
            { name: 'सौंदर्य', icon: '💄' },
            { name: 'दर्जी', icon: '👗' },
            { name: 'आयोजन स्थल', icon: '🏢' },
            { name: 'फोटोग्राफी', icon: '📷' },
            { name: 'इवेंट प्रबंधन', icon: '🎉' },
            { name: 'आभूषण', icon: '💍' },
            { name: 'बागवानी', icon: '🌱' },
            { name: 'कैटरिंग', icon: '🍲' },
            { name: 'पालतू देखभाल', icon: '🐾' },
            { name: 'बाल देखभाल', icon: '👶' },
            { name: 'बुजुर्ग देखभाल', icon: '🧓' },
            { name: 'विपणन', icon: '📈' },
            { name: 'डिज़ाइन', icon: '🎨' },
            { name: 'कानूनी सलाह', icon: '⚖️' },
            { name: 'फिटनेस प्रशिक्षण', icon: '🏋️' },
            { name: 'मरम्मत सेवाएं', icon: '🔧' },
            { name: 'लोड परिवहन', icon: '🚛' },
            { name: 'ट्यूशन', icon: '📖' },
            { name: 'परामर्श', icon: '🤝' },
            { name: 'व्यक्तिगत खरीदारी', icon: '🛍️' },
            { name: 'आवास', icon: '🏠' },
            { name: 'निर्माण', icon: '🔧' },
            { name: 'भंडारण', icon: '📦' },
            { name: 'अनुवाद', icon: '🌐' },
            { name: 'पूजा स्थल', icon: '⛪' },
            { name: 'खेल', icon: '🏅' },
            { name: 'मनोरंजन', icon: '🎭' },
            { name: 'रोजगार', icon: '💼' },
            { name: 'वित्तीय', icon: '💰' },
            { name: 'प्लंबिंग', icon: '🚰' },
            { name: 'विद्युत सेवाएँ', icon: '💡' },
          ]
  ,          
          
        },
        bengali: {
          frequentlyVisitedCommunities: [
            { people: 'মেক্সিকান', flag: 'mx', url: '/mexican' },
            { people: 'আমেরিকান', flag: 'us', url: '/american' },
            { people: 'আফ্রিকান', flag: 'af', url: '/african' },
          ],
          communities: [
            { people: 'আমেরিকান', flag: 'us', url: '/american' },
            { people: 'আলজেরিয়ান', flag: 'dz' },
            { people: 'আফ্রিকান', flag: 'af', url: '/african' },
            { people: 'আর্জেন্টাইন', flag: 'ar' },
            { people: 'অস্ট্রেলিয়ান', flag: 'au' },
            { people: 'ব্রিটিশ', flag: 'gb' },
            { people: 'চীনা', flag: 'cn' },
            { people: 'ইথিওপীয়', flag: 'et', url: '/ethiopian' },
    { people: 'ইরিত্রিয়ান', flag: 'er', url: '/eritrean' },
            { people: 'হাওয়াইয়ান', flag: 'us' },
            { people: 'ভারতীয়', flag: 'in' },
            { people: 'মেক্সিকান', flag: 'mx', url: '/mexican' },
            { people: 'জ্যামাইকান', flag: 'jm' },
            { people: 'লেবানিজ', flag: 'lb' },
            { people: 'নেপালি', flag: 'np' },
            { people: 'নাইজেরিয়ান', flag: 'ng', url: '/Nigerian' },
            { people: 'থাই', flag: 'th' },
          ],

          categories:[
            { name: 'মুদির দোকান', icon: '🛒', url: '/visitgroceries' },
            { name: 'রেস্টুরেন্ট', icon: '🍴', url: '/visitresturants' },
            { name: 'ফুড ট্রাক', icon: '🚚', url: '/visitfoodtrucks' },
            { name: 'কোশার', icon: '🕍', url: '/visitkosherplaces' },
            { name: 'ভেগান', icon: '🥗', url: '/visitveganplaces' },
            { name: 'কৃষক বাজার', icon: '🌽', url: '/farmersmarket' },
            { name: 'ফ্লি মার্কেট', icon: '🛍️', url: '/fleamarketplace' },
            { name: 'পরিষ্কার পরিষেবা', icon: '🧹', url: '/cleaning-services' },
            { name: 'আইটি পরিষেবা', icon: '💻', url: '/itservices' },
            { name: 'ক্যাটারিং', icon: '🍲', url: '/catering' },
  { name: 'দেয়ালচিত্র', icon: '🎨', url: '/murals' },
  { name: 'রিয়েলটরস', icon: '🏘️', url: '/realtors' },
  { name: 'ফটোগ্রাফি', icon: '📷', url: '/photography' },
  { name: 'বাগান পরিচর্যা', icon: '🌱', url: '/gardening' },
  { name: 'ফুলের মালা', icon: '🌸', url: '/wreaths' },
          ]
          ,
          service :[
            { name: 'বুটিক', icon: '👜' },
            { name: 'বাড়ির সাহায্য', icon: '🏡' },
            { name: 'রিয়েলটার', icon: '🏘️' },
            { name: 'পরিবহন', icon: '🚗' },
            { name: 'আইনগত', icon: '⚖️' },
            { name: 'শিক্ষা', icon: '📚' },
            { name: 'স্বাস্থ্য', icon: '⚕️' },
            { name: 'ছাত্র পরিষেবা', icon: '🎓' },
            { name: 'সৌন্দর্য', icon: '💄' },
            { name: 'দর্জি', icon: '👗' },
            { name: 'আয়োজনের স্থান', icon: '🏢' },
            { name: 'ফটোগ্রাফি', icon: '📷' },
            { name: 'ইভেন্ট ব্যবস্থাপনা', icon: '🎉' },
            { name: 'গয়না', icon: '💍' },
            { name: 'বাগবানি', icon: '🌱' },
            { name: 'ক্যাটারিং', icon: '🍲' },
            { name: 'পোষা প্রাণীর যত্ন', icon: '🐾' },
            { name: 'শিশু যত্ন', icon: '👶' },
            { name: 'বয়স্ক যত্ন', icon: '🧓' },
            { name: 'বিপণন', icon: '📈' },
            { name: 'ডিজাইন', icon: '🎨' },
            { name: 'আইনগত পরামর্শ', icon: '⚖️' },
            { name: 'ফিটনেস প্রশিক্ষণ', icon: '🏋️' },
            { name: 'মেরামত পরিষেবা', icon: '🔧' },
            { name: 'লোড পরিবহন', icon: '🚛' },
            { name: 'টিউশন', icon: '📖' },
            { name: 'পরামর্শ', icon: '🤝' },
            { name: 'ব্যক্তিগত কেনাকাটা', icon: '🛍️' },
            { name: 'আবাসন', icon: '🏠' },
            { name: 'নির্মাণ', icon: '🔧' },
            { name: 'সংরক্ষণ', icon: '📦' },
            { name: 'অনুবাদ', icon: '🌐' },
            { name: 'উপাসনা স্থান', icon: '⛪' },
            { name: 'খেলা', icon: '🏅' },
            { name: 'বিনোদন', icon: '🎭' },
            { name: 'চাকরি', icon: '💼' },
            { name: 'আর্থিক', icon: '💰' },
            { name: 'প্লাম্বিং', icon: '🚰' },
            { name: 'বৈদ্যুতিক পরিষেবা', icon: '💡' },
          ]
,          
        },
        swahili: {
          frequentlyVisitedCommunities: [
            { people: 'Meksikani', flag: 'mx', url: '/mexican' },
            { people: 'Mmarekani', flag: 'us', url: '/american' },
            { people: 'Mwafrika', flag: 'af', url: '/african' },
          ],
          communities: [
            { people: 'Mmarekani', flag: 'us', url: '/american' },
            { people: 'Mualjeria', flag: 'dz' },
            { people: 'Mwafrika', flag: 'af', url: '/african' },
            { people: 'Magentina', flag: 'ar' },
            { people: 'Muaustralia', flag: 'au' },
            { people: 'Mwingereza', flag: 'gb' },
            { people: 'Mchina', flag: 'cn' },
            { people: 'Mwethiopia', flag: 'et', url: '/ethiopian' },
    { people: 'Mweritrea', flag: 'er', url: '/eritrean' },
            { people: 'Mhawaia', flag: 'us' },
            { people: 'Mhindi', flag: 'in' },
            { people: 'Meksikani', flag: 'mx', url: '/mexican' },
            { people: 'Mjamaika', flag: 'jm' },
            { people: 'Mlebanoni', flag: 'lb' },
            { people: 'Mnepali', flag: 'np' },
            { people: 'Mnigeria', flag: 'ng' , url: '/Nigerian'},
            { people: 'Mthailand', flag: 'th' },
          ],
          categories: 
          [
            { name: 'Maduka ya vyakula', icon: '🛒', url: '/visitgroceries' },
            { name: 'Mikahawa', icon: '🍴', url: '/visitresturants' },
            { name: 'Gari la chakula', icon: '🚚', url: '/visitfoodtrucks' },
            { name: 'Kosher', icon: '🕍', url: '/visitkosherplaces' },
            { name: 'Vegani', icon: '🥗', url: '/visitveganplaces' },
            { name: 'Soko la wakulima', icon: '🌽', url: '/farmersmarket' },
            { name: 'Soko la bidhaa za zamani', icon: '🛍️', url: '/fleamarketplace' },
            { name: 'Huduma za usafi', icon: '🧹', url: '/cleaning-services' },
            { name: 'Huduma za IT', icon: '💻', url: '/itservices' },
            { name: 'Huduma ya Upishi', icon: '🍲', url: '/catering' },
  { name: 'Michoro ya Ukutani', icon: '🎨', url: '/murals' },
  { name: 'Madalali wa Nyumba', icon: '🏘️', url: '/realtors' },
  { name: 'Upigaji Picha', icon: '📷', url: '/photography' },
  { name: 'Bustani', icon: '🌱', url: '/gardening' },
  { name: 'Mashada ya Maua', icon: '🌸', url: '/wreaths' },
          ]
          
            ,

            service:[
              { name: 'Duka la Mitindo', icon: '👜' },
              { name: 'Msaada wa Nyumbani', icon: '🏡' },
              { name: 'Wakala wa Mali', icon: '🏘️' },
              { name: 'Usafiri', icon: '🚗' },
              { name: 'Kisheria', icon: '⚖️' },
              { name: 'Elimu', icon: '📚' },
              { name: 'Afya', icon: '⚕️' },
              { name: 'Huduma za Wanafunzi', icon: '🎓' },
              { name: 'Urembo', icon: '💄' },
              { name: 'Mshonaji', icon: '👗' },
              { name: 'Maeneo ya Matukio', icon: '🏢' },
              { name: 'Upigaji Picha', icon: '📷' },
              { name: 'Usimamizi wa Matukio', icon: '🎉' },
              { name: 'Vito', icon: '💍' },
              { name: 'Bustani', icon: '🌱' },
              { name: 'Huduma za Chakula', icon: '🍲' },
              { name: 'Uangalizi wa Wanyama', icon: '🐾' },
              { name: 'Huduma za Watoto', icon: '👶' },
              { name: 'Huduma kwa Wazee', icon: '🧓' },
              { name: 'Masoko', icon: '📈' },
              { name: 'Ubunifu', icon: '🎨' },
              { name: 'Ushauri wa Kisheria', icon: '⚖️' },
              { name: 'Mafunzo ya Fitness', icon: '🏋️' },
              { name: 'Huduma za Ukarabati', icon: '🔧' },
              { name: 'Usafiri wa Mizigo', icon: '🚛' },
              { name: 'Mafunzo ya ziada', icon: '📖' },
              { name: 'Ushauri', icon: '🤝' },
              { name: 'Ununuzi Binafsi', icon: '🛍️' },
              { name: 'Malazi', icon: '🏠' },
              { name: 'Ujenzi', icon: '🔧' },
              { name: 'Uhifadhi', icon: '📦' },
              { name: 'Tafsiri', icon: '🌐' },
              { name: 'Maeneo ya Ibada', icon: '⛪' },
              { name: 'Michezo', icon: '🏅' },
              { name: 'Burudani', icon: '🎭' },
              { name: 'Ajira', icon: '💼' },
              { name: 'Huduma za Kifedha', icon: '💰' },
              { name: 'Huduma za Mabomba', icon: '🚰' },
              { name: 'Huduma za Umeme', icon: '💡' },
            ]
,            
        },

       
          
        
          tigrinya: {
            frequentlyVisitedCommunities: [
              { people: 'ሜክሲካን', flag: 'mx', url: '/mexican' },
              { people: 'ኣመሪካዊ', flag: 'us', url: '/american' },
              { people: 'ኣፍሪካዊ', flag: 'af', url: '/african' },
            ],
            communities: [
              { people: 'ኣመሪካዊ', flag: 'us' },
              { people: 'ኣልጀሪያዊ', flag: 'dz' },
              { people: 'ኣፍሪካዊ', flag: 'af' },
              { people: 'ኣርጀንቲናዊ', flag: 'ar' },
              { people: 'ኣውስትራልያዊ', flag: 'au' },
              { people: 'ብሪታንያዊ', flag: 'gb' },
              { people: 'ቻይናዊ', flag: 'cn' },
              { people: 'ኢትዮጵያዊ', flag: 'et', url: '/ethiopian' },
    { people: 'ኤርትራዊ', flag: 'er', url: '/eritrean' },
              { people: 'ሃዋይያዊ', flag: 'us' },
              { people: 'ህንዳዊ', flag: 'in' },
              { people: 'ሜክሲካን', flag: 'mx' },
              { people: 'ጃማይካዊ', flag: 'jm' },
              { people: 'ሊባንኛ', flag: 'lb' },
              { people: 'ኔፓሊ', flag: 'np' },
              { people: 'ናይጄሪያዊ', flag: 'ng', url: '/Nigerian' },
              { people: 'ታይላንዳዊ', flag: 'th' },
            ],
            categories: [
              { name: 'መደብር', icon: '🛒', url: '/visitgroceries' },
              { name: 'ሬስቶራንቶች', icon: '🍴', url: '/visitresturants' },
              { name: 'የምግብ መኪና', icon: '🚚', url: '/visitfoodtrucks' },
              { name: 'ኮሽር', icon: '🕍', url: '/visitkosherplaces' },
              { name: 'ቬጋን', icon: '🥗', url: '/visitveganplaces' },
              { name: 'ገበሬ ገበያ', icon: '🌽', url: '/farmersmarket' },
              { name: 'ፍሊ ማርኬት ቦታ', icon: '🛍️', url: '/fleamarketplace' },
              { name: 'ንጽህና ኣገልግሎት', icon: '🧹', url: '/cleaning-services' },
              { name: 'ኣይቲ ኣገልግሎት', icon: '💻', url: '/itservices' },
              { name: 'ኣገልግሎት ምግቢ', icon: '🍲', url: '/catering' },
              { name: 'ስእሊታት መንደቕ', icon: '🎨', url: '/murals' },
              { name: 'ነጋዳይታት ናይ ኣባይቲ', icon: '🏘️', url: '/realtors' },
              { name: 'ፎቶግራፍ', icon: '📷', url: '/photography' },
              { name: 'ገረብነት', icon: '🌱', url: '/gardening' },
              { name: 'ኣክሊል ኣበባ', icon: '🌸', url: '/wreaths' },
            ]
            
            ,

            service:[
              { name: 'ቡቲክ', icon: '👜' },
              { name: 'ቤት እርዳታ', icon: '🏡' },
              { name: 'የንብረት ወኪል', icon: '🏘️' },
              { name: 'ትራንስፖርት', icon: '🚗' },
              { name: 'ሕጋዊ', icon: '⚖️' },
              { name: 'ትምህርት', icon: '📚' },
              { name: 'ጤና', icon: '⚕️' },
              { name: 'የተማሪ ኣገልግሎት', icon: '🎓' },
              { name: 'ውበት', icon: '💄' },
              { name: 'ልብስ', icon: '👗' },
              { name: 'ቦታዎች', icon: '🏢' },
              { name: 'ፎቶግራፊ', icon: '📷' },
              { name: 'ዝግጅት አስተዳደር', icon: '🎉' },
              { name: 'ጌጣጌጥ', icon: '💍' },
              { name: 'የምግብ መኪና', icon: '🚚' },
              { name: 'ምግብ ቤቶች', icon: '🍴' },
              { name: 'ተክል ሥራ', icon: '🌱' },
              { name: 'ምግብ ኣቀራረብ', icon: '🍲' },
              { name: 'የእንስሳት እንክብካቤ', icon: '🐾' },
              { name: 'የሕፃናት እንክብካቤ', icon: '👶' },
              { name: 'የሽማግሌ እንክብካቤ', icon: '🧓' },
              { name: 'ማርኬቲንግ', icon: '📈' },
              { name: 'ዲዛይን', icon: '🎨' },
              { name: 'ሕጋዊ ምክር', icon: '⚖️' },
              { name: 'ፊትኔስ ስልጠና', icon: '🏋️' },
              { name: 'ኣስተካክል አገልግሎት', icon: '🔧' },
              { name: 'ትራንስፖርት (ጭነት)', icon: '🚛' },
              { name: 'ትምህርት ኣስተማሪ', icon: '📖' },
              { name: 'ኮንስልቲንግ', icon: '🤝' },
              { name: 'የግል ግዢ', icon: '🛍️' },
              { name: 'መኖሪያ', icon: '🏠' },
              { name: 'ግንባታ', icon: '🔧' },
              { name: 'ማህደር', icon: '📦' },
              { name: 'ትርጉም', icon: '🌐' },
              { name: 'ስፍራ እምነት', icon: '⛪' },
              { name: 'ስፖርት', icon: '🏅' },
              { name: 'መዝናኛ', icon: '🎭' },
              { name: 'ስራ', icon: '💼' },
              { name: 'ገንዘብ', icon: '💰' },
              { name: 'ፕላምቢንግ', icon: '🚰' },
              { name: 'ኤሌክትሪክ', icon: '💡' },
            ]
,            
          },
          amharic: {
            frequentlyVisitedCommunities: [
              { people: 'ሜክሲካን', flag: 'mx', url: '/mexican' },
              { people: 'አሜሪካዊ', flag: 'us', url: '/american' },
              { people: 'አፍሪካዊ', flag: 'af', url: '/african' },
            ],
            communities: [
              { people: 'አሜሪካዊ', flag: 'us' },
              { people: 'አልጀሪያዊ', flag: 'dz' },
              { people: 'አፍሪካዊ', flag: 'af' },
              { people: 'አርጀንቲናዊ', flag: 'ar' },
              { people: 'አውስትራሊያዊ', flag: 'au' },
              { people: 'ብሪታንያዊ', flag: 'gb' },
              { people: 'ቻይናዊ', flag: 'cn' },
              { people: 'ኢትዮጵያዊ', flag: 'et', url: '/ethiopian' },
    { people: 'ኤርትራዊ', flag: 'er', url: '/eritrean' },
              { people: 'ሃዋይያዊ', flag: 'us' },
              { people: 'ህንዳዊ', flag: 'in' },
              { people: 'ሜክሲካን', flag: 'mx' },
              { people: 'ጃማይካዊ', flag: 'jm' },
              { people: 'ሊባንኛ', flag: 'lb' },
              { people: 'ኔፓሊ', flag: 'np' },
              { people: 'ናይጄሪያዊ', flag: 'ng' , url: '/Nigerian'},
              { people: 'ታይላንዳዊ', flag: 'th' },
            ],
            categories: [
              { name: 'የምግብ ሱቆች', icon: '🛒', url: '/visitgroceries' },
              { name: 'ምግብ ቤቶች', icon: '🍴', url: '/visitresturants' },
              { name: 'የምግብ ትራክ', icon: '🚚', url: '/visitfoodtrucks' },
              { name: 'ኮሽር', icon: '🕍', url: '/visitkosherplaces' },
              { name: 'ቬጋን', icon: '🥗', url: '/visitveganplaces' },
              { name: 'ገበሬዎች ገበያ', icon: '🌽', url: '/farmersmarket' },
              { name: 'ፍሊ ማርኬት ቦታ', icon: '🛍️', url: '/fleamarketplace' },
              { name: 'የንጽህና አገልግሎት', icon: '🧹', url: '/cleaning-services' },
              { name: 'የአይቲ አገልግሎት', icon: '💻', url: '/itservices' },
              { name: 'ምግብ አቅርቦት', icon: '🍲', url: '/catering' },
  { name: 'ቅጥሮች', icon: '🎨', url: '/murals' },
  { name: 'የቤት ንግዶች', icon: '🏘️', url: '/realtors' },
  { name: 'ፎቶግራፍ', icon: '📷', url: '/photography' },
  { name: 'አትክልት እርሻ', icon: '🌱', url: '/gardening' },
  { name: 'አበባ ዘርግቦች', icon: '🌸', url: '/wreaths' },
            ]
            ,

            service:[
              { name: 'ቡቲክ', icon: '👜' },
              { name: 'የቤት እርዳታ', icon: '🏡' },
              { name: 'የንብረት ወኪል', icon: '🏘️' },
              { name: 'ትራንስፖርት', icon: '🚗' },
              { name: 'ሕጋዊ', icon: '⚖️' },
              { name: 'ትምህርት', icon: '📚' },
              { name: 'ጤና', icon: '⚕️' },
              { name: 'የተማሪ አገልግሎት', icon: '🎓' },
              { name: 'ውበት', icon: '💄' },
              { name: 'ልብስ', icon: '👗' },
              { name: 'ቦታዎች', icon: '🏢' },
              { name: 'ፎቶግራፊ', icon: '📷' },
              { name: 'ዝግጅት አስተዳደር', icon: '🎉' },
              { name: 'ጌጣጌጥ', icon: '💍' },
              { name: 'የምግብ መኪና', icon: '🚚' },
              { name: 'ምግብ ቤቶች', icon: '🍴' },
              { name: 'ተክል ሥራ', icon: '🌱' },
              { name: 'ምግብ ኣቀራረብ', icon: '🍲' },
              { name: 'የእንስሳት እንክብካቤ', icon: '🐾' },
              { name: 'የሕፃናት እንክብካቤ', icon: '👶' },
              { name: 'የሽማግሌ እንክብካቤ', icon: '🧓' },
              { name: 'ማርኬቲንግ', icon: '📈' },
              { name: 'ዲዛይን', icon: '🎨' },
              { name: 'ሕጋዊ ምክር', icon: '⚖️' },
              { name: 'ፊትኔስ ስልጠና', icon: '🏋️' },
              { name: 'ኣስተካክል አገልግሎት', icon: '🔧' },
              { name: 'ትራንስፖርት (ጭነት)', icon: '🚛' },
              { name: 'ትምህርት ኣስተማሪ', icon: '📖' },
              { name: 'ኮንስልቲንግ', icon: '🤝' },
              { name: 'የግል ግዢ', icon: '🛍️' },
              { name: 'መኖሪያ', icon: '🏠' },
              { name: 'ግንባታ', icon: '🔧' },
              { name: 'ማህደር', icon: '📦' },
              { name: 'ትርጉም', icon: '🌐' },
              { name: 'ስፍራ እምነት', icon: '⛪' },
              { name: 'ስፖርት', icon: '🏅' },
              { name: 'መዝናኛ', icon: '🎭' },
              { name: 'ስራ', icon: '💼' },
              { name: 'ገንዘብ', icon: '💰' },
              { name: 'ፕላምቢንግ', icon: '🚰' },
              { name: 'ኤሌክትሪክ', icon: '💡' },
            ]
,            
          },
        
          igbo: {
            frequentlyVisitedCommunities: [
              { people: 'Meksikan', flag: 'mx', url: '/mexican' },
              { people: 'Amerịkan', flag: 'us', url: '/american' },
              { people: 'Afrịkan', flag: 'af', url: '/african' },
            ],
            communities: [
              { people: 'Amerịkan', flag: 'us' },
              { people: 'Algerian', flag: 'dz' },
              { people: 'Afrịkan', flag: 'af' },
              { people: 'Argentina', flag: 'ar' },
              { people: 'Australia', flag: 'au' },
              { people: 'British', flag: 'gb' },
              { people: 'Chinese', flag: 'cn' },
              { people: 'Ọlọhụụ Ethiopia', flag: 'et', url: '/ethiopian' },
              { people: 'Ọlọhụụ Eritrea', flag: 'er', url: '/eritrean' },
              { people: 'Hawaiian', flag: 'us' },
              { people: 'Indian', flag: 'in' },
              { people: 'Meksikan', flag: 'mx' },
              { people: 'Jamaican', flag: 'jm' },
              { people: 'Lebanese', flag: 'lb' },
              { people: 'Nepali', flag: 'np' },
              { people: 'Nigerian', flag: 'ng', url: '/Nigerian' },
              { people: 'Thai', flag: 'th' },
            ],
            categories: [
              { name: 'Ụlọ ahịa nri', icon: '🛒', url: '/visitgroceries' },
              { name: 'Ụlọ oriri na ọṅụṅụ', icon: '🍴', url: '/visitresturants' },
              { name: 'Ụgbọ nri', icon: '🚚', url: '/visitfoodtrucks' },
              { name: 'Kosher', icon: '🕍', url: '/visitkosherplaces' },
              { name: 'Vegani', icon: '🥗', url: '/visitveganplaces' },
              { name: "Ahịa ndị ọrụ ugbo", icon: '🌽', url: '/farmersmarket' },
              { name: "Ahịa Flea", icon: '🛍️', url: '/fleamarketplace' },
              { name: 'Ọrụ nsacha', icon: '🧹', url: '/cleaning-services' },
              { name: 'Ọrụ IT', icon: '💻', url: '/itservices' },
              { name: 'Nri Oriri', icon: '🍲', url: '/catering' },
              { name: 'Mgbidi eserese', icon: '🎨', url: '/murals' },
              { name: 'Ndị na-ere ụlọ', icon: '🏘️', url: '/realtors' },
              { name: 'Foto', icon: '📷', url: '/photography' },
              { name: 'Ọrụ Ubi', icon: '🌱', url: '/gardening' },
              { name: 'Okpu Okooko', icon: '🌸', url: '/wreaths' },
            ]
            ,

            service : [
              { name: 'Ụlọ ahịa ejiji', icon: '👜' },
              { name: 'Enyemaka n’ụlọ', icon: '🏡' },
              { name: 'Ọkachamara ire ụlọ', icon: '🏘️' },
              { name: 'Ụgbọ njem', icon: '🚗' },
              { name: 'Iwu', icon: '⚖️' },
              { name: 'Agụmakwụkwọ', icon: '📚' },
              { name: 'Ahụike', icon: '⚕️' },
              { name: 'Ọrụ ụmụ akwụkwọ', icon: '🎓' },
              { name: 'Ịma mma', icon: '💄' },
              { name: 'Onye na-ese uwe', icon: '👗' },
              { name: 'Ebe a na-eme mmemme', icon: '🏢' },
              { name: 'Ịse foto', icon: '📷' },
              { name: 'Njikwa mmemme', icon: '🎉' },
              { name: 'Ihe ịchọ mma', icon: '💍' },
              { name: 'Ọrụ ubi', icon: '🌱' },
              { name: 'Ikwu nri', icon: '🍲' },
              { name: 'Nlekọta anụmanụ', icon: '🐾' },
              { name: 'Nlekọta ụmụaka', icon: '👶' },
              { name: 'Nlekọta ndị okenye', icon: '🧓' },
              { name: 'Ahịa', icon: '📈' },
              { name: 'Imewe', icon: '🎨' },
              { name: 'Ntụziaka iwu', icon: '⚖️' },
              { name: 'Mmega ahụ', icon: '🏋️' },
              { name: 'Ọrụ mmezi', icon: '🔧' },
              { name: 'Ụgbọ njem ibu', icon: '🚛' },
              { name: 'Nkuzi mgbakwunye', icon: '📖' },
              { name: 'Ntụziaka', icon: '🤝' },
              { name: 'Ịzụta onwe', icon: '🛍️' },
              { name: 'Obibi', icon: '🏠' },
              { name: 'Wulite', icon: '🔧' },
              { name: 'Ịchekwa', icon: '📦' },
              { name: 'Nsụgharị', icon: '🌐' },
              { name: 'Ebe nsọ', icon: '⛪' },
              { name: 'Egwuregwu', icon: '🏅' },
              { name: 'Mmemme ntụrụndụ', icon: '🎭' },
              { name: 'Ọrụ', icon: '💼' },
              { name: 'Ego', icon: '💰' },
              { name: 'Plambing', icon: '🚰' },
              { name: 'Elekịtịk', icon: '💡' },
            ]
,            
          },
          hebrew: {
            frequentlyVisitedCommunities: [
              { people: 'מקסיקני', flag: 'mx', url: '/mexican' },
              { people: 'אמריקאי', flag: 'us', url: '/american' },
              { people: 'אפריקאי', flag: 'af', url: '/african' },
            ],
            communities: [
              { people: 'אמריקאי', flag: 'us' },
              { people: 'אלג\'יראי', flag: 'dz' },
              { people: 'אפריקאי', flag: 'af' },
              { people: 'ארגנטינאי', flag: 'ar' },
              { people: 'אוסטרלי', flag: 'au' },
              { people: 'בריטי', flag: 'gb' },
              { people: 'סיני', flag: 'cn' },
              { people: 'אתיופי', flag: 'et', url: '/ethiopian' },
{ people: 'אריתראי', flag: 'er', url: '/eritrean' },

              { people: 'הוואי', flag: 'us' },
              { people: 'הודי', flag: 'in' },
              { people: 'מקסיקני', flag: 'mx' },
              { people: 'ג\'מייקני', flag: 'jm' },
              { people: 'לבנוני', flag: 'lb' },
              { people: 'נפאלי', flag: 'np' },
              { people: 'ניגרי', flag: 'ng' , url: '/Nigerian'},
              { people: 'תאילנדי', flag: 'th' },
            ],
            categories: [
              { name: 'חנויות מכולת', icon: '🛒', url: '/visitgroceries' },
              { name: 'מסעדות', icon: '🍴', url: '/visitresturants' },
              { name: 'משאית אוכל', icon: '🚚', url: '/visitfoodtrucks' },
              { name: 'כשר', icon: '🕍', url: '/visitkosherplaces' },
              { name: 'טבעוני', icon: '🥗', url: '/visitveganplaces' },
              { name: 'שוק איכרים', icon: '🌽', url: '/farmersmarket' },
              { name: 'שוק פשפשים', icon: '🛍️', url: '/fleamarketplace' },
              { name: 'שירותי ניקיון', icon: '🧹', url: '/cleaning-services' },
              { name: 'שירותי IT', icon: '💻', url: '/itservices' },
              
  { name: 'קייטרינג', icon: '🍲', url: '/catering' },
  { name: 'ציורי קיר', icon: '🎨', url: '/murals' },
  { name: 'מתווכי נדל"ן', icon: '🏘️', url: '/realtors' },
  { name: 'צילום', icon: '📷', url: '/photography' },
  { name: 'גינון', icon: '🌱', url: '/gardening' },
  { name: 'זרי פרחים', icon: '🌸', url: '/wreaths' },
            ]
            ,

            service : [
              { name: 'בוטיק', icon: '👜' },
              { name: 'עזרה ביתית', icon: '🏡' },
              { name: 'סוכני נדל"ן', icon: '🏘️' },
              { name: 'תחבורה', icon: '🚗' },
              { name: 'משפטי', icon: '⚖️' },
              { name: 'חינוך', icon: '📚' },
              { name: 'בריאות', icon: '⚕️' },
              { name: 'שירותי סטודנטים', icon: '🎓' },
              { name: 'יופי', icon: '💄' },
              { name: 'חייט', icon: '👗' },
              { name: 'מקומות אירועים', icon: '🏢' },
              { name: 'צילום', icon: '📷' },
              { name: 'ניהול אירועים', icon: '🎉' },
              { name: 'תכשיטים', icon: '💍' },
              { name: 'גינון', icon: '🌱' },
              { name: 'קייטרינג', icon: '🍲' },
              { name: 'טיפול בבעלי חיים', icon: '🐾' },
              { name: 'טיפול בילדים', icon: '👶' },
              { name: 'טיפול בקשישים', icon: '🧓' },
              { name: 'שיווק', icon: '📈' },
              { name: 'עיצוב', icon: '🎨' },
              { name: 'ייעוץ משפטי', icon: '⚖️' },
              { name: 'אימון כושר', icon: '🏋️' },
              { name: 'שירותי תיקונים', icon: '🔧' },
              { name: 'תחבורה (מטענים)', icon: '🚛' },
              { name: 'תמיכה לימודית', icon: '📖' },
              { name: 'ייעוץ', icon: '🤝' },
              { name: 'קניות אישיות', icon: '🛍️' },
              { name: 'לינה', icon: '🏠' },
              { name: 'בנייה', icon: '🔧' },
              { name: 'אחסון', icon: '📦' },
              { name: 'תרגום', icon: '🌐' },
              { name: 'מקומות תפילה', icon: '⛪' },
              { name: 'ספורט', icon: '🏅' },
              { name: 'בידור', icon: '🎭' },
              { name: 'תעסוקה', icon: '💼' },
              { name: 'פיננסי', icon: '💰' },
              { name: 'אינסטלציה', icon: '🚰' },
              { name: 'חשמל', icon: '💡' },
            ]
,            
          },
          oromo: {
            frequentlyVisitedCommunities: [
              { people: 'Meksikaanii', flag: 'mx', url: '/mexican' },
              { people: 'Ameerikaa', flag: 'us', url: '/american' },
              { people: 'Afriikaa', flag: 'af', url: '/african' },
            ],
            communities: [
              { people: 'Ameerikaa', flag: 'us' },
              { people: 'Aljeeriya', flag: 'dz' },
              { people: 'Afriikaa', flag: 'af' },
              { people: 'Arjentiinaa', flag: 'ar' },
              { people: 'Ostiraaliyaa', flag: 'au' },
              { people: 'Biritish', flag: 'gb' },
              { people: 'Chaayinaa', flag: 'cn' },
              { people: 'Itoophiyaa', flag: 'et', url: '/ethiopian' },
{ people: 'Eertiraa', flag: 'er', url: '/eritrean' },

              { people: 'Hawaayii', flag: 'us' },
              { people: 'Indiyaa', flag: 'in' },
              { people: 'Meksikaanii', flag: 'mx',  url: '/mexican'},
              { people: 'Jaamayikaa', flag: 'jm' },
              { people: 'Libaanii', flag: 'lb' },
              { people: 'Neepalii', flag: 'np' },
              { people: 'Naayjeeriyaa', flag: 'ng', url: '/Nigerian' },
              { people: 'Tayilaandii', flag: 'th' },
            ],
            categories: [
              { name: 'Suqaa Nyaataa', icon: '🛒', url: '/visitgroceries' },
              { name: 'Mana Nyaataa', icon: '🍴', url: '/visitresturants' },
              { name: 'Fuud Truukii', icon: '🚚', url: '/visitfoodtrucks' },
              { name: 'Kosher', icon: '🕍', url: '/visitkosherplaces' },
              { name: 'Vegani', icon: '🥗', url: '/visitveganplaces' },
              { name: 'Suqaa Qonnaan Bultootaa', icon: '🌽', url: '/farmersmarket' },
              { name: 'Fili Maarketii', icon: '🛍️', url: '/fleamarketplace' },
              { name: 'Tajaajila Qulqullinaa', icon: '🧹', url: '/cleaning-services' },
              { name: 'Tajaajila IT', icon: '💻', url: '/itservices' },
              { name: 'Tajaajila Nyaata', icon: '🍲', url: '/catering' },
  { name: 'Suuraawwan Keessaa', icon: '🎨', url: '/murals' },
  { name: 'Gurgurtoota Manaa', icon: '🏘️', url: '/realtors' },
  { name: 'Suura Kaasuu', icon: '📷', url: '/photography' },
  { name: 'Qonna Magariisaa', icon: '🌱', url: '/gardening' },
  { name: 'Gumbiiwwan Daraaraa', icon: '🌸', url: '/wreaths' },
            ]
            
            ,

            service :[
              { name: 'Buutiiqii', icon: '👜' },
              { name: 'Gargaarsa Manaa', icon: '🏡' },
              { name: 'Abbootii Qabeenyaa', icon: '🏘️' },
              { name: 'Geejjiba', icon: '🚗' },
              { name: 'Seeraa', icon: '⚖️' },
              { name: 'Barnoota', icon: '📚' },
              { name: 'Fayyaa', icon: '⚕️' },
              { name: 'Tajaajila Barattootaa', icon: '🎓' },
              { name: 'Bareedina', icon: '💄' },
              { name: 'Taayilara', icon: '👗' },
              { name: 'Iddoo Hojii', icon: '🏢' },
              { name: 'Suuraa', icon: '📷' },
              { name: 'Qindoomina Ayyaanaa', icon: '🎉' },
              { name: 'Juwelarii', icon: '💍' },
              { name: 'Biqiltuu', icon: '🌱' },
              { name: 'Tajaajila Nyaataa', icon: '🍲' },
              { name: 'Tajaajila Horii', icon: '🐾' },
              { name: "Tajaajila Daa'imman", icon: '👶' },
              { name: 'Tajaajila Maanguddootaa', icon: '🧓' },
              { name: 'Gabaasa', icon: '📈' },
              { name: 'Dizaayinii', icon: '🎨' },
              { name: 'Gorsaa Seeraa', icon: '⚖️' },
              { name: 'Leenjii Qaamaa', icon: '🏋️' },
              { name: 'Tajaajila Sirreessaa', icon: '🔧' },
              { name: "Geejjiba Fe'umsaa", icon: '🚛' },
              { name: 'Gorsaa Barnootaa', icon: '📖' },
              { name: 'Consulting', icon: '🤝' },
              { name: 'Bittaa Dhuunfaa', icon: '🛍️' },
              { name: 'Akomodheeshinii', icon: '🏠' },
              { name: 'Ijaarsa', icon: '🔧' },
              { name: 'Kuusaa', icon: '📦' },
              { name: 'Hiikkaa', icon: '🌐' },
              { name: 'Iddoo Waaqeffannaa', icon: '⛪' },
              { name: 'Isportii', icon: '🏅' },
              { name: 'Bashannana', icon: '🎭' },
              { name: 'Hojii', icon: '💼' },
              { name: 'Maallaqa', icon: '💰' },
              { name: 'Pilaambii', icon: '🚰' },
              { name: 'Ilektrikaa', icon: '💡' },
            ]
,            
          },

          
            french: {
              frequentlyVisitedCommunities: [
                { people: 'Mexicain', flag: 'mx', url: '/mexican' },
                { people: 'Américain', flag: 'us', url: '/american' },
                { people: 'Africain', flag: 'af', url: '/african' },
              ],
              communities: [
                { people: 'Américain', flag: 'us' },
                { people: 'Algérien', flag: 'dz' },
                { people: 'Africain', flag: 'af' },
                { people: 'Argentin', flag: 'ar' },
                { people: 'Australien', flag: 'au' },
                { people: 'Britannique', flag: 'gb' },
                { people: 'Chinois', flag: 'cn' },
                { people: 'Éthiopien', flag: 'et', url: '/ethiopian' },
                { people: 'Érythréen', flag: 'er', url: '/eritrean' },
                
                { people: 'Hawaïen', flag: 'us' },
                { people: 'Indien', flag: 'in' },
                { people: 'Mexicain', flag: 'mx' },
                { people: 'Jamaïcain', flag: 'jm' },
                { people: 'Libanais', flag: 'lb' },
                { people: 'Népalais', flag: 'np' },
                { people: 'Nigérian', flag: 'ng', url: '/Nigerian'},
                { people: 'Thaïlandais', flag: 'th' },
              ],
              categories: [
                { name: 'Épiceries', icon: '🛒', url: '/visitgroceries' },
                { name: 'Restaurants', icon: '🍴', url: '/visitresturants' },
                { name: 'Camion de nourriture', icon: '🚚', url: '/visitfoodtrucks' },
                { name: 'Kosher', icon: '🕍', url: '/visitkosherplaces' },
                { name: 'Végétalien', icon: '🥗', url: '/visitveganplaces' },
                { name: 'Marché fermier', icon: '🌽', url: '/farmersmarket' },
                { name: 'Marché aux puces', icon: '🛍️', url: '/fleamarketplace' },
                { name: 'Services de nettoyage', icon: '🧹', url: '/cleaning-services' },
                { name: 'Services informatiques', icon: '💻', url: '/itservices' },
                { name: 'Traiteur', icon: '🍲', url: '/catering' },
  { name: 'Peintures murales', icon: '🎨', url: '/murals' },
  { name: 'Agents immobiliers', icon: '🏘️', url: '/realtors' },
  { name: 'Photographie', icon: '📷', url: '/photography' },
  { name: 'Jardinage', icon: '🌱', url: '/gardening' },
  { name: 'Couronnes de fleurs', icon: '🌸', url: '/wreaths' },
              ]
              ,

              service : [
                { name: 'Boutique', icon: '👜' },
                { name: 'Aide à domicile', icon: '🏡' },
                { name: 'Agents immobiliers', icon: '🏘️' },
                { name: 'Transport', icon: '🚗' },
                { name: 'Légal', icon: '⚖️' },
                { name: 'Éducation', icon: '📚' },
                { name: 'Santé', icon: '⚕️' },
                { name: 'Services aux étudiants', icon: '🎓' },
                { name: 'Beauté', icon: '💄' },
                { name: 'Tailleur', icon: '👗' },
                { name: 'Lieux d’événements', icon: '🏢' },
                { name: 'Photographie', icon: '📷' },
                { name: 'Gestion d’événements', icon: '🎉' },
                { name: 'Bijouterie', icon: '💍' },
                { name: 'Jardinage', icon: '🌱' },
                { name: 'Traiteur', icon: '🍲' },
                { name: 'Soins pour animaux', icon: '🐾' },
                { name: 'Garde d’enfants', icon: '👶' },
                { name: 'Soins aux personnes âgées', icon: '🧓' },
                { name: 'Marketing', icon: '📈' },
                { name: 'Design', icon: '🎨' },
                { name: 'Conseil juridique', icon: '⚖️' },
                { name: 'Entraînement physique', icon: '🏋️' },
                { name: 'Services de réparation', icon: '🔧' },
                { name: 'Transport de marchandises', icon: '🚛' },
                { name: 'Cours particuliers', icon: '📖' },
                { name: 'Consultance', icon: '🤝' },
                { name: 'Achats personnels', icon: '🛍️' },
                { name: 'Hébergement', icon: '🏠' },
                { name: 'Construction', icon: '🔧' },
                { name: 'Stockage', icon: '📦' },
                { name: 'Traduction', icon: '🌐' },
                { name: 'Lieux de culte', icon: '⛪' },
                { name: 'Sport', icon: '🏅' },
                { name: 'Divertissement', icon: '🎭' },
                { name: 'Emploi', icon: '💼' },
                { name: 'Financier', icon: '💰' },
                { name: 'Plomberie', icon: '🚰' },
                { name: 'Services électriques', icon: '💡' },
              ]
,              
            },
          
            portuguese: {
              frequentlyVisitedCommunities: [
                { people: 'Mexicano', flag: 'mx', url: '/mexican' },
                { people: 'Americano', flag: 'us', url: '/american' },
                { people: 'Africano', flag: 'af', url: '/african' },
              ],
              communities: [
                { people: 'Americano', flag: 'us' },
                { people: 'Argelino', flag: 'dz' },
                { people: 'Africano', flag: 'af' },
                { people: 'Argentino', flag: 'ar' },
                { people: 'Australiano', flag: 'au' },
                { people: 'Britânico', flag: 'gb' },
                { people: 'Chinês', flag: 'cn' },
                { people: 'Etíope', flag: 'et', url: '/ethiopian' },
{ people: 'Eritreu', flag: 'er', url: '/eritrean' },

                { people: 'Havaiano', flag: 'us' },
                { people: 'Indiano', flag: 'in' },
                { people: 'Mexicano', flag: 'mx' },
                { people: 'Jamaicano', flag: 'jm' },
                { people: 'Libanês', flag: 'lb' },
                { people: 'Nepalês', flag: 'np' },
                { people: 'Nigeriano', flag: 'ng', url: '/Nigerian' },
                { people: 'Tailandês', flag: 'th' },
              ],
              categories: [
                { name: 'Mercearias', icon: '🛒', url: '/visitgroceries' },
                { name: 'Restaurantes', icon: '🍴', url: '/visitresturants' },
                { name: 'Caminhão de Comida', icon: '🚚', url: '/visitfoodtrucks' },
                { name: 'Kosher', icon: '🕍', url: '/visitkosherplaces' },
                { name: 'Vegano', icon: '🥗', url: '/visitveganplaces' },
                { name: 'Mercado de Agricultores', icon: '🌽', url: '/farmersmarket' },
                { name: 'Mercado de Pulgas', icon: '🛍️', url: '/fleamarketplace' },
                { name: 'Serviços de Limpeza', icon: '🧹', url: '/cleaning-services' },
                { name: 'Serviços de TI', icon: '💻', url: '/itservices' },
                { name: 'Serviço de Buffet', icon: '🍲', url: '/catering' },
  { name: 'Murais', icon: '🎨', url: '/murals' },
  { name: 'Corretores de imóveis', icon: '🏘️', url: '/realtors' },
  { name: 'Fotografia', icon: '📷', url: '/photography' },
  { name: 'Jardinagem', icon: '🌱', url: '/gardening' },
  { name: 'Guirlandas Florais', icon: '🌸', url: '/wreaths' },
              ]
              ,

              service : [
                { name: 'Boutique', icon: '👜' },
                { name: 'Assistência domiciliar', icon: '🏡' },
                { name: 'Corretores de imóveis', icon: '🏘️' },
                { name: 'Transporte', icon: '🚗' },
                { name: 'Jurídico', icon: '⚖️' },
                { name: 'Educação', icon: '📚' },
                { name: 'Saúde', icon: '⚕️' },
                { name: 'Serviços para estudantes', icon: '🎓' },
                { name: 'Beleza', icon: '💄' },
                { name: 'Alfaiate', icon: '👗' },
                { name: 'Locais para eventos', icon: '🏢' },
                { name: 'Fotografia', icon: '📷' },
                { name: 'Gestão de eventos', icon: '🎉' },
                { name: 'Joalheria', icon: '💍' },
                { name: 'Jardinagem', icon: '🌱' },
                { name: 'Catering', icon: '🍲' },
                { name: 'Cuidados com animais', icon: '🐾' },
                { name: 'Cuidados infantis', icon: '👶' },
                { name: 'Cuidados com idosos', icon: '🧓' },
                { name: 'Marketing', icon: '📈' },
                { name: 'Design', icon: '🎨' },
                { name: 'Assessoria jurídica', icon: '⚖️' },
                { name: 'Treinamento físico', icon: '🏋️' },
                { name: 'Serviços de reparo', icon: '🔧' },
                { name: 'Transporte de carga', icon: '🚛' },
                { name: 'Aulas particulares', icon: '📖' },
                { name: 'Consultoria', icon: '🤝' },
                { name: 'Compras pessoais', icon: '🛍️' },
                { name: 'Alojamento', icon: '🏠' },
                { name: 'Construção', icon: '🔧' },
                { name: 'Armazenamento', icon: '📦' },
                { name: 'Tradução', icon: '🌐' },
                { name: 'Locais de culto', icon: '⛪' },
                { name: 'Esportes', icon: '🏅' },
                { name: 'Entretenimento', icon: '🎭' },
                { name: 'Emprego', icon: '💼' },
                { name: 'Financeiro', icon: '💰' },
                { name: 'Encanamento', icon: '🚰' },
                { name: 'Serviços elétricos', icon: '💡' },
              ]
,              
            },
         
          mandarin: {
    frequentlyVisitedCommunities: [
      { people: '墨西哥人', flag: 'mx', url: '/mexican' },
      { people: '美国人', flag: 'us', url: '/american' },
      { people: '非洲人', flag: 'af', url: '/african' },
    ],
    communities: [
      { people: '美国人', flag: 'us' },
      { people: '阿尔及利亚人', flag: 'dz' },
      { people: '非洲人', flag: 'af' },
      { people: '阿根廷人', flag: 'ar' },
      { people: '澳大利亚人', flag: 'au' },
      { people: '英国人', flag: 'gb' },
      { people: '中国人', flag: 'cn' },
      { people: '埃塞俄比亚人', flag: 'et' },
      { people: '夏威夷人', flag: 'us' },
      { people: '印度人', flag: 'in' },
      { people: '墨西哥人', flag: 'mx' },
      { people: '牙买加人', flag: 'jm' },
      { people: '黎巴嫩人', flag: 'lb' },
      { people: '尼泊尔人', flag: 'np' },
      { people: '尼日利亚人', flag: 'ng' },
      { people: '泰国人', flag: 'th' },
    ],
    categories: [
      { name: '杂货店', icon: '🛒', url: '/visitgroceries' },
      { name: '餐厅', icon: '🍴', url: '/visitresturants' },
      { name: '餐车', icon: '🚚', url: '/visitfoodtrucks' },
      { name: '科舍尔', icon: '🕍', url: '/visitkosherplaces' },
      { name: '纯素', icon: '🥗', url: '/visitveganplaces' },
      { name: '农贸市场', icon: '🌽', url: '/farmersmarket' },
      { name: '跳蚤市场', icon: '🛍️', url: '/fleamarketplace' },
      { name: '清洁服务', icon: '🧹', url: '/cleaning-services' },
      { name: 'IT服务', icon: '💻', url: '/itservices' },
      { name: '餐饮服务', icon: '🍲', url: '/catering' },
  { name: '壁画艺术', icon: '🎨', url: '/murals' },
  { name: '房地产经纪', icon: '🏘️', url: '/realtors' },
  { name: '摄影', icon: '📷', url: '/photography' },
  { name: '园艺', icon: '🌱', url: '/gardening' },
  { name: '花环', icon: '🌸', url: '/wreaths' },
    ]
    ,
    service : [
      { name: '精品店', icon: '👜' },
      { name: '家政服务', icon: '🏡' },
      { name: '房地产经纪人', icon: '🏘️' },
      { name: '交通运输', icon: '🚗' },
      { name: '法律服务', icon: '⚖️' },
      { name: '教育', icon: '📚' },
      { name: '健康', icon: '⚕️' },
      { name: '学生服务', icon: '🎓' },
      { name: '美容', icon: '💄' },
      { name: '裁缝', icon: '👗' },
      { name: '场馆', icon: '🏢' },
      { name: '摄影', icon: '📷' },
      { name: '活动管理', icon: '🎉' },
      { name: '珠宝', icon: '💍' },
      { name: '园艺', icon: '🌱' },
      { name: '餐饮服务', icon: '🍲' },
      { name: '宠物护理', icon: '🐾' },
      { name: '儿童看护', icon: '👶' },
      { name: '老人护理', icon: '🧓' },
      { name: '市场营销', icon: '📈' },
      { name: '设计', icon: '🎨' },
      { name: '法律咨询', icon: '⚖️' },
      { name: '健身训练', icon: '🏋️' },
      { name: '维修服务', icon: '🔧' },
      { name: '货物运输', icon: '🚛' },
      { name: '辅导', icon: '📖' },
      { name: '咨询服务', icon: '🤝' },
      { name: '私人购物', icon: '🛍️' },
      { name: '住宿', icon: '🏠' },
      { name: '建筑', icon: '🔧' },
      { name: '仓储', icon: '📦' },
      { name: '翻译', icon: '🌐' },
      { name: '礼拜场所', icon: '⛪' },
      { name: '体育', icon: '🏅' },
      { name: '娱乐', icon: '🎭' },
      { name: '就业', icon: '💼' },
      { name: '金融服务', icon: '💰' },
      { name: '水暖工程', icon: '🚰' },
      { name: '电工服务', icon: '💡' },
    ]
,    
  },
        
  wolof: {
    frequentlyVisitedCommunities: [
      { people: 'Mexicain', flag: 'mx', url: '/mexican' },
      { people: 'Amerikeŋ', flag: 'us', url: '/american' },
      { people: 'Afrikeŋ', flag: 'af', url: '/african' },
    ],
  
    communities: [
      { people: 'Amerikeŋ', flag: 'us' },
      { people: 'Alseri', flag: 'dz' },
      { people: 'Afrikeŋ', flag: 'af' },
      { people: 'Arsantin', flag: 'ar' },
      { people: 'Ostraali', flag: 'au' },
      { people: 'Angalteer', flag: 'gb' },
      { people: 'Siin', flag: 'cn' },
      { people: 'Ecoopi', flag: 'et' },
      { people: 'Haway', flag: 'us' },
      { people: 'End', flag: 'in' },
      { people: 'Mexicain', flag: 'mx' },
      { people: 'Jamaik', flag: 'jm' },
      { people: 'Libaaŋ', flag: 'lb' },
      { people: 'Nepaal', flag: 'np' },
      { people: 'Niseriya', flag: 'ng' , url: '/Nigerian'},
      { people: 'Taaylaand', flag: 'th' },
    ],
  
    categories: [
      { name: 'Bitik', icon: '🛒', url: '/visitgroceries' },
      { name: 'Restoraŋ', icon: '🍴', url: '/visitresturants' },
      { name: 'Kaminu lekk', icon: '🚚', url: '/visitfoodtrucks' },
      { name: 'Kosher', icon: '🕍', url: '/visitkosherplaces' },
      { name: 'Vegan', icon: '🥗', url: '/visitveganplaces' },
      { name: 'Marse buñu bey', icon: '🌽', url: '/farmersmarket' },
      { name: 'Marseu bu ànd ak garabu', icon: '🛍️', url: '/fleamarketplace' },
      { name: 'Set-setal', icon: '🧹', url: '/cleaning-services' },
      { name: 'Serwis IT', icon: '💻', url: '/itservices' },
      { name: 'Ketering', icon: '🍲', url: '/catering' },
      { name: 'Art mural', icon: '🎨', url: '/murals' },
      { name: 'Seriñ ay kër', icon: '🏘️', url: '/realtors' },
      { name: 'Foto', icon: '📷', url: '/photography' },
      { name: 'Gardenaj', icon: '🌱', url: '/gardening' },
      { name: 'Kuròn bu ñu defare ci tóor-tóor', icon: '🌸', url: '/wreaths' },
    ],
  
    service: [
      { name: 'Butik', icon: '👜' },
      { name: 'Ligéeyu kër', icon: '🏡' },
      { name: 'Seriñ ay kër', icon: '🏘️' },
      { name: 'Transpóor', icon: '🚗' },
      { name: 'Ligéeyu yoon', icon: '⚖️' },
      { name: 'Njàng', icon: '📚' },
      { name: 'Wér-gi-yaram', icon: '⚕️' },
      { name: 'Serwis ndongo', icon: '🎓' },
      { name: 'Feccali ak mën-mën', icon: '💄' },
      { name: 'Teër', icon: '👗' },
      { name: 'Barabi daara', icon: '🏢' },
      { name: 'Foto', icon: '📷' },
      { name: 'Toppandoo xew-xew', icon: '🎉' },
      { name: 'Almaal', icon: '💍' },
      { name: 'Gardenaj', icon: '🌱' },
      { name: 'Ketering', icon: '🍲' },
      { name: 'Jàppale xaj ak muus', icon: '🐾' },
      { name: 'Wottu xale', icon: '👶' },
      { name: 'Wottu mag ñi', icon: '🧓' },
      { name: 'Marketing', icon: '📈' },
      { name: 'Desen', icon: '🎨' },
      { name: 'Joxé yoonu', icon: '⚖️' },
      { name: 'Lantiyamant', icon: '🏋️' },
      { name: 'Tàggat-yaram', icon: '🔧' },
      { name: 'Transpóor marsandiis', icon: '🚛' },
      { name: 'Ndimbal njàng', icon: '📖' },
      { name: 'Consulting', icon: '🤝' },
      { name: 'Jëndu boppam', icon: '🛍️' },
      { name: 'Dal', icon: '🏠' },
      { name: 'Tabax', icon: '🔧' },
      { name: 'Depoo', icon: '📦' },
      { name: 'Firi', icon: '🌐' },
      { name: 'Barab u jaamu', icon: '⛪' },
      { name: 'Espoor', icon: '🏅' },
      { name: 'Awra', icon: '🎭' },
      { name: 'Ligéey', icon: '💼' },
      { name: 'Koppar gu xaalis', icon: '💰' },
      { name: 'Plonbri', icon: '🚰' },
      { name: 'Elektrisite', icon: '💡' },
    ],
  }
  
        
                        
      
    };
  

    //return based on language
    return dataMap[language] || dataMap.english; // Default to English if language not found
  }


  