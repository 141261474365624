import React, { useState, useEffect } from "react";
import logo from "../images/heroselam.jpg";
import adGoOurHub from "../images/ad_goourhub.jpg"; // <-- import your ad image
import "./VaduAplu.css";

const images = [
  require("../images/tacoblvd1.jpeg"),
  require("../images/tacoblvd2.jpg"),
  require("../images/tacoblvd3.jpg"),
  require("../images/tacoblvd4.jpeg"),
  require("../images/tacoblvd5.jpg"),
  require("../images/tacoblvd6.jpg"),
];

function TacoBlvd() {
  const [dealIndex, setDealIndex] = useState(0);
  const [fade, setFade] = useState(true);

  const deals = [
    "Get 10% on services when you mention GOOURHUB",
    "Mention GoOurHub to get better price/discounts",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setDealIndex((prev) => (prev + 1) % deals.length);
        setFade(true);
      }, 500);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="vaduaplu-container">
      {/* Header */}
      <header className="vaduaplu-header">
        {/* Left: Logo + Name */}
        <div className="vaduaplu-header-left">
          <img src={logo} alt="Logo" className="vaduaplu-logo" />
          <div className="vaduaplu-header-text">
            <h1 className="vaduaplu-company-name">Taco Blvd
              
            </h1>
            
          </div>
        </div>

        {/* Right: Phone + Address */}
        <div className="vaduaplu-header-right">
          <span className="vaduaplu-phone">Phone: (210) 998-2282</span>
          <br/>
          <span className="vaduaplu-address">
          Blanco Market Shopping Center
          <br/>
          18360 Blanco Rd #116, San Antonio, TX 78258
          <br/>
          <br/>
          
          Alamo Ranch
          <br/>
          5419 W Loop 1604 N, San Antonio, TX 78253
          </span>
        </div>
      </header>

      {/* Contact Info */}
      <div className="vaduaplu-contact-info">
  <p className="vaduaplu-text">
    <span>🌮</span> Welcome to Taco Blvd!
    <br/>
    <br />
    <span>🌿</span> Taco Blvd is a fast food taqueria that serves high quality and delicious tacos Brownsville style. Our specialities include but are not limited to Bistec, Pastor and Molleja Taquito
  </p>
  <br />
  <span>🌍</span> 
  <br />
  <span>🫘</span>Taquitos de barbacoa,
  <span>🥄</span> Bistec-asada quesadilla, 
  <span>🌶️</span> Al pastor-marinated pork quesadilla
</div>


      {/* Single Top Box combining Quick Menu & Deals */}
      <div className="vaduaplu-top-box">
        {/* Deals Section */}
        <div className="vaduaplu-top-section">
          <h2 className="vaduaplu-heading">Get Deals</h2>
          {/* Flashing Text */}
          <p className={`vaduaplu-deals-text ${fade ? "fade-in" : "fade-out"}`}>
            {deals[dealIndex]}
          </p>

          {/* Static Image below the deals text */}
          <img
            src={adGoOurHub}
            alt="GoOurHub Ad"
            className="vaduaplu-ad-image"
          />
        </div>

        {/* Quick Menu Section */}
        <div className="vaduaplu-top-section">
          <h2 className="vaduaplu-heading">Our Highlights</h2>
          <div className="vaduaplu-accordion">
            <div className="vaduaplu-accordion-item">
              <input type="checkbox" id="service1" className="hidden-checkbox"defaultChecked />
              <label htmlFor="service1" className="vaduaplu-label" align = "center">
              Our Highlights
              </label>
              <div className="vaduaplu-accordion-content">
              <ul className="vaduaplu-list">
               
                
                <li className="vaduaplu-list-item">Barbacoa tacos</li>
                <br/>
                <li className="vaduaplu-list-item">FChocolate brownie</li>
                <br/>
                  <li className="vaduaplu-list-item">Lspecial event?
                  <br/>
                  Call us to learn more about our special party platters.</li>
                  <br/>
                  <li className="vaduaplu-list-item">Order Online</li>
                  <br/>
                  <li className="vaduaplu-list-item">Hola Amigos!
                  HASHTAG #tacoblvdtx to show up on our INSTAGRAM</li>
                  <br/>
                 
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content / Gallery */}
      <main className="vaduaplu-main-content">
        <h2 className="vaduaplu-heading">Gallery</h2>
        <div className="vaduaplu-gallery">
          {[...Array(6)].map((_, index) => (
            <img
              key={index}
              src={images[index % images.length]}
              alt={`Work Sample ${index + 1}`}
              className="vaduaplu-image"
            />
          ))}
        </div>
      </main>

      {/* Extra Text Section */}
      <div className="vaduaplu-extra-text">
        <p className="vaduaplu-extra-text-p">
       <link href="https://www.tacoblvd.us/" rel="stylesheet"></link>
        </p>
      </div>
    </div>
  );
}

export default TacoBlvd;
