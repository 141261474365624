import React, { useEffect, useState, useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import './PromoSection.css';

const promoItems = [
  { type: 'image', src: require("../images/Promo_ad1.jpg") },
  { type: 'image', src: require("../images/Promo_ad2.jpg") },
  { type: 'video', src: require("../videos/ad1.mp4"), alt: 'Video Ad' },
 
];

const promoDeals = [
  '🔥 10% off at Pita Plus when you mention "GoOurHub"',
  '🍽️ Sign up with GoOurHub and get deals',
];

const PromoSection = () => {
  const [currentItem, setCurrentItem] = useState(0);
  const [timer, setTimer] = useState(3600);
  const [currentDeal, setCurrentDeal] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const dealTickerRef = useRef(null);

  const nextSlide = () => setCurrentItem((prev) => (prev + 1) % promoItems.length);
  const prevSlide = () => setCurrentItem((prev) => (prev - 1 + promoItems.length) % promoItems.length);

  useEffect(() => {
    if (!isPaused) {
      const interval = setInterval(nextSlide, 3000);
      return () => clearInterval(interval);
    }
  }, [isPaused]);

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);
    return () => clearInterval(countdown);
  }, []);

  useEffect(() => {
    const dealTicker = setInterval(() => {
      setCurrentDeal((prev) => (prev + 1) % promoDeals.length);
      if (dealTickerRef.current) {
        dealTickerRef.current.classList.remove('scroll-start');
        void dealTickerRef.current.offsetWidth;
        dealTickerRef.current.classList.add('scroll-start');
      }
    }, 4000);
    return () => clearInterval(dealTicker);
  }, []);

  const formatTime = (sec) => {
    const m = String(Math.floor(sec / 60)).padStart(2, '0');
    const s = String(sec % 60).padStart(2, '0');
    return `${m}:${s}`;
  };

  const current = promoItems[currentItem];

  return (
    <div className="promo-container">
      <div className="image-slider full-width">
        <div className="static-slider-frame">
          <AnimatePresence mode="wait">
            {current.type === 'video' ? (
              <motion.video
                key={`video-${currentItem}`}
                src={current.src}
                autoPlay
                muted
                loop
                className="slider-image"
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -100 }}
                transition={{ duration: 0.5 }}
              />
            ) : (
              <motion.img
                key={`image-${currentItem}`}
                src={current.src}
                alt={current.alt}
                className="slider-image"
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -100 }}
                transition={{ duration: 0.5 }}
              />
            )}
          </AnimatePresence>
        </div>
        <button className="Promo_nav-button prev" onClick={prevSlide}>❮</button>
        <button className="Promo_nav-button next" onClick={nextSlide}>❯</button>
        <button className="pause-button" onClick={() => setIsPaused(!isPaused)}>
          {isPaused ? '▶️' : '⏸️'}
        </button>
      </div>

      <div className="deal-ticker">
        <div className="ticker-text scroll-start" ref={dealTickerRef}>
          {promoDeals[currentDeal]}
        </div>
      </div>
    </div>
  );
};

export default PromoSection;