import React, { useState } from 'react'; 
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import './Menu.css';
import Header from './goourhub/component/Header';
import Footer from './goourhub/component/Footer';

import './NewTile.css';
import './goourhub/main/aligners.css';
import 'flag-icons/css/flag-icons.min.css'; // Import flag icons
import ProductCategories from './goourhub/component/ProductCategories';
import PitaPlus from './goourhub/component/PitaPlus';
import SelamFoodMart from './goourhub/component/SelamFoodMart';
import ServiceCategories from './goourhub/component/ServiceCategories';
import ImportantLinks from './goourhub/component/ImportantLinks';

import CommunityConnect from './goourhub/component/CommunityConnect';
import Template from './goourhub/main/template';
import TransitioningSectionForAd from './goourhub/component/TransitioningSectionForAd';
import VendorTemplate1 from './goourhub/component/VendorTemplate1'; 
import AfricanVillage from './goourhub/component/AfricanVillage';
import VendorTemplate2 from './goourhub/component/VendorTemplate2'; 
import VendorTemplate3 from './goourhub/component/VendorTemplate3'; 
import ContactUs from './goourhub/component/ContactUs';
import SignInModal from './goourhub/component/SignInModal';
import SignUpModal from './goourhub/component/SignUpModal';
import Mexican from './goourhub/component/Mexican';
import American from './goourhub/component/American';
import African from './goourhub/component/African';
import AboutUs from './goourhub/component/AboutUs';
import CommunityForum from './goourhub/component/CommunityForum';
import SliderForMobile from './goourhub/component/SliderForMobile';
import LanguageDropdown from './goourhub/component/LanguageDropdown';
//import ReviewSystem from './goourhub/component/ReviewSystem';
//import UserDashboard from './goourhub/component/UserDashboard';
import VisitResturants from './goourhub/component/VisitResturants';
import VisitGroceries from './goourhub/component/VisitGroceries';
import Eritrean from './goourhub/component/Eritrean';
import Ethiopian from './goourhub/component/Ethiopian';
import { getData, filter } from './goourhub/data/data';
import VisitFoodTrucks from './goourhub/component/VisitFoodTrucks';
import VisitKosherPlaces from './goourhub/component/VisitKosherPlaces';
import FarmersMarket from './goourhub/component/FarmersMarket';
import FleaMarketPlace from './goourhub/component/FleaMarketPlace';
import VisitVeganPlaces from './goourhub/component/VisitVeganPlaces';
import CleaningServices from './goourhub/component/CleaningServices';
import PetersEliteCleaning from './goourhub/component/PetersEliteCleaning';
import ITServices from './goourhub/component/ITServices';
import VaduAplu from './goourhub/component/VaduAplu';
import PartnerSignup from './goourhub/component/PartnerSignUp';
import ThankYouPartner from './goourhub/component/ThankYouPartner';
import FAQPage from './goourhub/component/FAQ';
import Terms from './goourhub/component/Terms';
import ZelleConfirmation from './goourhub/component/ZelleConfirmation';
import ZelleThankYou from './goourhub/component/ZelleThankYou';
import SocialLandingPage from './goourhub/component/SocialLandingPage';
import VendorThankYou from './goourhub/component/VendorThankYou'; 
import UserThankYou from './goourhub/component/UserThankYou';
import TacoBlvd from './goourhub/component/TacoBlvd';
import UnGwan from './goourhub/component/UnGwan';
import RigoPlant from './goourhub/component/RigoPlant';
import TrinketTrunk from './goourhub/component/TrinketTrunk';
import IcTroyz from './goourhub/component/IcToyz';
import TwoBunnies from './goourhub/component/TwoBunnies';
import G3Production from './goourhub/component/G3Production';
import JamesWreaths from './goourhub/component/JamesWreaths';
import AlexSword from './goourhub/component/AlexSword';
import Realtor from './goourhub/component/Realtor';
import Photography from './goourhub/component/Photography';
import Gardening from './goourhub/component/Gardening';
import Catering from './goourhub/component/Catering';
import Murals from './goourhub/component/Murals';
import Wreaths from './goourhub/component/Wreaths';
import StarrMurals from './goourhub/component/StarrMurals';
import NenaClothing from './goourhub/component/NenaClothing';
import FevenRealtor from './goourhub/component/FevenRealtor';
import Clothing from './goourhub/component/Clothing&Footwear';
import Toys from './goourhub/component/Toys';
import TwoLittleMonkeys from './goourhub/component/TwoLittleMonkeys';
import Nigerian from './goourhub/component/Nigerian';
import RequestForm from './goourhub/component/RequestForm';
import MiddleEastern from './goourhub/component/MiddleEastern';
import PromoSection from './goourhub/component/PromoSection';
import Jewelry from './goourhub/component/Jewelry';
import ChildCare from './goourhub/component/ChildCare';
import Electrical from './goourhub/component/Electrical';

import Plumbing from './goourhub/component/Plumbing';
import ElderlyCare from './goourhub/component/ElderlyCare';


function GoHubApp() {
  const [language, setLanguage] = useState("english"); // Default language
  const { frequentlyVisitedCommunities, communities, categories } = getData(language);
  const [searchQuery, setSearchQuery] = useState(""); // ✅ State for search
  const [filteredResults, setFilteredResults] = useState(categories); // ✅ State for filtered categories

  // ✅ Handle search and filter categories
  const handleSearch = (query) => {
    setSearchQuery(query); // Update the search query

    if (!query.trim()) {
      setFilteredResults(categories); // Show all if search is empty
    } else {
      const filtered = categories.filter((category) =>
        category.name.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredResults(filtered.length > 0 ? filtered : []);
    }
  };

  return (
    <Router>
      <div className="App">
        <Template>
          {/* Header Section */}
          <div className="headerSection">
            <Header onSearch={handleSearch} /> {/* ✅ Pass search handler */}
          </div>

         {/* Left Menu Section */}
         <div className="menuSection">
            <div className="menu-section">
              <LanguageDropdown selectedLanguage={language} onLanguageChange={setLanguage} />
              <span style={{ fontSize: '1.0rem', fontWeight: 'bold' }}>Community Hub</span>
              <CommunityConnect countries={communities} frequentlyVisited={frequentlyVisitedCommunities} />
              <SliderForMobile countries={[...frequentlyVisitedCommunities, ...communities]} />
            </div>
          </div>

 {/* Main Section */}
<div className="mainSection">
    <Routes>
        <Route
            path="/"
            element={
                <>
                    {/* ✅ Pass both key={language} and searchQuery */}
                    <ProductCategories 
  key={language} 
  searchQuery={searchQuery} 
  defaultCategories={ getData(language).defaultCategories || getData(language).categories } 
/>  
                    
                    <div className="new-tile-container-seperator"></div>
                    <ServiceCategories
  searchQuery={searchQuery}      // <-- pass it
  defaultServices={getData(language).service}
/>

                    <div className="new-tile-container-seperator"></div>
                </>
            }
              />
          
              
              <Route path="/partner-signup" element={<PartnerSignup />} />
              <Route path="/connect" element={<SocialLandingPage />} />
              <Route path="/vendor-thankyou" element={<VendorThankYou />} />
              <Route path="/user-thankyou" element={<UserThankYou />} />
              <Route path="/tacoblvd" element={<TacoBlvd />} />
              <Route path="/zelle-confirmation" element={<ZelleConfirmation />} />
              <Route path="/zelle-thank-you" element={<ZelleThankYou />} />
              <Route path="/terms" element={<Terms />} /> 
              <Route path="/faq" element={<FAQPage />} />
              <Route path="/thank-you-partner" element={<ThankYouPartner />} />
              <Route path="/vaduaplu" element={<VaduAplu />} />
              <Route path="/visitresturants" element={<VisitResturants />} />
              <Route path="/itservices" element={<ITServices />} />      
              <Route path="/visitgroceries" element={<VisitGroceries />} />
              <Route path="/visitveganplaces" element={<VisitVeganPlaces />} />
              <Route path="/peterselitecleaning" element={<PetersEliteCleaning />} />
              <Route path="/cleaning-services" element={<CleaningServices />} />
              <Route path="/visitkosherplaces" element={<VisitKosherPlaces />} />
              <Route path="/farmersmarket" element={<FarmersMarket />} />
              <Route path="/fleamarketplace" element={<FleaMarketPlace />} />
              <Route path="/visitfoodtrucks" element={<VisitFoodTrucks />} />
              <Route path="/visitgroceries" element={<VisitGroceries />} />
              <Route path="/pitaplus" element={<PitaPlus />} />
              <Route path="/selamfoodmart" element={<SelamFoodMart />} />
              <Route path="/AfricanVillage" element={<AfricanVillage />} />
              <Route path="/vendor-template1" element={<VendorTemplate1 />} />
              <Route path="/vendor-template2" element={<VendorTemplate2 />} />
              <Route path="/vendor-template3" element={<VendorTemplate3 />} />
              <Route path="/contact" element={<ContactUs />} />
              <Route path="/SignInModal" element={<SignInModal />} />
              <Route path="/SignUpModal" element={<SignUpModal />} />
              <Route path="/mexican" element={<Mexican />} />
              <Route path="/american" element={<American />} />
              <Route path="/ethiopian" element={<Ethiopian/>} />
              <Route path="/eritrean" element={<Eritrean />} />
              <Route path="/african" element={<African />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/Un-Gwan-Gway" element={<UnGwan />} />
              <Route path="/RigoPlant" element={<RigoPlant />} />
              <Route path="/TrinketTrunk" element={<TrinketTrunk />} />
              <Route path="/IcToyz" element={<IcTroyz />} />
              <Route path="/TwoBunnies" element={<TwoBunnies />} />
              <Route path="/G3Production" element={<G3Production />} />
              <Route path="/JamesWreaths" element={<JamesWreaths />} />
              <Route path="/AlexSword" element={<AlexSword />} />
              <Route path="/Realtor" element={<Realtor />} />
              <Route path="/Photography" element={<Photography />} />
              <Route path="/Gardening" element={<Gardening />} />
              <Route path="/Catering" element={<Catering />} />
              <Route path="/Murals" element={<Murals />} />
              <Route path="/Wreaths" element={<Wreaths />} />
              <Route path="/StarrMurals" element={<StarrMurals />} />
              <Route path="/NenaClothing" element={<NenaClothing />} />
              <Route path="/FevenRealtor" element={<FevenRealtor />} />
              <Route path="/Clothing" element={<Clothing />} />
              <Route path="/Toys" element={<Toys />} />
              <Route path="/TwoLittleMonkeys" element={<TwoLittleMonkeys />} />
              <Route path="/Nigerian" element={<Nigerian />} />
              <Route path="/request-form" element={<RequestForm />} />
             <Route path='/MiddleEastern' element={<MiddleEastern />} />
             <Route path='/ChildCare'element={<ChildCare />} />
            
<Route path='/PromoSection' element={<PromoSection />} />
<Route path='/Jewelry' element={<Jewelry />} />
<Route path='/Electrical' element={<Electrical/>} />
<Route path='/Plumbing'  element={<Plumbing/>} />
<Route path='/ElderlyCare'  element={<ElderlyCare/>} />




            
            </Routes>
          </div>

          {/* Right Navigation Section */}
          <div className="rightNavSection">
          <TransitioningSectionForAd />
            <ImportantLinks />
           
          </div>

          {/* Footer Section */}
          <Footer />
        </Template>
      </div>
    </Router>
  );
}

export default GoHubApp;
