import React, { useEffect, useState } from 'react';

function highlightText(text, query) {
  if (!query) return text;
  const regex = new RegExp(`(${query})`, 'gi');
  const parts = text.split(regex);
  return (
    <>
      {parts.map((part, i) =>
        part.toLowerCase() === query.toLowerCase() ? (
          <mark key={i}>{part}</mark>
        ) : (
          part
        )
      )}
    </>
  );
}

function ServiceCategories({ searchQuery = "", defaultServices: propDefaultServices }) {
  // Fallback services list in case no prop is provided
  const fallbackServices = [
    { name: 'Boutique', icon: '👜' },
    { name: 'Home Help', icon: '🏡' },
    { name: "Farmers’s Market", icon: '🌽', url: '/farmersmarket' },
    { name: 'Transportation', icon: '🚗' },
    { name: 'Legal', icon: '⚖️' },
    { name: 'Education', icon: '📚' },
    { name: 'Health', icon: '⚕️' },
    { name: 'Student Services', icon: '🎓' },
    { name: 'Beauty', icon: '💄' },
    { name: 'Tailor', icon: '👗' },
    { name: 'Venues', icon: '🏢' },
   
    { name: 'Event Management', icon: '🎉' },
    { name: 'Jewelry', icon: '💍' },
   
   
    { name: 'Pet Care', icon: '🐾' },
    { name: 'Child Care', icon: '👶' },
    { name: 'Elderly Care', icon: '🧓' },
    { name: 'Marketing', icon: '📈' },
   
    { name: 'Legal Advice', icon: '⚖️' },
    { name: 'Fitness Training', icon: '🏋️' },
    { name: 'Repair Services', icon: '🔧' },
    { name: 'Transportation', icon: '🚛' },
    { name: 'Tutoring', icon: '📖' },
    { name: 'Consulting', icon: '🤝' },
    { name: 'Personal Shopping', icon: '🛍️' },
    { name: 'Accommodation', icon: '🏠' },
    { name: 'Construction', icon: '🔧' },
    { name: 'Storage', icon: '📦' },
    { name: 'Translation', icon: '🌐' },
    { name: 'Worship Places', icon: '⛪' },
    { name: 'Sports', icon: '🏅' },
    { name: 'Entertainment', icon: '🎭' },
    { name: 'Employment', icon: '💼' },
    { name: 'Financial', icon: '💰' },
    { name: 'Plumbing', icon: '🚰' },
    { name: 'Electrical', icon: '💡' },
  ];

  // Use the passed defaultServices prop if provided; otherwise, use fallbackServices.
  const servicesDefault = propDefaultServices || fallbackServices;
  const [servicesToRender, setServicesToRender] = useState(servicesDefault);

  useEffect(() => {
    const query = searchQuery ? searchQuery.toLowerCase().trim() : "";
    if (query === "") {
      setServicesToRender(servicesDefault);
    } else {
      const filteredServices = servicesDefault.filter(service =>
        service.name.toLowerCase().includes(query)
      );
      setServicesToRender(filteredServices.length > 0 ? filteredServices : []);
    }
  }, [searchQuery, servicesDefault]);

  return (
    <div className="service-categories-container">
      <div className="new-tile-container">
        {servicesToRender.length > 0 ? (
          servicesToRender.map((service, index) => (
            <div key={index} className="new-tile">
              <span className="icon-for-new-tile">{service.icon}</span>
              <span>{highlightText(service.name, searchQuery)}</span>
            </div>
          ))
        ) : (
          <p>No results found.</p>
        )}
      </div>
    </div>
  );
}

export default ServiceCategories;