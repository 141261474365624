import React, { useState } from "react";
import "./FAQ.css";

const FAQPage = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "How do I contact GoOurHub for more complex needs?",
      answer:
        "If you require custom solutions beyond this trial offer, please Contact Us or email us at goourhub@gmail.com. Our team will be happy to assist you with tailored services.",
    },
    {
      question: "Will I receive an acknowledgment of my payment?",
      answer:
        "Yes. Once your payment is processed, you will receive an automatic confirmation via email.",
    },
    {
      question: "What if I change my mind after signing up?",
      answer:
        "You have a three-day window to withdraw your request. To cancel, send us an email in writing at goourhub@gmail.com or use our Contact Us form.",
    },
    {
      question: "Are the fees refundable?",
      answer:
        "• Fees are non-refundable once the trial setup process begins.\n• The only exception is if you cancel within the three-day withdrawal period.",
    },
    {
      question: "What are the next steps after I sign up and pay?",
      answer:
        "After signing up and making your payment:\n1. You will receive an acknowledgment email.\n2. If your uploads meet GoOurHub’s quality and content standards, we will send you an estimated date for prototype review.\n3. You will receive a preview for proofreading and final approval before your listing is posted.",
    },
    {
      question:
        "If I have a standard GoOurHub static page, do I need to approve it?",
      answer:
        "No. If you opt for the standard GoOurHub static page, it will be automatically processed.\n• However, it is your responsibility to review the content for accuracy.\n• If you find any errors, you must contact GoOurHub immediately to request corrections.",
    },
  ];

  return (
    <div className="faq-container">
      <h1 className="faq-title">Frequently Asked Questions (FAQs)</h1>
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className={`faq-item ${openIndex === index ? "active" : ""}`}
            onClick={() => toggleFAQ(index)}
          >
            <div className="faq-question">
              {faq.question}
              <span className="faq-toggle-icon">
                {openIndex === index ? "▲" : "▼"}
              </span>
            </div>
            <div
              className="faq-answer"
              style={{ maxHeight: openIndex === index ? "500px" : "0px" }}
            >
              <p className="faq-p">{faq.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQPage;
