import React, { useState, useEffect } from "react";
import logo from "../images/VaduAplu.jpg";
import adGoOurHub from "../images/ad_goourhub.jpg"; // <-- import your ad image
import "./VaduAplu.css";

const images = [
  require("../images/vadu4.jpg"),
  require("../images/vadu5.jpg"),
  require("../images/vadu6.jpg"),
  require("../images/vadu1.jpg"),
  require("../images/vadu2.jpg"),
  require("../images/vadu3.jpg"),
];

function VaduAplu() {
  const [dealIndex, setDealIndex] = useState(0);
  const [fade, setFade] = useState(true);

  const deals = [
    "Get 10% on services when you mention GOOURHUB",
    "Mention GoOurHub to get better price/discounts",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setDealIndex((prev) => (prev + 1) % deals.length);
        setFade(true);
      }, 500);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="vaduaplu-container">
      {/* Header */}
      <header className="vaduaplu-header">
        {/* Left: Logo + Name */}
        <div className="vaduaplu-header-left">
          <img src={logo} alt="Logo" className="vaduaplu-logo" />
          <div className="vaduaplu-header-text">
            <h1 className="vaduaplu-company-name">VaduAplu LLC</h1>
          </div>
        </div>

        {/* Right: Phone + Address */}
        <div className="vaduaplu-header-right">
          <span className="vaduaplu-phone">Phone: 210-251-7854</span>
          <span className="vaduaplu-address">
            San Antonio, TX 78230
          </span>
        </div>
      </header>

      {/* Contact Info */}
      <div className="vaduaplu-contact-info">
        <p className="vaduaplu-text">Reach Us Here — Email | VaduAplu@gmail.com</p>
      </div>

      {/* Single Top Box combining Quick Menu & Deals */}
      <div className="vaduaplu-top-box">
        {/* Deals Section */}
        <div className="vaduaplu-top-section">
          <h2 className="vaduaplu-heading">Get Deals</h2>
          {/* Flashing Text */}
          <p className={`vaduaplu-deals-text ${fade ? "fade-in" : "fade-out"}`}>
            {deals[dealIndex]}
          </p>

          {/* Static Image below the deals text */}
          <img
            src={adGoOurHub}
            alt="GoOurHub Ad"
            className="vaduaplu-ad-image"
          />
        </div>

        {/* Quick Menu Section */}
        <div className="vaduaplu-top-section">
          <h2 className="vaduaplu-heading">Quick Menu</h2>
          <div className="vaduaplu-accordion">
            <div className="vaduaplu-accordion-item">
              <input type="checkbox" id="service1" className="hidden-checkbox"defaultChecked />
              <label htmlFor="service1" className="vaduaplu-label">
                Our Services
              </label>
              <div className="vaduaplu-accordion-content">
              <ul className="vaduaplu-list">
                <li className="vaduaplu-list-item">Websites</li>
                <li className="vaduaplu-list-item">Software Solutions</li>
                <li className="vaduaplu-list-item">IT services</li>
                <li className="vaduaplu-list-item">Banner Design</li>
                  <li className="vaduaplu-list-item">Logo Design</li>
                  <li className="vaduaplu-list-item">Digital Editing</li>
                  <li className="vaduaplu-list-item">Flyers</li>
                  <li className="vaduaplu-list-item">Photography</li>
                </ul>
              </div>
            </div>git <ul
              class="nav nav-tabs  "
            >
              <li class="nav-item">
                <a href="#" class="nav-link active" aria-current="page"
                  >Active</a
                >
              </li>
              <li class="nav-item">
                <a href="#" class="nav-link">Link</a>
              </li>
              <li class="nav-item disabled">
                <a href="#" class="nav-link">Disabled</a>
              </li>
            </ul>
            
          </div>
        </div>
      </div>

      {/* Main Content / Gallery */}
      <main className="vaduaplu-main-content">
        <h2 className="vaduaplu-heading">Gallery</h2>
        <div className="vaduaplu-gallery">
          {[...Array(6)].map((_, index) => (
            <img
              key={index}
              src={images[index % images.length]}
              alt={`Work Sample ${index + 1}`}
              className="vaduaplu-image"
            />
          ))}
        </div>
      </main>

      {/* Extra Text Section */}
      <div className="vaduaplu-extra-text">
        <p className="vaduaplu-extra-text-p">
        Simplifying solutions for a complex world. VaduAplu LLC is a one-stop shop for all your digital needs. We provide services ranging from website development to digital editing. Contact us today to get started!
        </p>
      </div>
    </div>
  );
}

export default VaduAplu;
