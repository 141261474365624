import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './VisitResturants.css';
import CouponBanner from './CouponBanner';
const categories = [
  { name: 'African Village', icon: '🏘️', url: '/africanvillage', address: '123 Village Rd', phone: '123-456-7890' },
  { name: 'Taco Blvd', icon: '🍽️', url: '/tacoblvd', address: '456 Taco St', phone: '321-654-0987' },
  { name: 'Un-Gwan-Gway', icon: '🦁', url: '/un-Gwan-Gway', address: '789 Lion Ln', phone: '555-123-9999' },
 
{
  name: 'Hoka Hoka Fuku', icon: '🍽️', address: '11842 Bandera Rd, Helotes, TX 78023', phone : '(210) 530-1777', url: 'https://hokahokafuku.com/'
},{
  name: 'Syrian Kitchen',
  icon: '🍽️',
  address: '5750 Evers Rd, San Antonio, TX 78238',
  phone: '(210) 334-0589',
  url: 'https://syriankitchensa.com'
},
{
  name: "Mina & Dimi's Greek House",
  icon: '🍴',
  address: '2102 S Zarzamora St, San Antonio, TX 78207',
  phone: '(210) 432-4321',
  url: 'https://agreekhouse.com'
},
{
  name: "Demo's Greek Food",
  icon: '🍴',
  address: '7115 Blanco Rd/Loop 410, San Antonio',
  phone: '(210) 342-2772',
  url: 'https://demosgreekfood.com'
},

{
  name: "Chef's Table Turkish Mediterranean Grill",
  icon: '🍽️',
  address: '11851 Bandera Rd, Suite 123, Helotes, TX 78023',
  phone: '(210) 503-9070',
  url: 'https://chefstableturkishgrill.com'
},

{
  name: 'Jerusalem Grill',
  icon: '🍴',
  address: '5339 Wurzbach Rd, San Antonio, TX 78238',
  phone: '(210) 647-3333',
  url: 'https://jerusalemgrill.com'
},
{
  name: 'Kababchi Grill',
  icon: '🍴',
  address: '8222 Fredericksburg Rd, San Antonio, TX 78229',
  phone: '(210) 561-9000',
  url: 'https://kababchigrill.com'
},
// NO URL

  { 
    name: "Pasha Mediterranean Grill", 
    icon: "🍽️", 
    address: "Multiple Locations, San Antonio, TX", 
    phone: "(210) 592-9800", 
    url: "https://gopasha.com/"
  },
  { 
    name: "John The Greek Restaurant", 
    icon: "🍽️", 
    address: "16602 San Pedro Ave, San Antonio, TX 78232", 
    phone: "(210) 403-0565", 
    url: "https://www.johnthegreek.com/"
  },
  { 
    name: "Papouli's Greek Grill", 
    icon: "🍽️", 
    address: "Multiple Locations, San Antonio, TX", 
    phone: "(210) 545-7600", 
    url: "https://papoulis.com/"
  },
  { 
    name: "The Great Greek Mediterranean Grill", 
    icon: "🍽️", 
    address: "11647 Culebra Rd, San Antonio, TX 78254", 
    phone: "(210) 255-8244", 
    url: "https://www.thegreatgreekgrill.com/san-antonio-tx"
  },
  { 
    name: "Dallah Mediterranean Cuisine", 
    icon: "🍽️", 
    address: "5455 Babcock Rd #100, San Antonio, TX 78240", 
    phone: "(210) 558-3200", 
    url: "https://www.dallahsanantonio.com/"
  },
  { 
    name: "Ladino", 
    icon: "🍽️", 
    address: "200 E Grayson St Suite 100, San Antonio, TX 78215", 
    phone: "(210) 390-5555", 
    url: "https://ladinosatx.com/"
  },
  { 
    name: "Dimassi's Mediterranean Buffet", 
    icon: "🍽️", 
    address: "12858 Frontage Rd, San Antonio, TX 78249", 
    phone: "(210) 558-6200", 
    url: "https://www.dimassis.com/dimassis-mediterranean-buffet-lcations/san-antonio-de-zavala-tx"
  },
  { 
    name: "Zorba's Greek Mediterranean Grill", 
    icon: "🍽️", 
    address: "2110 NW Military Hwy, San Antonio, TX 78213", 
    phone: "(210) 541-9936", 
    url: "https://www.zorbasgreekmediterranean.com/"
  },
  { 
    name: "Demo's Greek Food", 
    icon: "🍽️", 
    address: "Multiple Locations, San Antonio, TX", 
    phone: "(210) 732-7777", 
    url: "https://www.demosgreekfood.com/"
  },
  { 
    name: "Kababchi Grill", 
    icon: "🍽️", 
    address: "8222 Fredericksburg Rd, San Antonio, TX 78229", 
    phone: "(210) 561-9000", 
    url: "http://kababchigrill.com/"
  },{
    name: "Mi Tierra Café y Panadería",
    icon: "🍽️",
    address: "218 Produce Row, San Antonio, TX 78207",
    phone: "(210) 225-1262",
    url: "https://lafamiliacortez.com/restaurants/mi-tierra/"
  },
  {
    name: "Rosario's Mexican Cafe Y Cantina",
    icon: "🍽️",
    address: "910 S Alamo St, San Antonio, TX 78205",
    phone: "(210) 223-1806",
    url: "https://rosariossa.com/"
  },

 
  {
    name: "El Taco Tote",
    icon: "🍽️",
    address: "8403 State Hwy 151 Access Rd, San Antonio, TX 78245",
    phone: "(210) 647-8226",
    url: "https://www.tacotote.com/"
  },
  {
    name: "Taco Palenque",
    icon: "🍽️",
    address: "1002 NE Interstate 410 Loop, San Antonio, TX 78209",
    phone: "(210) 822-1000",
    url: "https://www.tacopalenque.com/"
  },
  {
    name: "Pollos Asados Los Nortenos",
    icon: "🍽️",
    address: "4642 Rigsby Ave, San Antonio, TX 78222",
    phone: "(210) 648-3303",
    url: "https://www.tripadvisor.com/Restaurant_Review-g60956-d2423299-Reviews-Pollos_Asados_Los_Nortenos-San_Antonio_Texas.html"
  },
  {
    name: "Salsita's Drinks & Tacos",
    icon: "🍽️",
    address: "19179 Blanco Rd, San Antonio, TX 78258",
    phone: "(210) 545-2188",
    url: "https://www.tripadvisor.com/Restaurant_Review-g60956-d12205636-Reviews-Salsita_s_Drinks_Tacos-San_Antonio_Texas.html"
  },
  {
    name: "Domingo Restaurant",
    icon: "🍽️",
    address: "123 N St Mary's St, San Antonio, TX 78205",
    phone: "(210) 404-7516",
    url: "https://www.tripadvisor.com/Restaurant_Review-g60956-d23221493-Reviews-Domingo_Restaurant-San_Antonio_Texas.html"
  },
  {
    name: "Henry's Puffy Tacos",
    icon: "🍽️",
    address: "6030 Bandera Rd, San Antonio, TX 78238",
    phone: "(210) 647-8339",
    url: "https://www.mysanantonio.com/food/article/san-antonio-breakfast-tacos-bandera-road-20238570.php"
  },
 
  {
    name: "Rosario's Mexican Cafe y Cantina",
    icon: "🍽️",
    address: "9715 San Pedro Ave, San Antonio, TX 78216",
    phone: "(210) 223-1806",
    url: "https://rosariossa.com/"
  },
  
  {
    name: "Los Barrios Mexican Restaurant",
    icon: "🍽️",
    address: "4223 Blanco Rd, San Antonio, TX 78212",
    phone: "(210) 732-6017",
    url: "https://www.losbarriosrestaurant.com/"
  },
  {
    name: "El Taco Tote",
    icon: "🍽️",
    address: "9502 W Interstate 10, San Antonio, TX 78230",
    phone: "(210) 647-8226",
    url: "https://www.tacotote.com/"
  },
  {
    name: "Taco Palenque",
    icon: "🍽️",
    address: "811 San Pedro Ave, San Antonio, TX 78212",
    phone: "(210) 822-1000",
    url: "https://www.tacopalenque.com/"
  },
  {
    name: "Mixtli",
    icon: "🍽️",
    address: "812 S Alamo St Ste 103, San Antonio, TX 78205",
    phone: "(210) 338-0746",
    url: "https://restaurantmixtli.com/"
  },
  {
    name: "Henry's Puffy Tacos & Cantina",
    icon: "🍽️",
    address: "6030 Bandera Rd, San Antonio, TX 78238",
    phone: "(210) 432-7341",
    url: "https://www.henryspuffytacos.com/"
  },
  {
    name: "Garcia's Mexican Food",
    icon: "🍽️",
    address: "842 Fredericksburg Rd, San Antonio, TX 78201",
    phone: "(210) 735-4525",
    url: "https://www.garciasmexicanfood.com/"
  },
  {
    name: "Taquitos West Ave.",
    icon: "🍽️",
    address: "2818 West Ave, San Antonio, TX 78201",
    phone: "(210) 525-9888",
    url: "https://www.taquitoswestavenue.com/"
  },
  {
    name: "Mi Tierra Café y Panadería",
    icon: "🍽️",
    address: "218 Produce Row, San Antonio, TX 78207",
    phone: "(210) 225-1262",
    url: "https://lafamiliacortez.com/restaurants/mi-tierra/"
  },
  {
    name: "Rosario's Mexican Cafe Y Cantina",
    icon: "🍽️",
    address: "910 S Alamo St, San Antonio, TX 78205",
    phone: "(210) 223-1806",
    url: "https://rosariossa.com/"
  },
  {
    name: "La Fonda on Main",
    icon: "🍽️",
    address: "2415 N Main Ave, San Antonio, TX 78212",
    phone: "(210) 733-0621",
    url: "https://www.lafondaonmain.com/"
  },
  {
    name: "Los Barrios Mexican Restaurant",
    icon: "🍽️",
    address: "4223 Blanco Rd, San Antonio, TX 78212",
    phone: "(210) 732-6017",
    url: "https://www.losbarriosrestaurant.com/"
  },
  {
    name: "Henry’s Puffy Tacos",
    icon: "🍽️",
    address: "6030 Bandera Rd, San Antonio, TX 78238",
    phone: "(210) 647-8339",
    url: "https://www.henryspuffytacos.com/"
  },
  {
    name: "Taco Garage",
    icon: "🍽️",
    address: "8403 Broadway St, San Antonio, TX 78209",
    phone: "(210) 826-4405",
    url: "https://tacogarage.com/"
  },
  {
    name: "The Original Blanco Café",
    icon: "🍽️",
    address: "419 N St Mary's St, San Antonio, TX 78205",
    phone: "(210) 271-3300",
    url: "http://www.blancocafe.net/"
  },
  {
    name: "Chacho’s",
    icon: "🍽️",
    address: "7870 Callaghan Rd, San Antonio, TX 78229",
    phone: "(210) 375-6400",
    url: "https://chachos.com/"
  },
  {
    name: "Nicha’s Comida Mexicana",
    icon: "🍽️",
    address: "3119 Roosevelt Ave, San Antonio, TX 78214",
    phone: "(210) 922-3330",
    url: "https://www.nichas.com/"
  },
  {
    name: "Panchito’s Mexican Restaurant",
    icon: "🍽️",
    address: "4100 McCullough Ave, San Antonio, TX 78212",
    phone: "(210) 821-5338",
    url: "https://www.panchitos.net/"
  },{
    name: "Pasha Mediterranean Grill",
    icon: "🍽️",
    address: "9339 Wurzbach Rd, San Antonio, TX 78240",
    phone: "(210) 561-5858",
    url: "https://gopasha.com/"
  },
  {
    name: "Jerusalem Grill",
    icon: "🍽️",
    address: "3259 Wurzbach Rd, San Antonio, TX 78238",
    phone: "(210) 680-8400",
    url: "https://jerusalemgrillsa.com/"
  },
  {
    name: "Dimassi's Mediterranean Buffet",
    icon: "🍽️",
    address: "12636 West Ave, San Antonio, TX 78216",
    phone: "(210) 979-1700",
    url: "https://www.dimassis.com/"
  },
  {
    name: "Shisha Café",
    icon: "🍽️",
    address: "5500 Babcock Rd, San Antonio, TX 78240",
    phone: "(210) 694-4800",
    url: "http://www.shishacafesa.com/"
  },
  {
    name: "Ali Baba Mediterranean Grill",
    icon: "🍽️",
    address: "9307 Wurzbach Rd, San Antonio, TX 78240",
    phone: "(210) 691-1111",
    url: "https://www.facebook.com/AliBabaInternationalMarket/"
  },
  
  {
    name: "Naara Cafe and Hookah",
    icon: "🍽️",
    address: "9320 Wurzbach Rd, San Antonio, TX 78240",
    phone: "(210) 561-8336",
    url: "http://naaracafe.com/"
  },
  {
    name: "Kababchi Grill",
    icon: "🍽️",
    address: "9627 Wurzbach Rd, San Antonio, TX 78240",
    phone: "(210) 982-3433",
    url: "https://kababchigrill.com/"
  },

  ,
  {
    name: "Mina & Dimi's Greek House",
    icon: "🍽️",
    address: "7159 W US Hwy 90, San Antonio, TX 78227",
    phone: "12106743464",
    url: "http://www.agreekhouse.com/"
  },
  {
    name: "Opah Greek Flavor",
    icon: "🍽️",
    address: "7400 San Pedro Ave, San Antonio, TX 78216",
    phone: "12103499501",
    url: "https://m.facebook.com/pages/Opah-Greek-Flavor/121683691175869"
  },
  {
    name: "Zaatar Lebanese Grill",
    icon: "🍽️",
    address: "9323 Wurzbach Rd, San Antonio, TX 78240",
    phone: "12104753699",
    url: "http://gozaatar.com"
  },
  {
    name: "Chef's Table Turkish Mediterranean Grill",
    icon: "🍽️",
    address: "1546 Babcock Rd #102, San Antonio, TX 78229",
    phone: "12106645100",
    url: "https://www.chefstableturkishgrill.com"
  },
  {
    name: "Cedar Mediterranean Grill",
    icon: "🍽️",
    address: "8620 Fredericksburg Rd, San Antonio, TX 78240",
    phone: "12105586200",
    url: "https://www.cedarmediterraneangrillsa.com"
  },
  {
    name: "Mediterranean Turkish Grill",
    icon: "🍽️",
    address: "8507 McCullough Ave Suite B13, San Antonio, TX 78216",
    phone: "12103991645",
    url: "None"
  },
  {
    name: "Hala Cafe",
    icon: "🍽️",
    address: "10865 Shaenfield Rd #2103, San Antonio, TX 78254",
    phone: "12106004411",
    url: "http://hala.cafe"
  },
  {
    name: "Jerusalem Grill Mediterranean Restaurant-San Antonio",
    icon: "🍽️",
    address: "203 N Loop 1604 W, San Antonio, TX 78232",
    phone: "12104814210",
    url: "http://www.jerusalemgrill.net"
  },
  {
    name: "Pasha Mediterranean Grill",
    icon: "🍽️",
    address: "1207 N Loop 1604 W, San Antonio, TX 78232",
    phone: "12107641104",
    url: "https://gopasha.com"
  },
  {
    name: "Shawarma-TAK",
    icon: "🍽️",
    address: "15503 Babcock Rd, San Antonio, TX 78255",
    phone: "12106128903",
    url: "https://shawarmatak0645.s4shops.com"
  },
  {
    name: "Habibi Cafe",
    icon: "🍽️",
    address: "5306 Broadway, San Antonio, TX 78209",
    phone: "12104370242",
    url: "None"
  },
  {
    name: "Makan Halal Cuisine",
    icon: "🍽️",
    address: "7959 Fredericksburg Rd #215, San Antonio, TX 78229",
    phone: "12105004098",
    url: "None"
  },
  {
    name: "Pasha Express Mediterranean",
    icon: "🍽️",
    address: "10650 Culebra Rd #101, San Antonio, TX 78251",
    phone: "12105921666",
    url: "http://www.gopasha.com/"
  },
  {
    name: "Dimassi's Mediterranean Buffet",
    icon: "🍽️",
    address: "12858 I-10, San Antonio, TX 78249",
    phone: "12102512124",
    url: "https://www.dimassis.com/dimassis-mediterranean-buffet-lcations/san-antonio-de-zavala-tx"
  },
  {
    name: "Luxor Mediterranean at the RIM",
    icon: "🍽️",
    address: "17631 La Cantera Pkwy Suite #105 RIM, San Antonio, TX 78257",
    phone: "12104055555",
    url: "https://www.luxorcuisine.com"
  },
  {
    name: "Syrian Kitchen Halal Food",
    icon: "🍽️",
    address: "SINBAD CAFE, 5750 Evers Rd, San Antonio, TX 78238",
    phone: "12103340589",
    url: "https://menu.syriankitchensa.com/ar/"
  },
  {
    name: "Pita Pita Plus",
    icon: "🍽️",
    address: "16900 Blanco Rd, San Antonio, TX 78232",
    phone: "12109082595",
    url: "https://www.pitaplustx.com/"
  },
  {
    name: "NY Gyro King",
    icon: "🍽️",
    address: "19903 Stone Oak Pkwy suite 102, San Antonio, TX 78258",
    phone: "12102549368",
    url: "None"
  },
  {
    name: "CAVA",
    icon: "🍽️",
    address: "11225 Huebner Rd Suite 105, San Antonio, TX 78230",
    phone: "18302055293",
    url: "http://cava.com/"
  },
  {
    name: "Radad Grill",
    icon: "🍽️",
    address: "2317 Vance Jackson Rd, San Antonio, TX 78213",
    phone: "12103103869",
    url: "https://radadgrill.com/?utm_source=gbp"
  },
  
  {
    name: "CAVA",
    icon: "🍽️",
    address: "427 N Loop 1604 W Acc Road Suite 210, San Antonio, TX 78258",
    phone: "18302055302",
    url: "http://cava.com/"
  },
  {
    name: "CAVA",
    icon: "🍽️",
    address: "999 E Basse Rd Suite 125, San Antonio, TX 78209",
    phone: "17266103055",
    url: "http://cava.com/"
  },
  {
    name: "Rotana Café",
    icon: "🍽️",
    address: "2250 Thousand Oaks Dr Suite 218, San Antonio, TX 78232",
    phone: "12108868945",
    url: "None"
  },
  {
    name: "Dallah Mediterranean Cuisine",
    icon: "🍽️",
    address: "5450 Babcock Rd #112, San Antonio, TX 78240",
    phone: "12102339574",
    url: "https://www.dallahsanantonio.com"
  },
  {
    name: "Pasha Mediterranean Grill",
    icon: "🍽️",
    address: "9339 Wurzbach Rd, San Antonio, TX 78240",
    phone: "12105615858",
    url: "https://gopasha.com"
  },
  {
    name: "Naara Cafe and Hookah",
    icon: "🍽️",
    address: "9329 Wurzbach Rd, San Antonio, TX 78240",
    phone: "12105589800",
    url: "http://naaracafe.com"
  },
  {
    name: "Kababchi Grill",
    icon: "🍽️",
    address: "5500 Babcock Rd Ste. 102, San Antonio, TX 78240",
    phone: "12109609999",
    url: "http://kababchigrill.com"
  },
  {
    name: "The Pita Chick",
    icon: "🍽️",
    address: "4302 Hyatt Pl Dr, San Antonio, TX 78230",
    phone: "19513739977",
    url: "http://thepitachick.com"
  },
  {
    name: "Ladino",
    icon: "🍽️",
    address: "200 E Grayson St #100, San Antonio, TX 78215",
    phone: "12103256007",
    url: "https://ladinosatx.com"
  },
  {
    name: "Shawarma Press - San Antonio",
    icon: "🍽️",
    address: "Inside Walmart, 11210 Potranco Rd Suite 400, San Antonio, TX 78253",
    phone: "12107018559",
    url: "http://shawarmapress.com"
  },
  {
    name: "Shisha Cafe",
    icon: "🍽️",
    address: "5500 Babcock Rd #101, San Antonio, TX 78240",
    phone: "12106944800",
    url: "http://shishacafesa.com"
  },
  {
    name: "Abu Omar Halal - San Antonio, TX",
    icon: "🍽️",
    address: "7038 UTSA Boulevard, San Antonio, TX 78249",
    phone: "12104398514",
    url: "https://abuomarhalal.com"
  },
  {
    name: "Moroccan Bites Tajine",
    icon: "🍽️",
    address: "5718 Evers Rd, San Antonio, TX 78238",
    phone: "12107069700",
    url: "http://moroccanbitestagine.com"
  },
  {
    name: "Yummy Kosher Grill",
    icon: "🍽️",
    address: "Oak Grove Dr #15511, San Antonio, TX 78255",
    phone: "12107506770",
    url: "https://yummykoshergrill.com"
  },
];

 


function VisitResturants() {
  const [selected, setSelected] = useState(null);
  const navigate = useNavigate();

  const handleTileClick = (category) => {
    if (category.url?.startsWith('/')) {
      navigate(category.url); // Internal route
    } else {
      setSelected(category); // Show popup for external or missing URL
    }
  };
  

  const closePopup = () => setSelected(null);

  return (
    <div className="categories-container">
      <CouponBanner />
      <div className="new-tile-container-new">
        {categories.map((category, index) => (
          <div
          key={index}
          className={`new-tile ${category.url ? 'tile-clickable' : 'tile-informational'}`}
          onClick={() => handleTileClick(category)}
        >
          <span className="icon-for-new-tile">{category.icon}</span>
          
          <span className="tile-name">
            {category.name}
            {(!category.url || category.url.startsWith('http')) && (
              <span className="info-badge" title="Unverified">ⓘ</span>
            )}
          </span>
        </div>
        
        ))}
      </div>

      <div className="back-home-tile">
        <Link to="/" className="back-home-link">Go Back to Homepage</Link>
      </div>

      {/* Popup Box */}
      {selected && (
  <div className="popup-overlay" onClick={closePopup}>
    <div className="popup-box" onClick={(e) => e.stopPropagation()}>
      <h3>{selected.name}</h3>
      <p><strong>Address:</strong> {selected.address}</p>
      <p><strong>Phone:</strong> {selected.phone}</p>

      {selected.url && selected.url.startsWith('http') && (
  <p>
    <a
      href={selected.url}
      target="_blank"
      rel="noopener noreferrer"
      className="business-website-link"
    >
      {selected.url
        .replace(/^https?:\/\//, '') 
        .replace(/\/$/, '')}         
    </a>
  </p>
)}


      <button onClick={closePopup}>Close</button>

      {selected.name  && (
        <div className="extra-links">
          <Link to="/request-form" className="popup-link">Claim your business</Link> <span> / </span>
          <Link to="/request-form" className="popup-link">Opt out</Link> <span> / </span>
          <Link to="/request-form" className="popup-link">Suggest update</Link>
        </div>
      )}
      <hr className="popup-divider" />

<p className="disclaimer-text">
Disclaimer: This listing has not been claimed or verified by the business owner.
Information provided is based on publicly available sources.
</p>

    </div>
  </div>
)}

    </div>
  );
}

export default VisitResturants;
