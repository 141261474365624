import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../logo.png";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import "./ThankYouPartner.css";

const VendorThankYou = () => {
  const contentRef = useRef(null);

  // Define states here clearly
  const [noData, setNoData] = useState(false);
  const [loading, setLoading] = useState(true);
  const [first_name, setfirst_name] = useState("");
  const [vendorID, setVendorID] = useState("");
  const [currentDate, setCurrentDate] = useState("");

  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toLocaleDateString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    setCurrentDate(formattedDate);

    const dataString = localStorage.getItem("pendingVendorSignup");
    if (!dataString) {
      setNoData(true);
      setLoading(false);
      return;
    }

    const data = JSON.parse(dataString);
    setfirst_name(data.first_name);
    setVendorID(data.vendorId);

    // After data is retrieved, clear local storage
    localStorage.removeItem("pendingVendorSignup");

    setLoading(false);
  }, []);

  if (loading) {
    return (
      <div className="thank-you-page">
        <p>Processing your payment details, please wait...</p>
      </div>
    );
  }

  if (noData) {
    return (
      <div className="thank-you-page">
        <h2>No Signup Data Found</h2>
        <p>
          If you just completed a payment and reached this page, please contact
          us for assistance.
        </p>
        <Link to="/" className="neon-home-button pdf-hide">
          Back to Home
        </Link>
      </div>
    );
  }

  return (
    <div className="thank-you-page">
      <button
        className="neon-button pdf-hide"
        onClick={() => {
          const input = contentRef.current;
          const buttons = document.querySelectorAll(".pdf-hide");
          buttons.forEach((btn) => (btn.style.display = "none"));
          html2canvas(input, { scale: 3 }).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF("p", "mm", "a4");
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
            pdf.save(`Thank_You_${first_name}.pdf`);
            buttons.forEach((btn) => (btn.style.display = "block"));
          });
        }}
      >
        Download as PDF
      </button>

      <div ref={contentRef} className="thank-you-content">
        <div className="thank-you-logo">
          <img src={logo} alt="GoOurHub Logo" />
          <p className="thank-you-date">{currentDate}</p>
        </div>
        <h1 className="thank-you-title">
          Thank You, {first_name.charAt(0).toUpperCase() + first_name.slice(1)}!
        </h1>
        <h2 className="vendor-id">Your Vendor ID: {vendorID}</h2>
        <p>
          We are thrilled that you have signed up. Welcome to{" "}
          <strong>GoOurHub</strong>. Your application has been submitted.
        </p>
        <p>
          We appreciate your business. Together we will create{" "}
          <strong>amazing opportunities</strong>.
        </p>
        <p>
          If you have any questions, feel free to{" "}
          <Link to="/contact" className="thank-you-contact">
            contact us
          </Link>
          .
        </p>
        <Link to="/" className="neon-home-button pdf-hide">
          Back to Home
        </Link>
      </div>
    </div>
  );
};

export default VendorThankYou;
