import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import 'flag-icons/css/flag-icons.min.css'; // Import flag-icons
import './FlagStyles.css'; // Import custom CSS for flag styles

function CommunityConnect({ countries, frequentlyVisited }) {
  const navigate = useNavigate(); // Hook for navigation

  return (
    <div className="left-column">
      {/* Frequently Visited Section */}
      <div>
        <h5 className="section-heading margin-top">Frequently Visited</h5>
        <div className="frequent-list">
          {frequentlyVisited.map((country, index) => (
            <button
              key={index}
              className="flag-button"
              onClick={() => {
                if (country.url) navigate(country.url); // Navigate if URL exists
              }}
            >
              <span className={`fi fi-${country.flag} flag-icon`}></span>
              <span className="flag-name">{country.people}</span>
            </button>
          ))}
        </div>
      </div>

      {/* Horizontal Line */}
      <hr className="divider" />

      {/* All Countries List */}
      <div>
        <h5 className="section-heading">Communities</h5>
        <div className="country-list">
          {countries.map((country, index) => (
            <button
              key={index}
              className="flag-button"
              onClick={() => {
                if (country.url) navigate(country.url); // Navigate if URL exists
              }}
            >
              <span className={`fi fi-${country.flag} flag-icon`}></span>
              <span className="flag-name">{country.people}</span>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CommunityConnect;
