import React, { useState, useEffect } from "react";
import logo from "../images/rigoplantlogo.png";
import adGoOurHub from "../images/ad_goourhub.jpg"; // <-- import your ad image
import "./VaduAplu.css";

const images = [
  require("../images/rigoplant1.jpg"),
  require("../images/rigoplant2.jpg"),
  require("../images/rigoplant3.jpg"),
  require("../images/rigoplant4.jpg"),
  require("../images/rigoplant5.jpg"),
  require("../images/rigoplant6.jpg"),
];


function RigoPlant() {
  const [dealIndex, setDealIndex] = useState(0);
  const [fade, setFade] = useState(true);

  const deals = [
    "Get 10% on services when you mention GOOURHUB",
    "Mention GoOurHub to get better price/discounts",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setDealIndex((prev) => (prev + 1) % deals.length);
        setFade(true);
      }, 500);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="vaduaplu-container">
      {/* Header */}
      <header className="vaduaplu-header">
        {/* Left: Logo + Name */}
        <div className="vaduaplu-header-left">
          <img src={logo} alt="Logo" className="vaduaplu-logo" />
          <div className="vaduaplu-header-text">
            <h1 className="vaduaplu-company-name">Rigo's Plant
              
            </h1>
            
          </div>
        </div>

        {/* Right: Phone + Address */}
        <div className="vaduaplu-header-right">
          <span className="vaduaplu-phone">7th St. Traders Village</span>

<span className="vaduaplu-address">
 
    Ph : 726- 217-9146
  
</span>

        </div>
      </header>

      {/* Contact Info */}
      <div className="vaduaplu-contact-info">
  <p className="vaduaplu-text">
    <span>🪴</span> Welcome to <strong>Rigo's Plants</strong> (pronounced: <em>Rigo's Plant</em>)<br />
    <br />
    <br />

    <span>🌱</span> your cozy corner of curated greenery and botanical joy.<br />
    <br/>
    
    <br />
    
  </p>
  <br />
  <p className="vaduaplu-text">
    <span>🌿</span> We are a plant shop that offers a variety of plants, pots, and plant accessories. <br />
  </p>
</div>


      {/* Single Top Box combining Quick Menu & Deals */}
      <div className="vaduaplu-top-box">
        {/* Deals Section */}
        <div className="vaduaplu-top-section">
          <h2 className="vaduaplu-heading">Get Deals</h2>
          {/* Flashing Text */}
          <p className={`vaduaplu-deals-text ${fade ? "fade-in" : "fade-out"}`}>
            {deals[dealIndex]}
          </p>

          {/* Static Image below the deals text */}
          <img
            src={adGoOurHub}
            alt="GoOurHub Ad"
            className="vaduaplu-ad-image"
          />
        </div>

        {/* Quick Menu Section */}
        <div className="vaduaplu-top-section">
          <h2 className="vaduaplu-heading">Our Highlights</h2>
          <div className="vaduaplu-accordion">
            <div className="vaduaplu-accordion-item">
              <input type="checkbox" id="service1" className="hidden-checkbox"defaultChecked />
              <label htmlFor="service1" className="vaduaplu-label" align = "center">
              Our Highlights
              </label>
              <div className="vaduaplu-accordion-content">
              <ul className="vaduaplu-list">
          
  <li className="vaduaplu-list-item">
    🌱  Our most-loved plant starter pack: pothos, snake plant, and a surprise mini succulent.
  </li>
  <br></br>
  <li className="vaduaplu-list-item">
    🌼 Colorful picks like peace lily, bromeliad, or flowering kalanchoe to brighten any space.
  </li>
  <br></br>
  <li className="vaduaplu-list-item">
  🍃  Perfect for beginners: zero-fuss air plants, ZZ plant, and a care guide included.
  </li>
  <br></br>
  <li className="vaduaplu-list-item">
    🪴 Add-Ons: 
    <br />
    🌵 Cactus  | 🌬️ Air Plant  | 🌈 Pot Upgrade  | 💧 Moisture Meter  | 🧴 Leaf Shine Spray 
  </li>
</ul>



              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content / Gallery */}
      <main className="vaduaplu-main-content">
        <h2 className="vaduaplu-heading">Gallery</h2>
        <div className="vaduaplu-gallery">
          {[...Array(6)].map((_, index) => (
            <img
              key={index}
              src={images[index % images.length]}
              alt={`Work Sample ${index + 1}`}
              className="vaduaplu-image"
            />
          ))}
        </div>
      </main>

      {/* Extra Text Section */}
      <div className="vaduaplu-extra-text">
        <p className="vaduaplu-extra-text-p">
       Rigo's Plant
        </p>
      </div>
    </div>
  );
}

export default RigoPlant;
