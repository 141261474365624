import React from "react";
import "./VendorTemplate3.css";
import ReviewSystem from './ReviewSystem';

function VendorTemplate1() {
  return (
    <div className="vendor-template1-container">
      {/* About Section */}
      <div className="about-section">
        {/* Left Panel */}
        <div className="about-left">
          <img
            src={require("../images/african5.jpg")}
            alt="Mystic Soul"
            className="about-image"
          />
          <h2>Mystic Soul</h2>
          <p>African Arts and Crafts</p>
          <button className="email-button">Email Us</button>
          <div className="social-icons">
            <i className="fab fa-instagram"></i>
            <i className="fab fa-tiktok"></i>
            <i className="fab fa-facebook"></i>
            <i className="fab fa-linkedin"></i>
          </div>
        </div>
        <div>
    <h1>Vendor Name</h1>
    <p>Description of the vendor's services...</p>
    <ReviewSystem />
  </div>
        {/* Right Panel */}
        <div className="about-right">
          <h2>About Us</h2>
          <div className="product-gallery">
            <img src={require("../images/african1.jpg")} alt="Product 1" />
            <img src={require("../images/african2.jpg")} alt="Product 2" />
            <img src={require("../images/african3.jpg")} alt="Product 3" />
            <img src={require("../images/african4.jpg")} alt="Product 4" />
          </div>
          <button className="catalog-button">Product Catalog</button>
        </div>
      </div>
    </div>
    
  );
}

export default VendorTemplate1;
