import React, { useState, useEffect } from "react";
import logo from "../images/fevenLogo.jpg";
import adGoOurHub from "../images/ad_goourhub.jpg"; // <-- import your ad image
import "./VaduAplu.css";

const images = [
  require("../images/feven1.jpg"),
  require("../images/feven2.jpg"),
  require("../images/feven3.jpg"),
  require("../images/feven4.jpg"),
  require("../images/feven5.jpg"),
  require("../images/feven6.jpg"),
];


function FevenRealtor() {
  const [dealIndex, setDealIndex] = useState(0);
  const [fade, setFade] = useState(true);

  const deals = [
   
    "Mention GoOurHub to get better price/discounts",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setDealIndex((prev) => (prev + 1) % deals.length);
        setFade(true);
      }, 500);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="vaduaplu-container">
      {/* Header */}
      <header className="vaduaplu-header">
        {/* Left: Logo + Name */}
        <div className="vaduaplu-header-left">
          <img src={logo} alt="Logo" className="vaduaplu-logo" />
          <div className="vaduaplu-header-text">
            <h1 className="vaduaplu-company-name">Feven Tadesse</h1> 
              
          
            
          </div>
        </div>

        {/* Right: Phone + Address */}
        <div className="vaduaplu-header-right">
          <span className="vaduaplu-phone">TX Realtor®</span>
          📍 Office: 12042 Blanco Rd, Suite #102, San Antonio, TX 78216<br/>
          <br/>
          📞 Call or Text: <a className="a-links-vaduaplu" href="tel:+12103069801">(210) 306-9801</a><br />
    📧 Email: <a className="a-links-vaduaplu" href="mailto:feventadesse1234@gmail.com">feventadesse1234@gmail.com</a><br />
    🌐 Website: <a className="a-links-vaduaplu"href="https://feventadesse.com" target="_blank" rel="noopener noreferrer">feventadesse.com</a>
       

<span className="vaduaplu-address">
  
</span>

        </div>
      </header>

      {/* Contact Info */}
      <div className="vaduaplu-contact-info">
  <p className="vaduaplu-text">
    <br />
    <br />
    <br />

    🏡 <strong></strong> – Helping you unlock doors and find your dream property in the heart of San Antonio!

    <br />
    Feven Tadesse is Originally from Addis Ababa, Ethiopia. Fluent in English
and Amharic, proudly calls Texas ''home''. Feven prides herself on
helping others achieve the dream of home ownership. As your real estate
agent, Feven will be your advocate for you. Whether you are buying,
selling, or renting, she will work tirelessly with her skills and knowledge
in real estate she has acclimated herself the San Antonio market. In her
spare time Feven enjoys making memories with her 6 year old son Ezana,
and family.

    
    
  </p>
 

<br />
<br />
 


      {/* Single Top Box combining Quick Menu & Deals */}
      <div className="vaduaplu-top-box">
        {/* Deals Section */}
        <div className="vaduaplu-top-section">
          <h2 className="vaduaplu-heading">Get Deals</h2>
          {/* Flashing Text */}
          <p className={`vaduaplu-deals-text ${fade ? "fade-in" : "fade-out"}`}>
            {deals[dealIndex]}
          </p>

          {/* Static Image below the deals text */}
          <img
            src={adGoOurHub}
            alt="GoOurHub Ad"
            className="vaduaplu-ad-image"
          />
        </div>

        {/* Quick Menu Section */}
        <div className="vaduaplu-top-section">
          <h2 className="vaduaplu-heading">Our Highlights</h2>
          <div className="vaduaplu-accordion">
            <div className="vaduaplu-accordion-item">
              <input type="checkbox" id="service1" className="hidden-checkbox"defaultChecked />
              <label htmlFor="service1" className="vaduaplu-label" align = "center">
              Our Highlights
              </label>
              <ul className="vaduaplu-list">
  <li className="vaduaplu-list-item">
  <br/>
  <br/>

  </li>

  <li className="vaduaplu-list-item">
    🔑 Residential & Commercial Properties<br />
    🏠 First-Time Homebuyers Welcome<br />
    💼 Leasing | Selling | Buying | Investing<br />
    💰 VA & FHA Loans | Buyer Incentives | Low or Zero Closing Costs
  </li>
<br/>
<br/>
  <li className="vaduaplu-list-item">
    📍 Office: 12042 Blanco Rd, Suite #102, San Antonio, TX 78216
  </li>
  <br/>
  <br/>
  <li className="vaduaplu-list-item">
    📞 Call or Text: <a className="a-links-vaduaplu" href="tel:+12103069801">(210) 306-9801</a><br />
    📧 Email: <a className="a-links-vaduaplu" href="mailto:feventadesse1234@gmail.com">feventadesse1234@gmail.com</a><br />
    🌐 Website: <a className="a-links-vaduaplu"href="https://feventadesse.com" target="_blank" rel="noopener noreferrer">feventadesse.com</a>
  </li>
  <br/>
  <br/>
  <li className="vaduaplu-list-item">
    📸 Instagram: <a className="a-links-vaduaplu" href="https://www.instagram.com/feventherealtor/" target="_blank" rel="noopener noreferrer">@feventherealtor</a><br />
    📘 Facebook: <a className="a-links-vaduaplu"href="https://www.facebook.com/profile.php?id=100057304528067" target="_blank" rel="noopener noreferrer">Feven Tadesse, Realtor®</a>
  </li>
  <br/>
  <br/>
  <li className="vaduaplu-list-item">
    🕒 Availability: <strong>Always Open</strong><br />
    💲 Price Range: <strong>$$</strong>
  </li>
</ul>


              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content / Gallery */}
      <main className="vaduaplu-main-content">
        <h2 className="vaduaplu-heading">Gallery</h2>
        <div className="vaduaplu-gallery">
          {[...Array(6)].map((_, index) => (
            <img
              key={index}
              src={images[index % images.length]}
              alt={`Work Sample ${index + 1}`}
              className="vaduaplu-image"
            />
          ))}
        </div>
      </main>

      {/* Extra Text Section */}
      <div className="vaduaplu-extra-text">
        <p className="vaduaplu-extra-text-p">
      Feven Tadesse, Realtor®
        </p>
      </div>
    </div>
  );
}

export default FevenRealtor;
