import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './VisitResturants.css';
import CouponBanner from './CouponBanner';


// Define your three categories with names, icons, and URLs
const categories = [
   
  {
    "name": "Allgood Electric",
    "icon": "🔌",
    "specialties": ["Residential Electrical Installation", "Repair Services"],
    "address": "12106 Valliant St, San Antonio, TX 78216",
    "phone": "(210) 405-8207",
    "url": "https://www.allgoodelectric.com/"
  },
 
  {
    "name": "Bolt Electric",
    "icon": "⚡",
    "specialties": ["Residential and Commercial Electrical Services", "Repairs"],
    "address": "13106 Lookout Way, San Antonio, TX 78233",
    "phone": "(210) 545-2658",
    "url": "https://www.boltelectricsa.com/"
  },
  {
    "name": "Davila Electric Co Inc",
    "icon": "💡",
    "specialties": ["24-Hour Emergency Services", "Residential and Commercial Electrical Installation and Repair"],
    "address": "1842 Bandera Rd, San Antonio, TX 78228",
    "phone": "(210) 436-1551",
    "url": "https://www.davilaelectric.com/"
  },
  {
    "name": "JW's Electrical & Handyman Services",
    "icon": "🔧",
    "specialties": ["Electrical Services", "General Handyman Tasks"],
    "address": "San Antonio, TX 78242",
    "phone": "(210) 540-7946",
    "url": "https://www.yelp.com/biz/jws-electrical-and-handyman-services-san-antonio"
  },
  {
    "name": "Jon Wayne Service Company",
    "icon": "🛠️",
    "specialties": ["Electrical Repairs", "Maintenance", "Installations"],
    "address": "9272 US Highway 87 E, San Antonio, TX 78263",
    "phone": "(210) 293-6700",
    "url": "https://jonwayne.com/electrical"
  },
  {
    "name": "Mr. Handyman of North San Antonio and Stone Oak",
    "icon": "🔨",
    "specialties": ["Electrical Work", "Drywall Repair", "Carpentry"],
    "address": "San Antonio, TX",
    "phone": "(210) 899-6260",
    "url": "https://www.mrhandyman.com/stone-oak-garden-ridge-bulverde/"
  },
  {
    "name": "Diamondback AC, Heating & Refrigeration",
    "icon": "⚡",
    "specialties": ["Electrical Services", "Lighting", "Outlets", "Emergency Generators"],
    "address": "San Antonio, TX",
    "phone": "(210) 409-7271",
    "url": "https://diamondbackelectrical.com/"
  },
  {
    "name": "Will Fix It",
    "icon": "🔌",
    "specialties": ["Residential, Commercial, and Industrial Electrical Services"],
    "address": "1920 Grandstand Dr, San Antonio, TX 78238",
    "phone": "(210) 333-3300",
    "url": "https://www.willfixit.com/electrical-services"
  },
  {
    "name": "Alfonso's Electric/Handy Man",
    "icon": "🔧",
    "specialties": ["Home Theater System Installation", "Electrical Services"],
    "address": "San Antonio, TX",
    "phone": "(210) 123-4567",
    "url": "https://www.thumbtack.com/tx/san-antonio/tv-wall-mount-install/alfonsos-electric-handy-man/service/308614437032681589"
  },
  {
    name: "All Star Electric",
    icon: "⚡",
    specialties: ["Residential Electrical Services", "Commercial Electrical Services"],
    address: "San Antonio, TX",
    phone: "(210) 391-0274",
    url: "https://www.allstarelectric-sa.com/",
    external: true
  },
  {
    name: "Electrimax",
    icon: "🔌",
    specialties: ["Electrical Repairs", "Installations", "Maintenance"],
    address: "San Antonio, TX",
    phone: "(210) 900-4083",
    url: "https://www.electrimax.com/",
    external: true
  },
  {
    name: "True Electric Services",
    icon: "💡",
    specialties: ["Residential Electrical Services", "Commercial Electrical Services"],
    address: "San Antonio, TX",
    phone: "(210) 607-4000",
    url: "https://www.trueelectricservices.com/",
    external: true
  },
  {
    name: "Dunn Honest Electric",
    icon: "🔧",
    specialties: ["Electrical Repairs", "Installations", "Upgrades"],
    address: "San Antonio, TX",
    phone: "(210) 255-3086",
    url: "https://www.dunnhonestelectric.com/",
    external: true
  },
  {
    name: "Ohm's Electric",
    icon: "⚡",
    specialties: ["Residential Electrical Services", "Commercial Electrical Services"],
    address: "San Antonio, TX",
    phone: "(210) 789-2973",
    url: "https://www.ohmselectric.com/",
    external: true
  },
  {
    name: "Fan Handyman",
    icon: "🔨",
    specialties: ["Ceiling Fan Installation", "Light Fixture Installation", "General Electrical Repairs"],
    address: "San Antonio, TX",
    phone: "(210) 123-4567",
    url: "https://www.fanhandyman.com/",
    external: true
  },
  {
    name: "DC Tile & Paint Plus Handyman Services",
    icon: "🛠️",
    specialties: ["Electrical Services", "Tile Work", "Painting"],
    address: "San Antonio, TX",
    phone: "(210) 987-6543",
    url: "https://www.dctileandpaint.com/",
    external: true
  },
  {
    name: "John Wayne Service Company",
    icon: "🔧",
    specialties: ["Electrical Repairs", "Maintenance", "Installations"],
    address: "9272 US Highway 87 E, San Antonio, TX 78263",
    phone: "(210) 293-6700",
    url: "https://jonwayne.com/electrical",
    external: true
  },
  {
    name: "Will Fix It",
    icon: "🔌",
    specialties: ["Residential, Commercial, and Industrial Electrical Services"],
    address: "1920 Grandstand Dr, San Antonio, TX 78238",
    phone: "(210) 333-3300",
    url: "https://www.willfixit.com/electrical-services",
    external: true
  },
  {
    name: "Diamondback AC, Heating & Refrigeration",
    icon: "⚡",
    specialties: ["Electrical Services", "Lighting", "Outlets", "Emergency Generators"],
    address: "San Antonio, TX",
    phone: "(210) 409-7271",
    url: "https://diamondbackelectrical.com/",
    external: true
  },
];

function Electrical() {
  const [selected, setSelected] = useState(null);
  const navigate = useNavigate();

  const handleTileClick = (category) => {
    if (category.url?.startsWith('/')) {
      navigate(category.url); // Internal route
    } else {
      setSelected(category); // Show popup for external or missing URL
    }
  };
  

  const closePopup = () => setSelected(null);

  return (
    <div className="categories-container">
      <CouponBanner />
      <div className="new-tile-container-new">
        {categories.map((category, index) => (
          <div
          key={index}
          className={`new-tile ${category.url ? 'tile-clickable' : 'tile-informational'}`}
          onClick={() => handleTileClick(category)}
        >
          <span className="icon-for-new-tile">{category.icon}</span>
          
          <span className="tile-name">
            {category.name}
            {(!category.url || category.url.startsWith('http')) && (
              <span className="info-badge" title="Unverified">ⓘ</span>
            )}
          </span>
        </div>
        
        ))}
      </div>

      <div className="back-home-tile">
        <Link to="/" className="back-home-link">Go Back to Homepage</Link>
      </div>

      {/* Popup Box */}
      {selected && (
  <div className="popup-overlay" onClick={closePopup}>
    <div className="popup-box" onClick={(e) => e.stopPropagation()}>
      <h3>{selected.name}</h3>
      <p><strong>Address:</strong> {selected.address}</p>
      <p><strong>Phone:</strong> {selected.phone}</p>

      {selected.url && selected.url.startsWith('http') && (
  <p>
    <a
      href={selected.url}
      target="_blank"
      rel="noopener noreferrer"
      className="business-website-link"
    >
      {selected.url
        .replace(/^https?:\/\//, '') 
        .replace(/\/$/, '')}         
    </a>
  </p>
)}


      <button onClick={closePopup}>Close</button>

      {selected.name  && (
        <div className="extra-links">
          <Link to="/request-form" className="popup-link">Claim your business</Link> <span> / </span>
          <Link to="/request-form" className="popup-link">Opt out</Link> <span> / </span>
          <Link to="/request-form" className="popup-link">Suggest update</Link>
        </div>
      )}
      <hr className="popup-divider" />

<p className="disclaimer-text">
Disclaimer: This listing has not been claimed or verified by the business owner.
Information provided is based on publicly available sources.
</p>

    </div>
  </div>
)}

    </div>
  );
}


export default Electrical;
