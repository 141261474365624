import React, { useState } from 'react';
import { Volume2 } from 'lucide-react';

const TextToSpeech = ({ text }) => {
  const [isSpeaking, setIsSpeaking] = useState(false);

  const handleSpeak = () => {
    if (!window.speechSynthesis) {
      alert('Text-to-Speech not supported in this browser.');
      return;
    }

    const utterance = new SpeechSynthesisUtterance(text);
    utterance.onstart = () => setIsSpeaking(true);
    utterance.onend = () => setIsSpeaking(false);

    speechSynthesis.speak(utterance);
  };

  const stopSpeaking = () => {
    speechSynthesis.cancel();
    setIsSpeaking(false);
  };

  return (
    <div className="flex gap-4 items-center">
      <button 
        onClick={handleSpeak} 
        disabled={isSpeaking} 
        className="bg-purple-600 text-white px-6 py-3 text-lg rounded-2xl flex items-center gap-2 hover:bg-purple-700 disabled:opacity-50"
      >
        <Volume2 size={20} />
        {isSpeaking ? 'Speaking...' : 'Read Aloud'}
      </button>
      {isSpeaking && (
        <button 
          onClick={stopSpeaking} 
          className="bg-red-500 text-white px-4 py-2 text-lg rounded-lg hover:bg-red-600"
        >
          Stop
        </button>
      )}
    </div>
  );
};

export default TextToSpeech;

