import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../logo.png";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import emailjs from "@emailjs/browser";
import "./ThankYouPartner.css";

// Helper to format numeric counter as XXX-XXX-XXXX
function formatVendorID(num) {
  const str = num.toString().padStart(10, "0");
  return str.slice(0, 3) + "-" + str.slice(3, 6) + "-" + str.slice(6);
}

const ThankYouPartner = () => {
  const contentRef = useRef(null);

  // Local state
  const [firstName, setFirstName] = useState("");
  const [vendorID, setVendorID] = useState("");
  const [currentDate, setCurrentDate] = useState("");
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);

  useEffect(() => {
    // 1) Set today's date
    const today = new Date();
    const formattedDate = today.toLocaleDateString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    setCurrentDate(formattedDate);

    // 2) Retrieve pending signup data from localStorage
    const dataString = localStorage.getItem("pendingVendorSignup");
    if (!dataString) {
      // If no data found, user might have refreshed or not come from Stripe
      setNoData(true);
      setLoading(false);
      return;
    }

    const data = JSON.parse(dataString);
    setFirstName(data.firstName || "Partner");

    // 3) Generate the new Vendor ID only now (after payment success)
    const savedCounter = localStorage.getItem("userCounter");
    let currentCounter = savedCounter ? parseInt(savedCounter) : 1111111111;
    const newVendorID = formatVendorID(currentCounter);
    setVendorID(newVendorID);

    // 4) Prepare EmailJS parameters
    const emailParams = {
      firstName: data.firstName,
      lastName: data.lastName,
      businessName: data.businessName,
      businessType:
      (data.businessType === "others" || data.businessType === "multiple")
          ? data.otherBusinessType
          : data.businessType,
      email: data.email,
      phone: data.phone,
      phoneType: data.phoneType,
      preferredContact: data.preferredContact
        ? data.preferredContact.join(", ")
        : "",
      startDate: data.startDate,
      couponCode: data.couponCode,
      promoPrice: data.promoOffer
        ? data.promoOffer === "FREE"
          ? "Free"
          : `$${data.promoOffer}`
        : "",
      userID: `${data.businessName}_${newVendorID}`,
    };

    // 5) Send email to admin
    emailjs
      .send("service_wcb9p0d", "template_1716vkm", emailParams, "4L6c5XHTR-ND4i4-d")
      .then(() => console.log("Admin email sent successfully."))
      .catch((error) => console.error("Error sending admin email:", error));

    // 6) Send confirmation email to user
    emailjs
      .send("service_wcb9p0d", "template_xt8g4fq", emailParams, "4L6c5XHTR-ND4i4-d")
      .then(() => console.log("User confirmation email sent successfully."))
      .catch((error) => console.error("Error sending user email:", error))
      .finally(() => {
        // 7) Increment counter for the next user
        localStorage.setItem("userCounter", (currentCounter + 1).toString());

        // 8) Clear the pending data
        localStorage.removeItem("pendingVendorSignup");

        // Done
        setLoading(false);
      });
  }, []);

  // PDF Generation (unchanged)
  const generatePDF = () => {
    const input = contentRef.current;
    const buttons = document.querySelectorAll(".pdf-hide");
    buttons.forEach((btn) => (btn.style.display = "none"));

    html2canvas(input, {
      scale: 3,
      windowWidth: document.documentElement.offsetWidth,
      windowHeight: document.documentElement.scrollHeight,
      backgroundColor: null,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);

      pdf.save(`Thank_You_${firstName}.pdf`);
      buttons.forEach((btn) => (btn.style.display = "block"));
    });
  };

  // Show a loading state while we finish sending emails & generating ID
  if (loading) {
    return (
      <div className="thank-you-page">
        <p>Processing your payment details, please wait...</p>
      </div>
    );
  }

  // If no data was found, user might have navigated here directly
  if (noData) {
    return (
      <div className="thank-you-page">
        <h2>No Signup Data Found</h2>
        <p>
          If you just completed a payment and reached this page, please contact
          us for assistance.
        </p>
        <Link to="/" className="neon-home-button pdf-hide">
          Back to Home
        </Link>
      </div>
    );
  }

  // Normal "Thank You" content
  return (
    <div className="thank-you-page">
      <button className="neon-button pdf-hide" onClick={generatePDF}>
        Download as PDF
      </button>

      <div ref={contentRef} className="thank-you-content">
        <div className="thank-you-logo">
          <img src={logo} alt="GoOurHub Logo" />
          <p className="thank-you-date">{currentDate}</p>
        </div>

        <h1 className="thank-you-title">
          Thank You, {firstName.charAt(0).toUpperCase() + firstName.slice(1)}!
        </h1>
        <h2 className="vendor-id">Your Vendor ID: {vendorID}</h2>

        <p>
          We are thrilled that you have signed up. Welcome to{" "}
          <strong>GoOurHub</strong>. Your application has been submitted.
        </p>
        <p>
          We appreciate your business. Together we will create{" "}
          <strong>amazing opportunities</strong>.
        </p>
        <p>
          If you have any questions, feel free to{" "}
          <Link to="/contact" className="thank-you-contact">
            contact us
          </Link>
          .
        </p>

        <Link to="/" className="neon-home-button pdf-hide">
          Back to Home
        </Link>
      </div>
    </div>
  );
};

export default ThankYouPartner;
