import React, { useState } from 'react';
import './ContactUs.css';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    contactName: '',
    contactEmail: '',
    contactMessage: '',
    attachment: null
  });
  const [charCount, setCharCount] = useState(0);
  const [isSending, setIsSending] = useState(false);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'attachment') {
      setFormData({ ...formData, attachment: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
      if (name === 'contactMessage') {
        setCharCount(value.length);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSending(true);

    const form = new FormData();
    form.append("contactName", formData.contactName);
    form.append("contactEmail", formData.contactEmail);
    form.append("contactMessage", formData.contactMessage);
    if (formData.attachment) {
      form.append("attachment", formData.attachment);
    }

    try {
      const res = await fetch("https://goourhub.com/send.php", {
        method: "POST",
        body: form
      });

      const text = await res.text();
      if (res.ok || text.toLowerCase().includes("success")) {
        alert("✅ Message sent successfully!");
        setFormData({
          contactName: '',
          contactEmail: '',
          contactMessage: '',
          attachment: null
        });
        setCharCount(0);
      } else {
        console.error("Server response:", text);
        alert("⚠️ Message may have sent, but something seems off.");
      }
    } catch (err) {
      console.error("Error:", err);
      alert("❌ Something went wrong. Please try again later.");
    }

    setIsSending(false);
  };

  return (
    <div className="contact-container">
      <div className="contact-header">
        <h2>Reach Out to Us ✉️</h2>
      </div>
      <form className="contact-form" onSubmit={handleSubmit}>
        <input
          type="text"
          name="contactName"
          placeholder="Your Name"
          value={formData.contactName}
          onChange={handleChange}
          required
        />
        <input
          type="email"
          name="contactEmail"
          placeholder="Your Email"
          value={formData.contactEmail}
          onChange={handleChange}
          required
        />
        <textarea
          name="contactMessage"
          placeholder="Your Message"
          rows="4"
          maxLength="500"
          value={formData.contactMessage}
          onChange={handleChange}
          required
        ></textarea>
        <div className="char-count">{charCount}/500</div>
        <input
          type="file"
          name="attachment"
          accept=".jpg,.jpeg,.png,.pdf,.docx"
          onChange={handleChange}
        />
        <button type="submit" disabled={isSending}>
          {isSending ? "Sending..." : "Send Message"}
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
