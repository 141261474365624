// CouponBanner.jsx
import React, { useEffect, useState } from 'react';
import './CouponBanner.css';
import { Link } from 'react-router-dom';

const coupons = [
  {
    text: '🌮 10% off at African Village - Mention GoOurHub',
    bgColor: '#ffe8cc',
  },
  {
    text: '🥙 Buy 2 Meals , Get 1 Free at Un Gwan Gway ',
    bgColor: '#e6f4ea',
  },
  {
    text: '🚚 Free delivery on food trucks , when you refer a friend to signup',
    bgColor: '#f0e8ff',
  },
  {
    text: 'contact@goourhub.com for exclusive deals',
    bgColor: '#fff0f0',
  },
];

const CouponBanner = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const rotate = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % coupons.length);
    }, 5000);
    return () => clearInterval(rotate);
  }, []);

  const { text, bgColor } = coupons[currentIndex];

  const formatDealText = (text) => {
    const parts = text.split(/(signup|refer|contact@goourhub\.com)/g);

    return parts.map((part, idx) => {
      if (part === 'signup') {
        return (
          <Link to="/partner-signup" key={idx} className="promo-link">
            {part}
          </Link>
        );
      } else if (part === 'refer') {
        return (
          <Link to="/refer" key={idx} className="promo-link">
            {part}
          </Link>
        );
      } else if (part === 'contact@goourhub.com') {
        return (
          <Link to="/contact" key={idx} className="promo-link">
            {part}
          </Link>
        );
      } else {
        return <span key={idx}>{part}</span>;
      }
    });
  };

  return (
    <div className="coupon-banner" style={{ backgroundColor: bgColor }}>
      <p className="coupon-text">{formatDealText(text)}</p>
    </div>
  );
};

export default CouponBanner;
