import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './VisitResturants.css';
import CouponBanner from './CouponBanner';

// 🗂️ Split data by type
const data = {
  restaurants: [
    {
      name: "Mi Tierra Café y Panadería",
      icon: "🍽️",
      address: "218 Produce Row, San Antonio, TX 78207",
      phone: "(210) 225-1262",
      url: "https://lafamiliacortez.com/restaurants/mi-tierra/"
    },
    {
      name: "Rosario's Mexican Cafe Y Cantina",
      icon: "🍽️",
      address: "910 S Alamo St, San Antonio, TX 78205",
      phone: "(210) 223-1806",
      url: "https://rosariossa.com/"
    },
    {
      name: "La Fonda on Main",
      icon: "🍽️",
      address: "2415 N Main Ave, San Antonio, TX 78212",
      phone: "(210) 733-0621",
      url: "https://www.lafondaonmain.com/"
    },
    {
      name: "Los Barrios Mexican Restaurant",
      icon: "🍽️",
      address: "4223 Blanco Rd, San Antonio, TX 78212",
      phone: "(210) 732-6017",
      url: "https://www.losbarriosrestaurant.com/"
    }
  ],
  groceries: [
    {
      name: "La Fiesta Supermarket",
      icon: "🛒",
      address: "123 Grocery St, San Antonio, TX 78207",
      phone: "(210) 111-2222",
      url: "https://example.com/la-fiesta"
    },
    {
      name: "El Mercado Mexicano",
      icon: "🛒",
      address: "456 Fiesta Blvd, San Antonio, TX 78207",
      phone: "(210) 222-3333",
      url: "https://example.com/el-mercado"
    },

    {
      name: "La Michoacana Meat Market",
      icon: "🛒",
      address: "Various locations",
      phone: "(210) 123-4567",
      url: "https://lamichoacanameatmarket.com/"
    },
    {
      name: "El Rancho Supermercado",
      icon: "🛒",
      address: "3727 Culebra Rd, San Antonio, TX 78228",
      phone: "(210) 433-0800",
      url: "https://www.elranchoinc.com/"
    },
  ],
  foodTrucks: [
    {
      name: "Tacos El Gordo Truck",
      icon: "🚚",
      address: "789 Taco Lane, San Antonio, TX 78207",
      phone: "(210) 444-5555",
      url: "https://example.com/el-gordo-truck"
    },
    {
      name: "Los Tacos Hermanos",
      icon: "🚚",
      address: "888 Al Pastor Ave, San Antonio, TX 78208",
      phone: "(210) 666-7777",
      url: "https://example.com/tacos-hermanos"
    },

    {
      name: "Tacos El Regio",
      icon: "🚚",
      address: "Multiple mobile locations",
      phone: "(210) 555-7890",
      url: "https://www.instagram.com/tacoselregiosa/"
    },
    {
      name: "La Gloria Ice House (Food Truck)",
      icon: "🚚",
      address: "100 E Grayson St, San Antonio, TX 78215",
      phone: "(210) 267-9040",
      url: "https://chefjohnnyhernandez.com/la-gloria/"
    }
  ]
};

// 🔁 Section builder component
const CategorySection = ({ title, items, onTileClick }) => (
  <div className="category-section">
    <h2 className="category-header">{title}</h2>
    <div className="new-tile-container-new">
      {items.map((item, index) => (
        <div
          key={index}
          className={`new-tile ${item.url ? 'tile-clickable' : 'tile-informational'}`}
          onClick={() => onTileClick(item)}
        >
          <span className="icon-for-new-tile">{item.icon}</span>
          <span className="tile-name">
            {item.name}
            {(!item.url || item.url.startsWith('http')) && (
              <span className="info-badge" title="Unverified">ⓘ</span>
            )}
          </span>
        </div>
      ))}
    </div>
  </div>
);

// 🧠 Main Component
function Mexican() {
  const [selected, setSelected] = useState(null);
  const navigate = useNavigate();

  const handleTileClick = (item) => {
    if (item.url?.startsWith('/')) {
      navigate(item.url);
    } else {
      setSelected(item);
    }
  };

  const closePopup = () => setSelected(null);

  return (
    <div className="categories-container">
      <CouponBanner />
      <CategorySection title="Restaurants" items={data.restaurants} onTileClick={handleTileClick} />
      <CategorySection title="Grocery Stores" items={data.groceries} onTileClick={handleTileClick} />
      <CategorySection title="Food Trucks" items={data.foodTrucks} onTileClick={handleTileClick} />

      <div className="back-home-tile">
        <Link to="/" className="back-home-link">Go Back to Homepage</Link>
      </div>

      {selected && (
        <div className="popup-overlay" onClick={closePopup}>
          <div className="popup-box" onClick={(e) => e.stopPropagation()}>
            <h3>{selected.name}</h3>
            <p><strong>Address:</strong> {selected.address}</p>
            <p><strong>Phone:</strong> {selected.phone}</p>

            {selected.url && selected.url.startsWith('http') && (
              <p>
                <a
                  href={selected.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="business-website-link"
                >
                  {selected.url.replace(/^https?:\/\//, '').replace(/\/$/, '')}
                </a>
              </p>
            )}

            <button onClick={closePopup}>Close</button>

            <hr className="popup-divider" />
            <p className="disclaimer-text">
              Disclaimer: This listing has not been claimed or verified by the business owner.
              Information provided is based on publicly available sources.
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Mexican;
