import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './VisitResturants.css';
import CouponBanner from './CouponBanner';


// Define your three categories with names, icons, and URLs
const categories = [
   
    {
        name: "San Antonio Plumbing",
        icon: "🔧",
        specialties: ["Plumbing Repairs", "Maintenance", "Drain Cleaning"],
        address: "San Antonio, TX",
        phone: "(210) 490-7910",
        url: "https://saplumbing.net/",
        external: true
      },
      {
        name: "Will Fix It",
        icon: "🔧",
        specialties: ["Plumbing Repairs", "Installations", "Emergency Services"],
        address: "3535 Metro Pkwy, San Antonio, TX 78247",
        phone: "(210) 247-2752",
        url: "https://www.willfixit.com/plumbing-service",
        external: true
      },
      {
        name: "Beyer Plumbing",
        icon: "🔧",
        specialties: ["Residential Plumbing", "Inspections", "Repairs"],
        address: "17197 Beyer John Drive, Selma, TX 78154",
        phone: "(210) 809-6355",
        url: "https://beyerplumbing.com/residential-plumbing/",
        external: true
      },
      {
        name: "Jon Wayne Service Company",
        icon: "🔧",
        specialties: ["Plumbing Services", "Piping Efficiency", "Repairs"],
        address: "9272 US Highway 87 E, San Antonio, TX 78263",
        phone: "(210) 293-6700",
        url: "https://jonwayne.com/plumbing",
        external: true
      },
      {
        name: "J. R.'s Plumbing",
        icon: "🔧",
        specialties: ["Residential Plumbing", "Commercial Plumbing", "Repairs"],
        address: "San Antonio, TX",
        phone: "(210) 731-8433",
        url: "https://www.jrsplumbing.net/",
        external: true
      },
      {
        name: "Chambliss Plumbing Company",
        icon: "🔧",
        specialties: ["Installations", "Drain Cleaning", "Leak Detection"],
        address: "1875 E Borgfeld Dr, Timberwood Park, TX 78260",
        phone: "(210) 490-7910",
        url: "https://chamblissplumbing.com/",
        external: true
      },
      {
        name: "Anchor Plumbing Services",
        icon: "🔧",
        specialties: ["Drain Cleaning", "Sewer Line Repairs", "Water Treatment"],
        address: "San Antonio, TX",
        phone: "(210) 843-5800",
        url: "https://anchorplumbingservices.com/",
        external: true
      },
      {
        name: "Reliant Plumbing",
        icon: "🔧",
        specialties: ["Plumbing Services", "Repairs", "Maintenance"],
        address: "San Antonio, TX",
        phone: "(512) 222-6029",
        url: "https://reliantplumbing.com/areas-we-serve/san-antonio/",
        external: true
      },
      {
        name: "Bluebonnet Plumbing",
        icon: "🔧",
        specialties: ["Hydrostatic Tests", "Toilet Replacement", "General Repairs"],
        address: "San Antonio, TX",
        phone: "(210) 555-1234",
        url: "https://www.bluebonnet-plumbing.com/",
        external: true
      },
      {
        name: "Merritt Plumbing",
        icon: "🔧",
        specialties: ["Plumbing Services", "Repairs", "Installations"],
        address: "San Antonio, TX",
        phone: "(210) 555-5678",
        url: "https://www.merrittplumbingsa.com/",
        external: true
      },

      {
        name: "PlumbSmart",
        icon: "🔧",
        specialties: ["General Plumbing Repairs", "Drain Cleaning", "Water Heater Services"],
        address: "San Antonio, TX",
        phone: "(210) 970-6000",
        url: "https://www.plumbsmart.com/",
        external: true
      },
      {
        name: "Purpose Plumbing",
        icon: "🔧",
        specialties: ["Leak Detection", "Pipe Repair", "Emergency Plumbing Services"],
        address: "San Antonio, TX",
        phone: "(210) 639-1373",
        url: "https://www.purposeplumbingtx.com/",
        external: true
      },
      {
        name: "BG Plumbing",
        icon: "🔧",
        specialties: ["Residential Plumbing", "Fixture Installation", "Sewer Services"],
        address: "San Antonio, TX",
        phone: "(210) 429-5060",
        url: "https://www.bgplumbingtx.com/",
        external: true
      },
      {
        name: "Andre's Pro Plumbing",
        icon: "🔧",
        specialties: ["Plumbing Repairs", "Water Softeners", "Drain Cleaning"],
        address: "San Antonio, TX",
        phone: "(210) 849-7849",
        url: "https://www.andresproplumbing.com/",
        external: true
      },
      {
        name: "Roto-Rooter Plumbing & Water Cleanup",
        icon: "🔧",
        specialties: ["Drain Cleaning", "Water Damage Restoration", "Emergency Services"],
        address: "San Antonio, TX",
        phone: "(210) 810-3111",
        url: "https://www.rotorooter.com/sanantonio/",
        external: true
      },
];

function Plumbing() {
  const [selected, setSelected] = useState(null);
  const navigate = useNavigate();

  const handleTileClick = (category) => {
    if (category.url?.startsWith('/')) {
      navigate(category.url); // Internal route
    } else {
      setSelected(category); // Show popup for external or missing URL
    }
  };
  

  const closePopup = () => setSelected(null);

  return (
    <div className="categories-container">
      <CouponBanner />
      <div className="new-tile-container-new">
        {categories.map((category, index) => (
          <div
          key={index}
          className={`new-tile ${category.url ? 'tile-clickable' : 'tile-informational'}`}
          onClick={() => handleTileClick(category)}
        >
          <span className="icon-for-new-tile">{category.icon}</span>
          
          <span className="tile-name">
            {category.name}
            {(!category.url || category.url.startsWith('http')) && (
              <span className="info-badge" title="Unverified">ⓘ</span>
            )}
          </span>
        </div>
        
        ))}
      </div>

      <div className="back-home-tile">
        <Link to="/" className="back-home-link">Go Back to Homepage</Link>
      </div>

      {/* Popup Box */}
      {selected && (
  <div className="popup-overlay" onClick={closePopup}>
    <div className="popup-box" onClick={(e) => e.stopPropagation()}>
      <h3>{selected.name}</h3>
      <p><strong>Address:</strong> {selected.address}</p>
      <p><strong>Phone:</strong> {selected.phone}</p>

      {selected.url && selected.url.startsWith('http') && (
  <p>
    <a
      href={selected.url}
      target="_blank"
      rel="noopener noreferrer"
      className="business-website-link"
    >
      {selected.url
        .replace(/^https?:\/\//, '') 
        .replace(/\/$/, '')}         
    </a>
  </p>
)}


      <button onClick={closePopup}>Close</button>

      {selected.name  && (
        <div className="extra-links">
          <Link to="/request-form" className="popup-link">Claim your business</Link> <span> / </span>
          <Link to="/request-form" className="popup-link">Opt out</Link> <span> / </span>
          <Link to="/request-form" className="popup-link">Suggest update</Link>
        </div>
      )}
      <hr className="popup-divider" />

<p className="disclaimer-text">
Disclaimer: This listing has not been claimed or verified by the business owner.
Information provided is based on publicly available sources.
</p>

    </div>
  </div>
)}

    </div>
  );
}


export default Plumbing;
